import React, { useState, useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { Pagination } from "@mui/material";
import withStyles from "@material-ui/core/styles/withStyles";
import { Button } from "@mui/material";
import Toast from "components/Toast/Toast.jsx";
import HeaderReturnRequest from "../../components/ReturnRequest/Header";
import Returnrequestbody from "../../components/ReturnRequest/Body";
import AbstractComponent from "../../components/ReturnRequest/abstract";
import NativeSelect from "@mui/material/NativeSelect";
import InputLabel from "@mui/material/InputLabel";
import ReturnRequestController from "controllers/ReturnRequest";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
// import jwtDecode from "jwt-decode";
import UpdatePdf from "../../components/UpdatePdf";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "100px",
    marginLeft: "2%",
    paddingRight: "2%",
    position: "relative",
    maxHeight: "calc(100vh - 100px)", // Ajusta la altura según tus necesidades
    overflowY: "auto",
  },
  stickyElement: {
    position: "sticky",
    top: 1,
    backgroundColor: "white",
    zIndex: 100,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderRadius: 10,
    boxShadow: "2px 2px 10px gray",
    width: "95%",
    marginTop: "20px",
    alignItem: "center",
    justifyContent: "space-between",
    padding: "10px",
    marginLeft: "10px",
  },
  colum1: {},
  colum2: {
    borderRadius: 10,
    // boxShadow: "2px 2px 10px gray",
    backgroundColor: "#F1F1F1",
    padding: "10px",
  },
  select: {
    borderRadius: 10,
    boxShadow: "2px 2px 10px gray",
    backgroundColor: "rgba(231, 231, 231, 0.33)",
    width: "95%",
    marginTop: "20px",
    alignItem: "center",
    justifyContent: "space-between",
    padding: "10px",
    marginLeft: "10px",
  },
  bodyreturnrequest: {
    marginTop: "20px",
    borderRadius: 10,
    boxShadow: "2px 2px 10px gray",
    backgroundColor: "rgba(231, 231, 231, 0.33)",
    width: "95%",
    paddingLeft: "10px",
    paddingTop: "10px",
    marginLeft: "10px",
  },
  txt: {
    fontSize: "14px",
  },
  Line: {
    width: "95%",
    height: "10px",
  },
  "@media (max-Width: 960px)": {
    txt: {
      fontSize: "2vh",
    },
    bodyreturnrequest: {
      fontSize: "0.8vh",
      wordWrap: "breakWord",
    },
    colum2: {
      fontSize: "0.8vh",
    },
    txtResumen: {
      fontSize: "1.5vh",
    },
  },
}));
const ReturnRequest = (props) => {
  const classes = useStyles();

  /*   const [selectShiptorsku, setSelectShiptorsku] = useState("");
  const [selectSkuDesc, setSelectSkuDesc] = useState(""); */

  const {
    handlegetSkus,
    handlegetAllShiptoOrSku,
    observation,
    shiptoandskus,
    setShiptoandskus,
    reasonCancelation,
    setReasonCancelation,
    handleAddSkuShipto,
    returnList,
    setReturnList,
    handleDeleteReturnList,
    showLoader,
    handleChangeQty,
    handleChangeReasonReturn,
    getValueReasonReturn,
    handleChangeWarehouse,
    getValueWarehouse,
    handleChangeTypeReturn,
    getValueTypeReturn,
    handleConfirmReturnOrder,
    setToast,
    handleDescSkus,
    options,
    SetOptions,
    returnreason,
    shearch,
    setShearchItem,
    shearchItem,
    handleChangeObservation,
    showToast,
    pageReturn,
    numberPageReturn,
    setPageReturn,
    valueAutocomplete,
    valueAutocompleteTypeReturn,
    valueAutocompleteReasonReturn,
    valueAutocompleteWarehouse,
    setValueAutocomplete,
    setValueAutocompleteTypeReturn,
    setValueAutocompleteReasonReturn,
    setValueAutocompleteWarehouse,
    selectShiptorsku,
    setSelectShiptorsku,
    selectSkuDesc,
    setSelectSkuDesc,
    selectShiptoDesc,
    setSelectShiptoDesc,
    setTypeReturn2,
    typeReturn2,
    jwtToken,
    setReasonReturn,
    reasonsReturn,
    setWarehouse,
    warehouse,
    selectedPDF,
    setSelectedPDF,
    handleGetMaterialGroup,
    materialGroup,
    materialGroupSelected,
    setMaterialGroupSelected,
  } = ReturnRequestController(props);
  const [request, setRequest] = useState(1);

  const returnType = [
    { label: "Devolución habitual", value: "Devolución habitual" },
    { label: "Devolución certificada", value: "Devolución certificada" },
  ];
  const returnReason = [
    { label: "Exceso", value: "Exceso" },
    { label: "Baja rotación", value: "Baja rotación" },
    { label: "Corta fecha", value: "Corta fecha" },
    {
      label: "Retiro producto de procedimiento",
      value: "Retiro producto de procedimiento",
    },
  ];
  const Warehouse = [
    { label: "Bogota", value: "Bogota" },
    { label: "Medellin", value: "Medellin" },
    { label: "Cali", value: "Cali" },
    {
      label: "Barranquilla",
      value: "Barranquilla",
    },
  ];

  const Warehouse2 = [{ label: "Bogota", value: "Bogota" }];
  // var jwt = jwtDecode(props.userToken);
  // const [valueAutocomplete, setValueAutocomplete] = useState("");
  useEffect(
    async () => {
      if (request == 1) {
        setSelectSkuDesc("");
        setShiptoandskus([]);
        await handlegetSkus(props.userToken, null, null, selectShiptorsku);
        await handlegetAllShiptoOrSku(props.userToken, "sku", "shipto");
      } else if (request == 2) {
        /*  if (!selectShiptorsku) {
          setSelectShiptorsku("");
        } */
        setSelectShiptoDesc("");
        setShiptoandskus([]);

        await handlegetSkus(props.userToken, selectShiptorsku, null, null);
        await handlegetAllShiptoOrSku(props.userToken, "shipto", "sku");
      }
    },
    [shearchItem, request, selectShiptorsku, pageReturn, materialGroupSelected]
  );

  const handleChangePage = (pageReturn) => {
    setPageReturn(pageReturn - 1);
  };

  const handlePDFChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileNameParts = selectedFile.name.split(".");
      const fileExtension = fileNameParts[
        fileNameParts.length - 1
      ].toLowerCase();

      // Verifica que la extensión sea PDF.
      if (fileExtension === "pdf") {
        // Verifica el tamaño del archivo (en bytes).
        if (selectedFile.size <= 4.49 * 1024 * 1024) {
          setSelectedPDF(selectedFile);
        } else {
          alert(
            "El archivo seleccionado es demasiado grande. Por favor, seleccione un archivo PDF de 4.49MB o menos."
          );
          setSelectedPDF(null);
        }
      } else {
        alert("Por favor, seleccione un archivo PDF.");
        setSelectedPDF(null);
      }
    }
  };
  useEffect(async () => {
    await handleGetMaterialGroup(props.userToken);
  }, []);

  useEffect(
    () => {
      setValueAutocomplete(null);
      setValueAutocompleteTypeReturn(null);
      setValueAutocompleteReasonReturn(null);
      setValueAutocompleteWarehouse(null);
      setTypeReturn2(null);
      setReasonReturn(null);
      setWarehouse(null);
    },
    [request]
  );

  useEffect(
    () => {
      if (valueAutocompleteReasonReturn === "Corta fecha") {
        if (valueAutocompleteWarehouse !== "Bogota") {
          setValueAutocompleteWarehouse("Bogota"); // Establecer automáticamente la bodega a Bogotá
          setWarehouse("Bogota");
        }
      }
    },
    [valueAutocompleteReasonReturn]
  );

  // shiptoandskus = shiptoandskus.filter((item) => item.shipto != "");

  return (
    <div>
      <Grid xs={12} container className={classes.container}>
        <Grid xs={8} className={classes.colum1}>
          <Grid xs={12}>
            <HeaderReturnRequest request={request} setRequest={setRequest} />
          </Grid>
          <Grid container xs={12} className={classes.select}>
            {request == 1 ? (
              <Grid xs={12} container>
                <Grid xs={2}>
                  <h8>Familia:</h8>
                </Grid>
                <Grid xs={10}>
                  <Autocomplete
                    options={materialGroup}
                    value={materialGroupSelected}
                    autoHighlight
                    onChange={(e, newValue) => {
                      setMaterialGroupSelected(newValue?.value);
                    }}
                    sx={{
                      width: "100%",
                      "@media (max-Width: 960px)": {
                        width: 100,
                        height: 50,
                        fontSize: "1vh",
                        marginBottom: "3px",
                      },
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Familia" />
                    )}
                  />
                  <br />
                </Grid>
              </Grid>
            ) : null}
            <Grid xs={12} container>
              <Grid xs={2}>
                <h8 className={classes.txt}>
                  {request == 1 ? "Selecciona modelo:" : "Selecciona cliente:"}
                </h8>
              </Grid>
              <Grid xs={10}>
                <Autocomplete
                  options={options}
                  value={valueAutocomplete}
                  disableClearable={true}
                  onChange={(e, newValue) => {
                    setValueAutocomplete(newValue?.label);
                    setPageReturn(0);
                    if (
                      returnList?.length > 0 &&
                      newValue?.value != null &&
                      newValue?.value != returnList[0].model
                    ) {
                      showToast("error", "No sé permite más de un Shipto");
                    }

                    setSelectSkuDesc(newValue?.label);
                    setSelectShiptorsku(newValue?.value);
                    setSelectShiptoDesc(newValue?.label);
                    handleDescSkus(newValue?.label);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{
                    "@media (max-Width: 960px)": {
                      width: 100,
                      height: 50,
                      fontSize: "1vh",
                      marginLeft: "20%",
                    },
                  }}
                />
                <br />
              </Grid>
            </Grid>

            <Grid xs={12} container>
              <Grid xs={2}>
                <h8>Tipo:</h8>
              </Grid>
              <Grid xs={10}>
                <Autocomplete
                  options={returnType}
                  value={valueAutocompleteTypeReturn}
                  disableClearable={true}
                  onChange={(e, newValue) => {
                    setTypeReturn2(newValue.label);
                    setValueAutocompleteTypeReturn(newValue.label);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Tipo de devolución" />
                  )}
                  sx={{
                    width: "100%",
                    "@media (max-Width: 960px)": {
                      width: 100,
                      height: 50,
                      fontSize: "1vh",
                      marginBottom: "3px",
                    },
                  }}
                />
                <br />
              </Grid>
            </Grid>

            <Grid xs={12} container>
              <Grid xs={2}>
                <h8>Mótivo:</h8>
              </Grid>
              <Grid xs={10}>
                <Autocomplete
                  options={returnReason}
                  value={valueAutocompleteReasonReturn}
                  disableClearable={true}
                  onChange={(e, newValue) => {
                    setReasonReturn(newValue.label);
                    setValueAutocompleteReasonReturn(newValue.label);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Mótivo de devolución" />
                  )}
                  sx={{
                    "@media (max-Width: 960px)": {
                      width: 100,
                      height: 50,
                      fontSize: "1vh",
                      marginLeft: "20%",
                    },
                  }}
                />
                <br />
              </Grid>
            </Grid>

            <Grid xs={12} container>
              <Grid xs={2}>
                <h8>Bodega retorno:</h8>
              </Grid>
              <Grid xs={10}>
                <Autocomplete
                  options={
                    reasonsReturn === "Corta fecha" ? Warehouse2 : Warehouse
                  }
                  value={valueAutocompleteWarehouse}
                  disableClearable={true}
                  onChange={(e, newValue) => {
                    setWarehouse(newValue.label);
                    setValueAutocompleteWarehouse(newValue.label);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Envio a Bodega" />
                  )}
                  sx={{
                    "@media (max-Width: 960px)": {
                      width: 100,
                      height: 50,
                      fontSize: "1vh",
                      marginLeft: "20%",
                    },
                  }}
                />
                <br />
              </Grid>
            </Grid>

            <Grid xs={12} container>
              <Grid xs={2}>
                <h8>Observación:</h8>
              </Grid>
              <Grid xs={10}>
                <TextField
                  id="standard-multiline-flexible"
                  label="Observación"
                  multiline
                  inputProps={{ maxLength: 255 }}
                  helperText={`${observation.length}/${255}`}
                  maxRows={4}
                  size="large"
                  value={observation}
                  onChange={handleChangeObservation}
                  sx={{
                    width: "100%",
                  }}
                />
                <br />
              </Grid>
            </Grid>
            <Grid xs={12} container>
              <UpdatePdf pdf={selectedPDF} onChange={handlePDFChange} />
            </Grid>
          </Grid>
          {request == 2 && (
            <Grid xs={12} container className={classes.select}>
              <Grid xs={2}>
                <h8>Buscador:</h8>
              </Grid>
              <Grid xs={10}>
                <Autocomplete
                  value={shearchItem}
                  options={shearch}
                  onChange={(e, newValue) => {
                    setShearchItem(newValue?.value);
                    setPageReturn(0);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Buscador" />
                  )}
                  sx={{}}
                />
                <br />
              </Grid>
            </Grid>
          )}
          {valueAutocomplete && (
            <Grid className={classes.stickyElement}>
              <TextField
                id="outlined-required"
                label={
                  request == 1
                    ? "Mostrando inventario de modelo:"
                    : "Mostrando inventario de cliente:"
                }
                value={valueAutocomplete}
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  width: "100%",
                  "@media (max-Width: 960px)": {
                    width: 100,
                    height: 50,
                    fontSize: "1vh",
                    marginBottom: "3px",
                  },
                }}
              />
            </Grid>
          )}

          <Grid xs={12} className={classes.bodyreturnrequest}>
            {/*     <Grid>
              {request === 1 ? (
                <h8>
                  {" "}
                  Mostrando inventario de modelo:
                  <b>{selectShiptoDesc}</b>
                </h8>
              ) : (
                <h8>
                  {" "}
                  Mostrando inventario de cliente:
                  <b>{selectSkuDesc}</b>
                </h8>
              )}
              <hr className={classes.Line} />
            </Grid> */}
            {shiptoandskus
              ?.filter((item) => item.shipto != "")
              ?.map((item, id) => (
                <div>
                  <Returnrequestbody
                    setShiptoandskus={setShiptoandskus}
                    shiptosorskus={item}
                    index={id}
                    reasonCancelation={reasonCancelation}
                    setReasonCancelation={setReasonCancelation}
                    handleAddSkuShipto={handleAddSkuShipto}
                    handleChangeQty={handleChangeQty}
                    handleChangeReasonReturn={handleChangeReasonReturn}
                    getValueReasonReturn={getValueReasonReturn}
                    handleChangeWarehouse={handleChangeWarehouse}
                    getValueWarehouse={getValueWarehouse}
                    handleChangeTypeReturn={handleChangeTypeReturn}
                    getValueTypeReturn={getValueTypeReturn}
                    request={request}
                    returnreason={returnreason}
                    warehouse={warehouse}
                    reasonsReturn={reasonsReturn}
                  />
                </div>
              ))}
          </Grid>
          {shiptoandskus && (
            <Pagination
              hideNextButton={true}
              hidePrevButton={true}
              onChange={(e) => handleChangePage(e.target.textContent)}
              count={
                parseInt(numberPageReturn) < 10
                  ? 1
                  : parseInt(numberPageReturn) % 10 != 0
                    ? parseInt(numberPageReturn / 10) + 1
                    : parseInt(numberPageReturn / 10)
              }
              page={pageReturn + 1}
            />
          )}
        </Grid>
        <Grid xs={4} className={classes.colum2}>
          <h4 className={classes.txtResumen}>Resumen</h4>
          {returnList?.map((item, index) => (
            <AbstractComponent
              model={item.model}
              serial={item.serial}
              qty={item.qty}
              shipto={item.shipto}
              shiptoName={item.shiptoName}
              batch={item.batch}
              warehouse={item.warehouse}
              deleteCard={handleDeleteReturnList}
              index={index}
            />
          ))}
          {returnList?.length > 0 && jwtToken.groupSlug !== "super-user" ? (
            <Grid xs={12}>
              <Button
                fullWidth
                variant="contained"
                onClick={handleConfirmReturnOrder}
                sx={{
                  "@media (max-Width: 960px)": {
                    fontSize: "1vh",
                  },
                }}
              >
                Confirmar
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Toast
        innerRef={(ref) => {
          setToast(ref);
        }}
      />
    </div>
  );
};

export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
    data: state.root.general.dataUserAPI,
    applicant: state.root.user.userInfo.firstName,
    id: state.root.user.userInfo.memberId,
    address: state.root.user.userInfo.address,
    contactNo: state.root.user.userInfo.contactNo,
  }))(ReturnRequest)
);
