import React, { useState } from "react";
import {
  getOrdersAuthorization,
  updateOrderStatus,
  getidRep,
} from "protocols/requests";

export const OrdersAuthorizationController = (props) => {
  const [ordersAuthorization, setOrdersAuthorization] = useState([]);
  const [toast, setToast] = React.useState(React.createRef());
  const [disable, setDisable] = useState(false);

  const showToast = (type, content) => {
    if (toast) {
      if (type === "success") {
        toast.showSuccess(content);
      } else {
        toast.showError(content);
      }
    }
  };

  const handlegetOrdersAuthorization = async (token, id) => {
    props.setLoaderVisible(true);
    const result = await getOrdersAuthorization(token, id);
    props.setLoaderVisible(false);
    if (result.status === 200) {
      const response = result.response.response;
      setOrdersAuthorization(response);
    }
  };

  const handleUpdateOrder = async (id, status, superuser) => {
    const idRep = await getidRep(props.userToken, props.id);
    props.setLoaderVisible(true);
    const result = await updateOrderStatus(
      id,
      props.userToken,
      status,
      null,
      idRep.response?.response[0]?.benefitting_Rep,
      superuser === true ? props.id : null
    );
    props.setLoaderVisible(false);
    if (result.status == 200) {
      showToast("success", result.response.description);
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } else if (result.status == 404) {
      showToast("error", result.response.description);
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } else {
      showToast("error", result.response.description);
    }
  };

  return {
    ordersAuthorization,
    handlegetOrdersAuthorization,
    handleUpdateOrder,
    setToast,
    setDisable,
    disable,
  };
};
