import React, { useEffect } from "react";
import UserViews from "components/userViews";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//controller
import IncomingProductsController from "controllers/IncomingProductsControllers";
import empty from "../../../assets/img/dashboard/empty.svg";

function IncomingProducts(props) {
  const title = "Incoming Products";
  const {
    products,
    handleChangePage,
    totalPage,
    handlerChangeRecordsPage,
    dataFilter,
    handleGetIncomingProducts,
  } = IncomingProductsController();

  useEffect(async () => {
    if (products.length === 0) props.setLoaderVisible(true);
    if (Object.keys(props.data).length === 0)
      await handleGetIncomingProducts(props.userToken);
    if (products.length === 0) props.setLoaderVisible(false);
  }, []);

  return (
    <>
      {products.length !== 0 || Object.keys(props.data).length !== 0 ? (
        <UserViews
          title={title}
          dataFilter={dataFilter}
          members={
            Object.keys(props.data).length !== 0
              ? props.data.incomingProductsClinic
                ? props.data.incomingProductsClinic.response.orders
                : products
              : products
          }
          handleChangePage={handleChangePage}
          totalPage={totalPage}
          handlerChangeRecordsPage={handlerChangeRecordsPage}
          incomingProducts
          {...props}
        />
      ) : (
        <img
          src={empty}
          alt="Not found data"
          style={{ height: "16rem", margin: "6rem 3rem" }}
        />
      )}
    </>
  );
}

export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
    data: state.root.general.dataUserAPI,
  }))(IncomingProducts)
);
