import React, { useState, useEffect } from "react";
import { Grid, Modal } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Button } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CloseIcon from "@mui/icons-material/Close";
import StepShiptoStyles from "assets/jss/material-dashboard-pro-react/views/StepShiptoStyles";
/**
 * This is a React component that renders a list item for a shipping destination, with the ability to
 * view the customer profile and select or deselect the destination.
 * @returns A functional component called ShiptoItem, which renders a grid containing information about
 * a shipping destination (shipto) and buttons to select or deselect it. It also includes a modal that
 * displays more detailed information about the shipto when a "Profile" button is clicked. The
 * component is exported with styles applied using the withStyles higher-order component.
 */
const ShiptoItem = ({
  classes,
  shipto,
  handleDeleteShipto,
  handleAddShipto,
  auxbuttonsId,
  id,
}) => {
  const OpenCloseModal = () => {
    setModal(!modal);
  };
  const [modal, setModal] = useState(false);
  const body = (
    <Grid className={classes.modal}>
      <Grid className={classes.modalheader}>
        <h5 className={classes.textinfoheader}>Cerrar </h5>
        <CloseIcon
          fontSize="small"
          className={classes.closeicon}
          onClick={() => OpenCloseModal()}
        />
      </Grid>
      <Grid className={classes.containermodal} xs={12} container spacing={2}>
        <Grid xs={12} className={classes.titlemodal}>
          <h8 className={classes.clientprofiletxt}>Perfil de Cliente</h8>
        </Grid>
        <Grid xs={6} className={classes.modalinfo}>
          <Grid xs={12} container className={classes.containerTxtShipto}>
            <h8 className={classes.nameshipto}>{shipto.applicant_name}</h8>
            <h8 className={classes.idshipto}>{shipto.ship_to}</h8>
          </Grid>
          <Grid className={classes.modalinfochildren}>
            <LocationOnOutlinedIcon fontSize="small" />
            <h5 className={classes.textinfo}>{shipto.direction}</h5>
          </Grid>
        </Grid>

        <Grid xs={12} className={classes.buttonModal}>
          <Button
            size="small"
            variant="contained"
            disabled={shipto.locked == 1 ? true : false}
            sx={{
              backgroundColor: "#5CC7F8",
              width: "60%",
              fontSize: "65%",
              //height: "20px",
              //  fontSize: "100%",
              marginLeft: "20%",
            }}
            onClick={() => {
              OpenCloseModal();
              handleAddShipto(shipto);
            }}
          >
            Seleccionar y proceder al pedido
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
  return (
    <div>
      <div className={classes.containerList}>
        <Grid container xs={12} className={classes.client} key={id}>
          <Grid item xs={4} className={classes.desborde}>
            {shipto.locked == 1 ? (
              <h1 className={classes.txtred}>
                {shipto.applicant_name} Bloqueado por cartera
              </h1>
            ) : (
              <h1 className={classes.txt}>{shipto.applicant_name}</h1>
            )}
          </Grid>
          <Grid item xs={2} className={classes.desborde}>
            <h1 className={classes.txt}>{shipto.destination_place}</h1>
          </Grid>
          <Grid item xs={2} className={classes.desborde}>
            <h1 className={classes.txt}>{shipto.ship_to}</h1>
          </Grid>
          <Grid xs={4} item className={classes.buttons}>
            <Grid item xs={2}>
              <Button
                size="small"
                variant="outlined"
                sx={{
                  fontSize: "1.2vh",
                  color: "black",
                  borderRadius: "8px",
                  width: 50,
                  "@media (max-Width: 960px)": {
                    fontSize: "1vh",
                  },
                }}
                onClick={() => OpenCloseModal()}
              >
                Perfil del cliente
              </Button>
            </Grid>
            <Grid item xs={2}>
              {auxbuttonsId.includes(shipto.ship_to) ? (
                <Button
                  key={id}
                  size="small"
                  variant="contained"
                  sx={{
                    fontSize: "1.2vh",
                    backgroundColor: "blue",
                    borderRadius: "10px",
                    "@media (max-Width: 960px)": {
                      fontSize: "1vh",
                    },
                  }}
                  onClick={() => handleDeleteShipto(shipto)}
                >
                  Deseleccionar
                </Button>
              ) : (
                <Button
                  key={id}
                  size="small"
                  variant="contained"
                  // disabled={shipto.locked == 1 ? true : false}
                  sx={{
                    fontSize: "1.2vh",
                    backgroundColor: "#5CC7F8",
                    borderRadius: "10px",
                    "@media (max-Width: 960px)": {
                      fontSize: "1vh",
                    },
                  }}
                  onClick={() => handleAddShipto(shipto)}
                >
                  Seleccionar
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
        <hr className={classes.Line2} />
      </div>
      <Modal open={modal} onClose={OpenCloseModal}>
        {body}
      </Modal>
    </div>
  );
};

export default withStyles(StepShiptoStyles)(ShiptoItem);
