

 // ##############################
  // // // appBar styles
  // #############################
 
  
  const appBarStyle = theme => ({
    appBar_container_route:{
      display: "flex", 
      alignItems: "center",
      fontSize: 14,
      color: "grey",
      "& span":{
        color: "#000",
        marginLeft: 10,
        marginRight: 10
      },
      [theme.breakpoints.down(870)]: {
        visibility: "hidden"
      }
    },
    appBar: {
      backgroundColor: "white",
      zIndex: "20",
      height: "2rem",
      overflow: "hidden"
    },
    appBar_container: {
      "& img":{
        width: 100
      },

    },
    appBar_container_logout:{
      color: "#000",
      display: "flex",
      alignItems: "center",
      fontSize: 15,
      [theme.breakpoints.down("sm")]: {
        visibility: "hidden"
      }
    },
    appBar_button: {
      color: "#000",
      display: "flex",
      alignItems: "center",
      position: "absolute",
      visibility: "hidden",
      right: "20px",
      [theme.breakpoints.down("sm")]: {
        visibility: "visible"
      }
    },
    routes: {
      color: "#000",
      textDecoration: "underline"
    },
    logoHeader: {
      height: "100%",
      overflow: "hidden",
      marginTop: "2px",
      width: "85%",
      display: "flex",
      alignItems:"center",
      [theme.breakpoints.down(930)]: {
        width: "95%"
      },
      [theme.breakpoints.down(870)]: {
        width: "10rem"
      }
    },
    Appbar_User: {
      width: "11rem",
      display: "flex",
      marginLeft: "13px",
      justifyContent: "end",
      alignItems: "center"
    }
      
  });
  
  export default appBarStyle;
  