import React, { useState } from "react";
import jwtDecode from "jwt-decode";
import {
  getAllShiptoOrSku,
  getSkus,
  createTranferOrder,
  getidRep,
  getTranferOrders,
  updateStatusTransferOrder,
  getPendingTransferorders,
  getTransfersHistory,
  getiMaterialGoup,
  getXmlData,
  getProcessedTransferOrders,
  createTranferTemplateOrder,
  orderPdf,
} from "../../protocols/requests";

const TransfersRequestController = (props) => {
  const [shiptorsku, setShiptorsku] = useState([]);
  const [selectShiptorsku, setSelectShiptorsku] = useState("");
  const [materialGroup, setMaterialGroup] = useState([]);
  const [locked, setLocked] = useState("");
  const [shiptoandskus, setShiptoandskus] = useState([]);
  const [pickuptype, setPickuptype] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const [addressee, setAddressee] = useState([]);
  const [addressee2, setAddressee2] = useState("");
  const [selectaddressee, setSelectAddressee] = useState([]);
  const [selectaddresseelabel, setSelectAddresseeLabel] = useState([]);
  const [quantities, setQuantities] = useState([]);
  const [returnList, setReturnList] = useState([]);
  const [toast, setToast] = React.useState(React.createRef());
  const [transfersRequest, setTransfersRequest] = useState([]);
  const [desc, setDesc] = useState("");
  const [options, setOptions] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [transfersHistory, setTransfersHistory] = useState([]);
  const [materialGroupSelected, setMaterialGroupSelected] = useState("");
  const [orderId, setOrderId] = useState("");
  const [xmlData, setXmlData] = useState("");
  const [transfersOrdersProcessed, setTransfersOrdersProcessed] = useState([]);
  const [shearch, setShearch] = useState("");
  const [shearchItem, setShearchItem] = useState("");
  const [template, setTemplate] = useState("");
  const [valueAutocomplete, setValueAutocomplete] = useState("");
  const [selectSkuDesc, setSelectSkuDesc] = useState("");
  const [numberPageTransfer, setNumberPageTransfer] = useState();
  const [pageTransfer, setPageTransfer] = useState(0);
  const [observation, setObservation] = useState("");
  const [selectShiptoDesc, setSelectShiptoDesc] = useState("");
  const [jwtToken, setJwtToken] = useState("");
  const [disable, setDisable] = useState(false);
  const [
    numberPageTransferOrderStatus,
    setNumberPageTransferOrderStatus,
  ] = useState();
  const [pageTransferOrderStatus, setPageTransferOrderStatus] = useState(0);
  const [selectedPDF, setSelectedPDF] = useState(null);
  const [pageTransferHistory, setPageTransferHistory] = useState(0);
  const [numberPageTransferHistory, setNumberPageTransferHistory] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [estimatedValue, setEstimatedValue] = useState(0);
  const [observationUnlock, setObservationUnlock] = useState("");
  const [pdfList, setPdfList] = useState([]);

  const showToast = (type, content) => {
    if (toast) {
      if (type === "success") {
        toast.showSuccess(content);
      } else {
        toast.showError(content);
      }
    }
  };

  const handlegetAllShiptoOrSku = async (token, type, shearchShiptoOrSku) => {
    // props.setLoaderVisible(true);
    var jwt = jwtDecode(token);
    setJwtToken(jwt);
    const idRep = await getidRep(props.userToken, props.id);
    const result = await getAllShiptoOrSku(
      token,
      type,
      idRep.response?.response[0]?.benefitting_Rep
    );
    //props.setLoaderVisible(false);

    if (result.status === 200) {
      const response = result.response.response;
      setShiptorsku(response);
      setOptions(response);
    }
    const result2 = await getAllShiptoOrSku(
      token,
      shearchShiptoOrSku,
      idRep.response?.response[0]?.benefitting_Rep
    );
    if (result2.status === 200) {
      const response2 = result2.response.response;
      setShearch(response2);
    }
  };

  const handlegetPendingOrders = async (token) => {
    var jwt = jwtDecode(token);
    props.setLoaderVisible(true);
    const idRep = await getidRep(props.userToken, props.id);
    const result = await getPendingTransferorders(
      token,
      idRep.response?.response[0]?.benefitting_Rep,
      jwt.groupSlug
    );
    props.setLoaderVisible(false);
    if (result.status === 200) {
      const response = result.response.orders;
      setPendingOrders(response);
    }
  };

  const handlegetTransfersHistory = async (token) => {
    var jwt = jwtDecode(token);
    setJwtToken(jwt);
    props.setLoaderVisible(true);
    const idRep = await getidRep(props.userToken, props.id);
    const result = await getTransfersHistory(
      token,
      orderId,
      idRep.response?.response[0]?.benefitting_Rep,
      jwt.groupSlug,
      pageTransferHistory
    );
    props.setLoaderVisible(false);
    if (result.status === 200) {
      const response = result.response.response;
      const totalRecords = result.response.total_records;
      setTransfersHistory(response);
      setNumberPageTransferHistory(totalRecords);
    } else if (result.status === 422) {
      setPageTransferHistory(0);
    } else if (result.status === 404) {
      setNumberPageTransferHistory(null);
    } else {
      showToast("error", result.response.description);
      setNumberPageTransferHistory(null);
    }
  };

  const handlegetAllShipto = async (token, type) => {
    const idRep = await getidRep(props.userToken, props.id);
    props.setLoaderVisible(true);
    const result = await getAllShiptoOrSku(
      token,
      type,
      idRep.response?.response[0]?.benefitting_Rep
    );
    props.setLoaderVisible(false);
    if (result.status === 200) {
      const response = result.response.response;
      setAddressee(response);
    }
  };

  const handleGetTransfersProcessedOrders = async (token) => {
    var jwt = jwtDecode(token);
    const idRep = await getidRep(props.userToken, props.id);
    const result = await getProcessedTransferOrders(
      token,
      idRep.response?.response[0]?.benefitting_Rep,
      jwt.groupSlug,
      parseInt(pageTransferOrderStatus),
      orderId
    );

    if (result.status === 200) {
      const response = result.response.response;
      const totalRecords = result.response.total_records;
      setTransfersOrdersProcessed(response);
      setNumberPageTransferOrderStatus(totalRecords);
    }
  };
  const handleChangeObservation = (event) => {
    setObservation(event.target.value);
  };

  const handleSubmitPdf = async (order_id, pdf) => {
    const type = "transfer";
    let formData = new FormData();
    formData.append("pdfFile", pdf);
    props.setLoaderVisible(true);
    const result2 = await orderPdf(formData, props.userToken, order_id, type);
    props.setLoaderVisible(false);
    if (result2.status == 200) {
      showToast("success", "ADJUNTO SUBIDO CORRECTAMENTE");
      window.location.reload();
    } else {
      showToast("error", result2.response.description);
    }
  };

  const handlegetSkus = async (token, shipto, group, model) => {
    let type = "returns";
    const idRep = await getidRep(props.userToken, props.id);
    props.setLoaderVisible(true);
    const result = await getSkus(
      token,
      shipto,
      model,
      type,
      idRep.response?.response[0]?.benefitting_Rep,
      null,
      shearchItem,
      materialGroupSelected,
      parseInt(pageTransfer)
    );
    props.setLoaderVisible(false);
    if (result.status === 200) {
      const response = result.response.response;
      const totalRecords = result.response.response.total_records;
      setShiptoandskus(response.skus);
      setNumberPageTransfer(totalRecords);
    } else if (result.status === 422) {
      setPageTransfer(0);
    } else if (result.status === 404) {
      setNumberPageTransfer(null);
    } else {
      showToast("error", result.response.description);
      setNumberPageTransfer(null);
    }
  };

  const handleGetMaterialGroup = async (token) => {
    const idRep = await getidRep(props.userToken, props.id);
    const result = await getiMaterialGoup(
      token,
      idRep.response?.response[0]?.benefitting_Rep
    );
    if (result.status === 200) {
      const response = result.response.response;
      setMaterialGroup(response);
    }
  };

  const handleAddressee = (name, shipto, locked, adresseelabel) => {
    setSelectAddressee(name);
    setAddressee2(shipto);
    setSelectAddresseeLabel(adresseelabel);
    setLocked(locked == 1 ? true : false);
    if (locked) {
      showToast("error", "Este Shipto esta bloqueado por cartera");
    }
  };

  const handleDescSkus = (value) => {
    setDesc(value);
  };

  const handleChangeQty = (value, item, batch, serialbatch) => {
    let IdExist = false;
    const aux = {
      model: item.model,
      shipto: item.shipto,
      batch: batch,
      serialbatch: serialbatch,
      value,
    };
    if (quantities.length > 0) {
      for (let qty of quantities) {
        if (
          qty.model == item.model &&
          qty.shipto == item.shipto &&
          qty.batch == batch &&
          qty.serialbatch == serialbatch
        ) {
          qty.value = value;
          IdExist = true;
        }
      }
      if (!IdExist) {
        setQuantities((prev) => [...prev, aux]);
      } else {
        setQuantities(quantities);
      }
    } else {
      setQuantities((prev) => [...prev, aux]);
    }
  };

  const handleAddSkuShipto = async (item, batch, id) => {
    let qty;
    let exist = false;
    for (let quantity of quantities) {
      if (
        quantity.model == item.model &&
        quantity.shipto == item.shipto &&
        quantity.batch == batch.batch &&
        quantity.serialbatch == batch.serial
      )
        qty = quantity.value;
    }

    if (returnList.length > 0) {
      /* returnList.map(async (itemlist) => {
        if (item.shipto != itemlist.shipto) {
          exist = true;
        }
      }); */

      for (let returnOrder of returnList) {
        if (
          item.model == returnOrder.model &&
          item.shipto == returnOrder.shipto &&
          item.batch == returnOrder.batch &&
          item.serial == returnOrder.serial
        ) {
          exist = true;
        }
      }

      if (!exist) {
        const returnOrder = {
          model: item.model,
          qty,
          shipto: item.shipto,
          shiptoName: item.cust_name,
          batch: batch.batch,
          addressee: selectaddressee,
          serial: batch.serial,
          desc: desc,
          material_description: item.material_description,
          zfin: batch.material,
          current_inv: batch.current_inv,
          optimum: item.optimum_national,
          expirationDate: batch.expirationDate,
        };
        let exist2 = false;
        for (let item of returnList) {
          if (
            item.model == returnOrder.model &&
            item.shipto == returnOrder.shipto &&
            item.batch == returnOrder.batch &&
            item.serial == returnOrder.serial
          ) {
            exist2 = true;
          }
        }
        if (!exist2) {
          setShowLoader(false);
          await setReturnList((prevArray) => [...prevArray, returnOrder]);
          setShowLoader(true);
        }
      } else {
        showToast(
          "error",
          "No puede escoger más de un Shipto para el traslado"
        );
      }
    } else {
      const returnOrder = {
        model: item.model,
        qty,
        shipto: item.shipto,
        shiptoName: item.cust_name,
        batch: batch.batch,
        addressee: selectaddressee,
        serial: batch.serial,
        desc: desc,
        material_description: item.material_description,
        zfin: batch.material,
        current_inv: batch.current_inv,
        optimum: item.optimum_national,
        expirationDate: batch.expirationDate,
      };
      let exist2 = false;
      for (let item of returnList) {
        if (
          item.model == returnOrder.model &&
          item.shipto == returnOrder.shipto &&
          item.batch == returnOrder.batch &&
          item.serial == returnOrder.serial
        ) {
          exist2 = true;
        }
      }
      if (!exist2) {
        setShowLoader(false);
        await setReturnList((prevArray) => [...prevArray, returnOrder]);
        setShowLoader(true);
      }
    }
  };

  const handleConfirmReturnOrder = async (
    observationUnlock,
    estimatedValue,
    clientUnlockRequest,
    pdfList
  ) => {
    if (!pickuptype) {
      showToast("error", "Seleccione el típo de traslado");
    } else if (!addressee2) {
      showToast("error", "Seleccione el destinatario");
    } else {
      const skus = [];
      let pdfIds = [];
      const type = "transfer";

      if (pdfList && pdfList.length > 0) {
        for (let i = 0; i < pdfList.length; i++) {
          const formData = new FormData(); // Reiniciar FormData para cada archivo
          formData.append("pdfFile", pdfList[i]);

          try {
            const result2 = await orderPdf(
              formData,
              props.userToken,
              null,
              type
            );

            if (result2.status == 200) {
              pdfIds.push(result2.response.response.id);
            } else if (result2.status == 413) {
              showToast(
                "error",
                "El documento adjunto supera el tamaño máximo permitido (4.49MB)"
              );
              props.setLoaderVisible(false);
              return;
            } else {
              showToast("error", result2.response.description);
              props.setLoaderVisible(false);
              return;
            }
          } catch (error) {
            showToast("error", "Error al subir el archivo");
            props.setLoaderVisible(false);
            return;
          }
        }
      }

      if (returnList.length > 0) {
        returnList.map((item) => {
          const order = {
            model: item.model,
            optimum: item.optimum,
            current_inv: item.current_inv,
            quantity: parseInt(item.qty),
            shipTo: item.shipto,
            nameShipto: item.shiptoName,
            batch: item.batch,
            serial: item.serial,
            desc: item.material_description,
            zfin: item.zfin,
            expirationDate: item.expirationDate,
          };
          skus.push(order);
        });
      }
      const body = {
        addressee: selectaddressee,
        pickupOption: pickuptype,
        observation: observation,
        addresseeShipto: addressee2,
        observationUnlock: observationUnlock ? observationUnlock : null,
        estimatedValue: estimatedValue ? estimatedValue : null,
        clientUnlockRequest: clientUnlockRequest ? clientUnlockRequest : null,
        documents: pdfIds.length > 0 ? pdfIds : null,
        skus: skus,
      };
      props.setLoaderVisible(true);
      const idRep = await getidRep(props.userToken, props.id);
      if (idRep.status == 200) {
        if (selectedPDF) {
          const type = "transfer";
          // const palabras = result.response.description.split(" ");
          // const ordenCreada = palabras[palabras.length - 1];
          let formData = new FormData();
          formData.append("pdfFile", selectedPDF);
          const result2 = await orderPdf(formData, props.userToken, null, type);
          if (result2.status == 200) {
            const result = await createTranferOrder(
              body,
              props.userToken,
              idRep.response?.response[0]?.benefitting_Rep,
              result2.response.response.id
            );
            if (result.status == 200) {
              setShiptoandskus();
              setSelectShiptorsku("");

              setShearchItem(null);
              setValueAutocomplete(null);
              setPickuptype(null);
              setSelectShiptorsku("");
              setSelectSkuDesc("");
              setSelectShiptoDesc("");
              setSelectAddresseeLabel(null);
              setMaterialGroupSelected(null);
              setQuantities([]);
              setReturnList([]);
              setObservation("");
              setSelectedPDF(null);
              setNumberPageTransfer(null);
              setIsModalOpen(false);
              setObservationUnlock("");
              setEstimatedValue(0);
              setPdfList([]);

              showToast("success", result.response.description);
            } else {
              showToast("error", result.response.description);
            }
          } else if (result2.status == 413) {
            showToast(
              "error",
              "El documento adjunto supera el tamaño máximo permitido (4.49MB)"
            );
            props.setLoaderVisible(false);
          } else {
            showToast("error", result2.response.description);
          }
        } else {
          const result = await createTranferOrder(
            body,
            props.userToken,
            idRep.response?.response[0]?.benefitting_Rep
          );
          if (result.status == 200) {
            setShiptoandskus();
            setSelectShiptorsku("");

            setShearchItem(null);
            setValueAutocomplete(null);
            setPickuptype(null);
            setSelectShiptorsku("");
            setSelectSkuDesc("");
            setSelectShiptoDesc("");
            setSelectAddresseeLabel(null);
            setMaterialGroupSelected(null);
            setQuantities([]);
            setReturnList([]);
            setObservation("");
            setSelectedPDF(null);
            setNumberPageTransfer(null);
            setIsModalOpen(false);
            setObservationUnlock("");
            setEstimatedValue(0);
            setPdfList([]);
            showToast("success", result.response.description);
          } else {
            showToast("error", result.response.description);
          }
        }
      }

      props.setLoaderVisible(false);
    }
  };

  const handleGetTransfersRequest = async (token, type) => {
    const idRep = await getidRep(props.userToken, props.id);
    props.setLoaderVisible(true);
    const result = await getTranferOrders(
      token,
      type,
      idRep.response?.response[0]?.benefitting_Rep
    );
    props.setLoaderVisible(false);
    if (result.status == 200) {
      setTransfersRequest(result.response.response);
    }
  };

  const handleUpdateTransfers = async (id, status, superuser) => {
    const idRep = await getidRep(props.userToken, props.id);
    props.setLoaderVisible(true);
    const result = await updateStatusTransferOrder(
      props.userToken,
      id,
      status,
      idRep.response?.response[0]?.benefitting_Rep,
      superuser === true ? props.id : null
    );
    props.setLoaderVisible(false);
    if (result.status == 200) {
      showToast("success", result.response.description);
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } else if (result.status == 404) {
      showToast("error", result.response.description);
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } else {
      showToast("error", result.response.description);
    }
  };

  const handleDeleteReturnList = async (id) => {
    const auxReturnLis = [];
    setShowLoader(false);
    await returnList.map((item, index) => {
      if (id != index) auxReturnLis.push(item);
    });
    await setReturnList(auxReturnLis);
    setShowLoader(true);
  };
  const handleChange = (e) => {
    setOrderId(e.target.value);
  };
  const handlegetXmlData = async (token) => {
    var jwt = jwtDecode(token);
    let typeOrder = "transfer";
    props.setLoaderVisible(true);
    const idRep = await getidRep(props.userToken, props.id);
    const result = await getXmlData(
      token,
      typeOrder,
      idRep.response?.response[0]?.benefitting_Rep,
      jwt.groupSlug
    );
    props.setLoaderVisible(false);
    if (result.status === 200) {
      const response = result.response.response;
      setXmlData(response);
    }
  };

  const handleGenerateTemplate = async () => {
    const skus = [];

    const body = {
      representative: "Daniel Tejeda",
      division: "Vascular",
      date: "15/11/2022",
      applicant: "302300300",
      benefitting_Rep: "123456",
      origin: "Cartagena",
      sap: "12345",
      nit: "12345",
      address: "Cartagena",
      city: "Cartagena",
      addressee: "Patricia Cordoba",
      sap_addressee: "1234",
      nit_addressee: "12344",
      address_addressee: "12345",
      city_addressee: "Medellin",
      skus: [
        {
          reference: "value1",
          material: "value2",
          description: "value3",
          serial: "value4",
          batch: "value5",
          expirationdate: "value6",
          quantity: "value7",
        },
      ],
    };
    props.setLoaderVisible(true);

    const result = await createTranferTemplateOrder(
      body,
      "8c75f2cddb5947eea54295c2b2463936"
    );
    if (result.status == 200) {
      const response = result.response.link;
      setTemplate(response);
    }

    props.setLoaderVisible(false);
  };

  const downloadPdf = (pdfData, fileName) => {
    // Decodifica la cadena base64 a un ArrayBuffer
    const binaryData = atob(pdfData);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const byteArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      byteArray[i] = binaryData.charCodeAt(i);
    }

    // Crea un Blob a partir del ArrayBuffer
    const blob = new Blob([arrayBuffer], { type: "application/pdf" });

    // Crea un objeto URL a partir del Blob
    const url = window.URL.createObjectURL(blob);

    // Crea un elemento de anclaje invisible y configura los atributos
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = fileName; // Nombre del archivo de descarga

    // Agrega el elemento de anclaje al cuerpo del documento
    document.body.appendChild(a);

    // Simula un clic en el enlace para iniciar la descarga
    a.click();

    // Revoca el objeto URL para liberar recursos
    window.URL.revokeObjectURL(url);
  };

  return {
    handlegetAllShiptoOrSku,
    shiptorsku,
    handlegetSkus,
    setShiptoandskus,
    shiptoandskus,
    handlegetAllShipto,
    addressee,
    handleChangeQty,
    handleAddSkuShipto,
    returnList,
    setToast,
    handleConfirmReturnOrder,
    handleDeleteReturnList,
    handleAddressee,
    selectaddressee,
    transfersRequest,
    handleGetTransfersRequest,
    handleUpdateTransfers,
    setReturnList,
    handleDescSkus,
    options,
    handlegetPendingOrders,
    pendingOrders,
    handlegetTransfersHistory,
    transfersHistory,
    locked,
    handleGetMaterialGroup,
    materialGroup,
    materialGroupSelected,
    setMaterialGroupSelected,
    selectShiptorsku,
    setSelectShiptorsku,
    orderId,
    handleChange,
    handlegetXmlData,
    xmlData,
    handleGetTransfersProcessedOrders,
    transfersOrdersProcessed,
    shearch,
    setShearchItem,
    shearchItem,
    handleGenerateTemplate,
    template,
    valueAutocomplete,
    setValueAutocomplete,
    selectaddresseelabel,
    pickuptype,
    setSelectSkuDesc,
    selectSkuDesc,
    pageTransfer,
    numberPageTransfer,
    setPageTransfer,
    setNumberPageTransfer,
    handleChangeObservation,
    observation,
    selectShiptoDesc,
    setSelectShiptoDesc,
    jwtToken,
    disable,
    setDisable,
    setPickuptype,
    numberPageTransferOrderStatus,
    setNumberPageTransferOrderStatus,
    pageTransferOrderStatus,
    setPageTransferOrderStatus,
    selectedPDF,
    setSelectedPDF,
    handleSubmitPdf,
    downloadPdf,
    pageTransferHistory,
    setPageTransferHistory,
    numberPageTransferHistory,
    isModalOpen,
    setIsModalOpen,
    estimatedValue,
    setEstimatedValue,
    observationUnlock,
    setObservationUnlock,
    pdfList,
    setPdfList,
  };
};

export default TransfersRequestController;
