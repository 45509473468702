/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import addUsersStyle from "assets/jss/material-dashboard-pro-react/components/addUsersStyle";
import Grid from "@material-ui/core/Grid";

import InputForm from 'components/CustomInput/InputForm'
import Button from 'components/CustomButtons/Button'
import CheckboxComponent from "components/CustomInput/Checkbox";
import Select from 'components/CustomInput/Select'
import PlacesAutoComplete from 'components/GoogleMap/PlaceAutoComplete'
import TimePicker from 'components/CustomInput/TimePicker'
import { useTranslation } from "react-i18next";

function AddUsers(props) {
  const {title, classes, data, handlerChangeData, handlerSubmitMember, clinic, handleChangeAddress, handleRemove, clinicDeparment} = props;
  const { t } = useTranslation("userForm")
  const [Replenishment, setReplenishment] = useState([
    {
      name: "Daily",
      value: 1
    },
    {
      name: "Weekly",
      value: 7
    },
    {
      name: "Every 15 Days",
      value: 14
    },
    {
      name: "Monthly",
      value: 30
    },
  ]);
  const [clinicType, setClinicType] = useState([
    {
      name: "Consumer",
      value: "consumer"
    },
    {
      name: "Receiver",
      value: "Receiver"
    }
  ])
  return (
    <div className={classes.container}>
      <Grid className={classes.container_title}>
        <h3>{t(title)}</h3>
      </Grid>
      <Grid className={classes.container_form}>
        <Grid className={classes.container_form_header}>
           <h5>{t("Add/Edit Manager")}</h5>
        </Grid>
        <Grid container item spacing={2} className={classes.container_form_inputs}>
          <Grid item xs={12} sm={6}>
            <p>{t("First Name")}</p>
            <InputForm error={data["firstName"]? false : true} value={data.firstName} handlerChangeData={handlerChangeData} id="firstName" /></Grid>
          <Grid item xs={12} sm={6}>
          <p>{t("Last Name")}</p>
            <InputForm error={data["lastName"]? false : true} value={data.lastName} handlerChangeData={handlerChangeData} id="lastName"/></Grid>
          <Grid item xs={12} sm={6}>
          <p>{t("Email Address")}</p>
            <InputForm error={data["email"]? false : true} value={data.email} handlerChangeData={handlerChangeData} id="email"/></Grid>
          <Grid item xs={12} sm={6}>
          <p>{t("Password")}</p>
            <InputForm error={data["password"]? false : true} value={data.password} handlerChangeData={handlerChangeData} id="password"/></Grid>
            { !clinicDeparment ? <Grid item xs={12} sm={6}>
           <p>{t("Other System Reference Id")}</p>
            <InputForm optional value={data.otherSystemReferenceId} handlerChangeData={handlerChangeData} id="otherSystemReferenceId"/></Grid> : null}
          { clinic ? 
            <>
            <Grid item xs={12} sm={6}>
            <p>{t("Pending PO Location")}</p>
              <InputForm optional value={data.pendingPoLocation} handlerChangeData={handlerChangeData} id="pendingPoLocation"/>
            </Grid>
            <Grid item xs={12} sm={6}>
            <p>{t("Credit Limit")}</p>
              <InputForm number error={false} value={data.creditLimit} handlerChangeData={handlerChangeData} id="creditLimit"/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>{t("Credit Used")}</p>
              <InputForm number error={false} value={data.creditUsed} handlerChangeData={handlerChangeData} id="creditUsed" />
            </Grid>
            {/*<Grid item xs={12} sm={6}>
              <CheckboxComponent label={t("Ignore the Credit?")}/>
            </Grid>*/}
            <Grid item xs={12} sm={4}>
              <p>{t("Employee Id")}</p>
              <InputForm error={data["employeeId"]? false : true} value={data.employeeId} handlerChangeData={handlerChangeData} id="employeeId"/>
            </Grid>
            <Grid item xs={12} sm={2}>
              <p>{t("# Mail Note")}</p>
              <InputForm error={data["clinicNote"]? false : true} value={data.clinicNote} handlerChangeData={handlerChangeData} id="clinicNote"/>
            </Grid>
            <Grid item xs={12}>          
              <h5>{t("Email Cutoff Date")}</h5>
              <div className={classes.line}/>
            </Grid>
            <Grid item xs={12} sm={3}>
              <p>{t("Replenishment")}</p>
              <Select data={Replenishment} value={data.replenishmentDate} handlerChangeData={handlerChangeData} id="replenishmentDate"/>
            </Grid>
            <Grid item xs={12} sm={3}>
              <p>{t("Replenishment Time")}</p>
              <TimePicker handlerChangeData={handlerChangeData} id="replenishmentTime" value={data.replenishmentTime}/>
            </Grid>
            <Grid item xs={12} sm={3}>
              <p>{t("Billing")}</p>
              <Select data={Replenishment} value={data.billingDate} handlerChangeData={handlerChangeData} id="billingDate"/>
            </Grid>
            <Grid item xs={12} sm={3}>
              <p>{t("Billing Time")}</p>
              <TimePicker handlerChangeData={handlerChangeData} id="billingTime" value={data.billingTime}/>
            </Grid>
            <Grid item xs={12}>          
              <h5>{t("Settings")}</h5>
              <div className={classes.line}/>
            </Grid>
            {/*<Grid item xs={12} sm={6}>
              <CheckboxComponent label={t("Does the clinic & hospital receive reports?")} value={data.isClinicReport} handlerChangeData={handlerChangeData} id="isClinicReport"/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CheckboxComponent label={t("Does the clinic & hospital handle dash?")} value={data.isDash} handlerChangeData={handlerChangeData} id="isDash"/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CheckboxComponent label={t("Does the clinic & hospital have internal references?")} value={data.hasInternal_id} handlerChangeData={handlerChangeData} id="hasInternal_id"/>
            </Grid>*/}
            </>
            : null
          }
          { clinicDeparment ? 
            <>
            <Grid item xs={12} sm={6}>
              <p>{t("Account Type")}</p>
              <Select data={clinicType} value={data.accountType} handlerChangeData={handlerChangeData} id="accountType"/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>{t("Employee Id")}</p>
              <InputForm error={data["employeeId"]? false : true} value={data.employeeId} handlerChangeData={handlerChangeData} id="employeeId"/>
            </Grid>             
            {/*<Grid item xs={12} sm={4}>
              <Select data={clinicType}  handlerChangeData={handlerChangeData} id="account_type"/>
            </Grid>*/}
            </>
          : null

          }
          <Grid item xs={12}>          
          <h5>{t("Address Fields")}</h5>
          <div className={classes.line}/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <p>{t("Company Name")}</p>
            <InputForm error={data["companyName"]? false : true} value={data.companyName} handlerChangeData={handlerChangeData} id="companyName"/></Grid>
          <Grid item xs={12} sm={6}>
            <p>{t("Contact No.")}</p>
            <InputForm error={data["contactNo"]? false : true} value={data.contactNo} handlerChangeData={handlerChangeData} id="contactNo"/></Grid>
          <Grid item xs={12} sm={6}>
            <p>{t("Fax No.")}</p>
            <InputForm optional value={data.fax} handlerChangeData={handlerChangeData} id="fax"/></Grid>
          <Grid item xs={12} sm={6}>
            <p>{t("Address")}</p>
            <PlacesAutoComplete value={data.address} onChange={handleChangeAddress} id="address"/>
            </Grid>
          <Grid item xs={12} sm={6}>
            <p>{t("City")}</p>
            <InputForm disabled error={data["city"]? false : true} value={data.city} handlerChangeData={handlerChangeData} id="city"/></Grid>
          <Grid item xs={12} sm={6}>
            <p>{t("State")}</p>
            <InputForm disabled error={data["state"]? false : true} value={data.state} handlerChangeData={handlerChangeData} id="state"/></Grid>
          <Grid item xs={12} sm={6}>
            <p>{t("Country")}</p>
            <InputForm disabled error={data["country"]? false : true} value={data.country} handlerChangeData={handlerChangeData} id="country"/></Grid>
          <Grid item xs={12} sm={6}>
            <p>{t("Zip/Postal Code")}</p>
            <InputForm disabled error={data["zipCode"]? false : true} value={data.zipCode} handlerChangeData={handlerChangeData} id="zipCode"/></Grid>
          <Grid item xs={12}>
            <p>{t("Other Address Details")}</p>
            <InputForm error={data["addressDetails"]? false : true} area value={data.addressDetails} handlerChangeData={handlerChangeData} id="addressDetails"/></Grid>   
          {/*clinic ? 
            <>
             <Grid item xs={12}>          
              <h5>Api Fields</h5>
              <div className={classes.line}/>
             </Grid>
             <Grid item xs={12} sm={8}>
              <p>Api Url</p>
              <InputForm error={false}/>
             </Grid>
             <Grid item xs={12} sm={4}>
              <p>Api Port</p>
              <InputForm error={false}/>
             </Grid> 
             <Grid item xs={12} sm={6}>
              <p>Api Username</p>
              <InputForm optional/>
             </Grid> 
             <Grid item xs={12} sm={6}>
              <p>Api Password</p>
              <InputForm optional/>
             </Grid>  
            </>
          : null

          */}     
        </Grid>
        <Grid item xs={12} className={classes.container_btn}>
          <Button className={classes.btn_submit} iconSubmit onClick={handlerSubmitMember}>
            {t("Submit")}
          </Button>
          <Button className={classes.btn_return} iconReturn onClick={() => {
            props.history.push('/dashboard/maps')
          }}>
            {t("Return")}
          </Button>
        </Grid>        
      </Grid>
      
    </div>
  );
}

AddUsers.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(addUsersStyle)(AddUsers);
