import React, { useState } from "react";
import { createOrders, createDeliverys } from "protocols/requests";
import { useTranslation } from "react-i18next";

const FilesControllers = (props, token) => {
  const [toast, setToast] = React.useState(React.createRef());
  const { t } = useTranslation("userView");
  const [data, setData] = useState({
    fileOrders: null,
    nameOrders: null,
    fileDeliverys: null,
    nameDeliverys: null,
  });

  const { setLoaderVisible } = props;

  const handlerChangeData = (e) => {
    var key = e.target.id;
    var value = e.target.files[0];
    const name = key === "fileOrders" ? "nameOrders" : "nameDeliverys";
    setData({ ...data, [key]: value, [name]: value.name });
  };

  const handlerCreateOrdes = async () => {
    let file = data.fileOrders;
    let formdata = new FormData();
    formdata.append("orders", file);
    setLoaderVisible(true);
    const result = await createOrders(formdata, token);
    setLoaderVisible(false);
    if (result.status === 200) {
      showToast("success", t("orderSucces"));
    } else {
      if (result.response.description === "No file attached")
        showToast("error", t("NofileAttached"));
      else showToast("error", t("orderError"));
    }
  };

  const handlerCreateDeliverys = async () => {
    let file = data.fileDeliverys;
    let formdata = new FormData();
    formdata.append("orders", file);
    setLoaderVisible(true);
    const result = await createDeliverys(formdata, token);
    setLoaderVisible(false);
    if (result.status === 200) {
      showToast("success", t("orderSucces"));
    } else {
      if (result.response.description === "No file attached")
        showToast("error", t("NofileAttached"));
      else showToast("error", t("orderError"));
    }
  };

  const showToast = (type, content) => {
    if (toast) {
      if (type === "success") {
        toast.showSuccess(content);
      } else {
        toast.showError(content);
      }
    }
  };

  return {
    handlerChangeData,
    handlerCreateOrdes,
    handlerCreateDeliverys,
    data,
    setToast,
  };
};

export default FilesControllers;
