const StepShiptoStyles = (theme) => ({
  container: {
    backgroundColor: "rgba(231, 231, 231, 0.33)",
    width: "90%",
    margin: "0 auto",
    borderRadius: 8,
    paddingTop: "10px",
    paddingLeft: "2%",
  },
  headertable: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "20px",
    //marginLeft: "40px",
  },
  tittle: {
    marginTop: "20px",
    marginLeft: "4%",
    "& h5": {
      fontSize: "3vh",
    },
  },
  client: {
    display: "flex",
    justifyContent: "space-around",
    //marginTop: "-12px",
    //marginLeft: "2%",
  },

  txtH: { fontSize: "1vw" },
  txt: { fontSize: "2vh" },
  txtred: { fontSize: "2vh", color: "red" },

  Line: {
    width: "95%",
    height: "10px",
    marginTop: "-3px",
    marginLeft: "2%",
  },
  Line2: {
    width: "95%",
    height: "10px",

    marginLeft: "2%",
  },
  containerList: {
    paddingTop: "2px",
    paddingBottom: "2px",
    //backgroundColor: 'red',
  },
  desborde: {
    wordWrap: "break-word",
  },
  ordertype: {
    marginLeft: "5%",
    marginTop: "3%",
    justifyContent: "space-around",
  },
  modal: {
    position: "absolute",
    width: "30vw",
    height: "35%",
    backgroundColor: "#5DADE2",
    borderRadius: "6px",
    padding: "0px 10px 30px 10px",
    marginLeft: "auto",
    marginRight: "auto",
    top: "32.5%",
    left: "37.5%",
    transforms: "translate(-50%,-50%)",
  },
  modalheader: {
    display: "flex",
    position: "relative",
    float: "right",
    // left: "40%",
    alignItems: "center",
    paddingTop: 10,
  },
  containermodal: {
    backgroundColor: "white",
    borderRadius: "6px",
    margin: "0px auto",
    height: "95%",
  },
  titlemodal: { marginLeft: "6%", paddingTop: "5px" },
  clientprofiletxt: {
    fontSize: "100%",
    fontWeight: "bold",
  },
  modalinfo: { paddingLeft: "6%" },
  modalinfochildren: {
    display: "flex",
    alignItems: "center",
    marginLeft: "6%",
  },
  containerTxtShipto: {
    marginBottom: 20,
  },
  nameshipto: {
    fontSize: "100%",
    fontWeight: "bold",
  },
  idshipto: {
    fontSize: "80%",
  },
  textinfo: {
    fontSize: "80%",
    marginBottom: 20,
    marginTop: -10,
  },
  textPrices: {
    fontSize: "120%",
    fontWeight: "bold",
  },
  textinfoheader: {
    fontSize: "100%",
    color: "white",
  },
  "@media (max-Width: 960px)": {
    txt: {
      fontSize: "1.5vh",
    },
    txtred: {
      fontSize: "1.5vh",
    },
    txtH: {
      fontSize: "1.5vh",
    },
    txtdesc: {
      fontSize: "1.5vh",
      color: "red",
    },
    desc: {
      fontSize: "1.5vh",
    },
  },
  "@media (min-Width: 960px)": {
    buttons: {
      display: "flex",
      justifyContent: "space-around",
    },
  },
});

export default StepShiptoStyles;
