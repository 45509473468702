import React, { useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@mui/material/Grid";
import batchViewsStyle from "assets/jss/material-dashboard-pro-react/components/BatchViewStyle";

//Filter component
import FilterComponent from "./FilterComponent";
//list component
import OrderListComponent from "./OrderListComponent";
import { useTranslation } from "react-i18next";

function OrderComponent(props) {
  var {
    title,
    classes,
    orders,
    handlerChangeData,
    handlerCreateOrdes,
    data,
  } = props;
  const { t } = useTranslation("userView");
  const [showFilter, setShowFilter] = useState(false);
  return (
    <div className={classes.container}>
      <Grid className={classes.container_title}>
        <h3>{t(title)}</h3>
      </Grid>
      {/*<FilterComponent showFilter={showFilter} setShowFilter={setShowFilter} /> TODO: filtros para ordenes*/}
      <OrderListComponent orders={orders} {...props} />
    </div>
  );
}

OrderComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(batchViewsStyle)(OrderComponent);
