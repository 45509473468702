import React, { useEffect } from "react";
import UserViews from "components/userViews";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MemberControllers from "controllers/MemberControllers";

function ClinicHospital(props) {
  const title = "My Clinics & Hospitals";
  const {
    handleGetMember,
    members,
    handleChangePage,
    page,
    totalPage,
    handlerAddMember,
    localRecords,
    handlerChangeRecordsPage
  } = MemberControllers(title,props, 4 ,true);

  const token = props.userToken;

  useEffect(async () => {
    if (members.length === 0) props.setLoaderVisible(true);
    await handleGetMember("clinic-hospital", { token });
    if (members.length === 0) props.setLoaderVisible(false);
  },[page || localRecords]);

  return (
    <div>
      <UserViews
        title={title}
        handlerAddMember={handlerAddMember}
        members={members}
        clinic
        handleChangePage={handleChangePage}
        handlerChangeRecordsPage={handlerChangeRecordsPage}
        totalPage={totalPage}
        {...props}
      />
    </div>
  );
}

export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
  }))(ClinicHospital)
);
