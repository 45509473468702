import Moment from 'moment';
import config from 'config.js'
import cookie from 'js-cookie';

export const decodeToken = (token) => {
    var jwtDecode = require('jwt-decode');
    let userDecoded = '';
    try {
        userDecoded = jwtDecode(token)
    }
    catch (error) {
        console.log('jwt decode error', error);
    }

    return userDecoded;
}

export const replaceMiddleString = (str, leftOffset, rightOffset, replaceTo = "...") => {
    var newStr = str.replace(str.substring(leftOffset, str.length - rightOffset), "...");
    return newStr;
}

export const formatDiffDates = (resultObj) => {
    if (resultObj.years > 0)
        return resultObj.years + ' years ago';
    if (resultObj.months > 0)
        return resultObj.months + ' months ago';
    if (resultObj.weeks > 0)
        return resultObj.weeks + ' weeks ago';
    if (resultObj.days > 0)
        return resultObj.days + ' days ago';
    if (resultObj.hours > 0)
        return resultObj.hours + ' hours ago';
    if (resultObj.minutes > 0)
        return resultObj.minutes + ' minutes ago';

    return '';
}

export const diffDateWithNow = (date) => {
    var mDate = null;
    if (!Moment.isMoment(date))
        mDate = Moment(date).format();
    else
        mDate = date;

    var minutes = Moment().diff(mDate, 'minutes');
    var hours = Moment().diff(mDate, 'hours');
    var days = Moment().diff(mDate, 'days');
    var weeks = Moment().diff(mDate, 'weeks');
    var months = Moment().diff(mDate, 'months');
    var years = Moment().diff(mDate, 'years');

    var result = {
        minutes,
        hours,
        days,
        weeks,
        months,
        years
    }

    return result;
}

export const formatDate = (date, format = "MMMM DD, Y - HH:mm") => {
    let fomattedDate = Moment(date, ["YYYY", Moment.ISO_8601]).format(format);
    return fomattedDate;
}

export const getFinancePartnerApplicationsId = (path) => {
    var newStr = path.replace("/dashboard/financePartner/applications/", "");
    return newStr
}

export const getApplicationFinancePartnersId = (path) => {
    var newStr = path.replace("/dashboard/application/financePartner/", "");
    return newStr
}

export const getNotificationId = (path) => {
    var newStr = path.replace("/dashboard/notifications/", "");
    return newStr
}

export const exportFile = (data, sheetName = "FactR.xlsx") => {
    var XLSX = require('xlsx');
    /* convert state to workbook */
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, sheetName)
};

export const getBalanceType = () => {

}

export const getGridSize = (size) => {
    size = size.toLowerCase();
    if (size === 'xs') {
        return config.gridSize.xs;
    } else if (size === 's') {
        return config.gridSize.s;
    } else if (size === 'm') {
        return config.gridSize.m;
    } else if (size === 'l') {
        return config.gridSize.l;
    } else {
        return config.gridSize.xl;
    }
}

var domain = window.location.hostname;
var dotDomain = "." + window.location.hostname;
var attr = {
    domain: domain,
};
var dotAttr = {
    domain: dotDomain,
};

export const createLangCookie = (transTo) => {
    console.log(attr, dotAttr)
    cookie.set('googtrans', transTo);
    cookie.set('googtrans', transTo, dotAttr);
}

export const deleteLangCookie = () => {
    cookie.set('googtrans', "");
    cookie.set('googtrans', "", dotAttr);
}

export const readFileAsync = (file, asArrayBuff = false) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onload = () => {
            resolve(reader.result);
        };

        reader.onerror = reject;

        if (asArrayBuff)
            reader.readAsArrayBuffer(file);
        else
            reader.readAsDataURL(file);
    })
}

export const getImageDimensions = (file) => {
    return new Promise(function (resolved, rejected) {
        var i = new Image()
        i.onload = function () {
            resolved({ w: i.width, h: i.height })
        };
        i.onerror = function () {
            resolved(null)
        }
        i.src = file
    })
}

export const validatePassword = (rules, password) => {
    var msg = '';
 
    for (let i = 0; i < rules.length; i++) {
        let r = rules[i];
        let valid = password.match(r.regex);
        if (valid != null) {
        }
        else {
            msg = r.hints;
            break;
        }
    }
 
    return msg;
 }