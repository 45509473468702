/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@mui/material/Grid";
import batchViewsStyle from "assets/jss/material-dashboard-pro-react/components/BatchViewStyle";
//Filter component
import FilterComponent from "./FilterComponent";
//list component
import BatchListComponent from "./BatchListComponent";
//Translate
import { useTranslation } from "react-i18next";
import Button from "components/CustomButtons/Button";
function BatchView(props) {
  const {
    title,
    noFilter,
    classes,
    products,
    batch,
    transferred,
    handleChangePage,
    handlerChangeRecordsPage,
    totalPage,
    batchIncoming,
    batchId,
    handlerChangeData,
    handleGetScannedProductBatch,
    handleGetScannedFilter,
    filter,
    handlerCreateReplacementOrders,
  } = props;
  const [showFilter, setShowFilter] = useState(false);
  const { t } = useTranslation("batchView");
  return (
    <div className={classes.container}>
      {noFilter ? null : (
        <Grid className={classes.container_title}>
          <h3>{t(title)}</h3>
        </Grid>
      )}
      <div className={classes.containerBtnReplacement}>
        {batch ? (
          <Button color="success" onClick={handlerCreateReplacementOrders}>
            {t("replacementOrderCreate")}
          </Button>
        ) : null}
      </div>

      {//TODO: filtros scanned product batch
      noFilter ? null : (
        <FilterComponent
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          batchId={batchId}
          handlerChangeData={handlerChangeData}
          handleGetScannedProductBatch={handleGetScannedProductBatch}
          handleGetScannedFilter={handleGetScannedFilter}
        />
      )}
      {products ? (
        <BatchListComponent
          products={products}
          batch={batch}
          transferred={transferred}
          handleChangePage={handleChangePage}
          handlerChangeRecordsPage={handlerChangeRecordsPage}
          totalPage={totalPage}
          batchIncoming={batchIncoming}
          filter={filter}
          noFilterv
        />
      ) : (
        <BatchListComponent />
      )}
    </div>
  );
}

BatchView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(batchViewsStyle)(BatchView);
