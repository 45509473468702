import React, { useState } from "react";
import { getOrders, createOrders } from "protocols/requests";
import { useTranslation } from "react-i18next";

const OrdersControllers = (props, token) => {
  const [toast, setToast] = React.useState(React.createRef());
  const { t } = useTranslation("userView");
  const [orders, setOrders] = useState([]);
  const [data, setData] = useState({
    file: null,
    name: null,
  });
  const { setLoaderVisible } = props;
  const handleGetOrders = async (token) => {
    setLoaderVisible(true);
    const result = await getOrders(token);
    setLoaderVisible(false);
    if (result.status === 200) {
      const response = result.response.response;
      setOrders(response);
    }
  };

  const handlerChangeData = (e) => {
    var key = e.target.id;
    var value = e.target.files[0];
    setData({ ...data, [key]: value, name: value.name });
  };

  const handlerCreateOrdes = async () => {
    let file = data.file;
    let formdata = new FormData();
    formdata.append("orders", file);
    setLoaderVisible(true);
    console.log("x1 props");

    const result = await createOrders(formdata, token);
    setLoaderVisible(false);
    if (result.status === 200) {
      showToast("success", t("orderSucces"));
    } else {
      console.log("x1", result.response.description);
      if (result.response.description === "No file attached")
        showToast("error", t("NofileAttached"));
      else showToast("error", t("orderError"));
    }
  };

  const showToast = (type, content) => {
    if (toast) {
      if (type === "success") {
        toast.showSuccess(content);
      } else {
        toast.showError(content);
      }
    }
  };

  return {
    handleGetOrders,
    handlerChangeData,
    orders,
    handlerCreateOrdes,
    data,
    setToast,
  };
};

export default OrdersControllers;
