const dashboardDataStyle = {
    container:{
        height: "10rem",
        margin: "8px 0",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&:hover":{
            cursor: "pointer"
        }
    },
    containerInside: {
        width: "95%",
        height: "90%",
        fontFamily: "Poppins",
        fontWeight: "600",
        borderRadius: "13px",
        boxShadow: "0px 1px 8px -1px rgba(0,0,0,0.65)",
        color: "#3b3b3b"
    },
    title:{
        height: "50%",
        fontSize: "25px",
        padding: "14px 6%",
    },
    quantity:{
        height: "50%",
        fontSize: "36px",
        padding: "14px 10%",
        display: "flex",
        justifyContent: "flex-end"
    },
    containerTable: {
        padding: "10px 15px",
        marginBottom: "0px",
        width: "100%",
        position: "relative",
        height: "auto"
    }
}

export default dashboardDataStyle;