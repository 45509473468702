import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import PagesHeader from "components/Header/PagesHeader.jsx";

import pagesRoutes from "routes/pages.jsx";
import Toast from "components/Toast/Toast.jsx";
import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx";

import Loader from 'components/Loader/Loader.jsx'
import * as util from "../util/util";
import ChangeLanguage from "../components/ChangeLanguage";

class Pages extends React.Component {

  constructor(props) {
    super(props);
    this.loader = React.createRef();
    this.toast = React.createRef();
  }

  setLoaderVisible = (bShow) => {
    if (this.loader)
      this.loader.setLoaderVisible(bShow);
  }

  showToast = (type, content) => {
    if (this.toast) {
      if (type === 'success') {
        this.toast.showSuccess(content);
      } else {
        this.toast.showError(content);
      }
    }
  }

  /*componentWillMount = async() => {

    if(!cookie.get('googtrans')){
      var result = await getSiteSetting();
      if(result && result.data){
        var language = result.data.siteSettings.language.code;
        this.addGoogleTranslateScript('/en/' + language);
        window.location.reload();
      }
    }
  }*/

  componentDidMount = async() => {
    document.body.style.overflow = "unset";
    //this.googleTranslateSetup();
  }

  googleTranslateElementInit = () => {
    /* eslint-disable no-new */
    new window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element')
  }

  addGoogleTranslateScript = (transTo) => {
    // var elem = document.getElementById("googleTranslateScript");
    // if (elem) {
    //   elem.remove();
    //   window.location.reload();
    // }

    if (transTo != '') {
      util.createLangCookie(transTo);
      //window.location.reload();
      //this.googleTranslateSetup();
    } else {
      util.deleteLangCookie();
    }
  }

  googleTranslateSetup = () => {
    // google translate script setup
    window.googleTranslateElementInit = this.googleTranslateElementInit;
    const script = document.createElement("script");
    script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    script.type = 'text/javascript';
    script.async = true;
    script.id = 'googleTranslateScript';
    document.body.appendChild(script);
  }

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        {/* <div id="google_translate_element"></div> */}
        <Loader
          innerRef={ref => {
            this.loader = ref;
          }}
        />
        <Toast
          innerRef={ref => {
            this.toast = ref;
          }}
        />
          <PagesHeader {...rest} />
          <div className={classes.wrapper} ref="wrapper">
          <div
          className={classes.fullPage}
          >
            <Switch>
              {
                pagesRoutes.map((prop, key) => {
                  if (prop.collapse) {
                    return null;
                  }
                  if (prop.redirect) {
                    return (
                      <Redirect from={prop.path} to={prop.pathTo} key={key} />
                    );
                  }
                  return (
                    <Route
                      path={prop.path}
                      component={(props) => <prop.component addGoogleTranslateScript={this.addGoogleTranslateScript} setLoaderVisible={this.setLoaderVisible} showToast={this.showToast} {...props} />}
                      key={key}
                    />
                  );
                })
              }
            </Switch>
         {/** <Footer white />*/}
          </div>
        </div>
        <div className={classes.change_language}>
          <div className={classes.change_language_inside}>
            <ChangeLanguage/>
          </div>
        </div>
      </div>
    );
  }
}

Pages.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(pagesStyle)(Pages);
