const stepOrderConfirmStyles = (theme) => ({
  check: {
    display: "inline-block",
    width: "auto",
    margin: "auto",
    textAlign: "left",
  },
  tittle: {
    marginTop: "20px",
    marginLeft: "4%",
    "& h1": {
      fontSize: "3vh",
    },
  },
  column1: {},
  column2: {},
  product: {
    paddingLeft: "22%",
  },
  tittleproducts: {
    marginLeft: "20%",
    fontSize: "2vh",
  },
  txtproducts: {
    color: "gray",
    fontSize: "2vh",
    marginLeft: "4px",
    marginTop: "2px",
  },
  productName: {
    display: "flex",
    textAlign: "center",
    fontSize: "2vh",
  },
  nameshipto: {
    fontSize: "2.5vh",
    fontWeight: "bold",
  },
  send: {
    color: "gray",
    fontSize: "2vh",
  },
  icon: {
    paddingLeft: "40%",
  },
  address: {
    color: "gray",
    fontSize: "1.5vh",
  },
  id: {
    color: "gray",
    fontSize: "1.5vh",
  },
  contact: {
    display: "flex",
    alignItems: "center",
  },
  phone: {
    display: "flex",
    alignItems: "center",
  },
  contacttxt: {
    color: "gray",
    fontSize: "1.5vh",
    marginTop: "2px",
  },
  phonetxt: {
    color: "gray",
    fontSize: "1.5vh",
    marginTop: "2px",
  },
  select: {
    width: "95%",
    marginTop: "20px",
    alignItem: "center",
    justifyContent: "space-between",
    padding: "20px",
  },
  "@media (max-Width: 960px)": {
    txtproducts: {
      fontSize: "1.2vh",
    },
    productName: {
      fontSize: "1.2vh",
      wordWrap: "break-word",
    },
  },
});

export default stepOrderConfirmStyles;
