import React, { useState } from "react";
import { Grid, makeStyles, withStyles, Box, Modal } from "@material-ui/core";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Stepper, Step, StepLabel, Typography } from "@mui/material";
import GoogleMapReact from "google-map-react";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import RoomIcon from "@mui/icons-material/Room";
import { Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  orders: {
    boxShadow: "2px 2px 10px gray",
    borderRadius: 10,
    marginTop: "10px",
    padding: 20,
  },
  ordersdetail: {
    boxShadow: "2px 2px 10px gray",
    borderRadius: 10,
    marginTop: "10px",
    padding: 20,
  },
  column1: {
    alignItems: "center",
    paddingLeft: "10%",
    //paddingTop: "3%",
    // backgroundColor: "yellow",
  },
  column2: {
    textAlign: "center",
    paddingTop: "1%",
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "10%",
    // backgroundColor: "orange",
  },
  txtdetails: {
    fontSize: "100%",
    marginRight: 5,
  },
  rute: {
    marginRight: "20%",
  },
  stepper: {
    paddingLeft: "5%",
    paddingTop: "2%",
  },
  "@media (max-Width: 960px)": {
    column1: {
      fontSize: "1vh",
    },
    column2: {
      fontSize: "1vh",
    },
    txtorder: {
      fontSize: "1vh",
    },
    txtdetails: {
      fontSize: "1vh",
    },
    rute: {
      fontSize: "1vh",
    },
    stepper: {
      fontSize: "1vh",
    },
  },
}));

const Marker = () => <RoomIcon />;

const OrderStatusItem = (props) => {
  const { orderRequest, transfer, returnorder, order, downloadPdf } = props;
  const classes = useStyles();
  const [detail, setDetail] = useState(false);

  let coordenates = {
    lat: "",
    lng: "",
  };

  let defaultProps = {
    center: {
      lat: 10.312544,
      lng: -75.404158,
    },
    zoom: 15,
  };

  let step;

  for (let item of order.traceability) {
    if (item.status === order.traceability_status) {
      step = item.step;
      coordenates.lat = item.latitude;
      coordenates.lng = item.longitude;
    }
  }

  return (
    <Grid
      xs={12}
      className={detail ? classes.orders : classes.ordersdetail}
      container
    >
      <Grid xs={6} className={classes.column1}>
        <h5 className={classes.txtorder}>
          {" "}
          {returnorder ? "Retorno #" : transfer ? "Traslado #" : "Pedido #"}
          {order.order_id}
        </h5>
        <h5 className={classes.txtdetails}>
          Codigo de remisión SAP: #
          {order.paymentRemision ? order.paymentRemision : order.codigo_sap}
        </h5>
        {order.guide && (
          <h5 className={classes.txtdetails}>Número de guia #{order.guide}</h5>
        )}
      </Grid>
      <Grid xs={6} className={classes.column2}>
        <h5 className={classes.rute}>{order.traceability_status}</h5>
        {!detail ? (
          <h5 className={classes.txtdetails}>Ver Tracking Completo</h5>
        ) : (
          <h5 className={classes.txtdetails}>Ocultar Tracking Completo</h5>
        )}
        {!detail ? (
          <ArrowDownwardIcon
            fontSize="small"
            onClick={() => {
              setDetail(!detail);
            }}
            sx={{
              cursor: "pointer",
            }}
          />
        ) : (
          <ArrowUpwardIcon
            fontSize="small"
            onClick={() => {
              setDetail(!detail);
            }}
            sx={{
              cursor: "pointer",
            }}
          />
        )}
      </Grid>
      {detail ? (
        <Grid XS={12} container>
          <Grid xs={12} sm={6} className={classes.stepper}>
            <Stepper orientation="vertical" activeStep={step}>
              {order.traceability.map((traceability) => (
                <Step>
                  <StepLabel
                    optional={
                      <Typography variant="caption">
                        {traceability.creationDate} <br />
                        {traceability.date && "Fecha:" + traceability.date}{" "}
                        <br />
                        {traceability.hours &&
                          "Hora:" + traceability.hours}{" "}
                        <br />
                        {traceability.comment &&
                          "Comentario de transportadora:" +
                            traceability.comment}{" "}
                        <br />
                        {traceability.store && "Bodega:" + traceability.store}
                      </Typography>
                    }
                  >
                    {traceability.status}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>

          <Grid xs={12} sm={6}>
            {parseFloat(coordenates.lat) && parseFloat(coordenates.lng) ? (
              <div style={{ height: "40vh", width: "80%", marginTop: "20px" }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLEMAP_KEY,
                  }}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                  center={{
                    lat: parseFloat(coordenates.lat),
                    lng: parseFloat(coordenates.lng),
                  }}
                >
                  <Marker lat={coordenates.lat} lng={coordenates.lng} />
                </GoogleMapReact>
              </div>
            ) : null}
            {order?.url_document ? (
              <>
                <h5 className={classes.txtdetails}>Comprobante de entrega:</h5>
                <a href={order.url_document} target="_blank">
                  {order.url_document}
                </a>
              </>
            ) : order?.supportDocuments?.length != [] &&
            order?.supportDocuments?.length != null ? (
              <>
                <h5 className={classes.txtdetails}>Comprobante de entrega:</h5>
                <Button
                  onClick={() =>
                    downloadPdf(order?.supportDocuments[0], order?.order_id)
                  }
                >
                  Archivo Adjunto
                </Button>
              </>
            ) : null}
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};
export default OrderStatusItem;
