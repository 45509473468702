import React from "react";
import { Input, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function PDFInput(props) {
  const classes = useStyles();
  return (
    <div key={props.id}>
      <Input
        type="file"
        key={props.id}
        accept="application/pdf"
        className={classes.input}
        id={`pdf-input-${props.id}`}
        onChange={(e) => props.onChange(e, props.id)}
      />
      <label htmlFor={`pdf-input-${props.id}`}>
        <Button
          key={props.id}
          variant="contained"
          color="primary"
          component="span"
          className={classes.button}
          // onClick={() => props.handleSubmitPdf(props.orderId)}
          // disabled={props.pdf != null}
        >
          {props.pdf?.id && props.pdf?.id === props?.id
            ? props.pdf.pdf.name
            : props.pdf
              ? props.pdf.name
              : "Seleccionar archivo PDF"}
          {/*  {props.pdf?.id === props?.id
            ? props.pdf.pdf.name
            : "Seleccionar archivo PDF"} */}
        </Button>
      </label>
    </div>
  );
}

export default PDFInput;
