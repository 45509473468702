import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import cx from "classnames";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
// core components
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-dashboard-pro-react/components/headerLinksStyle";

class HeaderLinks extends React.Component {
  state = {
    open: false,
    openSetting: false,
  };
  handleClick = () => {
    this.setState({ open: !this.state.open });
  };
  handleClickSetting = () => {
    this.setState({ openSetting: !this.state.openSetting });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleCloseSetting = () => {
    this.setState({ openSetting: false });
  };
  handleMenuItemClicked = (e, obj) => {
    this.handleClose();
    this.props.history.push('/dashboard/notifications/' + obj.id);
  }
  render() {
    const { classes, rtlActive } = this.props;
    const { open, openSetting } = this.state;
    const note= {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      color: "#9c27b0",
      fontWeight: "400",
      fontSize: "13px",
      lineHeight: "13px",
      textDecoration: "underline"
    };
    const searchButton =
      classes.top +
      " " +
      classes.searchButton +
      " " +
      classNames({
        [classes.searchRTL]: rtlActive
      });
    const dropdownItem = classNames(
      classes.dropdownItem,
      classes.primaryHover,
      { [classes.dropdownItemRTL]: rtlActive }
    );
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive
    });
    const managerClasses = classNames({
      [classes.managerClasses]: true
    });
    return (
      <div className={wrapper}>
        {/* <CustomInput
          rtlActive={rtlActive}
          formControlProps={{
            className: classes.top + " " + classes.search
          }}
          inputProps={{
            placeholder: rtlActive ? "" : "Search",
            inputProps: {
              "aria-label": rtlActive ? "" : "Search",
              className: classes.searchInput
            }
          }}
        />
        <Button
          color="white"
          aria-label="edit"
          justIcon
          round
          className={searchButton}
        >
          <Search
            className={classes.headerLinksSvg + " " + classes.searchIcon}
          />
        </Button> */}
        {/* <Button
          color="transparent"
          simple
          aria-label="Dashboard"
          justIcon
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : ""
          }}
        >
          <Dashboard
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />
          <Hidden mdUp implementation="css">
            <span className={classes.linkText}>
              {rtlActive ? "" : "Dashboard"}
            </span>
          </Hidden>
        </Button> */}
        {this.props.username && <span>{this.props.username}</span>}
        {/*<div className={managerClasses} >*/}
        {/*  <Button*/}
        {/*    color="transparent"*/}
        {/*    justIcon*/}
        {/*    aria-label="Notifications"*/}
        {/*    aria-owns={open ? "menu-list" : null}*/}
        {/*    aria-haspopup="true"*/}
        {/*    onClick={this.handleClick}*/}
        {/*    className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}*/}
        {/*    muiClasses={{*/}
        {/*      label: rtlActive ? classes.labelRTL : ""*/}
        {/*    }}*/}
        {/*    buttonRef={node => {*/}
        {/*      this.anchorEl = node;*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <Notifications*/}
        {/*      className={*/}
        {/*        classes.headerLinksSvg +*/}
        {/*        " " +*/}
        {/*        (rtlActive*/}
        {/*          ? classes.links + " " + classes.linksRTL*/}
        {/*          : classes.links)*/}
        {/*      }*/}
        {/*    />*/}
        {/*    <span className={classes.notifications}>{parseInt(this.props.notificationCount) > 5 ? '5+' : this.props.notificationCount}</span>*/}
        {/*    <Hidden mdUp implementation="css">*/}
        {/*      <span onClick={this.handleClick} className={classes.linkText}>*/}
        {/*        {rtlActive ? "إعلام" : "Notification"}*/}
        {/*      </span>*/}
        {/*    </Hidden>*/}
        {/*  </Button>*/}
        {/*  <Popper*/}
        {/*    open={open}*/}
        {/*    anchorEl={this.anchorEl}*/}
        {/*    transition*/}
        {/*    disablePortal*/}
        {/*    placement="bottom"*/}
        {/*    className={classNames({*/}
        {/*      [classes.popperClose]: !open,*/}
        {/*      [classes.pooperResponsive]: true,*/}
        {/*      [classes.pooperNav]: true*/}
        {/*    })}*/}
        {/*  >*/}
        {/*    {({ TransitionProps, placement }) => (*/}
        {/*      <Grow*/}
        {/*        {...TransitionProps}*/}
        {/*        id="menu-list"*/}
        {/*        style={{ transformOrigin: "0 0 0" }}*/}
        {/*      >*/}
        {/*        <Paper className={classes.dropdown}>*/}
        {/*          <ClickAwayListener onClickAway={this.handleClose}>*/}
        {/*            <MenuList role="menu">*/}
        {/*              {this.props.notificationsData.notifications && this.props.notificationsData.notifications.map((obj, index) => {*/}
        {/*                if(index >=5 )*/}
        {/*                  return null;*/}

        {/*                return (*/}
        {/*                    <MenuItem*/}
        {/*                      key={index}*/}
        {/*                      onClick={e=>this.handleMenuItemClicked(e, obj)}*/}
        {/*                      className={dropdownItem}*/}
        {/*                    >*/}
        {/*                      {obj.title || ''}*/}
        {/*                      <br/>*/}
        {/*                      <Muted>*/}
        {/*                        { obj.dateCreated && util.formatDiffDates(util.diffDateWithNow(obj.dateCreated))}*/}
        {/*                      </Muted>*/}
        {/*                    </MenuItem>*/}
        {/*                )*/}
        {/*              })}*/}
        {/*              <MenuItem */}
        {/*                onClick={()=>this.props.history.push('/dashboard/notifications')} */}
        {/*                style={note}*/}
        {/*              >*/}
        {/*                VIEW ALL*/}
        {/*              </MenuItem>*/}
        {/*            </MenuList>*/}
        {/*          </ClickAwayListener>*/}
        {/*        </Paper>*/}
        {/*      </Grow>*/}
        {/*    )}*/}
        {/*  </Popper>*/}
        {/*</div>*/}
        <div className={managerClasses}>
          <Button
            color="transparent"
            aria-label="Person"
            justIcon
            aria-owns={openSetting ? "menu-list" : null}
            aria-haspopup="true"
            onClick={this.handleClickSetting}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : ""
            }}
            buttonRef={node => {
              this.anchorEl = node;
            }}
          >
            <Person
              className={
                classes.headerLinksSvg +
                " " +
                (rtlActive
                  ? classes.links + " " + classes.linksRTL
                  : classes.links)
              }
            />
            <Hidden mdUp implementation="css">
              <span onClick={this.handleClickSetting} className={classes.linkText}>
                {rtlActive ? "" : "Profile"}
              </span>
            </Hidden>
          </Button>
          <Popper
            open={openSetting}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            placement="bottom"
            className={classNames({
              [classes.popperClose]: !openSetting,
              [classes.pooperResponsive]: true,
              [classes.pooperNav]: true
            })}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list"
                style={{ transformOrigin: "0 0 0" }}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={this.handleCloseSetting}>
                    <MenuList role="menu">
                      <MenuItem
                        onClick={()=>{
                          this.props.showAccountSetting();
                          this.handleCloseSetting();
                        }}
                        className={dropdownItem}
                      >
                        {rtlActive
                          ? ""
                          : "Settings"}
                      </MenuItem>
                      <MenuItem
                        onClick={()=>{
                          this.props.logout();
                          this.handleCloseSetting();
                        }}
                        className={dropdownItem}
                      >
                        {rtlActive
                          ? ""
                          : "Logout"}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
        {/* <Button
          color="transparent"
          aria-label="Person"
          justIcon
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : ""
          }}
        >
          <Person
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />
          <Hidden mdUp implementation="css">
            <span className={classes.linkText}>
              {rtlActive ? "" : "Profile"}
            </span>
          </Hidden>
        </Button> */}
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
};

var withStylesClass = withStyles(headerLinksStyle)(HeaderLinks);
export default withRouter(connect(
  (state) => ({
    userToken: state.root.user.userToken,
    notificationCount: state.root.user.notificationCount,
    notificationsData: state.root.user.notificationData,
  })
)(withStylesClass));

