/**
 * This is a React component that displays a header with icons and text for a three-step process, with
 * the current step highlighted based on a prop passed to the component.
 * @param props - "props" is an object that contains all the properties passed to a React component.
 * These properties can be accessed within the component using dot notation (e.g. props.propertyName).
 * In the above code, the "steps" property is being destructured from the "props" object and used to
 * condition
 * @returns A React functional component called HeaderSteps.
 */
import React, { useEffect } from "react";
import UserViews from "components/userViews";
import { Grid, makeStyles, withStyles, Box } from "@material-ui/core";
import "../../../assets/jss/material-dashboard-pro-react/views/StepShiptoStyles";

import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import LockOpenIcon from "@mui/icons-material/LockOpen";

/* `const useStyles` is a function that uses the `makeStyles` hook from Material-UI to define and
customize the styles for the HeaderSteps component. It returns an object with CSS styles that can be
applied to the different elements of the component. The styles defined in this function are used to
set the height, width, border radius, box shadow, and other properties of the header and body of the
component, as well as the icons, text, and lines that are displayed in each step. The `@media` rule
at the end of the function is used to define styles for different screen sizes, in this case, for
screens with a maximum width of 960px. */
const useStyles = makeStyles((theme) => ({
  headerOrders: {
    height: "100px",
    width: "100%",
    // border: "solid 1px red",
    borderRadius: 10,
    boxShadow: "2px 2px 10px gray",
    display: "flex",
    alignItems: "center",
    //paddingLeft: "8%",
    // backgroundColor: "yellow",
  },
  bodyOrders: {
    // border: "solid 1px black",
    marginTop: "-40px",
    borderRadius: 10,
    boxShadow: "5px 5px 10px black",
    // backgroundColor: "blue",
  },

  icons: {
    display: "flex",
    justifyContent: "center",
    //backgroundColor: "orange",
    marginTop: "15px",
    marginBottom: "-18px",
    //marginLeft: "20px",
  },
  iconsOff: {
    display: "flex",
    justifyContent: "center",
    // backgroundColor: "orange",
    marginTop: "15px",
    marginBottom: "-18px",
    //marginLeft: "20px",
    color: "gray",
  },
  numbersOff: { color: "gray" },

  truck: {
    marginTop: "11px",
  },
  cart: { marginTop: "8px" },
  checklist: { marginTop: "9px" },
  txt: {
    fontSize: "12px",
  },
  txtOff: {
    fontSize: "12px",
    color: "gray",
  },
  Line: {
    marginTop: "-2px",
    width: "60%",
    height: "2px",
    backgroundColor: "#5DADE2",
  },

  vline: {
    borderLeft: " solid black",
    height: "40%",
    left: "6%",
    // marginRight: "10%",
  },
  containerTxt: {
    textAlign: "center",
  },
  containerLine: {
    paddingLeft: "30%",
  },
  "@media (max-Width: 960px)": {
    txt: {
      fontSize: "8px",
    },
    txtOff: {
      fontSize: "8px",
    },

    icons: {
      marginTop: "12px",
      marginBottom: "-16px",
    },
    iconsOff: {
      marginTop: "12px",
      marginBottom: "-16px",
    },
    Line: {
      width: "80%",
    },
  },
}));

//controller

/**
 * This is a React component that renders a header with three steps for an online ordering process.
 * @param props - An object containing the properties passed to the HeaderSteps component.
 * @returns A React component that renders a header with three steps, each step represented by an icon
 * and a text description. The current step is determined by the `steps` prop passed to the component.
 */
export default function HeaderSteps(props) {
  const classes = useStyles();

  const { steps, fourStep } = props;
  return (
    <div>
      <Grid xs={12} className={classes.headerOrders} spacing={3}>
        <Grid xs={4}>
          <Grid
            xs={12}
            className={steps == 1 ? classes.icons : classes.iconsOff}
          >
            <h1 className={steps == 1 ? classes.number : classes.numbersOff}>
              1
            </h1>
            <LocalShippingOutlinedIcon
              fontSize="large"
              className={classes.truck}
            />
          </Grid>
          <Grid xs={12} className={classes.containerTxt}>
            <h8 className={steps == 1 ? classes.txt : classes.txtOff}>
              A donde enviaras el pedido?
            </h8>
          </Grid>
          <Grid xs={12} className={classes.containerLine}>
            {steps == 1 ? <div className={classes.Line}> </div> : null} <br />
          </Grid>
        </Grid>
        <div className={classes.vline} />
        <Grid xs={4}>
          <Grid className={steps == 2 ? classes.icons : classes.iconsOff}>
            <h1 className={steps == 2 ? classes.number : classes.numbersOff}>
              2
            </h1>
            <ShoppingCartOutlinedIcon
              fontSize="large"
              className={classes.cart}
            />
          </Grid>
          <Grid xs={12} className={classes.containerTxt}>
            <h8 className={steps == 2 ? classes.txt : classes.txtOff}>
              Selecciona los productos
            </h8>
          </Grid>
          {steps == 2 ? (
            <Grid xs={12} className={classes.containerLine}>
              <div className={classes.Line}> </div>
            </Grid>
          ) : null}

          <br />
        </Grid>
        <div className={classes.vline} />
        <Grid xs={4}>
          <Grid className={steps == 3 ? classes.icons : classes.iconsOff}>
            <h1 className={steps == 3 ? classes.number : classes.numbersOff}>
              3
            </h1>
            <PlaylistAddCheckIcon
              fontSize="large"
              className={classes.checklist}
            />
          </Grid>
          <Grid xs={12} className={classes.containerTxt}>
            <h8 className={steps == 3 ? classes.txt : classes.txtOff}>
              Confirma el pedido
            </h8>
          </Grid>
          <Grid xs={12} className={classes.containerLine}>
            {steps == 3 ? <div className={classes.Line}> </div> : null} <br />
          </Grid>
        </Grid>
        {fourStep == true ? <div className={classes.vline} /> : null}
        {fourStep == true ? (
          <Grid xs={4}>
            <Grid className={steps == 4 ? classes.icons : classes.iconsOff}>
              <h1 className={steps == 4 ? classes.number : classes.numbersOff}>
                4
              </h1>
              <LockOpenIcon fontSize="large" className={classes.checklist} />
            </Grid>
            <Grid xs={12} className={classes.containerTxt}>
              <h8 className={steps == 4 ? classes.txt : classes.txtOff}>
                Solicitud de desbloqueo
              </h8>
            </Grid>
            <Grid xs={12} className={classes.containerLine}>
              {steps == 4 ? <div className={classes.Line}> </div> : null} <br />
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}
