import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import returnRequestStyle from "assets/jss/material-dashboard-pro-react/components/abstractComponentStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
const AbstractComponent = (props) => {
  const {
    classes,
    model,
    qty,
    shipto,
    shiptoName,
    deleteCard,
    index,
    batch,
    addressee,
  } = props;
  return (
    <Grid container xs={12} className={classes.container}>
      <Grid xs={11}>
        <Grid cs={12}>De:</Grid>
        <Grid container xs={12} className={classes.containerShipto}>
          <Grid xs={12} className={classes.nameShipto}>
            {shiptoName}
          </Grid>
          <Grid xs={12}>{shipto}</Grid>
        </Grid>
      </Grid>
      <Grid xs={1}>
        <FontAwesomeIcon
          icon={faClose}
          className={classes.icon_order}
          style={{ color: "#14A2DC", fontSize: "120%" }}
          onClick={() => deleteCard(index)}
        />
      </Grid>
      <Grid xs={3}>Modelos:</Grid>
      <Grid xs={3}>Cantidad:</Grid>
      <Grid xs={3}>Lote</Grid>
      <Grid xs={3}>Destinatario </Grid>
      <Grid container xs={12} className={classes.containerSkus}>
        <Grid xs={3}>{model}</Grid>
        <Grid xs={3}>{qty}</Grid>
        <Grid xs={3}>{batch}</Grid>
        <Grid xs={3}>{addressee} </Grid>
      </Grid>
    </Grid>
  );
};
export default withStyles(returnRequestStyle)(AbstractComponent);
