import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

//Import Style
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardDataStyle from "assets/jss/material-dashboard-pro-react/components/dashboardDataStyle";
import Grid from "@material-ui/core/Grid";

//Components and Translation
import UserViews from "../userViews";
import BatchView from "../Batch/BatchView";
import {useTranslation} from "react-i18next";

const DashboardData = ({classes, ...rest}) => {

    let size = 12 / 3;
    const { t } = useTranslation("dashboardData")

    if(Object.keys(rest.data).length <= 3){
        size = 12 / Object.keys(rest.data).length;
    }

    const [data, setData] = useState([]);
    const [tables, setTables] = useState([]);
    let info = [];

    const colors = [
        "#6dc57d",
        "#aaaef4",
        "#cda1a1",
        "#f5e0a7"
    ]

    const setDataTables = () => {
        info = []
        let products = []
        for (const sizeKey in rest.data) {
            switch (sizeKey.toString()){
                case "InventorySpecialist":
                    products = rest.data[sizeKey].orders.slice(0, 4)
                    info.push({table: <UserViews members={products} specialist noFilter/>, title: "Latest Products in Stock"})
                    break;
                case "TransferSpecialist":
                    products = rest.data[sizeKey].response.slice(0,4)
                    info.push({table: <BatchView products={products} transferred={true} noFilter/>, title:"Latest Transferred Specialist"})
                    break;
                case "incomingProductsClinic":
                    products = rest.data[sizeKey].response.orders.slice(0, 4)
                    info.push({table: <UserViews members={products} incomingProducts noFilter/>, title: "Latest Products Incoming"})
                    break;
                case "inventoryProducts":
                    products = rest.data[sizeKey].orders.slice(0, 4)
                    info.push({table: <UserViews members={products} clinicInventory noFilter/>, title: "Latest Products in Stock"})
                    break;
                case "scannedBatch":
                    products = rest.data[sizeKey].scanned.slice(0,4)
                    info.push({table: <BatchView products={products} batch={true} noFilter/>, title: "Latest Scanned Products"})
                    break;
                case "scannedBatchIncoming":
                    products = rest.data[sizeKey].scanned.slice(0,4)
                    info.push({table: <BatchView products={products} batchIncoming={true} noFilter/>, title: "Latest Scanned Products Incoming"})
                    break;
            }
            setTables(info);
        }
    }

    useEffect(()=>{
        for (const sizeKey in rest.data) {
            let quantity = 0;
            if(sizeKey.toString() === "incomingProductsClinic"){
                quantity = rest.data[sizeKey].response.totalRecords;
            }else if(sizeKey.toString() === "TransferSpecialist") {
                quantity = rest.data[sizeKey].response.length
            }else if(sizeKey.toString() !== ""){
                quantity = rest.data[sizeKey].totalRecords
            }

            if(sizeKey.toString() !== ""){
                info.push({
                    title: rest.data[sizeKey].title,
                    url: rest.data[sizeKey].url,
                    quantity
                })
            }

        }

        setData(info);
        setDataTables();

    }, [])

    return(
        <>
            {
                data.length !== 0 ? data.map((info, key)=><Grid sm={size} xs={12} className={classes.container} onClick={()=>{
                    rest.history.push({pathname: `/dashboard/${info.url}`})
                }}>
                    <div className={classes.containerInside} style={{background: colors[key]}}>
                        <div className={classes.title}>{t(info.title)}</div>
                        <div className={classes.quantity}>{info.quantity}</div>

                    </div>
                </Grid>) : null
            }

            {
                data.length !== 0 ? <div style={{width: "100%", margin:"15px 0"}}>
                    {
                        tables.map((table, key)=>
                            <div className={classes.containerTable}>
                                <div>
                                    <h3 style={{fontFamily: "Poppins", fontWeight: "600"}}>{t(table.title)}</h3>
                                </div>
                                {table.table}
                            </div>
                        )
                    }
                </div> : null
            }

        </>
    );

}

DashboardData.prototype = {
    classes: PropTypes.object.isRequired
}

export default withStyles(dashboardDataStyle)(DashboardData);
