

 // ##############################
  // // // appBar styles
  // #############################
 
  
  const myProfileStyle = theme => ({
    container:{
        marginTop: "87px"
    },
    container_title:{
        borderBottom: "1px solid grey",
        paddingBottom: "5px"
    },
    container_form:{ 
      display: "flex",
      justifyContent: "center",
          
    },
    container_form_header: {
        boxShadow: "0px 0px 2px grey",
        paddingLeft: 10,
        paddingTop: 10,
        paddingBottom: 5,
        marginTop: 10,
        backgroundColor: "#F0F0F0",
        marginBottom: "10px"
    },
    line: {
      widht: "100%",
      height: 10,
      borderBottom: "1px solid #DCDCDC"
    },
    btn_submit:{
      backgroundColor: "#14CE4B"
    },
    container_btn: {
      textAlign: "center",
      paddingTop: 20,
      paddingBottom: 20
    },
    container_reseat_password: {
      boxShadow: "0px 0px 2px grey",
      paddingTop: 10,
      paddingRight: 10,
      paddingLeft: 10,
     }
  });
  
  export default myProfileStyle;
  