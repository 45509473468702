import React, { useState, useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { Button } from "@mui/material";
import Toast from "components/Toast/Toast.jsx";
import HeaderTransfersRequest from "../../components/TransfersRequest/Header";
import TransfersRequestbody from "../../components/TransfersRequest/Body";
import AbstractComponent from "../../components/TransfersRequest/abstract";
import NativeSelect from "@mui/material/NativeSelect";
import InputLabel from "@mui/material/InputLabel";
import TransfersRequestController from "controllers/TransfersRequest";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Pagination } from "@mui/material";
// import jwtDecode from "jwt-decode";
import UpdatePdf from "../../components/UpdatePdf";
import ModalTransfersUnlockRequest from "../../components/OrderRequest/Unlockrequest/ModalTransfersUnlockRequest";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "100px",
    marginLeft: "2%",
    paddingRight: "2%",
  },
  colum1: {},
  colum2: {
    borderRadius: 10,
    // boxShadow: "2px 2px 10px gray",
    backgroundColor: "#F1F1F1",
    padding: "10px",
  },
  select: {
    borderRadius: 10,
    boxShadow: "2px 2px 10px gray",
    backgroundColor: "rgba(231, 231, 231, 0.33)",
    width: "95%",
    marginTop: "20px",
    alignItem: "center",
    justifyContent: "space-between",
    padding: "10px",
  },
  bodyreturnrequest: {
    marginTop: "20px",
    borderRadius: 10,
    boxShadow: "2px 2px 10px gray",
    backgroundColor: "rgba(231, 231, 231, 0.33)",
    width: "95%",
    paddingLeft: "10px",
    paddingTop: "10px",
  },
  txt: {
    fontSize: "14px",
  },
  Line: {
    width: "95%",
    height: "10px",
  },
  "@media (max-Width: 960px)": {
    txt: {
      fontSize: "2vh",
    },
    bodyreturnrequest: {
      fontSize: "0.8vh",
      wordWrap: "breakWord",
    },
    colum2: {
      fontSize: "0.8vh",
    },
    txtResumen: {
      fontSize: "1.5vh",
    },
  },
}));

const TransfersRequest = (props) => {
  const classes = useStyles();
  const [request, setRequest] = useState(1);

  /*   const [selectSkuDesc, setSelectSkuDesc] = useState(""); */

  const {
    handlegetAllShiptoOrSku,
    shiptorsku,
    handleChangePickuptype,
    handlegetSkus,
    setShiptoandskus,
    shiptoandskus,
    handlegetAllShipto,
    addressee,
    handleChangeQty,
    handleAddSkuShipto,
    returnList,
    setToast,
    handleConfirmReturnOrder,
    handleDeleteReturnList,
    handleAddressee,
    selectaddressee,
    setReturnList,
    handleDescSkus,
    options,
    locked,
    handleGetMaterialGroup,
    materialGroup,
    materialGroupSelected,
    setMaterialGroupSelected,
    selectShiptorsku,
    setSelectShiptorsku,
    shearch,
    setShearchItem,
    shearchItem,
    handleChangeObservation,
    observation,
    valueAutocomplete,
    setValueAutocomplete,
    selectaddresseelabel,
    pickuptype,
    selectSkuDesc,
    setSelectSkuDesc,
    selectShiptoDesc,
    setSelectShiptoDesc,
    pageTransfer,
    numberPageTransfer,
    setPageTransfer,
    setNumberPageTransfer,
    jwtToken,
    setPickuptype,
    selectedPDF,
    setSelectedPDF,
    isModalOpen,
    setIsModalOpen,
    estimatedValue,
    setEstimatedValue,
    observationUnlock,
    setObservationUnlock,
    pdfList,
    setPdfList,
  } = TransfersRequestController(props);

  const pyckupType = [
    { label: "Recoge account manager", value: "Recoge account manager" },
    { label: "Recoge transportadora", value: "Recoge transportadora" },
  ];
  // var jwt = jwtDecode(props.userToken);
  useEffect(
    async () => {
      if (request == 1) {
        setSelectSkuDesc("");
        setShiptoandskus([]);
        await handlegetSkus(props.userToken, null, null, selectShiptorsku);
        await handlegetAllShiptoOrSku(props.userToken, "sku", "shipto");
      } else if (request == 2) {
        /*   if (!selectShiptorsku) {
          setSelectShiptorsku("");
        } */
        setSelectShiptoDesc("");
        setShiptoandskus([]);
        await handlegetSkus(props.userToken, selectShiptorsku, null, null);
        await handlegetAllShiptoOrSku(props.userToken, "shipto", "sku");
      }
    },
    [shearchItem, request, selectShiptorsku, pageTransfer]
  );

  useEffect(async () => {
    await handlegetAllShipto(props.userToken, "shipto");
  }, []);
  useEffect(async () => {
    await handleGetMaterialGroup(props.userToken);
  }, []);

  // const handleChange = (e) => {
  //   const values = e.target.value.split("/", 2);
  //   const value = values[0];
  //   const name = values[1];
  //   setSelectSkuDesc(name);
  //   setSelectShiptorsku(value);
  //   handleDescSkus(name);
  // };
  const handleChange4 = (e) => {
    handleChangePickuptype(e.target.value);
  };
  /*   const handleChange2 = (e) => {
    const values = e.target.value.split("/", 2);
    const shipto = values[0];
    const name = values[1];
    handleAddressee(name, shipto);

    setReturnList([]);
  }; */

  const handlePDFChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileNameParts = selectedFile.name.split(".");
      const fileExtension = fileNameParts[
        fileNameParts.length - 1
      ].toLowerCase();

      // Verifica que la extensión sea PDF.
      if (fileExtension === "pdf") {
        // Verifica el tamaño del archivo (en bytes).
        if (selectedFile.size <= 4.49 * 1024 * 1024) {
          setSelectedPDF(selectedFile);
        } else {
          alert(
            "El archivo seleccionado es demasiado grande. Por favor, seleccione un archivo PDF de 4.49MB o menos."
          );
          setSelectedPDF(null);
        }
      } else {
        alert("Por favor, seleccione un archivo PDF.");
        setSelectedPDF(null);
      }
    }
  };

  const handleChangePage = (pageTransfer) => {
    setPageTransfer(pageTransfer - 1);
  };
  useEffect(
    () => {
      setValueAutocomplete(null);
    },
    [request]
  );

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Grid xs={12} container className={classes.container}>
        <Grid xs={8} className={classes.colum1}>
          <Grid xs={12}>
            <HeaderTransfersRequest request={request} setRequest={setRequest} />
          </Grid>
          <Grid container xs={12} className={classes.select}>
            <Grid xs={2}>
              <h8 className={classes.txt}>
                {request == 1 ? "Selecciona modelo:" : "Selecciona cliente:"}
              </h8>
            </Grid>
            <Grid xs={10}>
              <Autocomplete
                options={options}
                value={valueAutocomplete}
                onChange={(e, newValue) => {
                  setPageTransfer(0);
                  setValueAutocomplete(newValue?.label);
                  setSelectSkuDesc(newValue?.label);
                  setSelectShiptorsku(newValue?.value);
                  setSelectShiptoDesc(newValue?.label);
                  handleDescSkus(newValue?.label);
                }}
                renderInput={(params) => <TextField {...params} />}
                sx={{
                  "@media (max-Width: 960px)": {
                    width: 100,
                    height: 50,
                    fontSize: "1vh",
                    marginLeft: "20%",
                  },
                }}
              />
              <br />
              <br />
              <Autocomplete
                options={pyckupType}
                value={pickuptype}
                onChange={(e, newValue) => {
                  setPickuptype(newValue?.value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Tipo de traslado" />
                )}
                sx={{
                  width: 200,
                  "@media (max-Width: 960px)": {
                    width: 100,
                    height: 50,
                    fontSize: "1vh",
                    marginBottom: "3px",
                  },
                }}
              />

              <br />
              <br />
              <Autocomplete
                options={addressee}
                value={selectaddresseelabel}
                onChange={(e, newValue) => {
                  handleAddressee(
                    newValue?.cust_name,
                    newValue?.shipto,
                    newValue?.locked,
                    newValue?.label
                  );
                  setReturnList([]);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Destinatario" />
                )}
                sx={{
                  "@media (max-Width: 960px)": {
                    height: 50,
                    fontSize: "1vh",
                    marginBottom: "3px",
                  },
                }}
              />
              <br />
              <Grid xs={12} container>
                <UpdatePdf pdf={selectedPDF} onChange={handlePDFChange} />
              </Grid>
              <br />
              {request == 2 && (
                <Grid xs={6}>
                  {/*  <Autocomplete
                    options={materialGroup}
                    value={materialGroupSelected}
                    autoHighlight
                    onChange={(e, newValue) => {
                      setMaterialGroupSelected(newValue?.value);
                    }}
                    sx={{ width: "90%" }}
                    renderInput={(params) => (
                      <TextField {...params} label="Familia" />
                    )}
                  /> */}
                  <br />
                  <Autocomplete
                    value={shearchItem}
                    options={shearch}
                    onChange={(e, newValue) => {
                      setShearchItem(newValue?.value);
                      setPageTransfer(0);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Buscador" />
                    )}
                    sx={{
                      "@media (max-Width: 960px)": {
                        width: 100,
                        height: 50,
                        fontSize: "1vh",
                      },
                    }}
                  />
                  <Grid>
                    <ModalTransfersUnlockRequest
                      returnList={returnList}
                      handleConfirmReturnOrder={handleConfirmReturnOrder}
                      isOpen={isModalOpen}
                      onClose={closeModal}
                      estimatedValue={estimatedValue}
                      setEstimatedValue={setEstimatedValue}
                      observationUnlock={observationUnlock}
                      setObservationUnlock={setObservationUnlock}
                      pdfList={pdfList}
                      setPdfList={setPdfList}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container xs={12} className={classes.select}>
            <TextField
              id="standard-multiline-flexible"
              label="Observación"
              multiline
              maxRows={4}
              size="small"
              value={observation}
              onChange={handleChangeObservation}
              inputProps={{ maxLength: 255 }}
              helperText={`${observation.length}/${255}`}
              sx={{
                width: 500,
              }}
            />
          </Grid>
          <Grid xs={12} className={classes.bodyreturnrequest}>
            <Grid>
              {request === 1 ? (
                <h8>
                  {" "}
                  Mostrando inventario de modelo:
                  <b>{selectShiptoDesc}</b>
                </h8>
              ) : (
                <h8>
                  {" "}
                  Mostrando inventario de cliente:
                  <b>{selectSkuDesc}</b>
                </h8>
              )}
              <hr className={classes.Line} />
            </Grid>
            {shiptoandskus
              ?.filter((item) => item.shipto != "")
              ?.map((item, id) => (
                <div>
                  <TransfersRequestbody
                    setShiptoandskus={setShiptoandskus}
                    shiptosorskus={item}
                    index={id}
                    handleAddSkuShipto={handleAddSkuShipto}
                    handleChangeQty={handleChangeQty}
                    request={request}
                    locked={locked}
                  />
                </div>
              ))}
          </Grid>
          {shiptoandskus && (
            <Pagination
              hideNextButton={true}
              hidePrevButton={true}
              onChange={(e) => handleChangePage(e.target.textContent)}
              count={
                parseInt(numberPageTransfer) < 10
                  ? 1
                  : parseInt(numberPageTransfer) % 10 != 0
                    ? parseInt(numberPageTransfer / 10) + 1
                    : parseInt(numberPageTransfer / 10)
              }
              page={pageTransfer + 1}
            />
          )}
        </Grid>
        <Grid xs={4} className={classes.colum2}>
          <h4 className={classes.txtResumen}>Resumen</h4>
          {returnList?.map((item, index) => (
            <AbstractComponent
              model={item.model}
              qty={item.qty}
              shipto={item.shipto}
              shiptoName={item.shiptoName}
              batch={item.batch}
              deleteCard={handleDeleteReturnList}
              index={index}
              addressee={item.addressee}
            />
          ))}
          {returnList?.length > 0 && jwtToken.groupSlug !== "super-user" ? (
            <Grid xs={12}>
              <Button
                fullWidth
                variant="contained"
                onClick={() =>
                  locked === true && request === 2
                    ? openModal()
                    : handleConfirmReturnOrder()
                }
                sx={{
                  "@media (max-Width: 960px)": {
                    fontSize: "1vh",
                  },
                }}
              >
                Confirmar
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Toast
        innerRef={(ref) => {
          setToast(ref);
        }}
      />
    </div>
  );
};

export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
    data: state.root.general.dataUserAPI,
    applicant: state.root.user.userInfo.firstName,
    id: state.root.user.userInfo.memberId,
    address: state.root.user.userInfo.address,
    contactNo: state.root.user.userInfo.contactNo,
  }))(TransfersRequest)
);
