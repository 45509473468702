/* eslint-disable */
import React from "react";
import { connect } from 'react-redux';
import cookie from 'js-cookie';
import { withRouter } from "react-router-dom";

// core components
import {changeLanguage, clear} from 'redux/actions/general';
import * as request from 'redux/actions/request';
import Login from 'views/Login/Login.jsx'
import * as util from '../../util/util';

class App extends React.Component {

    state = {
        username: '',
        password: '',
        code: '',
        authorizationAlert: null,
        lockedUsername: false,
        Clanguage: false
    }

    setLoaderVisible = (bShow) => {
        this.props.setLoaderVisible(bShow);
    }

    clearStoreData = () => {
        this.props.dispatch(clear());
        cookie.remove('token');
    }

    componentDidMount = async () => {
        if(this.props.userToken){
            this.props.history.push("/dashboard")
        }else{
            cookie.remove('token');
        }
        // this.clearStoreData();
        // check url parameters
        if(this.props.match.params.username){
            this.setState({ username: this.props.match.params.username, lockedUsername: true });
        }
    }

    handleInputChange = (e) => {
        var key = e.target.id;
        var value = e.target.value;
        this.setState({ [key]: value });
    }

    complete = () => {
        this.props.showToast('error', "Email address and password is required");
    }

    handleSubmit = async (username, password, code) => {
        if(this.props.language.language){
            this.setState({Clanguage: true})
        }
        this.clearStoreData();
        this.setLoaderVisible(true);        
        this.props.dispatch(request.login(username, password, code)).then(result => {
            this.setLoaderVisible(false);
            if (result.error) {
                if(result.response.errors){
                let errors = result.response.errors;
                let message = errors[0].message;
                this.props.showToast('error', message);
                }else{
                  this.props.showToast('error', result.response.description);
                }              
            } else {
                cookie.set('token', result.data.token);
                this.props.dispatch(request.getUserDataToken()).then(() => {

                    this.setupLanguageTranslate(result.data.token);
                   // this.props.dispatch(updateMultiFactorAuthentication("offMTF"));
                }).then(() => {
                    this.navigateToDashboard();
                });
            }
        });
        this.setState({code: ''})
    }

    setupLanguageTranslate = (token) => {
        var jwt = util.decodeToken(token);
        var language='/en/en';
        if(jwt.language){
            language = '/en/' + jwt.language;
        }
        if(language !== '/en/en'){
            this.props.addGoogleTranslateScript(language);
        }else{
            this.props.addGoogleTranslateScript('');
        }
    }

    navigateToDashboard = async() => {
        if(this.state.Clanguage){
            await this.props.dispatch(changeLanguage());
            this.setState({Clanguage: false})
        }
        this.props.history.push('/dashboard');
        console.log("1x", this.props)
        // window.location.reload();
    }

   /* handleSubmitAuthiorzation = async (code) => {
        var { username, password } = this.state;
        this.setLoaderVisible(true);
        const result = await this.props.dispatch(request.login(username, password, code));
        this.setLoaderVisible(false);
        if (result.error) {
            this.props.showToast('error', result.description);
            // show error message
            console.log('login error', result);
        } else {
            cookie.set('token', result.data.authenticate.jwt);
            this.props.dispatch(request.getUserData()).then(() => {
                this.setupLanguageTranslate(result.data.authenticate.jwt);
                this.props.dispatch(updateMultiFactorAuthentication("onMTF"));
            }).then(() => {
                this.navigateToDashboard();
            });
        }
    }*/

   /* hideAlert() {
        this.setState({
            authorizationAlert: null
        });
    }*/

    render() {

        const style = {
            height: "100vh",
            display:"flex",
            alignItems:"center",
            paddingTop:"10vh",
            overflow: "hidden"
            // backgroundImage: "url('/backHome.jpg')",
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "cover",
            // backgroundPosition: "center",
        }

        const styleImg = {
            position: "absolute",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            top: 0,
            opacity: 0.85,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }

        return (
            <div style={style}>
                <Login
                    handleInputChange={this.handleInputChange}
                    handleSubmit={this.handleSubmit}
                    complete={this.complete}
                    {...this.state}
                />
                <div style={styleImg}>
                    <img src="/backHome.jpg" alt="..." style={{objectFit: "cover",  width: "100%", height: "100%"}}/>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(
    (state) => ({
       userToken: state.root.user.userToken,
       userInfo: state.root.user.userInfoToken,
       language: state.root.general.change_language,
    })
)(App))
