////firebase

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import {saveToken} from 'protocols/requests'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


//--------------------------------------------------------------

const firebaseConfig = {
  apiKey: "AIzaSyA6lx-ZpE2E17VNbHymAC4BzVw86v4jw9E",
  authDomain: "healthenviron-8f922.firebaseapp.com",
  projectId: "healthenviron-8f922",
  storageBucket: "healthenviron-8f922.appspot.com",
  messagingSenderId: "921801447343",
  appId: "1:921801447343:web:a549666b77e4521238415a"
};


export const configFirebase = async (props) => {
   
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);
    let response = null; 
    await Notification.requestPermission().then((permission) => {
      if(permission === 'granted'){    

        getToken(messaging, { vapidKey: 'BCeZMj3gnlAl3bbsm3pXYUyxDgzI3eMMMlJR1ZF6iK1zAmQueBFDosWm84hNciIWwWsLrQjx5lG7b03_jpdppHs' })
        .then(async (currentToken) => {     
         if (currentToken) {
           const body = {
            token: currentToken
           }
           const res = await saveToken(body, props.userToken);  
           // Send the token to your server and update the UI if necessary
           // ...
         } else {
           // Show permission request UI
           console.log('x1, No registration token available. Request permission to generate one.');
           // ...
         }
        }).catch((err) => {
          console.log('x1, An error occurred while retrieving token. ', err);
          // ...
        });  

        response = { code: 200, messaging}   

      }else{
        response = {code: 403 , description: "Notification permission denied."}
      } 
    });    
   return response;
  }

