/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import notificationStyle from "assets/jss/material-dashboard-pro-react/views/notificationStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Imput from "components/CustomInput/Input";
import Buttom from "components/CustomButtons/Button";
import { useTranslation } from "react-i18next";

const SettingDaysComponent = ({ classes, ...rest }) => {
  const { t } = useTranslation("userView");
  //const { onSubmit } = props;
  return (
    <Grid container xs={12} className={classes.container} spacing={2}>
      <Grid item xs={12}>
        <Imput placeHolder={t("Days")} onChange={rest.onChange} />
      </Grid>
      <Grid item xs={12} className={classes.ctnButton}>
        <Buttom
          children={t("Send")}
          color={"success"}
          onClick={rest.onSubmit}
        />
      </Grid>
    </Grid>
  );
};

SettingDaysComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(notificationStyle)(SettingDaysComponent);
