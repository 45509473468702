import React, { useState, useEffect } from "react";
import { Grid, makeStyles, withStyles, Box, Modal } from "@material-ui/core";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Button } from "@mui/material";
import Toast from "components/Toast/Toast.jsx";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

const useStyles = makeStyles((theme) => ({
  containerOrderAuthorization: {
    width: "90%",
    height: "500px",
    marginTop: "80px",
    padding: "10px",
    // backgroundColor: "blue",
  },
  ordersApproved: {
    boxShadow: "2px 2px 10px green",
    borderRadius: 10,
    marginTop: "10px",
    padding: 20,
  },
  ordersDenegaded: {
    boxShadow: "2px 2px 10px red",
    borderRadius: 10,
    marginTop: "10px",
    padding: 20,
  },
  orders: {
    boxShadow: "2px 2px 10px gray",
    borderRadius: 10,
    marginTop: "10px",
    padding: 20,
  },
  containerdetail: {
    // display: "flex",
  },

  ordersdetail: {
    boxShadow: "2px 2px 10px gray",
    borderRadius: 10,
    marginTop: "10px",
    padding: 20,
  },
  ordersdetailApproved: {
    boxShadow: "2px 2px 10px green",
    borderRadius: 10,
    marginTop: "10px",
    padding: 20,
  },
  ordersdetailDenegaded: {
    boxShadow: "2px 2px 10px red",
    borderRadius: 10,
    marginTop: "10px",
    padding: 20,
  },
  column1: {
    alignItems: "center",
    //paddingLeft: "5%",
    //paddingTop: "3%",
    // backgroundColor: "yellow",
  },
  column2: {
    textAlign: "center",
    paddingTop: "1%",
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "50px",
    // backgroundColor: "orange",
  },
  txtdetails: {
    fontSize: "100%",
    marginRight: 5,
  },
  txtaprobation: {
    fontSize: "90%",
    marginRight: 20,
  },
  txtorder: {
    fontSize: "110%",
    fontWeight: "bold",
  },
  txtproducts: {
    color: "gray",
    fontSize: "2vh",
    marginTop: "2px",
    marginLeft: 5,
  },
  txtproductsChild: {
    color: "gray",
    fontSize: "85%",
    marginTop: "2px",
    marginLeft: "10%",
  },
  productName: {
    //display: "flex",
    //textAlign: "center",
    fontSize: "2vh",
    marginLeft: "10%",
    marginTop: 20,
  },
  buttons: {
    //paddingTop: "8%",
    //paddingLeft: "20%",
  },
  habitualButton: {
    marginRight: "10px",
  },
  txtQty: {
    color: "black",
  },
  txtShipto: {
    color: "gray",
  },
  containerTxtShipto: {
    marginLeft: 5,
  },
  "@media (max-Width: 960px)": {
    ordersApproved: {
      fontSize: "1vh",
      wordWrap: "breakWord",
    },
    orders: { fontSize: "1vh", wordWrap: "breakWord" },
    ordersDenegaded: {
      fontSize: "1.2vh",
      wordWrap: "breakWord",
    },
    ordersdetailApproved: {
      fontSize: "1.2vh",
      wordWrap: "breakWord",
    },
    ordersdetailDenegaded: {
      fontSize: "1.2vh",
      wordWrap: "breakWord",
    },
    txtorder: {
      fontSize: "1.2vh",
    },
    txtdetails: {
      fontSize: "1.2vh",
    },
    productName: {
      fontSize: "1.2vh",
    },
    txtQty: {
      fontSize: "1.2vh",
    },
    txtproducts: {
      fontSize: "1vh",
    },
    optimun_base: {
      fontSize: "1.2vh",
    },
    txtproductsChild: {
      fontSize: "1.2vh",
    },
    containerTxtShipto: {
      fontSize: "1.2vh",
    },
    txtShipto: {
      fontSize: "1.2vh",
    },
    column2: {
      paddingRight: "10px",
    },
    shiptolist: {
      fontSize: "1.2vh",
    },
  },
}));
const Order = (props) => {
  const {
    order,
    id,
    handleUpdateOrder,
    history,
    returnOrder,
    quality,
    finance,
    pending,
    transferpending,
    transfer,
    superuser,
    setDisable,
    disable,
    selectedPDF,
    setSelectedPDF,
    handleSubmitPdf,
    downloadPdf,
    UpdatePdf,
    accountManager,
  } = props;
  const [detail, setDetail] = useState(true);
  const classes = useStyles();
  let skusorder = order.skus;
  let found;

  let aggregatedSkus = skusorder.reduce((acc, curr) => {
    let found;
    if (
      (curr.batch === "" && curr.serial === "") ||
      (!curr.batch && !curr.serial)
    ) {
      found = acc.find((item) => item.model === curr.model);
    } else if (curr.batch && curr.serial === "") {
      found = acc.find(
        (item) => item.model === curr.model && item.batch === curr.batch
      );
    } else if (curr.batch === "" && curr.serial) {
      found = acc.find(
        (item) => item.model === curr.model && item.serial === curr.serial
      );
    } else if (curr.batch && curr.serial) {
      found = acc.find(
        (item) =>
          item.model === curr.model &&
          item.batch === curr.batch &&
          item.serial === curr.serial
      );
    }

    if (found) {
      let quantity = parseInt(found.quantity);
      quantity += parseInt(curr.quantity);
      found.quantity = quantity;
    } else {
      acc.push({ ...curr });
    }

    return acc;
  }, []);

  const handlePDFChange = (event, id) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileNameParts = selectedFile.name.split(".");
      const fileExtension = fileNameParts[
        fileNameParts.length - 1
      ].toLowerCase();

      // Verifica que la extensión sea PDF.
      if (fileExtension === "pdf") {
        // Verifica el tamaño del archivo (en bytes).
        if (selectedFile.size <= 4.49 * 1024 * 1024) {
          setSelectedPDF({ pdf: selectedFile, id: id });
          handleSubmitPdf(order.order_id, selectedFile);
        } else {
          alert(
            "El archivo seleccionado es demasiado grande. Por favor, seleccione un archivo PDF de 4.49MB o menos."
          );
          setSelectedPDF(null);
        }
      } else {
        alert("Por favor, seleccione un archivo PDF.");
        setSelectedPDF(null);
      }
    }
  };

  /*  useEffect(
    () => {
      if (transfer && history) {
        handleSubmitPdf(order.order_id);
      }
    },
    [selectedPDF]
  ); */

  return (
    // <Grid container xs={12} className={classes.containerOrderAuthorization}>
    <Grid
      xs={12}
      className={
        detail && order.isApproved == true
          ? classes.ordersApproved
          : detail && order.isApproved == false
            ? classes.ordersDenegaded
            : !detail && order.isApproved == true
              ? classes.ordersdetailApproved
              : !detail && order.isApproved == false
                ? classes.ordersdetailDenegaded
                : classes.orders
      }
      container
    >
      <Grid xs={6} className={classes.column1}>
        <h5 className={classes.txtorder}>
          {" "}
          {returnOrder
            ? "Retorno #"
            : transfer || transferpending
              ? "Traslado #"
              : "Pedido #"}
          {order.order_id}
        </h5>
        <h5 className={classes.txtorder}>
          Account Manager: {order.account_manager}
        </h5>
        <h5 className={classes.txtorder}>
          {" "}
          {transfer ? "Tipo de recogida: " : null}
          {order.pickupOption}
        </h5>
        {!history ||
          (history &&
            order.codeSap && (
              <h5 className={classes.txtdetails}>
                Codigo de pédido SAP: {order.codeSap}
              </h5>
            ))}

        {order.observation && (
          <b>
            Observaciones: {order.observation} <br />
          </b>
        )}
        {(order.lifeUrgency && order.lifeUrgency == true) ||
        (order.lifeUrgency && order.lifeUrgency == 1) ? (
          <h5 color="red">Urgencia Vital</h5>
        ) : null}
        {transfer || history ? (
          <b>
            Estado:{" "}
            {order.isApproved == false && transfer
              ? "Rechazado por " + order.rechazado
              : order.status}
          </b>
        ) : null}
      </Grid>
      <Grid xs={6} className={classes.column2}>
        {history && detail && order.isApproved != "create" ? (
          <h5 className={classes.txtaprobation}>
            {order.isApproved == true
              ? "Fecha de actualización: "
              : pending && transferpending
                ? "Pendiente de aprobar por:" + order.pendingApproval
                : pending
                  ? "Pendiente"
                  : "Fecha de actualización: "}
            {order.approval_date}
          </h5>
        ) : (history && order.isApproved == "create") ||
        (finance && detail && !history) ||
        (quality && detail && !history) ? (
          <h5 className={classes.txtaprobation}>
            Fecha de creación:
            {order.creation_date}
          </h5>
        ) : null}
        {detail ? (
          <h5 className={classes.txtdetails}>Ver detalles del pedido</h5>
        ) : (
          <h5 className={classes.txtdetails}>Ocultar detalles del pedido</h5>
        )}
        {detail ? (
          <ArrowDownwardIcon
            fontSize="small"
            onClick={() => {
              setDetail(!detail);
            }}
            sx={{
              cursor: "pointer",
            }}
          />
        ) : (
          <ArrowUpwardIcon
            fontSize="small"
            onClick={() => {
              setDetail(!detail);
            }}
            sx={{
              cursor: "pointer",
            }}
          />
        )}
      </Grid>
      {!detail ? (
        <Grid xs={12} container className={classes.containerdetail}>
          {returnOrder ? (
            skusorder?.map((item, id) => (
              <Grid xs={6} item>
                <Grid xs={12} className={classes.productName}>
                  <h5 className={classes.txtproducts}>
                    {" "}
                    <b className={classes.txtQty}>({item.quantity} x) </b>{" "}
                    Modelo {item.desc} {item.model}
                  </h5>
                  <h5 className={classes.txtproductsChild}>
                    {" "}
                    Inventario óptimo: {item.optimun_base}
                  </h5>
                  <h5 className={classes.txtproductsChild}>
                    {" "}
                    Inventario actual: {item.current_inv}
                  </h5>
                  <h5 className={classes.txtproductsChild}>
                    {" "}
                    Lote: {item.batch}
                  </h5>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid xs={6} item>
              {(aggregatedSkus || []).map((sku, id) => (
                <Grid xs={12} className={classes.productName}>
                  <h5 className={classes.txtproducts}>
                    {" "}
                    <b className={classes.txtQty}>
                      ({sku.quantity} x){" "}
                    </b> Modelo {sku.desc} {sku.model}
                  </h5>
                  {finance || quality ? (
                    <h5 className={classes.txtproductsChild}>
                      {" "}
                      Remitente: {sku.name_shipto}
                    </h5>
                  ) : null}
                  <h5 className={classes.txtproductsChild}>
                    {" "}
                    Inventario óptimo: {sku.optimun_base}
                  </h5>
                  <h5 className={classes.txtproductsChild}>
                    {" "}
                    Inventario actual: {sku.current_inv}
                  </h5>
                  {sku.batch ? (
                    <h5 className={classes.txtproductsChild}>
                      {" "}
                      Lote: {sku.batch}
                    </h5>
                  ) : null}
                  {sku.serial ? (
                    <h5 className={classes.txtproductsChild}>
                      {" "}
                      Serial: {sku.serial}
                    </h5>
                  ) : null}
                  {sku.code_sap ? (
                    <h5 className={classes.txtproductsChild}>
                      {" "}
                      SAP: {sku.code_sap}
                    </h5>
                  ) : null}
                  {sku.remision ? (
                    <h5 className={classes.txtproductsChild}>
                      {" "}
                      Codigo de Remisión: {sku.remision}
                    </h5>
                  ) : null}
                  {sku.expirationDate ? (
                    <h5 className={classes.txtproductsChild}>
                      {" "}
                      Fecha de Vencimiento: {sku.expirationDate.split(" ")[0]}
                    </h5>
                  ) : null}
                </Grid>
              ))}
            </Grid>
          )}
          <Grid xs={6} container item>
            <Grid xs={1}>
              <LocationOnOutlinedIcon
                fontSize="small"
                sx={{ fontSize: "4vw" }}
              />
            </Grid>
            <Grid xs={10} className={classes.containerTxtShipto}>
              <h5 className={classes.txtShipto}>
                {returnOrder
                  ? "El pedido sera retornado a bodega"
                  : quality || finance
                    ? "El pedido sera trasladado a:"
                    : "El pedido sera enviado a"}
              </h5>
              <h3 className={classes.shiptolist}>
                {returnOrder ? (
                  <div>
                    <h5 className={classes.shiptolist}>
                      {skusorder[0]?.warehouse}
                    </h5>{" "}
                    <h5 className={classes.shiptolist}>
                      {skusorder[0]?.name_shipto}
                    </h5>{" "}
                    <h5 className={classes.shiptolist}>
                      Shipto: {skusorder[0]?.ship_to}
                    </h5>
                  </div>
                ) : quality || finance ? (
                  order.receiver
                ) : (
                  (aggregatedSkus || []).map((item) => (
                    <div>
                      <h5 className={classes.shiptolist}>{item.name_shipto}</h5>{" "}
                    </div>
                  ))
                )}
              </h3>
              {!returnOrder && transfer
                ? "Shipto:" + order.ship_to_receiver
                : !returnOrder && history
                  ? "Shipto:" + skusorder[0]?.ship_to
                  : null}{" "}
              <br />
              {order.pickupOption == "Recoge account manager" &&
                order.isApproved == true &&
                accountManager &&
                history && (
                  <UpdatePdf
                    pdf={selectedPDF}
                    onChange={handlePDFChange}
                    id={id}
                  />
                )}
              {order.documents ? (
                <Button
                  onClick={() =>
                    downloadPdf(order?.documents[0], order?.order_id)
                  }
                >
                  Descargar Archivo Adjunto
                </Button>
              ) : null}
            </Grid>
          </Grid>

          {((superuser && order.isApproved == true) ||
            (superuser && order.isApproved == "pending") ||
            (superuser && order.isApproved == "create") ||
            (superuser && order.status == "Solicitud aprobada por finanzas") ||
            (superuser &&
              order.status == "Solicitud aprobada por calidad")) && (
            <Grid xs={12} className={classes.buttons}>
              <Button
                onClick={() => {
                  const statusOrder = 2;
                  let superUser = true;
                  handleUpdateOrder(order.order_id, statusOrder, superUser);
                  // window.location.reload();
                  setDisable(true);
                }}
                key={id}
                size="small"
                variant="outlined"
                sx={{
                  fontSize: "1.5vh",
                  color: "black",
                  float: "right",
                }}
                disabled={disable}
              >
                {returnOrder
                  ? "RECHAZAR RETORNO"
                  : transfer
                    ? "RECHAZAR TRASLADO"
                    : "RECHAZAR PEDIDO"}
              </Button>
            </Grid>
          )}

          {!history && (
            <Grid xs={12} className={classes.buttons}>
              <Button
                onClick={() => {
                  const statusOrder = 2;
                  handleUpdateOrder(order.order_id, statusOrder);
                  // window.location.reload();
                  setDisable(true);
                }}
                key={id}
                size="small"
                variant="outlined"
                sx={{
                  fontSize: "1.5vh",
                  color: "black",
                  float: "right",
                }}
                disabled={disable}
              >
                {returnOrder
                  ? "RECHAZAR RETORNO"
                  : transfer
                    ? "RECHAZAR TRASLADO"
                    : "RECHAZAR PEDIDO"}
              </Button>
              {returnOrder && (
                <Button
                  onClick={() => {
                    const statusOrder = 3;
                    handleUpdateOrder(order.order_id, statusOrder);
                    setDisable(true);
                  }}
                  key={id}
                  size="small"
                  variant="outlined"
                  sx={{
                    fontSize: "1.5vh",
                    color: "black",
                    float: "right",
                    marginRight: "5px",
                  }}
                  disabled={disable}
                >
                  APROBACIÓN HABITUAL
                </Button>
              )}
              <Button
                onClick={() => {
                  const statusOrder = finance ? 1 : quality ? 3 : 1;
                  handleUpdateOrder(order.order_id, statusOrder);
                  // window.location.reload();
                  setDisable(true);
                }}
                key={id}
                size="small"
                variant="contained"
                sx={{
                  fontSize: "1.5vh",
                  backgroundColor: "#5CC7F8",
                  marginRight: "3%",
                  float: "right",
                }}
                disabled={disable}
              >
                {returnOrder
                  ? "APROBAR RETORNO"
                  : transfer
                    ? "APROBAR TRASLADO"
                    : "APROBAR PEDIDO"}
              </Button>
            </Grid>
          )}
        </Grid>
      ) : null}
    </Grid>
    // </Grid>
  );
};

export default Order;
