import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Stepcomponent1Styles from "assets/jss/material-dashboard-pro-react/components/stepSkusStyles";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import ErrorIcon from "@mui/icons-material/Error";
import Skus from "..";
/**
 * This is a functional component in JavaScript that renders a single SKU item with various properties
 * and functionalities.
 * @returns The SkuItem component with its JSX code and styles applied through the withStyles HOC.
 */
const SkuItem = ({
  classes,
  shipto,
  skus,
  id,
  setAuxSkus,
  auxSkus,
  data,
  setData,
  selectauxShipto,
  selectauxMaterialGroup,
  setAmount,
  amount,
  selectauxShiptoName,
  handleAddSku,
  auxbuttonsIdSkus,
  handleDeleteSku,
  firstModel,
  shearchSkus,
  materialGroupSelected,
  pageSkus,
  skusList,
  skusAux,
  auxwherehouse_inv,
  ArraySkus,
  idx,
  orderSkusByKits,
}) => {
  const inv = parseInt(skus.current_inv);
  const opt = parseInt(skus.optimum_national);

  //const [auxbuttonsIdSkus, setAuxbuttonsIdSkus] = useState([]);
  const [active, setActive] = useState(false);
  const [amountsku, setAmountsku] = useState("");
  let exist = false;

  useEffect(
    () => {
      setAmountsku("");
    },
    [selectauxShipto, shearchSkus, materialGroupSelected, pageSkus]
  );
  const handleChange = (e) => {
    setAmountsku(e.target.value);
    skus.quantity = e.target.value;
  };

  /* This code is checking if a SKU item with a specific `id` and `shiptoSelect` already exists in the
`auxbuttonsIdSkus` array. If it does, it sets the `exist` variable to `true` and assigns the
`quantity` value of that SKU item to the `value` variable. This is used later in the component to
display the existing quantity value in the input field. */
  let value;

  for (let item of auxbuttonsIdSkus) {
    if (item.id == id && item.shiptoSelect == selectauxShipto) {
      exist = true;
      value = item.quantity;
    }
  }
  for (let item of orderSkusByKits) {
    if (item.id == id && item.benefittingRep == selectauxShipto) {
      exist = true;
      value = item.amountTaken || item.backorderQty;
      if (value >= item.warehouse_inv && ArraySkus[idx + 1])
        ArraySkus[idx + 1].block = false;
    }
  }

  //LOGICA PARA RESTAR UNIDADES

  /* This code is iterating through the `auxSkus` array and checking if a SKU item with certain
conditions exists. The conditions are: */
  for (let item of auxSkus) {
    if (
      item.shiptoSelect != selectauxShipto &&
      shearchSkus &&
      item.quantity &&
      item.id == id &&
      item.warehouse_nearby == skus.warehouse_nearby &&
      item.model == skus.model &&
      item.batch == skus.batch
    ) {
      // skus.warehouse_inv = item.warehouse_inv;
      //BLOQUEO DE BODEGAS 0 PRESELECCIONADAS
      if (skus.warehouse_inv == 0) {
        skus.block = true;
        if (ArraySkus[idx + 1]) {
          ArraySkus[idx + 1].block = false;
        }
      }
    }
  }

  //
  /* This is a `useEffect` hook that is used to reset the `amountsku` state variable to an empty string
whenever any of the dependencies (`selectauxShipto`, `shearchSkus`, `materialGroupSelected`,
`pageSkus`) change. This is useful for resetting the input field value whenever the user selects a
different shipto, searches for a different SKU, selects a different material group, or changes the
page of the SKU list. */

  /* The above code is rendering a component that displays information about a SKU (stock keeping unit)
and allows the user to add or modify the quantity of that SKU to their order. The component includes
fields for the SKU's model, description, hierarchy, unit, optimum national inventory, current
inventory, nearby warehouse inventory, and warehouse inventory. It also includes a text field for
the user to input the quantity they want to order, and buttons to add or modify the SKU in the
order. The component also includes error handling for cases where the user inputs an invalid
quantity or the SKU's inventory levels are insufficient. */
  return (
    <Grid container xs={12} className={classes.containerSkuItem} key={id}>
      <Grid container xs={12} className={classes.containerModel}>
        <Grid item xs={2}>
          <h6 className={classes.model}>Modelo</h6>
          <h5 className={classes.model}>{skus.model}</h5>
        </Grid>
        <Grid item xs={2}>
          <h6 className={classes.txtdesc}>Descripción</h6>
          <h5 className={classes.txtdesc}>
            {skus.material_description
              ? skus.material_description
              : skus.material_description_2}
          </h5>
        </Grid>
        <Grid item xs={2}>
          <h6 className={classes.desc}>Jerarquía</h6>
          <h5 className={classes.desc}>{skus.ph1}</h5>
        </Grid>
        <Grid item xs={5}>
          <TextField
            error={
              Number(amountsku) > skus.warehouse_inv
                ? true
                : Number(amountsku) % skus.unit !== 0
                  ? true
                  : skus.optimum_national == 0
                    ? null
                    : Number(amountsku) + skus.current_inv >
                      skus.optimum_national
                      ? true
                      : null
            }
            id={toString(id)}
            value={value ? parseInt(value) : null}
            disabled={skus.block ? true : exist ? true : false}
            label="Cantidad"
            variant="standard"
            onChange={handleChange}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: {
                min: 1,
              },
            }}
            color={
              Number(amountsku) > skus.warehouse_inv
                ? "error"
                : skus.optimum_national == 0
                  ? null
                  : Number(amountsku) + skus.current_inv > skus.optimum_national
                    ? "error"
                    : null
            }
            helperText={
              skus.warehouse_inv == 0 && skus.quantity == null
                ? "Su pedido sera clasificado como backorder"
                : skus.warehouse_inv != 0 &&
                  Number(amountsku) > skus.warehouse_inv
                  ? "Su pedido supera la cantidad en la bodega"
                  : Number(amountsku) % skus.unit !== 0
                    ? "Su pedido no tiene las unidades correctas"
                    : skus.optimum_national == 0
                      ? null
                      : Number(amountsku) + skus.current_inv >
                        skus.optimum_national
                        ? "Su pedido supera la cantidad"
                        : null
            }
            sx={{
              "@media (max-Width: 960px)": {
                fontSize: "0.5vh",
              },
            }}
          />
          {exist ? (
            <>
              <Button
                size="small"
                variant="contained"
                disabled={skus.block ? true : false}
                key={id}
                onClick={() => {
                  handleDeleteSku(skus, id);
                }}
                sx={{
                  fontSize: "1.5vh",
                  backgroundColor: "blue",
                  borderRadius: "10px",
                  "@media (max-Width: 960px)": {
                    fontSize: "1vh",
                  },
                }}
              >
                Modificar
              </Button>
            </>
          ) : (
            <>
              <Button
                size="small"
                variant="contained"
                key={id}
                disabled={
                  skus.block
                    ? true
                    : amountsku == ""
                      ? true
                      : Number(amountsku) % skus.unit !== 0 ||
                        (Number(amountsku) > skus.warehouse_inv &&
                          skus.warehouse_inv != 0)
                        ? true
                        : skus.quantity > 0
                          ? false
                          : true
                }
                onClick={() => {
                  skus.name = selectauxShiptoName;
                  skus.shiptoSelect = selectauxShipto;
                  skus.desc = skus.material_description;
                  handleAddSku(skus, id);
                  setAmountsku("");
                }}
                sx={{
                  fontSize: "1.5vh",
                  backgroundColor: "#5CC7F8",
                  borderRadius: "10px",

                  "@media (max-Width: 960px)": {
                    fontSize: "1vh",
                  },
                }}
              >
                Agregar
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item xs={1} className={classes.items}>
        <h5 className={classes.optimum_national}>{skus.unit}</h5>
        <h8>Unidad</h8>
      </Grid>
      <Grid item xs={2} className={classes.items}>
        <h5 className={classes.optimum_national}>{skus.optimum_national}</h5>
        <h8>Inventario óptimo</h8>
      </Grid>
      {inv > opt ? (
        <Grid item xs={3} className={classes.items}>
          <h5 className={classes.current_inv_num}>
            {skus.current_inv} <ErrorIcon fontSize="small" color="error" />
          </h5>
          <h8>Inventario actual</h8>
        </Grid>
      ) : (
        <Grid item xs={3} className={classes.items}>
          <h5 className={classes.current_inv}>{skus.current_inv}</h5>
          <h8>Inventario Actual</h8>
        </Grid>
      )}

      <Grid item xs={3} className={classes.items}>
        <h5 className={classes.warehouse_nearby}>{skus.warehouse_nearby}</h5>
        <h8>Bodega con inventario</h8>
      </Grid>
      <Grid item xs={3} className={classes.items}>
        <h5 className={classes.warehouse_inv}>{skus.warehouse_inv}</h5>
        <h8>Cantidad en bodega {skus.warehouse_nearby}</h8>
      </Grid>
    </Grid>
  );
};

export default withStyles(Stepcomponent1Styles)(SkuItem);
