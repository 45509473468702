import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormGroup,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import OrdersRequestController from "controllers/OrdersRequest/index";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ExportExcel from "react-export-excel";
import jwtDecode from "jwt-decode";
import { fieldOptions } from "../../util/fieldOptions";
import Toast from "components/Toast/Toast.jsx";
import Autocomplete from "@mui/material/Autocomplete";
import dayjs from "dayjs";

const ExcelFile = ExportExcel.ExcelFile;
const ExcelSheet = ExportExcel.ExcelSheet;
const ExcelColumn = ExportExcel.ExcelColumn;

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "7%",
  },
}));

const ExportReportsAccountManager = (props) => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [account, setAccount] = useState("");
  const [accountLabel, setAccountLabel] = useState("");

  const [orderId, setOrderId] = useState("");
  const [reportType, setReportType] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [dateError, setDateError] = useState("");
  const [reportTypeError, setReportTypeError] = useState("");
  const [accountError, setAccountError] = useState("");
  const [selectAll, setSelectAll] = useState(false);

  const {
    handleGetOrdersReport,
    clientsReports,
    allClientsReports,
    handleGetAccounts,
    accountList,
    setToast,
  } = OrdersRequestController(props);

  var jwt = jwtDecode(props.userToken);

  const typeUser = jwt.groupSlug;

  useEffect(async () => {
    handleGetAccounts();
  }, []);

  const handleSearchReports = async () => {
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    if ((startDate && !endDate) || (!startDate && endDate)) {
      setDateError("Ambas fechas (Inicio y Fin) son obligatorias.");
      return;
    } else if (startDate && endDate && !reportType) {
      setReportTypeError(
        "El tipo de reporte es obligatorio para esta busqueda"
      );
      return;
    } else if (account && !reportType) {
      setAccountError("El tipo de reporte es obligatorio para esta busqueda");
      return;
    } else if (startDate && endDate && end.diff(start, "month", true) > 2) {
      setDateError(
        "La diferencia entre las fechas no puede ser mayor a 2 meses."
      );
      return;
    }

    setDateError("");
    setReportTypeError("");
    setAccountError("");
    await handleGetOrdersReport(
      startDate,
      endDate,
      account,
      orderId,
      reportType,
      typeUser
    );
  };

  const handleExportClick = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedFields([]);
    setSelectAll(false);
  };

  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    setSelectAll(checked);

    if (checked) {
      // Selecciona todos los campos
      const allFields = fieldOptions[typeUser][reportType]?.map(
        (field) => field.value
      );
      setSelectedFields(allFields);
    } else {
      // Deselecciona todos los campos
      setSelectedFields([]);
    }
  };

  const handleFieldSelection = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setSelectedFields((prevFields) => [...prevFields, value]);
    } else {
      setSelectedFields((prevFields) =>
        prevFields.filter((field) => field !== value)
      );
    }
    if (
      selectedFields.length + 1 ===
      fieldOptions[typeUser][reportType]?.length
    ) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  const generateExportData = () => {
    return allClientsReports.flatMap((report) =>
      report.skus.map((sku) => {
        let rowData = {};

        selectedFields.forEach((field) => {
          // Check if the field exists in the main report object
          if (report.hasOwnProperty(field)) {
            rowData[field] = report[field];
          }
          // Check if the field exists in the sku object
          if (sku.hasOwnProperty(field)) {
            rowData[field] = sku[field];
          }
        });

        return rowData;
      })
    );
  };

  const renderTableHeaders = () => {
    switch (clientsReports[0]?.orderType) {
      case "ORDER":
        return (
          <>
            <TableCell>ID Pedido</TableCell>
            <TableCell>Modelo</TableCell>
            <TableCell>Lote</TableCell>
            <TableCell>Serial</TableCell>
            <TableCell>Account Manager</TableCell>
            <TableCell>Fecha y hora de creación</TableCell>
            <TableCell>Usuario que aprobó</TableCell>
            <TableCell>Hora de aprobación</TableCell>
            <TableCell>Usuario que rechazó</TableCell>
            <TableCell>Hora de rechazo</TableCell>
            <TableCell>Usuario aprobación cartera</TableCell>
            <TableCell>Hora de aprobación cartera</TableCell>
          </>
        );
      case "TRANSFER":
        return (
          <>
            <TableCell>Order ID</TableCell>
            <TableCell>Modelo</TableCell>
            <TableCell>Lote</TableCell>
            <TableCell>Serial</TableCell>
            <TableCell>Nombre cliente Origen</TableCell>
            <TableCell>Nombre cliente Destino</TableCell>
            <TableCell>Tipo</TableCell>
            <TableCell>Fecha y Hora de creación</TableCell>
            <TableCell>Usuario aprobación finanzas</TableCell>
            <TableCell>Hora de aprobación finanzas</TableCell>
            <TableCell>Usuario aprobación calidad</TableCell>
            <TableCell>Hora de aprobación calidad</TableCell>
            <TableCell>Usuario que rechazó</TableCell>
            <TableCell>Hora de rechazo</TableCell>
            <TableCell>Usuario aprobación cartera</TableCell>
            <TableCell>Hora de aprobación cartera</TableCell>
            {/* <TableCell>Transportadora</TableCell> */}
            <TableCell>Account Manager</TableCell>
          </>
        );
      case "RETURN":
        return (
          <>
            <TableCell>Order ID</TableCell>
            <TableCell>Fecha y hora de creación</TableCell>
            <TableCell>Usuario que aprobó</TableCell>
            <TableCell>Hora de aprobación</TableCell>
            <TableCell>Usuario que rechazó</TableCell>
            <TableCell>Hora de rechazo</TableCell>
            {/* <TableCell>Transportadora</TableCell> */}
            <TableCell>Observaciones</TableCell>
            <TableCell>Nombre Shipto</TableCell>
            <TableCell>Fecha de creación</TableCell>
            <TableCell>Bodega</TableCell>
          </>
        );
      default:
        return null;
    }
  };

  const renderTableRows = () => {
    return clientsReports.map((report, id) => (
      <React.Fragment key={id}>
        {report.skus.map((sku, skuId) => (
          <TableRow key={skuId}>
            {report.orderType === "ORDER" && (
              <>
                <TableCell>{report.order_id}</TableCell>
                <TableCell>{sku.model}</TableCell>
                <TableCell>{sku.batch}</TableCell>
                <TableCell>{sku.serial}</TableCell>
                <TableCell>{report.account_manager}</TableCell>
                <TableCell>{report.creation_time}</TableCell>
                <TableCell>{report.approved_by_user}</TableCell>
                <TableCell>{report.approved_time}</TableCell>
                <TableCell>{report.rejected_by_user}</TableCell>
                <TableCell>{report.rejected_time}</TableCell>
                <TableCell>{report.coordinator_approval_user}</TableCell>
                <TableCell>{report.coordinator_approval_time}</TableCell>
              </>
            )}
            {report.orderType === "TRANSFER" && (
              <>
                <TableCell>{report.order_id}</TableCell>
                <TableCell>{sku.model}</TableCell>
                <TableCell>{sku.batch}</TableCell>
                <TableCell>{sku.serial}</TableCell>
                <TableCell>{sku.name_shipto}</TableCell>
                <TableCell>{report.receiver}</TableCell>
                <TableCell>{report.type}</TableCell>
                <TableCell>{report.creation_time}</TableCell>
                <TableCell>{report.finance_approval_user}</TableCell>
                <TableCell>{report.finance_approval_time}</TableCell>
                <TableCell>{report.quality_approval_user}</TableCell>
                <TableCell>{report.quality_approval_time}</TableCell>
                <TableCell>{report.rejected_by_user}</TableCell>
                <TableCell>{report.rejected_time}</TableCell>
                <TableCell>{report.coordinator_approval_user}</TableCell>
                <TableCell>{report.coordinator_approval_time}</TableCell>
                {/* <TableCell>{report.carrier}</TableCell> */}
                <TableCell>{report.account_manager}</TableCell>
              </>
            )}
            {report.orderType === "RETURN" && (
              <>
                <TableCell>{report.order_id}</TableCell>
                <TableCell>{report.creation_time}</TableCell>
                <TableCell>{report.approved_by_user}</TableCell>
                <TableCell>{report.approved_time}</TableCell>
                <TableCell>{report.rejected_by_user}</TableCell>
                <TableCell>{report.rejected_time}</TableCell>
                {/* <TableCell>{report.carrier}</TableCell> */}
                <TableCell>{report.observation}</TableCell>
                <TableCell>{sku.name_shipto}</TableCell>
                <TableCell>{report.creation_date}</TableCell>
                <TableCell>{sku.warehouse}</TableCell>
              </>
            )}
          </TableRow>
        ))}
      </React.Fragment>
    ));
  };

  return (
    <Box className={classes.container}>
      <Typography variant="h4">Exportación de Reportes</Typography>
      <Grid container spacing={2} mb={2} mt={2}>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            label="Fecha de Inicio"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            fullWidth
            error={Boolean(dateError)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            label="Fecha de Fin"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            fullWidth
            error={Boolean(dateError)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {dateError && (
            <Typography color="error" variant="body2">
              {dateError}
            </Typography>
          )}
        </Grid>

        {typeUser !== "Account-manager" && (
          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
              options={accountList}
              value={accountLabel}
              autoHighlight
              error={Boolean(accountError)}
              onChange={(e, newValue) => {
                setAccount(newValue?.value);
                setAccountLabel(newValue?.label);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Account Manager" />
              )}
            />
            {accountError && (
              <Typography color="error" variant="body2">
                {accountError}
              </Typography>
            )}
          </Grid>
        )}
      </Grid>

      <FormControl component="fieldset">
        <FormLabel component="legend">Tipo de Reporte</FormLabel>
        <RadioGroup
          aria-label="reportType"
          name="reportType"
          row
          value={reportType}
          onChange={(e) => setReportType(e.target.value)}
          error={Boolean(reportTypeError)}
        >
          {typeUser !== "quality" && (
            <>
              <FormControlLabel
                value="order"
                control={<Radio />}
                label="Pedidos"
              />
              <FormControlLabel
                value="return"
                control={<Radio />}
                label="Retornos"
              />
            </>
          )}
          <FormControlLabel
            value="transfer"
            control={<Radio />}
            label="Traslados"
          />
        </RadioGroup>
      </FormControl>
      {reportTypeError && (
        <Typography color="error" variant="body2">
          {reportTypeError}
        </Typography>
      )}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={8} md={6}>
          <TextField
            label="Order ID"
            value={orderId}
            onChange={(e) => setOrderId(e.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            fullWidth
            onClick={handleSearchReports}
            disabled={
              !startDate && !endDate && !account && !orderId && !reportType
            }
          >
            Buscar
          </Button>
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleExportClick}
            fullWidth
            disabled={!clientsReports.length}
          >
            Exportar
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>{renderTableHeaders()}</TableRow>
          </TableHead>
          <TableBody>{renderTableRows()}</TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Selecciona los campos a exportar
        </DialogTitle>
        <DialogContent>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={selectAll} onChange={handleSelectAll} />
              }
              label="Seleccionar todos"
            />
            {fieldOptions[typeUser][reportType]?.map((field) => (
              <FormControlLabel
                key={field?.value}
                control={
                  <Checkbox
                    value={field?.value}
                    checked={selectedFields.includes(field?.value)}
                    onChange={handleFieldSelection}
                  />
                }
                label={field?.label}
              />
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancelar
          </Button>
          <ExcelFile
            element={
              <Button
                onClick={handleCloseModal}
                color="primary"
                variant="contained"
                disabled={selectedFields.length < 1}
              >
                Exportar
              </Button>
            }
            filename="Reportes Health Environ"
          >
            <ExcelSheet
              data={generateExportData()}
              name="Reportes Health Environ"
            >
              {selectedFields.map((field) => (
                <ExcelColumn
                  key={field}
                  label={
                    fieldOptions[typeUser][reportType]?.find(
                      (f) => f.value === field
                    )?.label
                  }
                  value={field}
                />
              ))}
            </ExcelSheet>
          </ExcelFile>
        </DialogActions>
      </Dialog>
      <Toast
        innerRef={(ref) => {
          setToast(ref);
        }}
      />
    </Box>
  );
};
export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
    data: state.root.general.dataUserAPI,
    applicant: state.root.user.userInfo.firstName,
    id: state.root.user.userInfo.memberId,
    address: state.root.user.userInfo.address,
    contactNo: state.root.user.userInfo.contactNo,
  }))(ExportReportsAccountManager)
);
