

 // ##############################
  // // // appBar styles
  // #############################
 
  
  const userViewsStyle = theme => ({    
    popup_header:{
        border: "0.5px solid #C8C8C8",
        textAlign: "center",
        padding: 5,
        color: "black",
        fontSize: 13

    },
    popup_body:{
        border: "1px solid #C8C8C8",
        padding: 5,
        color: "black",
        fontSize: 12,
        fontWeight: "bold",
        whiteSpace: "normal",
        overflow: "hidden",
        textVerflow: "Ellipsis",
        '& span':{
            color: "grey",
        fontWeight: "normal",

        }
    }

  });
  
  export default userViewsStyle;
  