/* eslint-disable */
import React, { useTransition } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@mui/material/Grid";
import batchViewsStyle from "assets/jss/material-dashboard-pro-react/components/BatchViewStyle";
//icons
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//components
import InputForm from "components/CustomInput/InputForm";
import Select from "components/CustomInput/Select";
import Button from "components/CustomButtons/Button";
//translation
import { useTranslation } from "react-i18next";

function FilterComponent(props) {
  const {
    classes,
    showFilter,
    setShowFilter,
    batchId,
    handlerChangeData,
    handleGetScannedProductBatch,
    handleGetScannedFilter,
  } = props;
  const { t } = useTranslation("batchView");

  return (
    <>
      <div
        className={classes.filter_container}
        onClick={() => setShowFilter(!showFilter)}
      >
        <span>{t("Search Filter")}</span>
        <FontAwesomeIcon
          icon={showFilter ? faAngleUp : faAngleDown}
          style={{
            color: "grey",
            fontSize: 12,
          }}
        />
      </div>
      {showFilter ? (
        <div className={classes.filter_container_data}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <p>{t("Batch Id")}</p>
              <InputForm
                optional
                value={batchId}
                id="batchId"
                handlerChangeData={handlerChangeData}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>{t("Clinic & Hospital")}</p>
              <Select
                data={[{ name: "Imbanaco", value: "Imbanaco" }]}
                id="clinics"
                handlerChangeData={handlerChangeData}
              />
            </Grid>
            {/*<Grid item xs={12} sm={6}>
              <p>{t("Scanned date since")}</p>
              <InputForm optional />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>{t("Scanned date until")}</p>
              <InputForm optional />
            </Grid>*/}
            <Grid
              item
              xs={12}
              spacing={2}
              className={classes.filter_container_btn}
            >
              <Button
                color="info"
                size="sm"
                style={{ marginRight: 5 }}
                onClick={handleGetScannedFilter}
              >
                {" "}
                {t("Search")}
              </Button>
              <Button size="sm" style={{ marginLeft: 5 }}>
                {" "}
                {t("Clean")}
              </Button>
            </Grid>
          </Grid>
        </div>
      ) : null}
    </>
  );
}

FilterComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(batchViewsStyle)(FilterComponent);
