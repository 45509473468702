import React, { useEffect } from "react";
import UserViews from "components/userViews";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
//controller
import NotificationController from "controllers/NotificationControllers";
import empty from "../../../../assets/img/dashboard/empty.svg";

function Notifications(props) {
    const title = "Notifications";
    const {
        products,
        handleChangePage,
        totalPage,
        handlerChangeRecordsPage,
        dataFilter,
        handleGetNotification,
    } = NotificationController();

    useEffect(async () => {
        props.setLoaderVisible(true);
        await handleGetNotification(props.userToken);
        props.setLoaderVisible(false);
    }, []);

    return (
        <>
            {products.length !== 0 ?
                <UserViews
                    title={title}
                    dataFilter={dataFilter}
                    //handlerAddMember={handlerAddMember}
                    members={products}
                    handleChangePage={handleChangePage}
                    totalPage={totalPage}
                    handlerChangeRecordsPage={handlerChangeRecordsPage}
                    notifications
                    {...props}
                />
                :<img src={empty} alt="Not found data" style={{height: "16rem", margin: "6rem 3rem"}}/>}
        </>
    );
}

export default withRouter(
    connect((state) => ({
        userToken: state.root.user.userToken,
        userInfo: state.root.user.userInfoToken,
        data: state.root.general.dataUserAPI
    }))(Notifications)
);
