import React from "react";
import moment from "moment";
import { TimePicker } from "antd";

function TimePickerComponent(props) {
  const { handlerChangeData, id, value } = props;
  return (
    <TimePicker
      size="large"
      style={{ width: "100%" }}
      onChange={(value, time) =>
        handlerChangeData({ target: { id, value: time } })
      }
      value={moment(value, "HH:mm:ss")}
    />
  );
}

export default TimePickerComponent;
