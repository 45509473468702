import React, { useState } from "react";
import { getExpiredInventory } from "protocols/requests";

const ExpiredInventoryControllers = () => {
    const [products, setProducts] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPage, setTotalPage] = useState(null);
    const [localRecords, setLocalRecords] = useState(10);
    const [dataFilter, setDataFilter] = useState([
        {
            name: "UPN",
            value: "UPN",
        },
        {
            name: "Product Description",
            value: "description",
        },
    ]);
    const handleGetExpiredInventory = async (token) => {
        const result = await getExpiredInventory(token);
        if (result.status === 200) {
            const response = result.response.response;
            setProducts(response);
        }
    };

    const handlerChangeRecordsPage = (value) => {
        setLocalRecords(value);
    };

    const handleChangePage = (page) => {
        page = page - 1;
        setPage(page);
    };

    return {
        products,
        page,
        handleChangePage,
        totalPage,
        handlerChangeRecordsPage,
        localRecords,
        dataFilter,
        handleGetExpiredInventory,
    };
};

export default ExpiredInventoryControllers;