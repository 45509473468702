import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// react plugin for creating charts

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

import MapView from "components/MapView";
import OrderDashboard from "components/OrderDashboard";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.jsx";
import DashboardData from "components/DashboardData";
import {configFirebase} from 'util/firebase'
import { onMessage } from "firebase/messaging";

import {
  getMembers,
  getRecords,
  getProducts,
  getScannedProductBatch,
  getScannedIncomingProductBatch,
} from "protocols/requests";

//icons
import {
  faListCheck,
  faHandHoldingDollar,
  faDolly,
  faPills,
  faUserGear,
  faUserTag,
  faUserDoctor,
  faWarehouse,
  faHospital,
  faTruck,
  faPen,
} from "@fortawesome/free-solid-svg-icons";

import {
  IconLocationManager,
  IconLocationClinic,
  IconLocation,
  IconLocationCarrier,
  IconLocationWarehouse,
} from "components/MapView/IconLocation";

class Dashboard extends React.Component {
  state = {
    list: [
      {
        quantity: null,
        title: "products",
        icon: faPills,
        onClick: () => {
          this.props.history.push("/dashboard/products");
        },
      },
    ],
    members: [],
    showMap: false,
    records: {},
  };

  async componentWillMount() {
    let listCopy = this.state.list;
    let records;

    //---Config firebase
    
    const resConfig = await configFirebase(this.props);
    if(resConfig.code == 200){
      onMessage(resConfig.messaging, (payload) => {
        this.props.showToast("warning", payload.data, true)
       });
    }else{
      alert('Habilitar los notificacion del navegador')
    }

    if (this.props.userInfo.groupSlug !== "Account-manager")
      this.props.setLoaderVisible(false); //estaba en true , lo cambie para que se desapareciera de la pantalla de los demas tipos de usuarios.
    const result = this.props.dataUser.members;
    if (Object.keys(this.props.dataUser).length !== 0) {
      this.props.setLoaderVisible(false);
    }

    if (result) {
      this.setState({ list: listCopy, members: result.members });
    } else {
      this.setState({ list: listCopy, members: [] });
    }

    if (
      this.props.userInfo.groupSlug === "main-manager" ||
      this.props.userInfo.groupSlug === "manager"
    ) {
      if (this.props.dataUser.records) {
        records = this.props.dataUser.records[0];
        this.state.list.map((item) => {
          if (item.title === "products") item.quantity = records.products;
        });
        this.props.setLoaderVisible(false);
      }
    }

    if (this.props.userInfo.groupSlug === "main-manager") {
      if (records) {
        listCopy.push(
          {
            quantity: records.managers,
            title: "Managers",
            icon: faUserGear,
            onClick: () => {
              this.props.history.push("/dashboard/manager");
            },
          },
          {
            quantity: records.salesExecutive,
            title: "Sales Executives",
            icon: faUserTag,
            onClick: () => {
              this.props.history.push("/dashboard/SalesExecutives");
            },
          },
          {
            quantity: records.specialist,
            title: "Clinical Specialist",
            icon: faUserDoctor,
            onClick: () => {
              this.props.history.push("/dashboard/ClinicalSpecialist");
            },
          }
        );
      }
    }

    if (
      this.props.userInfo.groupSlug === "main-manager" ||
      this.props.userInfo.groupSlug === "manager"
    ) {
      if (records) {
        this.setState({ showMap: true });
        listCopy.push(
          {
            quantity: records.clinicHospital,
            title: "Clinic & Hospital",
            icon: faHospital,
            onClick: () => {
              this.props.history.push("/dashboard/ClinicHospitals");
            },
          },
          {
            title: "set days for expiration alert",
            icon: faPen,
            onClick: () => {
              this.props.history.push("/dashboard/settingDays");
            },
          }
        );
      }
    }
  }

  selectorIcon(id) {
    switch (id) {
      case 2:
        return IconLocationManager;
        break;
      case 3:
        return IconLocationCarrier;
        break;
      case 4:
        return IconLocationClinic;
        break;
      case 5:
        return IconLocationWarehouse;
        break;
      case 6:
        return IconLocationClinic;
        break;
      case 7:
        return IconLocationManager;
        break;
      case 8:
        return IconLocationManager;
        break;
      default:
        return IconLocation;
    }
  }

  selectorHeaderTitle(id) {
    switch (id) {
      case 2:
        return "Manager";
        break;
      case 3:
        return "Carrier / Company";
        break;
      case 4:
        return "Clinic & Hospital";
        break;
      case 5:
        return "Warehouse";
        break;
      case 6:
        return "Clinic & Hospital Department";
        break;
      case 7:
        return "Sales Executive";
        break;
      case 8:
        return "Clinical Specialist";
        break;
      default:
        return "Member";
    }
  }

  render() {
    const { classes, userInfo } = this.props;
    return (
      <Grid container xs={12}>
        {this.state.showMap ? (
          <Grid item xs={7} style={{ padding: 10 }}>
            <MapView
              members={this.state.members}
              selectorIcon={this.selectorIcon}
              selectorHeaderTitle={this.selectorHeaderTitle}
              {...this.props}
            />
          </Grid>
        ) : null}
        {userInfo.groupSlug === "main-manager" ||
        userInfo.groupSlug === "manager" ||
        userInfo.groupSlug === "Account_manager" ? (
          <Grid item xs={5} style={{ paddingTop: 10 }}>
            <OrderDashboard classes={classes} list={this.state.list} />
          </Grid>
        ) : (
          <DashboardData data={this.props.dataUser} {...this.props} />
        )}
      </Grid>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

var withStylesClass = withStyles(dashboardStyle)(Dashboard);
export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
    dataUser: state.root.general.dataUserAPI,
  }))(withStylesClass)
);
