/*eslint-disable*/
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
//import AddAlert from "@material-ui/icons/AddAlert";
//import Close from "@material-ui/icons/Close";
// core components
//import Heading from "components/Heading/Heading.jsx";
//import GridContainer from "components/Grid/GridContainer.jsx";
//import GridItem from "components/Grid/GridItem.jsx";
//import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
//import Button from "components/CustomButtons/Button.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
//import Instruction from "components/Instruction/Instruction.jsx";
//import Card from "components/Card/Card.jsx";
//import CardBody from "components/Card/CardBody.jsx";

import notificationsStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";
import TransfersRequest from "views/TransfersRequest";

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tc: false,
      type: "danger",
      content: "info",
      icon: null,
      contentObject: null,
    };
  }
  // this function caused an issue which will reset the redux state
  // to stop the warning of calling setState of unmounted component
  // componentWillUnmount() {
  //     var id = window.setTimeout(null, 0);
  //     //console.log('componentWillUnmount', id);
  //     while (id--) {
  //         //window.clearTimeout(id);
  //     }
  // }

  showNotification(place) {
    if (!this.state[place]) {
      var x = [];
      x[place] = true;
      this.setState(x);
      const timeoutId = setTimeout(
        function() {
          x[place] = false;
          this.setState(x);
        }.bind(this),
        60000
      );
      //console.log('timeoutId', timeoutId);
    }
  }
  // public warning, danger, success
  showSuccess = (content, icon) => {
    this.showToast("success", content, icon);
  };
  showError = (content, icon) => {
    this.showToast("danger", content, icon);
  };
  showWarning = (content, icon) => {
    this.showToast("warning", content, icon);
  };
  showNotify = (content, icon) => {
    this.showToast("warning", content, icon, true);
  };
  // private
  showToast = (type, content, icon, notify) => {
    if (notify) {
      this.setState({ type, contentObject: content, icon });
      this.showNotification("tc");
    } else {
      this.setState({ type, content, icon });
      this.showNotification("tc");
    }
  };

  // onClick={() => this.showNotification("tc")}
  render() {
    const { classes, button, handlerRemoveMember } = this.props;
    return (
      <div>
        {this.state.icon ? (
          <Snackbar
            place="tc"
            color={this.state.type}
            icon={this.state.icon}
            message={this.state.content}
            messageObject={this.state.contentObject}
            open={this.state.tc}
            closeNotification={() => this.setState({ tc: false })}
            close
            button={button}
            handlerRemoveMember={handlerRemoveMember}
          />
        ) : (
          <Snackbar
            place="tc"
            color={this.state.type}
            message={this.state.content}
            messageObject={this.state.contentObject}
            open={this.state.tc}
            closeNotification={() => this.setState({ tc: false })}
            close
            button={button}
            handlerRemoveMember={handlerRemoveMember}
          />
        )}
      </div>
    );
  }
}

export default withStyles(notificationsStyle)(Notifications);
