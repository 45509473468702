import React, { useState } from "react";
import { updateExpiredDays } from "protocols/requests";

const SettingDaysControllers = (props) => {
  const [data, setData] = useState("");
  const { userToken, setLoaderVisible } = props;
  const [toast, setToast] = React.useState(React.createRef());

  const showToast = (type, content) => {
    if (toast) {
      if (type === "success") {
        toast.showSuccess(content);
      } else {
        toast.showError(content);
      }
    }
  };

  const handlerChangeData = (e) => {
    var value = e.target.value;
    setData(Number(value));
  };

  const handleUpdatedDays = async () => {
    const body = {
      days: data,
    };
    setLoaderVisible(true);
    const result = await updateExpiredDays(body, userToken);
    setLoaderVisible(false);
    if (result.response.code === 200) {
      showToast("success", "Configuration updated successfully");
    } else {
      showToast("error", "There was a problem updating the settings");
    }
  };

  return {
    handleUpdatedDays,
    handlerChangeData,
    showToast,
    setToast,
  };
};

export default SettingDaysControllers;
