import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";

function InputForm({ ...props }) {
  const { 
    classes,
    error,
    area,
    value,
    handlerChangeData,
    id,
    optional,
    disabled,
    number
  } = props;

  return (
    <>
    {
      optional ? 
      area 
        ? 
        <textarea className={classes.textarea_form_optional} id={id} value={value} onChange={handlerChangeData} />
        : 
        <input type={number? "number":"text"} id={id} className={classes.input_form_optional} value={value} onChange={handlerChangeData} />
      :
      disabled 
       ?
        area ?
         <textarea className={error ? classes.textarea_form_error : classes.textarea_form} id={id} value={value} disabled/>
         : 
         <input type={number? "number":"text"} id={id} className={error ? classes.input_form_error : classes.input_form} value={value} disabled/>
       :
        area 
        ? 
        <textarea className={error ? classes.textarea_form_error : classes.textarea_form} id={id} value={value} onChange={handlerChangeData} />
        : 
        <input type={number? "number":"text"} id={id} className={error ? classes.input_form_error : classes.input_form} value={value} onChange={handlerChangeData} />
      
    }
    </>
  );
}

InputForm.propTypes = {
  classes: PropTypes.object.isRequired, 
};

export default withStyles(customInputStyle)(InputForm);
