import { combineReducers } from "redux";
import * as types from "../actions/types";

const userToken = (state = "", action) => {
  return action.type === types.UPDATE_USERTOKEN ? action.payload : state;
};

const userInfoToken = (state = "", action) => {
  return action.type === types.UPDATE_userInfo ? action.payload : state;
};

const userInfo = (state = "", action) => {
  return action.type === types.UPDATE_userData ? action.payload : state;
};

// key Store
const userKeyStore = (state = {}, action) => {
  var newUserObj = Object.assign({}, state);
  switch (action.type) {
    case types.UPDATE_USER_INFO_BY_KEY: {
      newUserObj[action.key] = action.payload;
      return newUserObj;
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  userToken,
  userKeyStore,
  userInfoToken,
  userInfo,
});

export default reducer;
