import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import returnRequestStyle from "assets/jss/material-dashboard-pro-react/components/abstractComponentStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
const AbstractComponent = (props) => {
  const {
    classes,
    model,
    qty,
    shipto,
    shiptoName,
    deleteCard,
    index,
    batch,
    warehouse,
    serial,
  } = props;
  return (
    <Grid container xs={12} className={classes.container}>
      <Grid xs={12} container>
        <Grid xs={12}>
          <FontAwesomeIcon
            icon={faClose}
            className={classes.icon_order}
            style={{ color: "#14A2DC", fontSize: "120%" }}
            onClick={() => deleteCard(index)}
          />
        </Grid>

        <br />
        <Grid container xs={12} className={classes.containerShipto}>
          <Grid xs={6} className={classes.nameShipto}>
            Cliente:
          </Grid>
          <Grid xs={6} className={classes.nameShipto}>
            {shiptoName}
          </Grid>
          {/* <Grid xs={12}>{shipto}</Grid> */}
        </Grid>
        <Grid container xs={12} className={classes.containerShipto}>
          <Grid xs={6} className={classes.nameShipto}>
            Bodega retorno:
          </Grid>
          <Grid xs={6} className={classes.nameShipto}>
            {warehouse}
          </Grid>
          {/* <Grid xs={12}>{shipto}</Grid> */}
        </Grid>
        <hr className={classes.LineResume} />
      </Grid>
      {/* <Grid xs={1}>
        <FontAwesomeIcon
          icon={faClose}
          className={classes.icon_order}
          style={{ color: "#14A2DC", fontSize: "120%" }}
          onClick={() => deleteCard(index)}
        />
      </Grid> */}
      <Grid xs={3}>Modelo:</Grid>
      <Grid xs={3}>Lote</Grid>
      <Grid xs={3}>Serial</Grid>
      <Grid xs={3}>Cantidad:</Grid>
      <Grid container xs={12} className={classes.containerSkus}>
        <Grid xs={3}>{model}</Grid>
        <Grid xs={3}>{batch}</Grid>
        <Grid xs={3}>{serial}</Grid>
        <Grid xs={3}>{qty}</Grid>
      </Grid>
    </Grid>
  );
};
export default withStyles(returnRequestStyle)(AbstractComponent);
