import React, { useState } from "react";
import { getMembers } from "protocols/requests";

const MemberControllers = (title, props, id, clinic, clinicDeparment) => {
  const [members, setMembers] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(null);
  const [localRecords, setLocalRecords] = useState(10);

  const handleGetMember = async (memberType, data) => {
    const result = await getMembers(memberType, {
      token: data.token,
      page,
      records: localRecords,
    });

    if (result.status === 200) {
      const members = result.response.response.members;
      const total = result.response.response.totalRecords;
      const total_page = Math.ceil(total / localRecords);
      setTotalPage(total_page);
      setMembers(members);
    }
  };

  const handlerAddMember = () => {
    if (clinic) {
      props.history.push({
        pathname: "/dashboard/member/add-clinic-hospital",
        state: { title: title, id: id },
      });
    } else {
      props.history.push({
        pathname: "/dashboard/member/add",
        state: { title: title, id: id, clinicDeparment: clinicDeparment },
      });
    }
  };

  const handlerChangeRecordsPage = (value) => {
    setLocalRecords(value.target.value);
  };

  const handleChangePage = (page) => {
    page = page - 1;
    setPage(page);
  };

  return {
    handleGetMember,
    members,
    page,
    handleChangePage,
    totalPage,
    handlerAddMember,
    handlerChangeRecordsPage,
    localRecords,
  };
};

export default MemberControllers;
