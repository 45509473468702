import React, { useState } from "react";
import { getProducts } from "protocols/requests";
import { dataTotal } from "redux/actions/general";

const ProductControllers = (props) => {
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(10);
  const [localRecords, setLocalRecords] = useState(10);
  const [filterType, setFilterType] = useState(null);
  const [filter, setFilter] = useState(false);
  const [filterValue, setFilterValue] = useState(null);
  const [dataFilter, setDataFilter] = useState([
    {
      name: "UPN",
      value: "UPN",
    },
    {
      name: "GTIN",
      value: "GTIN",
    },
  ]);

  const handleGetProducts = async (data) => {
    if (page !== 0 || (page === 0 && !props.data.products)) {
      const result = await getProducts(data.token, {
        page,
        records: localRecords,
      });
      if (result.status === 200) {
        const products = result.response.response.products;
        const total = result.response.response.totalRecords;
        const total_page = Math.ceil(total / localRecords);
        setTotalPage(total_page);
        setProducts(products);
        if (page === 0 && !props.data.scannedBatch) {
          props.dispatch(dataTotal(result.response.response, "products"));
        }
      }
    }
  };

  const handleGetProductFilter = async () => {
    console.log("x1", filterType, filterValue);
    if (filterType && filterValue) {
      props.setLoaderVisible(true);
      const filterTypeCopy =
        filterType === "UPN" ? "upn" : filterType === "GTIN" ? "gtin" : null;
      const result = await getProducts(props.userToken, {
        page,
        records: localRecords,
        filterType: filterTypeCopy,
        filterValue,
      });
      props.setLoaderVisible(false);
      if (result.status === 200) {
        const products = result.response.response.products;
        const total = result.response.response.totalRecords;
        const total_page = Math.ceil(total / localRecords);
        setTotalPage(total_page);
        setProducts(products);
        setFilter(true);
      }
      setFilterValue(null);
      setFilterType(null);
    }
  };

  const handlerChangeRecordsPage = (value) => {
    setLocalRecords(value.target.value);
  };
  const handleChangePage = (page) => {
    page = page - 1;
    setPage(page);
    setFilter(false);
  };

  const handlerChangeData = (e) => {
    var value = e.target.value;
    var id = e.target.id;
    if (id === "filterProduct") {
      setFilterType(value);
    } else {
      setFilterValue(value);
    }
  };

  return {
    handleGetProducts,
    products,
    page,
    handleChangePage,
    totalPage,
    handlerChangeRecordsPage,
    localRecords,
    dataFilter,
    handlerChangeData,
    handleGetProductFilter,
    filter,
  };
};

export default ProductControllers;
