/* This is a React component that displays the status of orders. It imports various modules from the
Material-UI library, as well as the `OrderStatusItem` component and the `OrdersRequestController`
module. It also uses the `useEffect` hook to fetch processed orders from the server when the
component mounts. The component then maps over the processed orders and renders an `OrderStatusItem`
component for each one. Finally, it exports the component wrapped in the `withRouter` and `connect`
higher-order components to provide access to the router and Redux store, respectively. */
import React, { useEffect } from "react";
import { Grid, makeStyles, withStyles, Box } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import { Pagination } from "@mui/material";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import OrderStatusItem from "components/OrderStatusItem";
import OrdersRequestController from "../../../controllers/OrdersRequest";

const useStyles = makeStyles((theme) => ({
  Orders: {
    width: "100%",
    //height: "500px",
    marginTop: "10px",
    padding: "10px",
    // backgroundColor: "red",
  },
  tittleOrderStatus: {
    fontSize: "14px",
  },
  containerView: {
    padding: 20,
  },
}));

/**
 * The OrderStatus function displays the status of processed orders.
 * @param props - props is an object that contains the properties passed down to the OrderStatus
 * component from its parent component. These properties can include data, functions, or other
 * components that the OrderStatus component needs to render properly.
 * @returns A React functional component that renders a list of processed orders with their status.
 */
const OrderStatus = (props) => {
  const {
    handleGetProcessedOrders,
    ordersProcessed,
    pageOrderStatus,
    setPageOrderStatus,
    numberPageOrderStatus,
    handleChange,
    orderId,
  } = OrdersRequestController(props);

  const handleChangePage = (pageOrderStatus) => {
    setPageOrderStatus(pageOrderStatus - 1);
  };

  useEffect(
    async () => {
      await handleGetProcessedOrders(props.userToken);
    },
    [pageOrderStatus, orderId]
  );

  let orderstatus = true;

  const classes = useStyles();
  return (
    <Grid className={classes.containerView} container>
      <Grid xs={10}>
        <h5 className={classes.tittleOrderStatus}>
          <b>Estado de pedidos</b>{" "}
        </h5>
      </Grid>
      <Grid xs={2} className={classes.shearch}>
        <TextField
          id="filled-search"
          label="Busqueda por ID"
          type="search"
          variant="filled"
          onChange={handleChange}
          sx={{
            "@media (max-Width: 960px)": {
              width: 100,
              height: 60,
              fontSize: "0.5vh",
            },
          }}
        />
      </Grid>
      <Grid container xs={12} className={classes.Orders}>
        {ordersProcessed.map((order, id) => (
          <Grid xs={12}>
            <OrderStatusItem order={order} id={id} orderRequest />
          </Grid>
        ))}
      </Grid>
      {ordersProcessed && (
        <Pagination
          hideNextButton={true}
          hidePrevButton={true}
          onChange={(e) => handleChangePage(e.target.textContent)}
          count={
            parseInt(numberPageOrderStatus) < 10
              ? 1
              : parseInt(numberPageOrderStatus) % 10 != 0
                ? parseInt(numberPageOrderStatus / 10) + 1
                : parseInt(numberPageOrderStatus / 10)
          }
          page={pageOrderStatus + 1}
        />
      )}
    </Grid>
  );
};

export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
    data: state.root.general.dataUserAPI,
    applicant: state.root.user.userInfo.firstName,
    id: state.root.user.userInfo.memberId,
    address: state.root.user.userInfo.address,
    contactNo: state.root.user.userInfo.contactNo,
  }))(OrderStatus)
);
