import React from "react";
//import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import cookie from 'js-cookie';
import { connect } from 'react-redux';

import { getDashboardRoutes } from "routes/dashboard.jsx";

import * as util from "../util/util";
import config from 'config.js'
import { wsConnect } from 'socket/actions';

class App extends React.Component {

  componentDidMount() {
    // check authentication
    if (!cookie.get('token') || cookie.get('token') !== this.props.userToken) {
      this.props.history.push('/');
    }
    var decoded = util.decodeToken(this.props.userToken);

    //
    var username = decoded.email;


    var dashboardRoutes = getDashboardRoutes(this.props.userToken);

    // return to parent
    this.props.afterConfig(username, dashboardRoutes);

    // conncet to the socket
    let host = config.api_url + "/healthenviron";
    this.props.dispatch(wsConnect(host, this.props.userToken));
  }

  componentDidUpdate = () => {
    // check connection
    if(this.props.userKeyStore.socket === null || this.props.userKeyStore.socket !== 'CONNECTED'){
      // conncet to the socket
      let host = config.api_url + "/healthenviron";
      this.props.dispatch(wsConnect(host, this.props.userToken));
    }
  }

  render() {
    return null;
  }
  
}

export default withRouter(connect(
  (state) => ({
    userToken: state.root.user.userToken,
    //financePartner: state.root.user.financePartnerInfo,
    //application: state.root.user.applicationInfo,
    //financePartnersList: state.root.user.financePartnersList,
    userKeyStore: state.root.user.userKeyStore,
    userInfo: state.root.user.userInfoToken
  })
)(App));
