import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { CloudUpload, Delete, Visibility, GetApp } from "@material-ui/icons";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Toast from "components/Toast/Toast.jsx";

import OrdersRequestController from "controllers/OrdersRequest";

const useStyles = makeStyles((theme) => ({
  containerView: {
    padding: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(2, 0),
  },
  table: {
    minWidth: 650,
  },
  activeRow: {
    backgroundColor: theme.palette.action.selected,
  },
  previewTable: {
    minWidth: 650,
    marginTop: theme.spacing(2),
  },
}));

const LoadingBlockedClients = (props) => {
  const {
    setSelectedExcel,
    handleUpdateBlockedClients,
    selectedExcel,
    setToast,
    blockedClientsDocument,
    setBlockedClientsDocument,
    handleGetBlockedCustomerHistory,
    handleDeleteDocumentBlockedClients,
    handleGetDocumentsById,
  } = OrdersRequestController(props);
  const classes = useStyles();

  const [previewContent, setPreviewContent] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    handleGetBlockedCustomerHistory();
  }, []);

  useEffect(
    () => {
      handleUpdateBlockedClients();
    },
    [selectedExcel]
  );

  const handleDelete = async (index, id) => {
    setBlockedClientsDocument((prevFiles) =>
      prevFiles.filter((_, i) => i !== index)
    );
    await handleDeleteDocumentBlockedClients(props.userToken, id);
  };

  const handlePreview = (content) => {
    setPreviewContent(content);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPreviewContent(null);
  };

  const handleExcelChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileNameParts = selectedFile.name.split(".");
      const fileExtension = fileNameParts[
        fileNameParts.length - 1
      ].toLowerCase();

      // Verifica que la extensión sea XLSX o XLS.
      if (fileExtension === "xlsx" || fileExtension === "xls") {
        // Verifica el tamaño del archivo (en bytes).
        if (selectedFile.size <= 4.49 * 1024 * 1024) {
          const reader = new FileReader();

          // Define el comportamiento una vez que el archivo se ha leído
          reader.onloadend = () => {
            const base64Data = reader.result.split(",")[1]; // Obtiene solo la parte Base64
            const jsonData = {
              name: selectedFile.name,
              data: base64Data,
            };

            setSelectedExcel(jsonData); // Aquí se establece el objeto JSON que contiene los datos Base64
          };
          reader.readAsDataURL(selectedFile);
        } else {
          alert(
            "El archivo seleccionado es demasiado grande. Por favor, seleccione un archivo Excel de 4.49MB o menos."
          );
          setSelectedExcel(null);
        }
      } else {
        alert("Por favor, seleccione un archivo Excel (XLS o XLSX).");
        setSelectedExcel(null);
      }
    }
  };

  return (
    <div className={classes.containerView}>
      <Typography variant="h5">Cargue de clientes por coordinador</Typography>
      <input
        accept=".xlsx"
        style={{ display: "none" }}
        id="file-upload"
        type="file"
        onChange={handleExcelChange}
      />
      <label htmlFor="file-upload">
        <Button
          variant="contained"
          color="primary"
          component="span"
          startIcon={<CloudUpload />}
          className={classes.uploadButton}
        >
          Cargar archivo
        </Button>
      </label>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nombre archivo</TableCell>
              <TableCell>Fecha de carga</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Detalle</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {blockedClientsDocument?.map((file, index) => (
              <TableRow
                key={index}
                className={file.status === "Activo" ? classes.activeRow : ""}
              >
                <TableCell>{file.documentName}</TableCell>
                <TableCell>{file.date.split("T")[0]}</TableCell>
                <TableCell>{index !== 0 ? "Inactivo" : "Activo"}</TableCell>
                <TableCell>
                  {index !== 0 && ( // Desactivar el botón de eliminar para el archivo activo.
                    <IconButton onClick={() => handleDelete(index, file.id)}>
                      <Delete color="secondary" />
                    </IconButton>
                  )}
                  {/*  <IconButton onClick={() => handlePreview(file.content)}>
                    <Visibility color="primary" />
                  </IconButton> */}
                  <IconButton
                    onClick={() =>
                      handleGetDocumentsById(file.documentId, file.documentName)
                    }
                  >
                    <GetApp color="action" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Previsualización del archivo</DialogTitle>
        <DialogContent>
          {previewContent && (
            <TableContainer component={Paper}>
              <Table
                className={classes.previewTable}
                aria-label="preview table"
              >
                <TableHead>
                  <TableRow>
                    {previewContent[0].map((cell, index) => (
                      <TableCell key={index}>{cell}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {previewContent.slice(1).map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <TableCell key={cellIndex}>{cell}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog> */}
      <Toast
        innerRef={(ref) => {
          setToast(ref);
        }}
      />
    </div>
  );
};

export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
    data: state.root.general.dataUserAPI,
    applicant: state.root.user.userInfo.firstName,
    id: state.root.user.userInfo.memberId,
    address: state.root.user.userInfo.address,
    contactNo: state.root.user.userInfo.contactNo,
  }))(LoadingBlockedClients)
);
