

 // ##############################
  // // // batch view styles
  // #############################
 
  
  const batchViewsStyle = theme => ({
    
    container_title:{
        borderBottom: "1px solid grey",
        marginTop: "85px",
    },
    filter_container:{
        backgroundColor: "#F2F0F0",
        boxShadow: "0px 0px 2px grey",
        marginTop: 10,
        textAlign: "center",
        padding: 15,
        cursor: "pointer",
        fontSize: "100%",
        '& span':{
            marginRight: 10,            
        }
    },
    filter_container_data:{
      boxShadow: "0px 0px 2px grey",
      padding: 10,
      transition: "all 2s ease-out"
    },
    filter_container_btn:{
        textAlign: "center",
    },
    container_list_item:{
      boxShadow: "0px 0px 2px grey",
      backgroundColor: "#F2F2F2",
      paddingTop: 10,
      fontSize: "80%",
      fontWeight: "bold" ,
      height: "auto", 
      marginTop: 10,
      borderRadius: 2,
      paddingLeft: 20
    },
    content_item_id:{
        border: "1px solid #0080D5",
        paddingRight: 3,
        paddingLeft: 3,
        borderRadius: 5,
        marginRight: 5,
        color: "#0080D5",
        cursor: "pointer",
        maxHeight: "50%",
        marginTop: 5
    },
    content_item:{
        border: "1px solid #5B5B5B",
        paddingRight: 3,
        paddingLeft: 3,
        borderRadius: 5,
        color: "#5B5B5B",
        marginRight: 5,
        maxHeight: "50%",
        marginTop: 5
      },
      content_item_replacement_true:{
        border: "1px solid #00930B",
        paddingRight: 3,
        paddingLeft: 3,
        borderRadius: 5,
        color: "#00930B",
        marginRight: 5,
        maxHeight: "50%",
        marginTop: 5,
        //backgroundColor: "#DCFDDE"
      },
      content_item_replacement_false:{
        border: "1px solid #D15D4C",
        paddingRight: 3,
        paddingLeft: 3,
        borderRadius: 5,
        color: "#D15D4C",
        marginRight: 5,
        maxHeight: "50%",
        marginTop: 5,
        //backgroundColor: "red"
      },
      checkbox:{
          backgroundColor: "red",
          height: 17,
          width: 17,
          marginTop: 5
      },
      container_actions:{
          '& p':{
            fontSize: 15,
            marginLeft: 5,
            marginRight: 10,
            fontWeight: "normal"
          },         
      },
      icon:{
        fontSize: 17,
        cursor: "pointer",
        marginTop: 2
      },
      icon_order: {
        fontSize: 13,
        cursor: "pointer",
        marginTop: 2,
        marginRight: 5
      },
      container_list_item_information: {
         boxShadow: "0px 0px 2px grey",
         padding: 10,
         fontFamily: "Poppins",
         paddingBottom: 30,
         '& p': {
             color: "black",
             fontWeight: "bold",
             marginBottom: 0,
         }
      },
      uploadFile: {
        display: "flex",
        borderBottom: "1px solid",
        paddingBottom: 10,
        '& input': {
          display: "none"
        },
        '& label': {
          display: "block",
          borderRadius: "6px",
          cursor:"pointer",
          color: "black",
          width: "20%",
          textAlign: "center",
          padding: "4px 2px 4px 2px",
          border: "1px solid",
          height: "80%"
        },
        '& p': {
          width: "100%",
          marginLeft: 5
        }
      },

      btnFile: {
        marginTop: -2
      },

      containerBtnReplacement: {
        width: "100%",
        paddingLeft: "70%"
      }
   
  });
  
  export default batchViewsStyle;
  