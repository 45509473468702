import React from "react";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function CheckboxComponent(props) {
  
  const {label,  value, handlerChangeData, id} = props;

  return(
    <FormGroup>
      <FormControlLabel control={<Checkbox checked={value === 1 ? true : false} onChange={handlerChangeData} id={id} value={value} />} label={label} />
    </FormGroup>
  )
}

export default CheckboxComponent;
