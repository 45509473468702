// ##############################
// // // LockScreenPage view styles
// #############################

const lockScreenPageStyle = theme => ({
  container: {
    zIndex: "0",
    height: "95vh",
    width: "100vw",
    overflow: "hidden",
    position: "absolute",
    backgroundImage: "url('/texture.jpg')",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    '& h2': {
      color: "#215ab4",
      fontSize: 25,
      fontWeight: "800"
    },
    '& h1': {
      color: "#4a4a4a",
      fontWeight: "800"
    },
    '& h4': {
      color: "#5e5e5e",
      fontWeight: "500"
    },
    [theme.breakpoints.down("md")]: {
      '& h2': {
        fontSize: 26,
      },
      '& h1': {
        fontSize: 28,
      },
      '& h4': {
        fontSize: 20,
        color: "#333333",
      }
    },
    [theme.breakpoints.down("sm")]: {
      '& h2': {
        fontSize: 21,
      },
      '& h1': {
        fontSize: 23,
      },
      '& h4': {
        fontSize: 16,
        color: "#333333",
      }
    }
  },
  containerImg: {
    zIndex: "2",
    position: "absolute",
    height: "50rem",
    width: "50rem",
    top: "-14rem",
    right: "-10rem",
    overflow: "hidden",
    borderRadius: "50rem",
    backgroundColor: "transparent",
    border: "10px solid #7a9caf",
    color: "#7a9caf",
    [theme.breakpoints.down("md")]: {
      opacity: 0.8
    },
    [theme.breakpoints.down(400)]: {
      right: "-17rem",
    }
  },
  btn: {
    border: "none",
    color: "#d9d6d6",
    backgroundColor: "#215ab4",
    fontSize: 14,
    marginTop: 20,
    fontWeight: "700"
  },
  content: {
    zIndex: 90,
    position: "relative",
    paddingTop: "10vh",
    margin: "0 3rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    flexDirection: "column",
    height: "100%",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "0 1.5rem"
    }
  }
});

export default lockScreenPageStyle;
