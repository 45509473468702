import React, { useState } from "react";
import { getApprovalhistory, getXmlData } from "protocols/requests";
import jwtDecode from "jwt-decode";

export const ApprovalhistoryController = (props) => {
  const [approvalhistory, setApprovalhistory] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [xmlData, setXmlData] = useState("");
  const [backOrderList, setBackOrderList] = useState([]);
  const [jwtToken, setJwtToken] = useState("");
  const [pageHistory, setPageHistory] = useState(0);
  const [numberPageHistory, setNumberPageHistory] = useState();
  const [toast, setToast] = React.useState(React.createRef());

  const showToast = (type, content) => {
    if (toast) {
      if (type === "success") {
        toast.showSuccess(content);
      } else {
        toast.showError(content);
      }
    }
  };

  const handlegetApprovalhistory = async (token, memberId) => {
    var jwt = jwtDecode(token);
    setJwtToken(jwt);
    props.setLoaderVisible(true);
    const result = await getApprovalhistory(
      token,
      orderId,
      memberId,
      jwt.groupSlug,
      parseInt(pageHistory)
    );
    props.setLoaderVisible(false);
    if (result.status === 200) {
      const response = result.response.response;
      const History = response.filter((item) => item.backOrder == false);
      const totalRecords = History.length;
      setNumberPageHistory(totalRecords);
      setApprovalhistory(History);
      const backOrdersHistory = response.filter(
        (item) => item.backOrder == true
      );
      setBackOrderList(backOrdersHistory);
    } else if (result.status === 422) {
      setPageHistory(0);
    } else if (result.status === 404) {
      setNumberPageHistory(null);
    } else {
      showToast("error", result.response.description);
      setNumberPageHistory(null);
    }
  };

  const handlegetXmlData = async (token) => {
    let typeOrder = "order";
    props.setLoaderVisible(true);
    const result = await getXmlData(token, typeOrder);
    props.setLoaderVisible(false);
    if (result.status === 200) {
      const response = result.response.response;
      setXmlData(response);
    }
  };

  const handleChange = (e) => {
    setOrderId(e.target.value);
  };

  const downloadPdf = (pdfData, fileName) => {
    // Decodifica la cadena base64 a un ArrayBuffer
    const binaryData = atob(pdfData);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const byteArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      byteArray[i] = binaryData.charCodeAt(i);
    }

    // Crea un Blob a partir del ArrayBuffer
    const blob = new Blob([arrayBuffer], { type: "application/pdf" });

    // Crea un objeto URL a partir del Blob
    const url = window.URL.createObjectURL(blob);

    // Crea un elemento de anclaje invisible y configura los atributos
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = fileName; // Nombre del archivo de descarga

    // Agrega el elemento de anclaje al cuerpo del documento
    document.body.appendChild(a);

    // Simula un clic en el enlace para iniciar la descarga
    a.click();

    // Revoca el objeto URL para liberar recursos
    window.URL.revokeObjectURL(url);
  };

  return {
    approvalhistory,
    handlegetApprovalhistory,
    handleChange,
    orderId,
    handlegetXmlData,
    xmlData,
    backOrderList,
    jwtToken,
    pageHistory,
    setPageHistory,
    numberPageHistory,
    downloadPdf,
  };
};
