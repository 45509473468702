

 // ##############################
  // // // appBar styles
  // #############################
 
  
  const userViewsStyle = theme => ({
    
    container_title:{
        borderBottom: "2px solid grey",
        paddingTop: "20px",
        paddingBottom: "2px"
    },
    container_btn:{
        width: "100%",
        textAlign: "right",
        marginTop: 20
    },
    btn: {
        backgroundColor: "#319f4b",
        color: "white",
        fontSize: 13,
        padding: '10px 15px 10px 15px',
        marginBottom: 15
    },
    header_search: {
        backgroundColor: "#F1F1F1",
        padding: 15,
        border: "1px solid #D0D0D0",
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
    },
    content_search:{
        // border: "1px solid #D0D0D0",
        // borderBottomLeftRadius: 5,
        // borderBottomRightRadius: 5,
        padding: "0",
        display: "flex",
        justifyContent: "flex-end"
    },
    table: {
        backgroundColor: "blue"
    }
  });
  
  export default userViewsStyle;
  