import React, { useState } from "react";
import { getProductsInventory } from "protocols/requests";

const ProductsInventoryControllers = () => {
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(null);
  const [localRecords, setLocalRecords] = useState(10);
  const [dataFilter, setDataFilter] = useState([
    {
      name: "UPN",
      value: "UPN",
    },
    {
      name: "Product Description",
      value: "description",
    },
  ]);
  const handleGetProductsInventory = async (token) => {
    const result = await getProductsInventory(token);
    if (result.status === 200) {
      const response = result.response.response;
      setProducts(response.orders);
      /* const products = result.response.response.members;
        const total = result.response.response.totalRecords;
        const total_page = Math.ceil(total / localRecords);
        setTotalPage(total_page);
        setProducts(products);
      }*/
    }
  };

  const handlerChangeRecordsPage = (value) => {
    setLocalRecords(value);
  };

  const handleChangePage = (page) => {
    page = page - 1;
    setPage(page);
  };

  return {
    products,
    page,
    handleChangePage,
    totalPage,
    handlerChangeRecordsPage,
    localRecords,
    dataFilter,
    handleGetProductsInventory,
  };
};

export default ProductsInventoryControllers;
