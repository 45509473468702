import React from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink, Location, withRouter } from "react-router-dom";
import cx from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import Toolbar from "@mui/material/Toolbar";
import { styled, useTheme } from "@mui/material/styles";

// core components
import HeaderLinks from "components/Header/HeaderLinks.jsx";

// appBar component
import AppBarComponent from "components/AppBar/index";

import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx";
import { connect } from "react-redux";
import { clear } from "redux/actions/general";
import cookie from "js-cookie";
import { withTranslation } from "react-i18next";

var ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  render() {
    const { className, headerLinks, links } = this.props;
    return (
      <div className={className} ref="sidebarWrapper">
        {headerLinks}
        {links}
      </div>
    );
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      miniActive: true,
      openMenu: false,
    };
    this.activeRoute.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (window.addEventListener) {
      window.addEventListener(
        "resize",
        () => {
          if (window.innerWidth >= 960 && this.state.openMenu) {
            this.setState({ openMenu: !this.state.openMenu });
          }
        },
        true
      );
    }
    if (prevState.openMenu !== this.state.openMenu) {
      this.props.open();
    }
  }

  openSideMenu = () => {
    this.setState({ openMenu: !this.state.openMenu });
  };

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }

  openCollapse(collapse) {
    var st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }

  openTab = () => {
    if (window.innerWidth < 960) {
      this.setState({ openMenu: !this.state.openMenu });
    }
  };

  LogOut = async () => {
    await this.props.dispatch(clear());
    cookie.remove("token");
    this.props.history.push("/login-page");
  };

  render() {
    const {
      classes,
      color,
      logo,
      image,
      logoText,
      routes,
      bgColor,
      rtlActive,
    } = this.props;

    const activeStyle = { backgroundColor: "#333c43" };

    const { t } = this.props;

    const openedMixin = (theme) => ({
      width: 260,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: "hidden",
    });

    const closedMixin = (theme) => ({
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: `calc(${theme.spacing(7)} + 55px)`,
      [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(5)} )`,
      },
    });

    /* const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
      ({ theme, open }) => ({
        width: 250,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        }),
      }),
    );*/

    var links = (
      <List className={classes.list}>
        {routes?.map((prop, key) => {
          if (!prop.children) {
            if (prop.redirect || prop.hideInSideBar) {
              return null;
            }
            if (prop.collapse) {
              const navLinkClasses =
                classes.itemLink +
                " " +
                cx({
                  [" " + classes.collapseActive]: this.activeRoute(prop.path),
                });
              const itemText =
                classes.itemText +
                " " +
                cx({
                  [classes.itemTextMini]:
                    this.props.miniActive && this.state.miniActive,
                  [classes.itemTextMiniRTL]:
                    rtlActive && this.props.miniActive && this.state.miniActive,
                  [classes.itemTextRTL]: rtlActive,
                });
              var collapseItemText =
                classes.collapseItemText +
                " " +
                cx({
                  [classes.collapseItemTextMini]:
                    this.props.miniActive && this.state.miniActive,
                  [classes.collapseItemTextMiniRTL]:
                    rtlActive && this.props.miniActive && this.state.miniActive,
                  [classes.collapseItemTextRTL]: rtlActive,
                });

              const itemIcon =
                classes.itemIcon +
                " " +
                cx({
                  [classes.itemIconRTL]: rtlActive,
                });
              const caret =
                classes.caret +
                " " +
                cx({
                  [classes.caretRTL]: rtlActive,
                });
              return (
                <ListItem key={key} className={classes.item}>
                  <ListItem
                    key={key}
                    onClick={() => this.openCollapse(prop.state)}
                    className={navLinkClasses}
                  >
                    <ListItemIcon className={itemIcon}>
                      {typeof prop.icon === "string" ? (
                        <Icon>{prop.icon}</Icon>
                      ) : (
                        <FontAwesomeIcon icon={prop.icon} />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={t(prop.name)}
                      secondary={
                        <b
                          className={
                            caret +
                            " " +
                            (this.state[prop.state] ? classes.caretActive : "")
                          }
                        />
                      }
                      disableTypography={true}
                      className={classes.itemTextCollapse}
                    />
                  </ListItem>
                  {/*<NavLink
                    to={"#"}
                    className={navLinkClasses}
                    activeStyle={activeStyle}
                    onClick={() => this.openCollapse(prop.state)}
                  >
                    <ListItemIcon className={itemIcon}>
                    {
                    typeof prop.icon === "string" ? (
                      <Icon>{prop.icon}</Icon>
                    ) : (
                      <FontAwesomeIcon icon={prop.icon} />
                    )}
                    </ListItemIcon>
                    <ListItemText
                      primary={prop.name}
                      secondary={
                        <b
                          className={
                            caret +
                            " " +
                            (this.state[prop.state] ? classes.caretActive : "")
                          }
                        />
                      }
                      disableTypography={true}
                      className={itemText}
                    />
                    </NavLink>*/}
                  <Collapse in={this.state[prop.state]} unmountOnExit>
                    <List className={classes.list + " " + classes.collapseList}>
                      {prop.views.map((prop, key) => {
                        if (prop.redirect) {
                          return null;
                        }
                        const navLinkClasses =
                          classes.collapseItemLink +
                          " " +
                          cx({
                            [" " + classes[color]]: this.activeRoute(prop.path),
                          });
                        const collapseItemMini =
                          (prop.intranslatable ? "notranslate " : "") +
                          classes.collapseItemMini +
                          " " +
                          cx({
                            [classes.collapseItemMiniRTL]: rtlActive,
                          });
                        if (prop.intranslatable) {
                          collapseItemText += " notranslate";
                        }
                        return (
                          <ListItem key={key} className={classes.collapseItem}>
                            <NavLink
                              to={prop.path}
                              className={classes.noDecoration}
                            >
                              <span className={collapseItemMini}>
                                {prop.mini}
                              </span>
                              <ListItemText
                                primary={t(prop.name)}
                                disableTypography={true}
                                className={collapseItemText}
                                onClick={this.openTab}
                              />
                            </NavLink>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                </ListItem>
              );
            }
            const navLinkClasses =
              classes.itemLink +
              " " +
              cx({
                [" " + classes[color]]: this.activeRoute(prop.path),
              });

            const itemText =
              prop.path === this.props.location.pathname
                ? classes.itemTextActive
                : classes.itemText +
                  " " +
                  cx({
                    [classes.itemTextMini]:
                      this.props.miniActive && this.state.miniActive,
                    [classes.itemTextMiniRTL]:
                      rtlActive &&
                      this.props.miniActive &&
                      this.state.miniActive,
                    [classes.itemTextRTL]: rtlActive,
                  });

            const itemIcon =
              prop.path === this.props.location.pathname
                ? classes.itemIconActive
                : classes.itemIcon +
                  " " +
                  cx({
                    [classes.itemIconRTL]: rtlActive,
                  });

            const itemIconAlert =
              prop.path === this.props.location.pathname
                ? classes.itemIconActive
                : classes.itemIconAlert +
                  " " +
                  cx({
                    [classes.itemIconRTL]: rtlActive,
                  });

            if (prop.path !== "" || prop.name !== "Logout") {
              if (prop.name === "Refresh Data") {
                return (
                  <ListItem
                    key={key}
                    className={classes.item}
                    onClick={this.props.refresh}
                  >
                    <div
                      className={navLinkClasses + " " + classes.refresh}
                      style={{ cursor: "pointer" }}
                    >
                      <ListItemIcon
                        className={itemIcon}
                        style={{ marginLeft: "21px", marginTop: "11px" }}
                      >
                        {typeof prop.icon === "string" ? (
                          <Icon>{prop.icon}</Icon>
                        ) : (
                          <FontAwesomeIcon icon={prop.icon} />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={t(prop.name)}
                        disableTypography={true}
                        className={itemText}
                      />
                    </div>
                  </ListItem>
                );
              } else {
                return (
                  <ListItem
                    key={key}
                    className={classes.item}
                    onClick={this.openTab}
                  >
                    <NavLink
                      to={prop.path}
                      className={navLinkClasses}
                      activeStyle={activeStyle}
                      isActive={(match, location) => {
                        if (match) {
                          if (match.url === location.pathname) {
                            return true;
                          }
                        }
                      }}
                    >
                      <ListItemIcon
                        className={prop.alert ? itemIconAlert : itemIcon}
                        style={{ marginLeft: "21px", marginTop: "11px" }}
                      >
                        {typeof prop.icon === "string" ? (
                          <Icon>{prop.icon}</Icon>
                        ) : (
                          <FontAwesomeIcon icon={prop.icon} />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={t(prop.name)}
                        disableTypography={true}
                        className={itemText}
                      />
                    </NavLink>
                  </ListItem>
                );
              }
            } else {
              if (window.innerWidth <= 960) {
                return (
                  <ListItem
                    key={key}
                    className={classes.item}
                    onClick={this.LogOut}
                  >
                    <div
                      className={navLinkClasses + " " + classes.logOutButton}
                      style={{ cursor: "pointer" }}
                    >
                      <ListItemIcon
                        className={prop.alert ? itemIconAlert : itemIcon}
                        style={{ marginLeft: "21px", marginTop: "11px" }}
                      >
                        {typeof prop.icon === "string" ? (
                          <Icon>{prop.icon}</Icon>
                        ) : (
                          <FontAwesomeIcon icon={prop.icon} />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={t(prop.name)}
                        disableTypography={true}
                        className={itemText}
                      />
                    </div>
                  </ListItem>
                );
              } else {
                return <span />;
              }
            }
          }
        })}
      </List>
    );

    const drawerPaper =
      classes.drawerPaper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.drawerPaperRTL]: rtlActive,
      });
    const sidebarWrapper =
      classes.sidebarWrapper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1,
      });
    return (
      <div ref="mainPanel">
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={rtlActive ? "left" : "right"}
            open={this.state.openMenu}
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"],
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <Toolbar />
            <SidebarWrapper className={sidebarWrapper} links={links} />
          </Drawer>
        </Hidden>
        <AppBarComponent
          route={this.props.location.pathname}
          userInfo={this.props.userInfo}
          open={this.openSideMenu}
        />{" "}
        {/* top bar */}
        <Hidden smDown implementation="css">
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={rtlActive ? "right" : "left"}
            variant="permanent"
            open={this.state.openMenu}
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"],
            }}
          >
            <Toolbar />

            <SidebarWrapper
              className={sidebarWrapper}
              //user={user}
              links={links}
            />
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: "red",
};

// option: hideInSideBar: false,
Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose",
    "black",
    "#333c43",
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
};

const withStylesClass = withStyles(sidebarStyle)(Sidebar);

export default withRouter(withTranslation("sidebar")(withStylesClass));
