import React from "react";
import PropTypes from "prop-types";
import { Popup } from "react-leaflet";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import mapViewStyle from "assets/jss/material-dashboard-pro-react/components/mapViewStyle";
import Button from "components/CustomButtons/Button";
const PopupComponent = (props) => {
  const { classes, data, selectorHeaderTitle } = props;
  return (
    <Popup>
      <Grid container style={{ width: 300 }}>
        <Grid item xs={12} className={classes.popup_header}>
          {selectorHeaderTitle(parseInt(data.groupId, 10))}
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={4} className={classes.popup_body}>
            Name:
          </Grid>
          <Grid item xs={8} className={classes.popup_body}>
            <span>
              {data.firstName} {data.lastName}
            </span>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={4} className={classes.popup_body}>
            Company:
          </Grid>
          <Grid item xs={8} className={classes.popup_body}>
            <span>{data.companyName}</span>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={4} className={classes.popup_body}>
            Email:
          </Grid>
          <Grid item xs={8} className={classes.popup_body}>
            <span>{data.email}</span>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={4} className={classes.popup_body}>
            Address:
          </Grid>
          <Grid item xs={8} className={classes.popup_body}>
            <span>
              {data.address},{data.city},{data.state},{data.country}
            </span>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={4} className={classes.popup_body}>
            Phone:
          </Grid>
          <Grid item xs={8} className={classes.popup_body}>
            <span>{data.phone}</span>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={4} className={classes.popup_body}>
            Fax:
          </Grid>
          <Grid item xs={8} className={classes.popup_body}>
            <span>{data.fax}</span>
          </Grid>
        </Grid>
        <Grid xs={12} className={classes.popup_header}>
          <Button color="success" onClick={() => {
            console.log("1x", props)
            props.history.push({
              pathname: "/dashboard/member/edit",
              state: {
                data: data,
                type: "member",
                clinicDeparment: undefined,
              },
            });
          }}>Manage</Button>
        </Grid>
      </Grid>
    </Popup>
  );
};

PopupComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

var withStylesClass = withStyles(mapViewStyle)(PopupComponent);

export default withStylesClass;
