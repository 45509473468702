import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import Button from "components/CustomButtons/Button";
import orderDashboardStyle from "assets/jss/material-dashboard-pro-react/components/orderDashboard";
const OrderDashboard = ({ classes, list }) => {
  const { t } = useTranslation("dashboardData");

  return (
    <div className={classes.container}>
      {
        <Grid item xs={12}>
          <Button className={classes.btn} color="transparent">
            View Order Dashboard
          </Button>
        </Grid>
      }

      {list.map((item) => (
        <Grid
          container
          xs={12}
          className={classes.item_list}
          onClick={item.onClick}
        >
          <Grid item xs={11}>
            <span>{item.quantity}</span>
            {t(item.title)}
          </Grid>
          <Grid item xs={1}>
            <FontAwesomeIcon
              icon={item.icon}
              style={{ fontSize: 20, color: "#E0E0E0" }}
            />
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

OrderDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

var withStylesClass = withStyles(orderDashboardStyle)(OrderDashboard);

export default withStylesClass;
