import React, { useState } from "react";
import { getTransferBetweenSpecialist } from "protocols/requests";

const TransferBetweenSpecialist = () => {
const [products, setProducts] = useState([]);
const [page, setPage] = useState(0);
const [totalPage, setTotalPage] = useState(null);
const [localRecords, setLocalRecords] = useState(10);
const [dataFilter, setDataFilter] = useState([
        {
            name: "Batch ID",
            value: "batchID",
        },
        {
            name: "Status",
            value: "status",
        },
    ]);
const handleGetTransferBetweenSpecialist = async (token) => {
    const result = await getTransferBetweenSpecialist(token);
    if (result.status === 200) {
        const response = result.response.response;
        setProducts(response);
        /* const products = result.response.response.members;
        const total = result.response.response.totalRecords;
        const total_page = Math.ceil(total / localRecords);
        setTotalPage(total_page);
        setProducts(products);
      }*/
    }
};

const handlerChangeRecordsPage = (value) => {
    setLocalRecords(value);
};

const handleChangePage = (page) => {
    page = page - 1;
    setPage(page);
};

return {
    products,
    page,
    handleChangePage,
    totalPage,
    handlerChangeRecordsPage,
    localRecords,
    dataFilter,
    handleGetTransferBetweenSpecialist,
};
};

export default TransferBetweenSpecialist;
