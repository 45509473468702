import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import returnRequestStyle from "assets/jss/material-dashboard-pro-react/components/abstractComponentStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import ErrorIcon from "@mui/icons-material/Error";

const AbstractComponent = (props) => {
  const {
    classes,
    model,
    qty,
    description,
    deleteCard,
    index,
    warehouse,
    warehouse_inv,
    id,
  } = props;

  const renderWarehouse = () => {
    if (warehouse_inv === 0) {
      return (
        <div style={{ color: "red" }}>
          <ErrorIcon fontSize="small" color="error" />
          <strong>BackOrder</strong>
        </div>
      );
    } else if (qty < 0) {
      return (
        <div style={{ color: "red" }}>
          <ErrorIcon fontSize="small" color="error" />
          {warehouse} <br />
          <strong>Cantidad Negativa</strong>
        </div>
      );
    }
    return warehouse;
  };
  const renderQty = () => {
    if (qty < 0) {
      return (
        <div style={{ color: "red" }}>
          <ErrorIcon fontSize="small" color="error" />
          <strong>{qty}</strong>
        </div>
      );
    }
    return qty;
  };

  return (
    <Grid container xs={12} className={classes.container}>
      <Grid xs={12}>
        <FontAwesomeIcon
          icon={faClose}
          className={classes.icon_order}
          style={{ color: "#14A2DC", fontSize: "120%" }}
          onClick={() => deleteCard(id)}
        />
      </Grid>
      <Grid xs={3}>Modelos:</Grid>
      <Grid xs={3}>Descripcion:</Grid>
      <Grid xs={3}>Cantidad</Grid>
      <Grid xs={3}>Bodega</Grid>
      <Grid container xs={12} className={classes.containerSkus}>
        <Grid xs={3}>{model}</Grid>
        <Grid xs={3}>{description}</Grid>
        <Grid xs={3}>{qty}</Grid>
        <Grid xs={3}>{renderWarehouse()}</Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(returnRequestStyle)(AbstractComponent);
