module.exports = {
    api_url: "http://localhost:3002",
    timeout: 30000,
    gridSize: {
        xs: 3,
        s: 4,
        m: 6,
        l: 8,
        xl: 12,
    },
    stellarXURL: "https://stellar.expert/explorer/testnet",
    horizonURL: "https://horizon-testnet.stellar.org",
    passwordRegexHints: [
        { regex: /(?=.{8,})/, hints: "eight characters" },
        { regex: /(?=.*[0-9])/, hints: "one number" },
        { regex: /(?=.*[A-Za-z])/, hints: "one letter" },
        { regex: /(?=.*[~`!@#$%^&*()\-_=+{}[\];:‘“|\\/.><,])/, hints: "one special character" },
     ]
}