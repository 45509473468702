/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@mui/material/Grid";
import batchViewsStyle from "assets/jss/material-dashboard-pro-react/components/BatchViewStyle";
//Translate
import { useTranslation } from "react-i18next";

function ItemList(props) {
  const {
    classes,
    id,
    setShowInformation,
    showInformation,
    setShowInformationId,
    product,
    batch,
    transferred,
    batchIncoming,
  } = props;

  const { t } = useTranslation("batchView");

  return (
    <Grid container xs={12} className={classes.container_list_item}>
      {transferred ? (
        <Grid container item xs={11}>
          <p
            className={classes.content_item_id}
            onClick={() => {
              setShowInformation(!showInformation);
              setShowInformationId(id);
            }}
          >
            ID: {product.batch_id}
          </p>
          <p className={classes.content_item}>
            {t("Name")}: {product.f_name_origin} {product.l_name_origin}
          </p>
          <p className={classes.content_item}>
            {t("Date")}: {product.batch_datetime.slice(0, -8)}
          </p>
          <p className={classes.content_item}>
            {t("Status")}: {product.status}
          </p>
        </Grid>
      ) : batch ? (
        <Grid container item xs={11}>
          <p
            className={classes.content_item_id}
            onClick={() => {
              setShowInformation(!showInformation);
              setShowInformationId(id);
            }}
          >
            ID: {product.batch_id}
          </p>
          <p className={classes.content_item}>
            {t("Clinic / Hospital")}: {product.company_name}
          </p>
          <p className={classes.content_item}>
            {t("Date")}: {product.batch_datetime.slice(0, -8)}
          </p>
          <p className={classes.content_item}>{product.type_name_en}</p>
          {batch && product.order_type === "KE/KB" ? (
            <p
              className={
                product.replacement_status === "true"
                  ? classes.content_item_replacement_false
                  : classes.content_item_replacement_true
              }
            >
              {t("replacement")}:{" "}
              {product.replacement_status === "true"
                ? t("replacementFalse")
                : t("replacementTrue")}
            </p>
          ) : null}
        </Grid>
      ) : batchIncoming ? (
        <Grid container item xs={11}>
          <p
            className={classes.content_item_id}
            onClick={() => {
              setShowInformation(!showInformation);
              setShowInformationId(id);
            }}
          >
            ID: {product.batch_id}
          </p>
          <p className={classes.content_item}>
            {t("Clinic / Hospital")}: {product.company_name}
          </p>
          <p className={classes.content_item}>
            {t("Date")}: {product.batch_datetime.slice(0, -8)}
          </p>
        </Grid>
      ) : null}
      {/*<Grid container item xs={1} className={classes.container_actions}>*/}
      {/*  <input type="checkbox" className={classes.checkbox} />*/}
      {/*  <p>Select</p>*/}
      {/*  <FontAwesomeIcon icon={faPenToSquare} className={classes.icon} />*/}
      {/*</Grid>*/}
    </Grid>
  );
}

ItemList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(batchViewsStyle)(ItemList);
