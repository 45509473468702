import {
  baseUrl,
  baseUrlAbbott,
  baseUrlTemplate,
} from "../variables/constants";

const request = async (
  url,
  method,
  headers,
  data,
  params,
  abbott,
  template
) => {
  var promiseRq = null;

  let apiUrl;

  if (template) {
    apiUrl = `${baseUrlTemplate}${url}`;
  } else if (abbott) {
    apiUrl = `${baseUrlAbbott}${url}`;
  } else {
    apiUrl = `${baseUrl}${url}`;
  }

  if (data) {
    promiseRq = await fetch(apiUrl, {
      method: method,
      headers: {
        ...headers,
      },
      body: JSON.stringify(data),
      params: params,
    });
  } else {
    promiseRq = await fetch(apiUrl, {
      method: method,
      headers: {
        ...headers,
      },
      params: params,
    });
  }
  const response = await promiseRq.json();
  const status = promiseRq.status;
  return {
    response,
    status,
  };
};

const requestFormData = async (url, method, headers, data, params) => {
  var promiseRq = null;

  const apiUrl = `${baseUrl}${url}`;
  promiseRq = await fetch(apiUrl, {
    method: method,
    headers: {
      ...headers,
    },
    body: data,
    params: params,
  });
  const response = await promiseRq.json();
  const status = promiseRq.status;
  return {
    response,
    status,
  };
};

export const post = async (url, headers, data, params, template) => {
  const result2 = await request(
    url,
    "POST",
    headers,
    data,
    params,
    null,
    template
  );
  return result2;
};

export const get = async (url, headers, params, abbott) => {
  return await request(url, "get", headers, null, params, abbott);
};

export const put = async (url, headers, data, params) => {
  return await request(url, "PUT", headers, data, params);
};

export const patch = async (url, headers, data, params) => {
  return await request(url, "PATCH", headers, data, params);
};

export const remove = async (url, headers, data, params) => {
  return await request(url, "DELETE", headers, data, params);
};

export const formData = async (url, headers, data, params) => {
  return await requestFormData(url, "POST", headers, data, params);
};
