import React, { useState } from "react";
import * as XLSX from "xlsx";

import jwtDecode from "jwt-decode";
import {
  getShipTo,
  getidRep,
  getiMaterialGoup,
  getSkus,
  confirmOrder,
  getOrdersAuthorization,
  getProcessedOrders,
  getSkusbyKits,
  orderPdf,
  getClientUnlockRequest,
  updateUnlockRequest,
  deleteDocumentById,
  updateUnlockOrderStatus,
  getDocumentById,
  updateUnlockTransferStatus,
  getOrdersReports,
  getCatalogAccounts,
  loadBlockedClients,
  getBlockedCustomerHistory,
  DeleteBlockedClientsDocuments,
  getXmlData,
  getBlockedClients,
  manageBlockedClients,
  orderExcel,
} from "protocols/requests";
import ItemList from "components/Batch/BatchListComponent/ItemList";

const OrdersRequestController = (props) => {
  /* The above code is a React functional component that declares multiple state variables using the
useState hook. These state variables are used to manage the state of various data in the component.
The component also declares a ref variable for a toast notification. */
  const [shipto, setShipto] = useState([]);
  const [allshipto, setAllShipto] = useState([]);
  const [ordersProcessed, setOrdersProcessed] = useState([]);
  const [ordersAuthorization, setOrdersAuthorization] = useState([]);
  const [skus, setSkus] = useState([]);
  const [skusAux, setSkusAux] = useState([]);
  const [allSkus, setAllSkus] = useState([]);
  const [materialGroup, setMaterialGroup] = useState([]);
  const [namesKits, setNamesKits] = useState([]);
  const [orderSkusByKits, setOrderSkusByKits] = useState([]);
  const [idRep, setidRep] = useState({});
  const [auxShipto, setAuxShipto] = useState([]);
  const [auxSkus, setAuxSkus] = useState([]);
  const [steps, setSteps] = useState(1);
  const [fourStep, setFourStep] = useState(false);
  const [data, setData] = useState({ shipto: "", skus: [] });
  const [amount, setAmount] = useState([]);
  const [orderType, setOrderType] = useState(0);
  const [selectauxShipto, setSelectauxShipto] = useState("");
  const [selectauxShiptoName, setSelectauxShiptoName] = useState("");
  const [page, setPage] = useState(1);
  const [pageSkus, setPageSkus] = useState(0);
  const [pageOrderStatus, setPageOrderStatus] = useState(0);
  const [numberPageOrderStatus, setNumberPageOrderStatus] = useState();

  const [numberPage, setNumberpage] = useState();
  const [numberPageSkus, setNumberPageSkus] = useState();
  const [shearchShipto, setShearchShipto] = useState("");
  const [shearchSkus, setShearchSkus] = useState("");
  const [auxbuttonsId, setAuxbuttonsId] = useState([]);
  const [active, setActive] = useState(false);
  const [auxbuttonsIdSkus, setAuxbuttonsIdSkus] = useState([]);
  const [materialGroupSelected, setMaterialGroupSelected] = useState("");
  const [lifeUrgency, setLifeUrgency] = useState(false);
  const [firstModel, setFirstModel] = useState();
  const [auxwherehouse_inv, setAuxWhereHouse_Inv] = useState();
  const [observation, setObservation] = useState("");
  const [jwtToken, setJwtToken] = useState("");
  const [toast, setToast] = React.useState(React.createRef());
  const [showLoader, setShowLoader] = useState(true);
  const [orderId, setOrderId] = useState("");
  const [selectedPDF, setSelectedPDF] = useState(null);
  const [selectedExcel, setSelectedExcel] = useState(null);
  const [clientUnlockRequest, setClientUnlockRequest] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [decision, setDecision] = useState("");
  const [observationCoordinator, setObservationCoordinator] = useState("");
  const [unlockType, setUnlockType] = useState("");
  const [clientsReports, setClientsReports] = useState([]);
  const [allClientsReports, setAllClientsReports] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [blockedClientsDocument, setBlockedClientsDocument] = useState([]);
  const [blockedClients, setBlockedClients] = useState([]);
  const [xmlData, setXmlData] = useState("");
  const showToast = (type, content) => {
    if (toast) {
      if (type === "success") {
        toast.showSuccess(content);
      } else {
        toast.showError(content);
      }
    }
  };

  const handleChange = (e) => {
    setOrderId(e.target.value);
  };

  /**
   * This function updates the state of an observation based on a change in an input field.
   * @param event - The event parameter is an object that contains information about the event that
   * triggered the handleChangeObservation function. This object includes properties such as the type of
   * event, the target element that triggered the event, and any additional data related to the event.
   * In this case, the event parameter is used to get the
   */
  const handleChangeObservation = (event) => {
    setObservation(event.target.value);
  };

  /**
   * This function toggles the value of a boolean variable called "lifeUrgency".
   */
  const handleChangeLifeUrgency = () => {
    setLifeUrgency(!lifeUrgency);
  };

  /**
   * The function sets the selected auxiliary shipto and its name.
   * @param shipto - The shipto parameter is a variable that contains information about a selected
   * shipping address. It could be an object or an ID that identifies the selected shipping address.
   * @param name - The name parameter is a string that represents the name of the selected shipto. It is
   * used to set the state variable selectauxShiptoName.
   */
  const handleSelectAuxShipto = (shipto, name) => {
    setSelectauxShipto(shipto);
    setSelectauxShiptoName(name);
  };

  /**
   * This function handles getting a representative's ID and using it to retrieve related shipping
   * information.
   * @param token - The authentication token required to access the API.
   * @param id - The `id` parameter is a unique identifier used to retrieve information about a specific
   * object or resource. In this case, it is being used to retrieve information about a representative.
   */
  const handleGetidRep = async (token, id) => {
    props.setLoaderVisible(true);
    const result = await getidRep(token, id);
    if (result.status === 200) {
      const response = result.response.response;
      const result2 = await getShipTo(
        token,
        response[0].benefitting_Rep,
        page,
        null,
        shearchShipto
      );
      props.setLoaderVisible(false);
      if (result2.status === 200) {
        const response = result2.response.response;
        const totalRecords = result2.response.total_records;
        setShipto(response);
        setNumberpage(totalRecords);
      }

      await setidRep(response);
    }
  };

  /**
   * This function handles getting processed orders using a token and sets the response to the
   * processed orders.
   * @param token - The `token` parameter is likely a token or authorization key that is required to
   * access the `getProcessedOrders` function. It is passed as an argument to the
   * `handleGetProcessedOrders` function.
   */
  const handleGetProcessedOrders = async (token) => {
    var jwt = jwtDecode(token);
    const idRep = await getidRep(props.userToken, props.id);
    const result = await getProcessedOrders(
      token,
      idRep.response?.response[0]?.benefitting_Rep,
      jwt.groupSlug,
      parseInt(pageOrderStatus),
      orderId
    );

    if (result.status === 200) {
      const response = result.response.response;
      const totalRecords = result.response.total_records;
      setOrdersProcessed(response);
      setNumberPageOrderStatus(totalRecords);
    }
  };

  /**
   * This function retrieves all shipping information using a token and sets it to a state variable.
   * @param token - The token parameter is likely an authentication token or access token that is
   * required to make API requests to the server. It is used to verify the identity of the user making
   * the request and ensure that they have the necessary permissions to access the requested resources.
   */
  const handlegetAllshipto = async (token) => {
    var jwt = jwtDecode(token);
    setJwtToken(jwt);
    let all = true;
    const result2 = await getShipTo(token, idRep, 0, all);
    if (result2.status === 200) {
      const response = result2.response.response;
      setAllShipto(response);
    }
  };

  /**
   * This function retrieves material group data using a token and sets it to the state if the response
   * status is 200.
   * @param token - It is a token that is used for authentication and authorization purposes. It is
   * likely that this token is used to access some API or backend service that requires authentication.
   */
  const handleGetMaterialGroup = async (token) => {
    const result = await getiMaterialGoup(token, idRep[0].benefitting_Rep);
    if (result.status === 200) {
      const response = result.response.response;
      setMaterialGroup(response);
    }
  };
  const handleGetKits = async (token) => {
    const result = await getiMaterialGoup(
      token,
      idRep[0].benefitting_Rep,
      "kit"
    );
    if (result.status === 200) {
      const response = result.response.response;
      setNamesKits(response);
    }
  };

  const handleGetSkusByKits = async (token, shipto, kit) => {
    const result = await getSkusbyKits(
      token,
      shipto,
      kit,
      idRep[0].benefitting_Rep
    );
    if (result.status === 200) {
      const response = result.response.response;
      setOrderSkusByKits(response);
      // setSkusByKits(response);
    } else if (result.status === 404) {
      setOrderSkusByKits([]);
    }
  };

  const handleDeleteOrderList = async (id) => {
    // Crea copias de los arrays
    const updatedOrderSkusByKits = [...orderSkusByKits];
    const updatedAuxSkus = [...auxSkus];

    // Encuentra el índice del elemento en orderSkusByKits
    const indexInOrderSkus = updatedOrderSkusByKits.findIndex(
      (item) => item.id === id
    );

    // Encuentra el índice del elemento en auxSkus si no lo encontraste en orderSkusByKits
    const indexInAuxSkus =
      indexInOrderSkus === -1
        ? updatedAuxSkus.findIndex((item) => item.id === id)
        : -1;

    // Elimina el elemento de la copia correspondiente si se encontró en alguno de los arrays
    if (indexInOrderSkus !== -1) {
      updatedOrderSkusByKits.splice(indexInOrderSkus, 1);
    } else if (indexInAuxSkus !== -1) {
      updatedAuxSkus.splice(indexInAuxSkus, 1);
    }
    // Actualiza los estados con las copias actualizadas
    setOrderSkusByKits(updatedOrderSkusByKits);
    setAuxSkus(updatedAuxSkus);
  };

  /**
   * This function handles the retrieval and processing of SKUs based on various filters and conditions.
   * @param token - a token used for authentication and authorization purposes
   * @param shipto - The parameter "shipto" is a variable that represents the ship to location for which
   * the SKUs are being retrieved. It is used as a filter to only retrieve SKUs that are available at
   * that specific location.
   */
  const handlegetSkus = async (token, shipto) => {
    let type = "orders";
    let localSkuAux = [];
    // await setSkusAux([]);
    props.setLoaderVisible(true);
    const result = await getSkus(
      token,
      shipto,
      null,
      type,
      idRep[0].benefitting_Rep,
      null,
      shearchSkus,
      materialGroupSelected,
      parseInt(pageSkus)
    );
    props.setLoaderVisible(false);
    if (result.status === 200) {
      const response = result.response.response;
      const totalRecords = result.response.response.total_records;
      if (auxSkus.length > 0) {
        for (let item of auxSkus) {
          for (let item2 of response.skus) {
            if (
              item.batch == item2.batch &&
              item.serial == item2.serial &&
              item.warehouse_nearby == item2.warehouse_nearby &&
              item.id == item2.id &&
              shearchSkus
            ) {
              // item2.quantity = item.quantity;
              item2.warehouse_inv =
                Number(item2.warehouse_inv) - Number(item.quantity);
              if (item2.warehouse_inv < 0) {
                item2.warehouse_inv = 0;
              }
            }
          }
        }
      }
      // logica para bloqueo FEFO
      if (shearchSkus) {
        //se valida solo para cuando se use el filtro
        for (let item of response.skus) {
          item.shiptoSelect = shipto;
          //se recorre el array de skus para crear un array auxiliar

          if (localSkuAux.length == 0) {
            await localSkuAux.push(item);
            await setSkusAux((prev) => [...prev, item]);
          } else {
            /*   let exist = false;
            for (let item5 of localSkuAux) {
              if (item.id == item5.id) {
                exist = true;
              }
            } */
            if (item.model == localSkuAux[0].model /*  && !exist */) {
              //se valida el modelo para cuando se cambie de pagina
              await localSkuAux.push(item);
              await setSkusAux((prev) => [...prev, item]);
            } else {
              localSkuAux = [];
              await localSkuAux.push(item);
              await setSkusAux([]);
              await setSkusAux((prev) => [...prev, item]);
            }
          }
        }

        if (pageSkus == 0) {
          for (let item of response.skus) {
            // setSkusAux(skusAux);
            if (item.expirationDate && item.id != localSkuAux[0].id)
              // se valida el backorder y el primero de la lista para no bloquear
              item.block = true;
            for (let button of auxbuttonsIdSkus) {
              if (button.id == item.id && button.shiptoSelect == shipto) {
                item.block = false;
              }
            }
          }
        } else {
          if (skusAux[skusAux.length - 1].block == false) {
            for (let item of response.skus) {
              if (item.expirationDate && item.id != localSkuAux[0].id)
                item.block = true;
              for (let button of auxbuttonsIdSkus) {
                if (button.id == item.id && button.shiptoSelect == shipto) {
                  item.block = false;
                }
              }
            }
          } else {
            for (let item of response.skus) {
              item.block = true;
              for (let button of auxbuttonsIdSkus) {
                if (button.id == item.id && button.shiptoSelect == shipto) {
                  item.block = false;
                }
              }
            }
          }
        }
      }

      auxSkus.every((item) => item.quantity);

      //---------
      setSkus(response.skus);
      setNumberPageSkus(totalRecords);
    } else if (result.status === 422) {
      setPageSkus(0);
    } else {
      showToast("error", "No se encontraron skus para este ship to");
      setSkus([]);
    }
  };

  /**
   * This function retrieves all SKUs for a given ship to and sets them in state.
   * @param token - a token used for authentication and authorization purposes
   * @param shipto - The shipto parameter is a variable that is used to filter the results of the getSkus
   * function by a specific shipping destination. If the materialGroupSelected variable is true, then the
   * shipto parameter is used to filter the results by the selected material group. If
   * materialGroupSelected is false
   */
  const handlegetAllSkus = async (token, shipto) => {
    let all = true;
    let type = "orders";
    const idRep = await getidRep(token, props.id);
    const result = await getSkus(
      token,
      materialGroupSelected ? shipto : null,
      null,
      type,
      idRep?.response?.response[0]?.benefitting_Rep,
      all,
      null,
      materialGroupSelected
    );
    if (result.status === 200) {
      const response = result.response.response;
      setAllSkus(response.skus);
    } else {
      showToast("error", "No se encontraron skus para este ship to");
    }
  };

  /**
   * This function handles the authorization for getting orders and sets the orders authorization
   * response.
   * @param token - The token parameter is likely a string or object that contains an authorization token
   * used for authentication purposes when making API requests.
   */
  const handlegetOrdersAuthorization = async (token) => {
    props.setLoaderVisible(true);
    const result = await getOrdersAuthorization(token);
    props.setLoaderVisible(false);
    if (result.status === 200) {
      const response = result.response.response;
      setOrdersAuthorization(response);
    }
  };

  /**
   * The function checks for certain conditions and increments the value of a variable called "steps" if
   * all conditions are met.
   */
  const next = async () => {
    if (!orderType && steps == 1) {
      showToast("error", "Seleccione el típo de pedido");
    } else if (auxShipto.length == 0) {
      showToast("error", "Debe seleccionar al menos un Shipto");
    } else if (steps == 2 && selectauxShipto == "") {
      showToast("error", "Debe seleccionar al menos un Shipto");
    } else if (
      steps == 2 &&
      auxSkus.length == 0 &&
      orderSkusByKits.length == 0
    ) {
      showToast("error", "Debe seleccionar al menos 1 Sku");
    } else setSteps(steps + 1);
  };

  /**
   * This function handles the confirmation of an order by organizing the order details and sending them
   * to the server.
   * @param token - It is a token used for authentication and authorization purposes in the API calls.
   */
  const handleConfirmOrder = async (
    token,
    observationUnlock,
    estimatedValue,
    clientUnlockRequest,
    pdfList
  ) => {
    if (steps == 3 && auxSkus.length == 0 && orderSkusByKits.length == 0) {
      showToast("error", "No puede solicitar un pedido vacío");
      setSteps(2);
      setObservation("");
      setLifeUrgency(false);
    } else {
      let body = [];
      const backorder = [];
      let existShipto = false;
      let exisBckor = false;
      let pdfIds = [];
      const type = "order";
      let formData = new FormData();

      if (pdfList && pdfList.length > 0) {
        for (let i = 0; i < pdfList.length; i++) {
          const formData = new FormData(); // Reiniciar FormData para cada archivo
          formData.append("pdfFile", pdfList[i]);

          try {
            const result2 = await orderPdf(
              formData,
              props.userToken,
              null,
              type
            );

            if (result2.status == 200) {
              pdfIds.push(result2.response.response.id);
            } else if (result2.status == 413) {
              showToast(
                "error",
                "El documento adjunto supera el tamaño máximo permitido (4.49MB)"
              );
              props.setLoaderVisible(false);
              return;
            } else {
              showToast("error", result2.response.description);
              props.setLoaderVisible(false);
              return;
            }
          } catch (error) {
            showToast("error", "Error al subir el archivo");
            props.setLoaderVisible(false);
            return;
          }
        }
      }

      {
        [...orderSkusByKits, ...auxSkus].map((item) => {
          // item.warehouse_inv = Number(item.warehouse_inv) + Number(item.quantity);
          if (item.warehouse_inv == 0) {
            if (backorder.length == 0) {
              backorder.push({
                shipTo: item.shiptoSelect
                  ? item.shiptoSelect
                  : item.benefittingRep,
                name: item.name ? item.name : item.shitoName,
                typeOrder:
                  orderType == "Consignación"
                    ? 1
                    : orderType == "Procedimiento"
                      ? 2
                      : orderType == "Venta directa"
                        ? 3
                        : null,
                lifeUrgency: lifeUrgency,

                observation: observation,
                observationUnlock: observationUnlock,
                estimatedValue: estimatedValue,
                clientUnlockRequest: clientUnlockRequest,
                documents: pdfIds,

                skus: [
                  {
                    model: item.model,
                    desc:
                      item.material_description == null
                        ? ""
                        : item.material_description,
                    optimum:
                      item.optimum_national == null ? 0 : item.optimum_national,
                    current_inv:
                      item.current_inv == null ? 0 : item.current_inv,
                    quantity: item.backorderQty
                      ? Number(item.backorderQty)
                      : Number(item.quantity),
                    zfin: item.material,
                    serial: item.serial == null ? "" : item.serial,
                    batch: item.batch ? item.batch : "",
                    storage: toString(item.warehouse_nearby),
                    zfin: item.material,
                    warehouseInv: item.warehouse_inv,
                  },
                ],
              });
            } else {
              backorder.map((x) => {
                if (
                  item.shiptoSelect == x.shipTo ||
                  item.benefittingRep == x.shipTo
                )
                  exisBckor = true;
                else exisBckor = false;
              });

              if (exisBckor) {
                backorder.map((i) => {
                  i.skus.push({
                    model: item.model,
                    desc:
                      item.material_description == null
                        ? ""
                        : item.material_description,
                    optimum:
                      item.optimum_national == null ? 0 : item.optimum_national,
                    current_inv:
                      item.current_inv == null ? 0 : item.current_inv,
                    quantity: item.backorderQty
                      ? Number(item.backorderQty)
                      : Number(item.quantity),
                    zfin: item.material,
                    warehouseInv: item.warehouse_inv,
                    storage: item.warehouse_nearby,
                    batch: item.batch ? item.batch : "",
                    serial: item.serial == null ? "" : item.serial,
                  });
                });
              } else {
                backorder.push({
                  shipTo: item.shiptoSelect
                    ? item.shiptoSelect
                    : item.benefittingRep,
                  name: item.name ? item.name : item.shitoName,
                  typeOrder:
                    orderType == "Consignación"
                      ? 1
                      : orderType == "Procedimiento"
                        ? 2
                        : orderType == "Venta directa"
                          ? 3
                          : null,
                  lifeUrgency: lifeUrgency,
                  observation: observation,
                  observationUnlock: observationUnlock,
                  estimatedValue: estimatedValue,
                  clientUnlockRequest: clientUnlockRequest,
                  documents: pdfIds,
                  skus: [
                    {
                      model: item.model,
                      desc:
                        item.material_description == null
                          ? ""
                          : item.material_description,
                      optimum:
                        item.optimum_national == null
                          ? 0
                          : item.optimum_national,
                      current_inv:
                        item.current_inv == null ? 0 : item.current_inv,
                      quantity: item.backorderQty
                        ? Number(item.backorderQty)
                        : Number(item.quantity),
                      zfin: item.material,
                      serial: item.serial == null ? "" : item.serial,
                      batch: item.batch ? item.batch : "",
                      storage: toString(item.warehouse_nearby),
                      zfin: item.material,
                      warehouseInv: item.warehouse_inv,
                    },
                  ],
                });
              }
            }
          } else {
            if (body.length == 0) {
              body.push({
                shipTo: item.shiptoSelect
                  ? item.shiptoSelect
                  : item.benefittingRep,
                name: item.name ? item.name : item.shitoName,
                typeOrder:
                  orderType == "Consignación"
                    ? 1
                    : orderType == "Procedimiento"
                      ? 2
                      : orderType == "Venta directa"
                        ? 3
                        : null,
                lifeUrgency: lifeUrgency,
                observation: observation,
                observationUnlock: observationUnlock,
                estimatedValue: estimatedValue,
                clientUnlockRequest: clientUnlockRequest,
                documents: pdfIds,
                skus: [
                  {
                    model: item.model,
                    desc:
                      item.material_description == null
                        ? ""
                        : item.material_description,

                    optimum:
                      item.optimum_national == null ? 0 : item.optimum_national,
                    current_inv:
                      item.current_inv == null ? 0 : item.current_inv,
                    quantity: item.amountTaken
                      ? Number(item.amountTaken)
                      : Number(item.quantity),
                    zfin: item.material,
                    serial: item.serial == null ? "" : item.serial,
                    batch: item.batch ? item.batch : "",
                    storage: item.warehouse_nearby,
                    zfin: item.material,
                    warehouseInv: item.warehouse_inv,
                  },
                ],
              });
            } else {
              body.map((x) => {
                if (
                  item.shiptoSelect == x.shipTo ||
                  item.benefittingRep == x.shipTo
                ) {
                  existShipto = true;
                } else {
                  existShipto = false;
                }
              });

              if (existShipto) {
                body.map((i) => {
                  if (
                    item.shiptoSelect == i.shipTo ||
                    item.benefittingRep == i.shipTo
                  ) {
                    i.skus.push({
                      model: item.model,
                      desc:
                        item.material_description == null
                          ? ""
                          : item.material_description,
                      optimum:
                        item.optimum_national == null
                          ? 0
                          : item.optimum_national,
                      current_inv:
                        item.current_inv == null ? 0 : item.current_inv,
                      quantity: item.amountTaken
                        ? Number(item.amountTaken)
                        : Number(item.quantity),
                      zfin: item.material,
                      warehouseInv: item.warehouse_inv,
                      storage: item.warehouse_nearby,
                      batch: item.batch ? item.batch : "",
                      serial: item.serial == null ? "" : item.serial,
                    });
                  }
                });
              } else {
                body.push({
                  shipTo: item.shiptoSelect
                    ? item.shiptoSelect
                    : item.benefittingRep,
                  name: item.name ? item.name : item.shitoName,
                  typeOrder:
                    orderType == "Consignación"
                      ? 1
                      : orderType == "Procedimiento"
                        ? 2
                        : orderType == "Venta directa"
                          ? 3
                          : null,
                  lifeUrgency: lifeUrgency,
                  observation: observation,
                  observationUnlock: observationUnlock,
                  estimatedValue: estimatedValue,
                  clientUnlockRequest: clientUnlockRequest,
                  documents: pdfIds,
                  skus: [
                    {
                      model: item.model,
                      desc:
                        item.material_description == null
                          ? ""
                          : item.material_description,
                      optimum:
                        item.optimum_national == null
                          ? 0
                          : item.optimum_national,
                      current_inv:
                        item.current_inv == null ? 0 : item.current_inv,
                      quantity: item.amountTaken
                        ? Number(item.amountTaken)
                        : Number(item.quantity),
                      zfin: item.material,
                      serial: item.serial == null ? "" : item.serial,
                      batch: item.batch ? item.batch : "",
                      storage: item.warehouse_nearby,
                      zfin: item.material,
                      warehouseInv: item.warehouse_inv,
                    },
                  ],
                });
              }
            }
          }
        });
      }
      if (backorder.length > 0) body = body.concat(backorder);

      props.setLoaderVisible(true);

      if (selectedPDF) {
        const type = "order";
        /*   const palabras = result.response.description.split(" ");
          const ordenCreada = palabras[palabras.length - 1]; */
        let formData = new FormData();
        formData.append("pdfFile", selectedPDF);
        const result2 = await orderPdf(
          formData,
          props.userToken,
          // ordenCreada,
          type
        );

        if (result2.status == 200) {
          const result = await confirmOrder(
            body,
            token,
            idRep[0].benefitting_Rep,
            result2.response.response.id
          );
          props.setLoaderVisible(false);

          if (result.status == 200) {
            // window.location.reload();
            showToast("success", result.response.description);
            setSteps(1);
            setOrderType(0);
            setAuxShipto([]);
            setShearchShipto("");
            setAuxbuttonsId([]);
            if (selectauxShipto != "") {
              setSelectauxShipto("");
            }
            setSkus([]);
            setShearchSkus("");
            setMaterialGroupSelected("");
            setAuxSkus([]);
            setOrderSkusByKits([]);
            setObservation("");
            setAuxbuttonsIdSkus([]);
            setNumberPageSkus(null);
            setLifeUrgency(false);
            setSelectedPDF(null);
            setFourStep(false);
            body = [];
          } else {
            showToast("error", result.response.description);
          }
        } else if (result2.status == 413) {
          showToast(
            "error",
            "El documento adjunto supera el tamaño máximo permitido (4.49MB)"
          );
          props.setLoaderVisible(false);
        } else {
          showToast("error", result2.response.description);
        }
      } else {
        const result = await confirmOrder(
          body,
          token,
          idRep[0].benefitting_Rep
        );
        props.setLoaderVisible(false);

        if (result.status == 200) {
          // window.location.reload();
          showToast("success", result.response.description);
          setSteps(1);
          setOrderType(0);
          setAuxShipto([]);
          setShearchShipto("");
          setAuxbuttonsId([]);
          if (selectauxShipto != "") {
            setSelectauxShipto("");
          }
          setSkus([]);
          setShearchSkus("");
          setMaterialGroupSelected("");
          setAuxSkus([]);
          setOrderSkusByKits([]);
          setObservation("");
          setAuxbuttonsIdSkus([]);
          setNumberPageSkus(null);
          setLifeUrgency(false);
          setSelectedPDF(null);
          setFourStep(false);
          body = [];
        } else {
          showToast("error", result.response.description);
        }
      }
    }
  };

  const handleCancelUnlockRequest = () => {
    setSteps(1);
    setOrderType(0);
    setAuxShipto([]);
    setShearchShipto("");
    setAuxbuttonsId([]);
    if (selectauxShipto != "") {
      setSelectauxShipto("");
    }
    setSkus([]);
    setShearchSkus("");
    setMaterialGroupSelected("");
    setAuxSkus([]);
    setOrderSkusByKits([]);
    setObservation("");
    setAuxbuttonsIdSkus([]);
    setNumberPageSkus(null);
    setLifeUrgency(false);
    setSelectedPDF(null);
    setFourStep(false);
  };

  /**
   * The function adds a new shipto object to an array and updates the array of unique shipto IDs.
   * @param shipto - shipto is a parameter that represents an object containing information about a
   * shipping address. It is being passed as an argument to the function handleAddShipto.
   */
  const handleAddShipto = (shipto) => {
    const existsLockedShipto = auxShipto.some((item) => item.locked === 1);

    if (shipto.locked == 1 && auxShipto.length > 0) {
      showToast(
        "error",
        "No se permite seleccionar un shipto bloqueado si ya hay otros shipto seleccionados"
      );
      return;
    }

    if (existsLockedShipto) {
      // Mostrar mensaje de advertencia y salir de la función
      showToast(
        "error",
        "No se permite seleccionar otro shipto, si ya seleccionó un shipto bloqueado por cartera"
      );
      return;
    }

    if (shipto.locked == 1) {
      setFourStep(true);
    }
    auxShipto.push(shipto);

    if (!auxbuttonsId.includes(shipto.ship_to)) {
      auxbuttonsId.push(shipto.ship_to);
    }

    setAuxShipto(auxShipto);
    setAuxbuttonsId(auxbuttonsId);
    setActive(!active);
  };

  /**
   * The function handles the deletion of a shipto object from an array and updates the state variables.
   * @param shipto - The parameter "shipto" is an object that represents a shipping address. It is used
   * in the function to identify which shipping address to delete from the "auxShipto" array.
   */
  const handleDeleteShipto = (shipto) => {
    if (shipto.locked == 1) {
      setFourStep(false);
    }
    let id = 0;
    let idDelete = null;
    for (let item of auxShipto) {
      if (item.ship_to == shipto.ship_to) {
        idDelete = id;
      }
      id++;
    }
    var i = auxShipto.indexOf(shipto);
    if (idDelete !== null) {
      auxShipto.splice(idDelete, 1);
    }

    var idx = auxbuttonsId.indexOf(shipto.ship_to);

    if (idx !== -1) {
      auxbuttonsId.splice(idx, 1);
    }
    setAuxShipto(auxShipto);
    setAuxbuttonsId(auxbuttonsId);
    setActive(!active);
  };

  /**
   * The function handles adding a SKU to a list and checks for certain conditions before doing so.
   * @param sku - An object representing a product SKU (stock keeping unit) with properties such as
   * quantity, warehouse inventory, model, batch, and nearby warehouse location.
   * @param id - The ID of the SKU being added.
   */
  const handleAddSku = (sku, id) => {
    //logica para bloqueo FEFO
    if (sku.quantity == sku.warehouse_inv || sku.warehouse_inv == 0) {
      // se valida que la cantidad pedida sea la misma en bodega
      for (let i = 0; i < skusAux.length; i++) {
        if (skusAux[i]?.id == sku.id) {
          // se valida el id del sku que se agrego
          for (let item of skus) {
            if (skusAux[i + 1]?.id == item.id) {
              item.block = false;
            } // se desbloquea el sku que tenga el mismo id del sku siguiente por eso se le suma 1 a la pasicion
          }
        }
      }
    }
    //LOGICA PARA RESTAR UNIDADES CON BLOQUEO
    // for (let i = 0; i < skusAux.length; i++) {
    //   if (skusAux[i]?.id == sku.id) {
    //     for (let item of skus) {
    //       if (
    //         skusAux[i]?.id == item.id &&
    //         skusAux[i]?.model == item.model &&
    //         skusAux[i]?.batch == item.batch &&
    //         skusAux[i]?.warehouse_nearby == item.warehouse_nearby
    //       ) {
    //         item.warehouse_inv =
    //           Number(item.warehouse_inv) - Number(item.quantity);
    //         console.log("ENTRE", item);

    //         /*     item.warehouse_inv =
    //           Number(item.warehouse_inv) - Number(item.quantity);
    //         skusAux[i].warehouse_inv = item.warehouse_inv;
    //         console.log(skusAux[i].warehouse_inv, "ENTRE");
    //         setAuxWhereHouse_Inv(skusAux[i].warehouse_inv); */
    //       }
    //     }
    //   }
    // }
    //------
    let exist = false;

    auxSkus.push(sku);

    for (let item of auxbuttonsIdSkus) {
      if (item.id == id && item.shiptoSelect == sku.shiptoSelect) {
        exist = true;
      }
    }

    if (!exist) {
      auxbuttonsIdSkus.push({
        id: id,
        quantity: sku.quantity,
        shiptoSelect: sku.shiptoSelect,
      });
    }

    setAuxSkus(auxSkus);
    setAuxbuttonsIdSkus(auxbuttonsIdSkus);
    setActive(!active);
  };

  /**
   * This function handles the deletion of a SKU from a list and updates related data.
   * @param sku - The SKU (Stock Keeping Unit) object that needs to be deleted.
   * @param idBtn - The id of the button that triggered the delete action.
   */
  const handleDeleteSku = (sku, idBtn) => {
    //LOGICA PARA RESTAR UNIDADES
    // for (let i = 0; i < skusAux.length; i++) {
    //   if (skusAux[i]?.id == sku.id) {
    //     for (let item of skus) {
    //       if (
    //         skusAux[i]?.id == item.id &&
    //         skusAux[i]?.model == item.model &&
    //         skusAux[i]?.batch == item.batch &&
    //         skusAux[i]?.warehouse_nearby == item.warehouse_nearby
    //       ) {
    //         item.warehouse_inv =
    //           Number(item.warehouse_inv) + Number(item.quantity);
    //         console.log(item, "ENTRE2");

    //         /*  item.warehouse_inv =
    //           Number(item.warehouse_inv) + Number(item.quantity);
    //         skusAux[i].warehouse_inv = item.warehouse_inv;
    //         console.log(skusAux[i].warehouse_inv, "ENTRE");
    //         setAuxWhereHouse_Inv(skusAux[i].warehouse_inv); */
    //       }
    //     }
    //   }
    // }
    //

    if (skusAux.length !== 0) {
      let position;
      for (let i = 0; i < skusAux.length; i++) {
        if (skusAux[i]?.id === idBtn) {
          position = i;
        }
      }

      for (let i = 0; i < skusAux.length; i++) {
        if (i > position) {
          for (let item of skus) {
            if (item.id == skusAux[i]?.id) {
              item.block = true;
            }
          }
          for (let j = 0; j < auxbuttonsIdSkus.length; j++) {
            if (
              auxbuttonsIdSkus[j]?.id == skusAux[i]?.id &&
              auxbuttonsIdSkus[j]?.shiptoSelect == skusAux[i]?.shiptoSelect
            ) {
              /*  for (let item of skus) {
                if (item.id == auxbuttonsIdSkus[j]?.id) {
                  item.warehouse_inv =
                    Number(item.warehouse_inv) + Number(item.quantity);
                }
              } */

              auxbuttonsIdSkus.splice(j, 1);
            }
          }

          for (let k = 0; k < auxSkus.length; k++) {
            if (
              auxSkus[k]?.id == skusAux[i]?.id &&
              auxSkus[k]?.shiptoSelect == skusAux[i]?.shiptoSelect
            ) {
              auxSkus.splice(k, 1);
            }
          }
        }
      }
    }
    let id = 0;
    let idDeleteSku = null;
    for (let item of auxSkus) {
      if (
        item.model == sku.model &&
        item.batch == sku.batch &&
        item.serial == sku.serial
      ) {
        idDeleteSku = id;
      }
      id++;
    }
    if (idDeleteSku !== null) {
      auxSkus.splice(idDeleteSku, 1);
    }

    let idbutton = 0;
    let idDeleted = null;
    for (let item of auxbuttonsIdSkus) {
      if (item.id == idBtn) {
        idDeleted = idbutton;
      }
      idbutton++;
    }

    if (idDeleted !== null) {
      auxbuttonsIdSkus.splice(idDeleted, 1);
    }

    if (skusAux.length !== 0) {
      let position;
      for (let i = 0; i < skusAux.length; i++) {
        if (skusAux[i]?.id === idBtn) {
          position = i;
        }
      }

      for (let i = 0; i < skusAux.length; i++) {
        if (i > position) {
          for (let item of skus) {
            if (item.id == skusAux[i]?.id) {
              item.block = true;
            }
          }
          for (let j = 0; j < auxbuttonsIdSkus.length; j++) {
            if (
              auxbuttonsIdSkus[j]?.id == skusAux[i]?.id &&
              auxbuttonsIdSkus[j]?.selectauxShipto == shipto
            ) {
              /*  for (let item of skus) {
                if (item.id == auxbuttonsIdSkus[j]?.id) {
                  item.warehouse_inv =
                    Number(item.warehouse_inv) + Number(item.quantity);
                }
              } */

              auxbuttonsIdSkus.splice(j, 1);
            }
          }

          for (let k = 0; k < auxSkus.length; k++) {
            if (
              auxSkus[k]?.id == skusAux[i]?.id &&
              auxSkus[k]?.selectauxShipto == shipto
            ) {
              auxSkus.splice(k, 1);
            }
          }
        }
      }
    }

    setAuxSkus(auxSkus);
    setAuxbuttonsIdSkus(auxbuttonsIdSkus);

    setActive(!active);
  };

  const downloadPdf = (pdfData, fileName) => {
    // Decodifica la cadena base64 a un ArrayBuffer
    const binaryData = atob(pdfData);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const byteArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      byteArray[i] = binaryData.charCodeAt(i);
    }

    // Crea un Blob a partir del ArrayBuffer
    const blob = new Blob([arrayBuffer], { type: "application/pdf" });

    // Crea un objeto URL a partir del Blob
    const url = window.URL.createObjectURL(blob);

    // Crea un elemento de anclaje invisible y configura los atributos
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = fileName; // Nombre del archivo de descarga

    // Agrega el elemento de anclaje al cuerpo del documento
    document.body.appendChild(a);

    // Simula un clic en el enlace para iniciar la descarga
    a.click();

    // Revoca el objeto URL para liberar recursos
    window.URL.revokeObjectURL(url);
  };

  // ------------ FASE 3 -----------

  const handleGetClientUnlockRequest = async (
    token,
    status,
    transfer,
    isAccount,
    history
  ) => {
    if (isAccount) {
      transfer = true;
      const idRep = await getidRep(props.userToken, props.id);
      props.setLoaderVisible(true);
      const result = await getClientUnlockRequest(
        token,
        status,
        null,
        idRep?.response?.response[0]?.benefitting_Rep
      );
      const result2 = await getClientUnlockRequest(
        token,
        status,
        transfer,
        idRep?.response?.response[0]?.benefitting_Rep
      );
      props.setLoaderVisible(false);

      if (result.status === 200 && result2.status === 200) {
        const response = result.response.response;
        const response2 = result2.response.response;
        const combineresults = [...response, ...response2];
        setClientUnlockRequest(combineresults);
      } else if (result.status === 200) {
        setClientUnlockRequest(result.response.response);
      } else if (result2.status === 200) {
        setClientUnlockRequest(result2.response.response);
      } else {
        setClientUnlockRequest([]);
      }
    } else {
      props.setLoaderVisible(true);

      const idRep = await getidRep(props.userToken, props.id);
      const result = await getClientUnlockRequest(
        token,
        status,
        transfer,
        idRep?.response?.response[0]?.benefitting_Rep,
        history
      );
      props.setLoaderVisible(false);
      if (result.status === 200) {
        const response = result.response.response;
        setClientUnlockRequest(response);
        props.setLoaderVisible(false);
      } else if (result.status === 404) {
        // showToast("error", "No hay solicitudes para mostrar");
        props.setLoaderVisible(false);
        setClientUnlockRequest([]);
      } else {
        showToast("error", result.response.description);
        props.setLoaderVisible(false);
        setClientUnlockRequest([]);
      }
    }
  };

  const handleUpdateUnlockOrder = async ({
    observationUnlock,
    pdfList,
    newPdfList,
    selectedRequest,
    isTransfer,
  }) => {
    let pdfIds = [];
    // const documentIds = pdfList.map((item) => item.document_id);
    let formData = new FormData();
    let body = {};
    let order_id = selectedRequest?.order_id;
    const type = "order";

    if (newPdfList && newPdfList.length > 0) {
      // Procesamos la lista de PDFs
      for (let i = 0; i < newPdfList.length; i++) {
        const formData = new FormData(); // Reiniciar FormData para cada archivo
        formData.append("pdfFile", newPdfList[i]);
        const result2 = await orderPdf(formData, props.userToken, null, type);

        if (result2.status == 200) {
          pdfIds.push(result2.response.response.id);
        } else if (result2.status == 413) {
          showToast(
            "error",
            "El documento adjunto supera el tamaño máximo permitido (4.49MB)"
          );
          props.setLoaderVisible(false);
          return;
        } else {
          showToast("error", result2.response.description);
          props.setLoaderVisible(false);
          return;
        }
      }
    }

    body = {
      observation: observationUnlock,
      documents: pdfIds.length > 0 ? pdfIds : null,
    };

    props.setLoaderVisible(true);
    const result = await updateUnlockRequest(
      body,
      props.userToken,
      order_id,
      isTransfer
    );
    props.setLoaderVisible(false);
    if (result.status == 200) {
      showToast("success", "Orden actualizada con éxito");

      window.location.reload();
    } else {
      showToast("error", "Error al actualizar la orden");

      return;
    }
  };

  const handleDeleteDocumentById = async (token, id) => {
    const result = await deleteDocumentById(id, token);

    if (result.status === 200) {
      console.log("Eliminado");
    }
  };

  const handleUpdateUnlockOrderStatus = async (
    status,
    selectedRequest,
    observationCoordinator
  ) => {
    let order_id = selectedRequest?.order_id;
    let body = {
      coordinatorObservation: observationCoordinator,
    };
    props.setLoaderVisible(true);
    const idRep = await getidRep(props.userToken, props.id);
    const result = await updateUnlockOrderStatus(
      body,
      props.userToken,
      order_id,
      status,
      idRep?.response?.response[0]?.benefitting_Rep
    );

    if (result.status == 200) {
      showToast("success", "Orden actualizada con éxito");
      props.setLoaderVisible(false);
      window.location.reload();
    } else {
      showToast("error", "Error al actualizar la orden");
      props.setLoaderVisible(false);
      return;
    }
  };

  const handleGetDocumentsById = async (documentId, documentName) => {
    props.setLoaderVisible(true);
    try {
      const result = await getDocumentById(props.userToken, documentId);
      downloadPdf(result.response.response, documentName);
    } catch (error) {
      console.error("Error fetching document:", error);
    } finally {
      props.setLoaderVisible(false);
    }
  };

  const handleUpdateUnlockTransfersStatus = async (
    status,
    selectedRequest,
    observationCoordinator
  ) => {
    let order_id = selectedRequest?.order_id;
    let body = {
      coordinatorObservation: observationCoordinator,
    };
    props.setLoaderVisible(true);
    const idRep = await getidRep(props.userToken, props.id);
    const result = await updateUnlockTransferStatus(
      body,
      props.userToken,
      order_id,
      status,
      idRep?.response?.response[0]?.benefitting_Rep
    );

    if (result.status == 200) {
      showToast("success", "Orden actualizada con éxito");
      // alert("Orden actualizada con éxito");
      props.setLoaderVisible(false);
      window.location.reload();
    } else {
      showToast("error", "Error al actualizar la orden");
      // alert("Error al actualizar la orden");
      props.setLoaderVisible(false);
      return;
    }
  };

  const handleUpdateBlockedClients = async () => {
    if (selectedExcel) {
      try {
        const result = await orderExcel(
          selectedExcel,
          props.userToken,
          null,
          "excel"
        ); // Subir a S3

        if (result.status === 200) {
          console.log("Archivo subido a S3, ID:", result.response.response.id);
          const binaryString = atob(selectedExcel.data); // Decodifica Base64
          const len = binaryString.length;
          const data = new Uint8Array(len);

          for (let i = 0; i < len; i++) {
            data[i] = binaryString.charCodeAt(i);
          }
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          const content = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

          console.log("AQUI", content);

          // Extraer los datos de las columnas relevantes
          const clients = content.slice(1).map((row) => ({
            customer: row[0], // Customer
            coordinator: row[3], // Coordinador
            coordinatorBackup: row[4], // Coordinador Backup
            portfolioManager: row[5], // Gerente de Cartera
            treasuryManager: row[6], // Gerente de Tesorería
          }));

          console.log("Clientes extraídos:", clients);

          let body = {
            clients: clients,
            documentId: result.response.response.id,
            documentName: selectedExcel.name,
            comment: "",
          };
          const idRep = await getidRep(props.userToken, props.id);

          const result2 = await loadBlockedClients(
            body,
            props.userToken,
            idRep?.response?.response[0]?.benefitting_Rep
          );

          if (result2.status === 200) {
            showToast("success", result2.response.description);
            window.location.reload();
          } else {
            showToast("error", "Error en la solicitud");
          }
        }
      } catch (error) {
        console.error("Error al subir el archivo o procesar los datos", error);
      }
    }
  };

  const handleGetBlockedCustomerHistory = async () => {
    const result = await getBlockedCustomerHistory(props.userToken);

    if (result.status === 200) {
      const response = result.response.response;
      setBlockedClientsDocument(response);
    } else {
      showToast("error", result.response.description);
      props.setLoaderVisible(false);
      return;
    }
  };

  const handlegetBlockedClients = async () => {
    const result = await getBlockedClients(props.userToken);

    if (result.status === 200) {
      const response = result.response.response;
      setBlockedClients(response);
    } else {
      showToast("error", result.response.description);
      props.setLoaderVisible(false);
      return;
    }
  };

  const handleManageBlockedClients = async (selectedClient, observation) => {
    let body = {
      customerId: selectedClient?.id,
      status: selectedClient?.locked === 1 ? 0 : 1,
      comment: observation,
    };
    props.setLoaderVisible(true);
    const idRep = await getidRep(props.userToken, props.id);

    const result = await manageBlockedClients(
      body,
      props.userToken,
      idRep?.response?.response[0]?.benefitting_Rep
    );

    if (result.status == 200) {
      showToast("success", "Cliente Actualizado");
      // alert("Orden actualizada con éxito");
      props.setLoaderVisible(false);
      window.location.reload();
    } else {
      showToast("error", "Error al actualizar el cliente");
      // alert("Error al actualizar la orden");
      props.setLoaderVisible(false);
      return;
    }
  };

  const handleDeleteDocumentBlockedClients = async (token, id) => {
    const result = await DeleteBlockedClientsDocuments(id, token);

    if (result.status === 200) {
      console.log("Eliminado");
    }
  };

  const handlegetXmlData = async (token) => {
    let typeOrder = "portfolio";
    props.setLoaderVisible(true);
    const idRep = await getidRep(props.userToken, props.id);

    const result = await getXmlData(
      token,
      typeOrder,
      idRep?.response?.response[0]?.benefitting_Rep
    );
    props.setLoaderVisible(false);
    if (result.status === 200) {
      const response = result.response.response;
      setXmlData(response);
    }
  };

  // ------------------ REPORTES ----------------------------

  const handleGetOrdersReport = async (
    startDate,
    endDate,
    account,
    orderId,
    reportType,
    typeUser
  ) => {
    props.setLoaderVisible(true);
    const idRep = await getidRep(props.userToken, props.id);
    const initialResult = await getOrdersReports(
      props.userToken,
      startDate,
      endDate,
      account
        ? account
        : typeUser !== "Account-manager"
          ? null
          : idRep?.response?.response[0]?.benefitting_Rep,
      orderId,
      reportType,
      0, // Página inicial (0)
      10 // 10 registros por página
    );

    props.setLoaderVisible(false);

    if (initialResult.status === 200) {
      setClientsReports(initialResult.response.response.data); // Mostrar la primera página
      const totalRecords = initialResult.response.response.totalRecords;
      const totalPages = Math.ceil(totalRecords / 500) - 1; // Calcula el número total de paginas

      const allReports = [];

      for (let page = 0; page <= totalPages; page++) {
        // Recorre desde 0 hasta totalPages
        const exportResult = await getOrdersReports(
          props.userToken,
          startDate,
          endDate,
          account
            ? account
            : typeUser !== "Account-manager"
              ? null
              : idRep?.response?.response[0]?.benefitting_Rep,
          orderId,
          reportType,
          page, // Variable de página
          500 // 500 registros por página en excel.
        );

        if (exportResult.status === 200) {
          allReports.push(...exportResult.response.response.data);
        } else {
          showToast("error", exportResult.response.description);
          setAllClientsReports([]);
          break;
        }
      }

      setAllClientsReports(allReports); // Guardar todos los registros
    } else {
      showToast("error", initialResult.response.description);
      setClientsReports([]);
      props.setLoaderVisible(false);
      return;
    }
  };

  const handleGetAccounts = async () => {
    props.setLoaderVisible(true);
    const result = await getCatalogAccounts(props.userToken);
    props.setLoaderVisible(false);

    if (result.status === 200) {
      const response = result.response.response.data;
      setAccountList(response);
    } else {
      showToast("error", result.response.description);
      props.setLoaderVisible(false);
      return;
    }
  };

  return {
    shipto,
    idRep,
    skus,
    setSkus,
    auxSkus,
    steps,
    setSteps,
    fourStep,
    setAuxSkus,
    auxShipto,
    setAuxShipto,
    handleGetidRep,
    materialGroup,
    handleGetMaterialGroup,
    handleGetKits,
    handlegetSkus,
    data,
    setData,
    setAmount,
    amount,
    next,
    handleConfirmOrder,
    showToast,
    setToast,
    ordersAuthorization,
    handlegetOrdersAuthorization,
    setOrderType,
    handleSelectAuxShipto,
    selectauxShipto,
    page,
    setPage,
    numberPage,
    handlegetAllshipto,
    allshipto,
    setShearchShipto,
    shearchShipto,
    handleAddShipto,
    handleDeleteShipto,
    auxbuttonsId,
    setShearchSkus,
    shearchSkus,
    allSkus,
    handlegetAllSkus,
    handleAddSku,
    auxbuttonsIdSkus,
    handleDeleteSku,
    setAuxbuttonsIdSkus,
    setMaterialGroupSelected,
    materialGroupSelected,
    handleGetProcessedOrders,
    ordersProcessed,
    handleChangeLifeUrgency,
    lifeUrgency,
    firstModel,
    numberPageSkus,
    pageSkus,
    setPageSkus,
    setSelectauxShipto,
    setSkus,
    setNumberPageSkus,
    skusAux,
    auxwherehouse_inv,
    handleChangeObservation,
    observation,
    jwtToken,
    pageOrderStatus,
    setPageOrderStatus,
    numberPageOrderStatus,
    setNumberPageOrderStatus,
    namesKits,
    handleGetSkusByKits,
    orderSkusByKits,
    setOrderSkusByKits,
    handleDeleteOrderList,
    showLoader,
    handleChange,
    orderId,
    selectedPDF,
    setSelectedPDF,
    downloadPdf,
    handleCancelUnlockRequest,
    handleGetClientUnlockRequest,
    clientUnlockRequest,
    handleUpdateUnlockOrder,
    handleDeleteDocumentById,
    handleUpdateUnlockOrderStatus,
    selectedRequest,
    setSelectedRequest,
    decision,
    setDecision,
    observationCoordinator,
    setObservationCoordinator,
    unlockType,
    setUnlockType,
    handleGetDocumentsById,
    handleUpdateUnlockTransfersStatus,
    handleGetOrdersReport,
    clientsReports,
    allClientsReports,
    handleGetAccounts,
    accountList,
    setSelectedExcel,
    selectedExcel,
    handleUpdateBlockedClients,
    handleGetBlockedCustomerHistory,
    blockedClientsDocument,
    setBlockedClientsDocument,
    handleDeleteDocumentBlockedClients,
    handlegetXmlData,
    xmlData,
    handlegetBlockedClients,
    blockedClients,
    handleManageBlockedClients,
  };
};
export default OrdersRequestController;
