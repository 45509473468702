/* eslint-disable */
import React, { Component, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@mui/material/Grid";
import userViewsStyle from "assets/jss/material-dashboard-pro-react/components/userViews";
import Button from "components/CustomButtons/Button.jsx";
import Select from "components/CustomInput/Select.jsx";
import Input from "components/CustomInput/Input";
import Toast from "components/Toast/Toast.jsx";
import Pagination from "@mui/material/Pagination";
import Table from "components/userViews/Table";
import DataTable from "./Table/DataTable";

//controllers
import {
  handleRemoveMember,
  handleChangeStatus,
} from "controllers/EditMemberControllers";

import NotificationsControllers from "controllers/NotificationsControllers";
//Translation
import { useTranslation } from "react-i18next";

function UserViews(props) {
  const {
    title,
    classes,
    clinic,
    handlerAddMember,
    members,
    products,
    handleChangePage,
    totalPage,
    handlerChangeRecordsPage,
    clinicDeparment,
    specialist,
    clinicInventory,
    incomingProducts,
    scannedBatch,
    dataFilter,
    noFilter,
    noFilterSearch,
    mainManager,
    notifications,
    Expired,
    places,
    handlerChangeData,
    handleGetProductFilter,
  } = props;
  const { handleUpdatedNotifications } = NotificationsControllers(
    props.userToken
  );
  const [pagesNum, setPagesNum] = useState([
    {
      name: "10",
      value: 10,
    },
    {
      name: "20",
      value: 20,
    },
    {
      name: "30",
      value: 30,
    },
    {
      name: "40",
      value: 40,
    },
    {
      name: "50",
      value: 50,
    },
  ]);
  const [currency, setCurrency] = React.useState("");
  const [idRemove, setIdRemove] = React.useState(null);
  const [idNotification, setIdNotification] = React.useState(null);
  let toast = React.createRef();
  const showToast = (type, content, id, notification) => {
    if (toast) {
      if (type === "warning") {
        if (notification) {
          setIdNotification(id);
        } else {
          setIdRemove(id);
        }
        toast.showWarning(content);
      } else if (type === "success") {
        toast.showSuccess(content);
      } else {
        toast.showError(content);
      }
    }
  };

  const handlerRemove = async () => {
    const result = await handleRemoveMember(idRemove, props.userToken);
    if (result.status === 200) {
      showToast("success", result.response.description);
      window.location.reload();
    } else {
      showToast("error", result.response.description);
    }
  };

  const handlerRemoveNotification = async () => {
    const result = await handleUpdatedNotifications(idNotification);
    if (result.status) {
      showToast("success", result.response.response.description);
      window.location.reload();
    } else {
      showToast("error", result.response.response.description);
    }
  };

  const { t } = useTranslation("userView");

  return (
    <div className={classes.container}>
      <Toast
        innerRef={(ref) => {
          toast = ref;
        }}
        button
        handlerRemoveMember={
          notifications ? handlerRemoveNotification : handlerRemove
        }
      />
      {noFilter ? null : (
        <Grid className={classes.container_title}>
          <h3 style={{ fontFamily: "Poppins" }}>{t(title)}</h3>
        </Grid>
      )}
      {noFilter || noFilterSearch ? null : ( // TODO: filtros clinic & hospitals
        <Grid container xs={12} sm={12}>
          {/*<Grid
            className={classes.container_btn}
            xs={12}
            sm={2}
            style={{
              display: "flex",
              alignItems: "start",
              justifyContent: "flex-start",
            }}
          >
            {products ||
            specialist ||
            clinicInventory ||
            incomingProducts ||
            scannedBatch ||
            notifications ||
            Expired ||
            places ? null : (
              <div style={{ width: "100%", marginTop: "5px" }}>
                <Button
                  className={classes.btn}
                  iconAdd
                  onClick={handlerAddMember}
                  fullWidth={"100%"}
                >
                  {mainManager ? "Import" : t("Add New") //TODO: boton import
                  }
                </Button>
              </div>
            )}
          </Grid>*/}
          <Grid sm={1} />
          {notifications ||
          Expired ||
          clinic ||
          incomingProducts ||
          clinicInventory ||
          places ? null : (
            <Grid
              container
              xs={12}
              sm={9}
              style={{ marginTop: 20, marginBottom: 20 }}
            >
              <Grid container item xs={12} className={classes.content_search}>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Select
                    currency={currency}
                    products={products}
                    data={dataFilter}
                    handlerChangeData={handlerChangeData}
                    id="filterProduct"
                  />
                </Grid>
                <Grid sm={0.5} />
                <Grid
                  item
                  xs={12}
                  sm={3}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Input
                    placeHolder={t("Value")}
                    style={{
                      width: "100%",
                      height: "40px",
                      padding: "0 10px",
                      border: "none",
                      borderBottom: "1px solid #B0B0B0",
                      fontSize: "16px",
                      color: "#606060",
                      margin: "8px 0",
                    }}
                    onChange={handlerChangeData}
                    id="filterValue"
                  />{" "}
                </Grid>
                {<Grid sm={1} />}
                <Grid item xs={12} sm={2}>
                  {" "}
                  <Button
                    color="transparent"
                    fullWidth={"100%"}
                    iconSearch
                    onClick={handleGetProductFilter}
                  >
                    {t("Search")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
      {specialist ? (
        <DataTable
          products={members}
          model={"specialist"}
          noFilter={noFilter ? true : false}
        />
      ) : clinicInventory ? (
        <DataTable
          products={members}
          model={"clinicInventory"}
          noFilter={noFilter ? true : false}
        />
      ) : incomingProducts ? (
        <DataTable
          products={members}
          model={"incomingProducts"}
          noFilter={noFilter ? true : false}
        />
      ) : (
        <Table
          clinicDeparment={clinicDeparment}
          clinic={clinic}
          members={members}
          product={products}
          notifications={notifications ? true : false}
          Expired={Expired ? true : false}
          places={places ? true : false}
          {...props}
          showToast={showToast}
          handleRemoveMember={handleRemoveMember}
          handleChangeStatus={handleChangeStatus}
        />
      )}

      {noFilter ? null : (
        <Grid
          xs={12}
          style={{
            display: "flex",
            justifyContent: "right",
          }}
        >
          {/*<Grid container xs={2}>
            <Grid item xs={6} style={{ paddingTop: 8 }}>
              <p>{t("Result Per Page:")}</p>
            </Grid>
            <Grid item xs={4}>
              <Select
                page
                handlerChangeData={handlerChangeRecordsPage}
                data={pagesNum}
              />
            </Grid>
          </Grid>*/}
        </Grid>
      )}
      {noFilter ? null : (
        <Grid
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {totalPage > 1 ? (
            <Pagination
              count={totalPage}
              color="primary"
              onChange={(event, page) => {
                handleChangePage(page);
              }}
            />
          ) : null}
        </Grid>
      )}
    </div>
  );
}

UserViews.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(userViewsStyle)(UserViews);
