
 // ##############################
  // // // orderDasboard styles
  // #############################
 
  
  const orderDasboard = theme => ({    
    container: {
        backgroundColor: "#2B2B2B",
        height: "90vh",
        width: "100%",
        padding: 10
    },
    btn: {
        width: "100%",
        border: "1px solid grey",
        color: "grey",
    },
    item_list: {
        backgroundColor: "#34393e",
        borderRadius: 5,
        marginBottom: 10,
        padding: 5,
        color: "#C8C5C5",
        fontSize: 17,
        '& span':{
            marginRight: 10
        },
        cursor: "pointer"
    }
  });
  
  export default orderDasboard;
  