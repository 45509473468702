import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import changeLanguageStyle from "../../assets/jss/material-dashboard-pro-react/components/changeLanguageStyle";
import {useTranslation} from "react-i18next";
import {changeLanguage} from "../../redux/actions/general";

function ChangeLanguage (props) {

    const { classes } = props;
    const [change, setChange] = useState(false);
    const {i18n} = useTranslation()

    useEffect(()=>{
        if(props.language.language){
            setChange(true)
            i18n.changeLanguage("es");
        }
    },[])

    const changing = () =>{
        setChange(!change);
        props.dispatch(changeLanguage())
        if(change){
            i18n.changeLanguage("en");
        }else{
            i18n.changeLanguage("es");
        }
    }

    return(
        <div className={classes.container}>
            <span className={classes.language}>EN</span>
            <div className={change ? classes.toggle + " " + classes.toggleActive : classes.toggle} onClick={changing}>
                <div className={change ? classes.dot + " " + classes.dotActive : classes.dot}/>
            </div>
            <span className={classes.language}>ES</span>
        </div>
    );
}

ChangeLanguage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(
    (state) => ({
        language: state.root.general.change_language,
    })
)(withStyles(changeLanguageStyle)(ChangeLanguage))