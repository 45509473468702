export const fieldOptions = {
  "Account-manager": {
    order: [
      { label: "ID Pedido", value: "order_id" },
      { label: "Modelo", value: "model" },
      { label: "Tipo", value: "type" },
      { label: "Cantidad", value: "quantity" },
      { label: "Lote", value: "batch" },
      { label: "Serial", value: "serial" },
      { label: "Nombre Shipto", value: "name_shipto" },
      { label: "Shipto", value: "ship_to" },
      { label: "Fecha de creación", value: "creation_date" },
      { label: "División", value: "division" },
      { label: "Account Manager", value: "account_manager" },
      {
        label: "Observación del representante de ventas",
        value: "sales_rep_observation",
      },
      { label: "Estado", value: "status" },
      /*  {
        // label: "Fecha de actualización de estado",
        value: "update_date",
      },
      {
        // label: "Hora de actualización de estado",
        value: "status_update_time",
      }, */
      // { label: "Comentario de transportadora", value: "carrier_comment" },
      { label: "Fecha y hora de creación", value: "creation_time" },
      { label: "Usuario que aprobó", value: "approved_by_user" },
      { label: "Hora de aprobación", value: "approved_time" },
      { label: "Usuario que rechazó", value: "rejected_by_user" },
      { label: "Hora de rechazo", value: "rejected_time" },
      {
        label: "Usuario aprobación cartera",
        value: "coordinator_approval_user",
      },
      {
        label: "Hora de aprobación cartera",
        value: "coordinator_approval_time",
      },
      { label: "Urgencia vital", value: "vital_urgency" },
      { label: "Backorder", value: "backorder" },
      { label: "Transportadora", value: "carrier" },
      {
        label: "Observaciones transportadora",
        value: "carrier_observations",
      },
      { label: "Guía de remesa", value: "guide" },
      { label: "Bodega de origen", value: "origin_warehouse" },
      { label: "Ciudad de destino", value: "destination_city" },
      { label: "Material", value: "material" },
      // { label: "Fecha de vencimiento", value: "expiration_date" },
      { label: "Remisión", value: "remission" },
    ],
    transfer: [
      { label: "Order ID", value: "order_id" },
      { label: "Modelo", value: "model" },
      { label: "Cantidad", value: "quantity" },
      { label: "Lote", value: "batch" },
      { label: "Serial", value: "serial" },
      { label: "Estado", value: "status" },
      { label: "Shipto Origen", value: "ship_to" },
      { label: "Nombre cliente Origen", value: "name_shipto" },
      { label: "Shipto Destino", value: "ship_to_receiver" },
      { label: "Nombre cliente Destino", value: "receiver" },
      { label: "Fecha de creación", value: "creation_date" },
      { label: "Tipo", value: "type" },
      { label: "División", value: "division" },
      { label: "Fecha y Hora de creación", value: "creation_time" },
      {
        label: "Usuario aprobación finanzas",
        value: "finance_approval_user",
      },
      {
        label: "Hora de aprobación finanzas",
        value: "finance_approval_time",
      },
      {
        label: "Usuario aprobación calidad",
        value: "quality_approval_user",
      },
      {
        label: "Hora de aprobación calidad",
        value: "quality_approval_time",
      },
      { label: "Usuario que rechazó", value: "rejected_by_user" },
      { label: "Hora de rechazo", value: "rejected_time" },
      {
        label: "Usuario aprobación cartera",
        value: "coordinator_approval_user",
      },
      {
        label: "Hora de aprobación cartera",
        value: "coordinator_approval_time",
      },
      { label: "Transportadora", value: "carrier" },
      /*   {
        // label: "Fecha de actualización de estado",
        value: "update_date",
      },
      {
        // label: "Hora de actualización de estado",
        value: "status_update_time",
      }, */
      { label: "Guía de remesa", value: "guide" },
      // { label: "Comentario de transportadora", value: "carrier_comment" },
      { label: "Ciudad de destino", value: "destination_city" },
      { label: "Ciudad de origen", value: "origin_city" },
      { label: "URL de transportadora", value: "carrier_url" },
      { label: "Account Manager", value: "account_manager" },
      /*  {
        label: "Fecha de vencimiento producto",
        value: "product_expiration_date",
      }, */
      { label: "Material", value: "material" },
      { label: "Remisión", value: "remission" },
    ],
    return: [
      { label: "Order ID", value: "order_id" },
      { label: "Modelo", value: "model" },
      { label: "Cantidad", value: "quantity" },
      { label: "Lote", value: "batch" },
      { label: "Serial", value: "serial" },
      // { label: "Fecha de expiración", value: "expiration_date" },
      { label: "Estado", value: "status" },
      { label: "Razón de retorno", value: "reason_return" },

      { label: "Fecha y hora de creación", value: "creation_time" },
      { label: "Usuario que aprobó", value: "approved_by_user" },
      { label: "Hora de aprobación", value: "approved_time" },
      { label: "Usuario que rechazó", value: "rejected_by_user" },
      { label: "Hora de rechazo", value: "rejected_time" },
      { label: "Transportadora", value: "carrier" },
      { label: "Observaciones", value: "observation" },
      { label: "Nombre Shipto", value: "name_shipto" },
      { label: "Shipto", value: "ship_to" },
      { label: "Fecha de creación", value: "creation_date" },
      { label: "División", value: "division" },
      { label: "Account Manager", value: "account_manager" },
      { label: "Bodega", value: "warehouse" },
      // { label: "Fecha de expiración", value: "expiration_date" },
      { label: "Ciudad de origen", value: "origin_city" },
      { label: "Tipo de devolución", value: "return_type" },
      { label: "Estado en tracking", value: "traking_status" },
      /*  {
        // label: "Fecha de actualización de estado",
        value: "status_update_date",
      },
      {
        // label: "Hora de actualización de estado",
        value: "status_update_time",
      }, */
      // { label: "Comentario de transportadora", value: "carrier_comment" },
      { label: "Guía de remesa", value: "guide" },
      {
        label: "Observaciones transportadora",
        value: "carrier_observations",
      },
      { label: "Material", value: "material" },
    ],
  },
  "super-user": {
    order: [
      { label: "ID Pedido", value: "order_id" },
      { label: "Modelo", value: "model" },
      { label: "Tipo", value: "type" },
      { label: "Cantidad", value: "quantity" },
      { label: "Lote", value: "batch" },
      { label: "Serial", value: "serial" },
      { label: "Nombre Shipto", value: "name_shipto" },
      { label: "Shipto", value: "ship_to" },
      { label: "Fecha de creación", value: "creation_date" },
      { label: "División", value: "division" },
      { label: "Account Manager", value: "account_manager" },
      {
        label: "Observación del representante de ventas",
        value: "sales_rep_observation",
      },
      { label: "Estado", value: "status" },
      /*  {
        // label: "Fecha de actualización de estado",
        value: "update_date",
      },
      {
        // label: "Hora de actualización de estado",
        value: "status_update_time",
      }, */
      // { label: "Comentario de transportadora", value: "carrier_comment" },
      { label: "Fecha y hora de creación", value: "creation_time" },
      { label: "Usuario que aprobó", value: "approved_by_user" },
      { label: "Hora de aprobación", value: "approved_time" },
      { label: "Usuario que rechazó", value: "rejected_by_user" },
      { label: "Hora de rechazo", value: "rejected_time" },
      {
        label: "Usuario aprobación cartera",
        value: "coordinator_approval_user",
      },
      {
        label: "Hora de aprobación cartera",
        value: "coordinator_approval_time",
      },
      { label: "Urgencia vital", value: "vital_urgency" },
      { label: "Backorder", value: "backorder" },
      { label: "Transportadora", value: "carrier" },
      {
        label: "Observaciones transportadora",
        value: "carrier_observations",
      },
      { label: "Guía de remesa", value: "guide" },
      { label: "Bodega de origen", value: "origin_warehouse" },
      { label: "Ciudad de destino", value: "destination_city" },
      { label: "Material", value: "material" },
      // { label: "Fecha de vencimiento", value: "expiration_date" },
      { label: "Remisión", value: "remission" },
    ],
    transfer: [
      { label: "Order ID", value: "order_id" },
      { label: "Modelo", value: "model" },
      { label: "Cantidad", value: "quantity" },
      { label: "Lote", value: "batch" },
      { label: "Serial", value: "serial" },
      { label: "Estado", value: "status" },
      { label: "Shipto Origen", value: "ship_to" },
      { label: "Nombre cliente Origen", value: "name_shipto" },
      { label: "Shipto Destino", value: "ship_to_receiver" },
      { label: "Nombre cliente Destino", value: "receiver" },
      { label: "Fecha de creación", value: "creation_date" },
      { label: "Tipo", value: "type" },
      { label: "División", value: "division" },
      { label: "Fecha y Hora de creación", value: "creation_time" },
      {
        label: "Usuario aprobación finanzas",
        value: "finance_approval_user",
      },
      {
        label: "Hora de aprobación finanzas",
        value: "finance_approval_time",
      },
      {
        label: "Usuario aprobación calidad",
        value: "quality_approval_user",
      },
      {
        label: "Hora de aprobación calidad",
        value: "quality_approval_time",
      },
      { label: "Usuario que rechazó", value: "rejected_by_user" },
      { label: "Hora de rechazo", value: "rejected_time" },
      {
        label: "Usuario aprobación cartera",
        value: "coordinator_approval_user",
      },
      {
        label: "Hora de aprobación cartera",
        value: "coordinator_approval_time",
      },
      { label: "Transportadora", value: "carrier" },
      /*   {
        // label: "Fecha de actualización de estado",
        value: "update_date",
      },
      {
        // label: "Hora de actualización de estado",
        value: "status_update_time",
      }, */
      { label: "Guía de remesa", value: "guide" },
      // { label: "Comentario de transportadora", value: "carrier_comment" },
      { label: "Ciudad de destino", value: "destination_city" },
      { label: "Ciudad de origen", value: "origin_city" },
      { label: "URL de transportadora", value: "carrier_url" },
      { label: "Account Manager", value: "account_manager" },
      /*  {
        label: "Fecha de vencimiento producto",
        value: "product_expiration_date",
      }, */
      { label: "Material", value: "material" },
      { label: "Remisión", value: "remission" },
    ],
    return: [
      { label: "Order ID", value: "order_id" },
      { label: "Modelo", value: "model" },
      { label: "Cantidad", value: "quantity" },
      { label: "Lote", value: "batch" },
      { label: "Serial", value: "serial" },
      // { label: "Fecha de expiración", value: "expiration_date" },
      { label: "Estado", value: "status" },
      { label: "Razón de retorno", value: "reason_return" },
      { label: "Fecha y hora de creación", value: "creation_time" },
      { label: "Usuario que aprobó", value: "approved_by_user" },
      { label: "Hora de aprobación", value: "approved_time" },
      { label: "Usuario que rechazó", value: "rejected_by_user" },
      { label: "Hora de rechazo", value: "rejected_time" },
      // { label: "Transportadora", value: "carrier" },
      { label: "Observaciones", value: "observation" },
      { label: "Nombre Shipto", value: "name_shipto" },
      { label: "Shipto", value: "ship_to" },
      { label: "Fecha de creación", value: "creation_date" },
      { label: "División", value: "division" },
      { label: "Account Manager", value: "account_manager" },
      { label: "Bodega", value: "warehouse" },
      // { label: "Fecha de expiración", value: "expiration_date" },
      { label: "Ciudad de origen", value: "origin_city" },
      { label: "Tipo de devolución", value: "return_type" },
      { label: "Estado en tracking", value: "traking_status" },
      /*  {
        // label: "Fecha de actualización de estado",
        value: "status_update_date",
      },
      {
        // label: "Hora de actualización de estado",
        value: "status_update_time",
      }, */
      // { label: "Comentario de transportadora", value: "carrier_comment" },
      { label: "Guía de remesa", value: "guide" },
      {
        label: "Observaciones transportadora",
        value: "carrier_observations",
      },
      { label: "Material", value: "material" },
    ],
  },
  finance: {
    order: [
      { label: "ID Pedido", value: "order_id" },
      { label: "Modelo", value: "model" },
      { label: "Tipo", value: "type" },
      { label: "Cantidad", value: "quantity" },
      { label: "Lote", value: "batch" },
      { label: "Serial", value: "serial" },
      { label: "Nombre Shipto", value: "name_shipto" },
      { label: "Shipto", value: "ship_to" },
      { label: "Fecha de creación", value: "creation_date" },
      { label: "División", value: "division" },
      { label: "Account Manager", value: "account_manager" },
      {
        label: "Observación del representante de ventas",
        value: "sales_rep_observation",
      },
      { label: "Estado", value: "status" },
      /* 
      {
        // "label": "Fecha de actualización de estado",
        "value": "status_update_date"
      },
      {
        // "label": "Hora de actualización de estado",
        "value": "status_update_time"
      },
      // { "label": "Comentario de transportadora", "value": "carrier_comment" }, 
      */
      { label: "Fecha y hora de creación", value: "creation_time" },
      { label: "Usuario que aprobó", value: "approved_by_user" },
      { label: "Hora de aprobación", value: "approved_time" },
      { label: "Usuario que rechazó", value: "rejected_by_user" },
      { label: "Hora de rechazo", value: "rejected_time" },
      {
        label: "Usuario aprobación cartera",
        value: "coordinator_approval_user",
      },
      {
        label: "Hora de aprobación cartera",
        value: "coordinator_approval_time",
      },
      { label: "Urgencia vital", value: "vital_urgency" },
      { label: "Backorder", value: "backorder" },

      { label: "Transportadora", value: "carrier" },

      {
        label: "Observaciones transportadora",
        value: "carrier_observations",
      },
      { label: "Guía de remesa", value: "guide" },
      { label: "Bodega de origen", value: "origin_warehouse" },
      { label: "Ciudad de destino", value: "destination_city" },
      { label: "Material", value: "material" },
      /* 
      // { "label": "Fecha de vencimiento", "value": "expiration_date" }, 
      */
      { label: "Remisión", value: "remission" },
    ],
    transfer: [
      { label: "Order ID", value: "order_id" },
      { label: "Modelo", value: "model" },
      { label: "Cantidad", value: "quantity" },
      { label: "Lote", value: "batch" },
      { label: "Serial", value: "serial" },
      { label: "Estado", value: "status" },
      { label: "Shipto Origen", value: "ship_to" },
      { label: "Nombre cliente Origen", value: "name_shipto" },
      { label: "Shipto Destino", value: "ship_to_receiver" },
      { label: "Nombre cliente Destino", value: "receiver" },
      { label: "Fecha de creación", value: "creation_date" },
      { label: "Tipo", value: "type" },
      { label: "División", value: "division" },
      { label: "Fecha y Hora de creación", value: "creation_time" },
      {
        label: "Usuario aprobación finanzas",
        value: "finance_approval_user",
      },
      {
        label: "Hora de aprobación finanzas",
        value: "finance_approval_time",
      },
      {
        label: "Usuario aprobación calidad",
        value: "quality_approval_user",
      },
      {
        label: "Hora de aprobación calidad",
        value: "quality_approval_time",
      },
      { label: "Usuario que rechazó", value: "rejected_by_user" },
      { label: "Hora de rechazo", value: "rejected_time" },
      {
        label: "Usuario aprobación cartera",
        value: "coordinator_approval_user",
      },
      {
        label: "Hora de aprobación cartera",
        value: "coordinator_approval_time",
      },

      { label: "Transportadora", value: "carrier" },

      /* 
      {
        // "label": "Fecha de actualización de estado",
        "value": "status_update_date"
      },
      {
        // "label": "Hora de actualización de estado",
        "value": "status_update_time"
      }, 
      */
      { label: "Guía de remesa", value: "guide" },
      /* 
      // { "label": "Comentario de transportadora", "value": "carrier_comment" }, 
      */
      { label: "Ciudad de destino", value: "destination_city" },
      { label: "Ciudad de origen", value: "origin_city" },
      { label: "URL de transportadora", value: "carrier_url" },
      { label: "Account Manager", value: "account_manager" },
      /* 
      {
        "label": "Fecha de vencimiento producto",
        "value": "product_expiration_date"
      }, 
      */
      { label: "Material", value: "material" },
      { label: "Remisión", value: "remission" },
    ],
    return: [
      { label: "Order ID", value: "order_id" },
      { label: "Modelo", value: "model" },
      { label: "Cantidad", value: "quantity" },
      { label: "Lote", value: "batch" },
      { label: "Serial", value: "serial" },
      /* 
      // { "label": "Fecha de expiración", "value": "expiration_date" },
      */
      { label: "Estado", value: "status" },
      { label: "Razón de retorno", value: "reason_return" },
      { label: "Fecha y hora de creación", value: "creation_time" },
      { label: "Usuario que aprobó", value: "approved_by_user" },
      { label: "Hora de aprobación", value: "approved_time" },
      { label: "Usuario que rechazó", value: "rejected_by_user" },
      { label: "Hora de rechazo", value: "rejected_time" },
      { label: "Observaciones", value: "observation" },
      { label: "Nombre Shipto", value: "name_shipto" },
      { label: "Shipto", value: "ship_to" },
      { label: "Fecha de creación", value: "creation_date" },
      { label: "División", value: "division" },
      { label: "Account Manager", value: "account_manager" },
      { label: "Bodega", value: "warehouse" },
      /* 
      // { "label": "Fecha de expiración", "value": "expiration_date" }, 
      */
      { label: "Ciudad de origen", value: "origin_city" },
      { label: "Tipo de devolución", value: "return_type" },
      { label: "Estado en tracking", value: "traking_status" },
      /* 
      {
        // "label": "Fecha de actualización de estado",
        "value": "status_update_date"
      },
      {
        // "label": "Hora de actualización de estado",
        "value": "status_update_time"
      }, 
      */
      /* 
      // { "label": "Comentario de transportadora", "value": "carrier_comment" }, 
      */
      { label: "Guía de remesa", value: "guide" },
      {
        label: "Observaciones transportadora",
        value: "carrier_observations",
      },
      { label: "Material", value: "material" },
    ],
  },
  quality: {
    transfer: [
      { label: "Order ID", value: "order_id" },
      { label: "Modelo", value: "model" },
      { label: "Cantidad", value: "quantity" },
      { label: "Lote", value: "batch" },
      { label: "Serial", value: "serial" },
      { label: "Estado", value: "status" },
      { label: "Shipto Origen", value: "ship_to" },
      { label: "Nombre cliente Origen", value: "name_shipto" },
      { label: "Shipto Destino", value: "ship_to_receiver" },
      { label: "Nombre cliente Destino", value: "receiver" },
      { label: "Fecha de creación", value: "creation_date" },
      { label: "Tipo", value: "type" },
      { label: "División", value: "division" },
      { label: "Fecha y Hora de creación", value: "creation_time" },
      {
        label: "Usuario aprobación finanzas",
        value: "finance_approval_user",
      },
      {
        label: "Hora de aprobación finanzas",
        value: "finance_approval_time",
      },
      {
        label: "Usuario aprobación calidad",
        value: "quality_approval_user",
      },
      {
        label: "Hora de aprobación calidad",
        value: "quality_approval_time",
      },
      { label: "Usuario que rechazó", value: "rejected_by_user" },
      { label: "Hora de rechazo", value: "rejected_time" },

      { label: "Transportadora", value: "carrier" },

      /* 
      {
        // "label": "Fecha de actualización de estado",
        "value": "status_update_date"
      },
      {
        // "label": "Hora de actualización de estado",
        "value": "status_update_time"
      }, 
      */
      { label: "Guía de remesa", value: "guide" },
      /* 
      // { "label": "Comentario de transportadora", "value": "carrier_comment" },
      */
      { label: "Ciudad de destino", value: "destination_city" },
      { label: "Ciudad de origen", value: "origin_city" },
      { label: "URL de transportadora", value: "carrier_url" },
      { label: "Account Manager", value: "account_manager" },
      /* 
      {
        "label": "Fecha de vencimiento producto",
        "value": "product_expiration_date"
      }, 
      */
      { label: "Material", value: "material" },
    ],
  },
};
