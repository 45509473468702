import React, { useEffect, useState, ChangeEvent } from "react";
import { Grid, Icon, makeStyles, styled, Paper, Box} from "@material-ui/core";
import Order from "components/OrderAuthorization/index";
import { Bar, Pie, Doughnut } from "react-chartjs-2";
//import returnReportsProd from 'components/ReportsProd/index'
import { Chart as ChartJS } from "chart.js/auto";
import ReturnRequestController from "controllers/ReturnRequest";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Toast from "components/Toast/Toast";
import Table from 'react-bootstrap/Table';
import { ContentCutOutlined, Grid3x3, Warning } from "@mui/icons-material";
import { Alert } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBell, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
const useStyles = makeStyles((theme) => ({
  Orders: {
    width: "100%",
    //height: "500px",
    marginTop: "10px",
    padding: "10px",
    // backgroundColor: "red",
  },
  tittlePendingOrders: {
    fontSize: "14px",
  },
  containerView: {
    padding: 20,
  },
  button: {
    position: "relative",
    //left: "5%",
    border: "none",
    borderRadius: "8px",
    width: "12%",
    backgroundColor: "#5DADE2",
    color: "white",
    marginRight: "12px",
  },
}));


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'blank' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function ReturnReports(props) {


  const { handlegetReturnReports, returnReports } = ReturnRequestController(
    props
  );

  useEffect(async () => {
    await handlegetReturnReports(props.userToken);
  }, []);
  const classes = useStyles();

  //Data reports
  const [reports, setReports] = useState({})
  const [dataGraphOS, setDataGraphOS] = useState({labels: [], datasets: []})
  const [dataGraphRS, setDataGraphRS] = useState({labels: [], datasets: []})
  const [dataGraphTS, setDataGraphTS] = useState({labels: [], datasets: []})
  const [dataGraphOC, setDataGraphOC] = useState({labels: [], datasets: [],})
  const [dataGraphRC, setDataGraphRC] = useState({labels: [], datasets: []})
  const [dataGraphTC, setDataGraphTC] = useState({labels: [], datasets: []})
  const dictionary  = {
    'order for finance approval': 'Aprobada',
    'order without approval': 'Sin aprobacion',
    'order approved': 'Aprobada',
    'order refused': 'Rechazada',
    'return direct to supply': 'Aprobada',
    'return for approval ': 'Sin aprobacion',
    'return approved ': 'aprobada',
    'return refused': 'Rechazada',
    'transfer financial approval and quality': 'Aprobada',
    'transfer quality only approval': 'Sin aprobacion',
    'transfer refused': 'Rechazada',
    'transfer approved by finance not quality': 'Aprobado por Financiera',
    'transfer approved by quality not finance': 'Aprobado por Calidad',
    'approved only quality': 'Sin Aprobacion',
    'approved for quality and finance': 'Aprobada',
    'unattended order': 'Backorder'
    }  
    
  useEffect(() => {
    if (Object.keys(returnReports).length > 0)
      setReports(returnReports)
  });

  //Efect grafics
  useEffect( () => {
    //Orders
    if (Object.keys(reports).length > 0 && dataGraphOC.labels.length === 0){
      const labels =  reports['Orders']['rankingOrdersClients'][0]['amountOrdersClient'].map((data) => data.applicant_name)
      const datasets = [{label: "Cantidad de Pedidos", 
                          data: reports['Orders']['rankingOrdersClients'][0]['amountOrdersClient'].map((data) => data.sum), 
                          backgroundColor: [
                            "rgba(255, 99, 132, 0.5)",
                            "rgba(108, 148, 188, 0.5)",
                            "rgba(87, 161, 31, 0.5)",
                            "rgba(235, 9, 9, 0.5)",
                            "rgba(39, 105, 193, 0.5)",
                            "rgba(138, 19, 31, 0.5)",
                            "rgba(128, 122, 98, 0.5)",
                          ],
                          //borderColor: "black",
                          responsive: true,
                          maintainAspectRatio: true,
                          borderWidth: 1,}]
                          setDataGraphOC({
                            labels,
                            datasets
      })
    }

    if (Object.keys(reports).length > 0 && dataGraphOS.labels.length === 0){
    const labels = Object.keys(reports['Orders']['statusOrders'][0])
    const datasets = [{label: "Estatus de los Pedidos", 
                        data: Object.values(reports['Orders']['statusOrders'][0]), 
                        backgroundColor: [
                          "rgba(255, 99, 132, 0.5)",
                          "rgba(108, 148, 188, 0.5)",
                          "rgba(87, 161, 31, 0.5)",
                          "rgba(235, 9, 9, 0.5)",
                          "rgba(39, 105, 193, 0.5)",
                          "rgba(138, 19, 31, 0.5)",
                          "rgba(128, 122, 98, 0.5)",
                        ],
                        //borderColor: "black",
                        maintainAspectRatio: false,
                        responsive: true,
                        borderWidth: 1,}]
                        setDataGraphOS({
                          labels,
                          datasets
    })
  }

    //Returns
    if (Object.keys(reports).length > 0 && dataGraphRC.labels.length === 0){
      const labels =  reports['Returns']['rankingReturnsClients'][0]['amountReturnClient'].map((data) => data.applicant_name)
      const datasets = [{label: "Cantidad de Retornos", 
                          data: reports['Returns']['rankingReturnsClients'][0]['amountReturnClient'].map((data) => data.sum), 
                          backgroundColor: [
                            "rgba(255, 99, 132, 0.5)",
                            "rgba(108, 148, 188, 0.5)",
                            "rgba(87, 161, 31, 0.5)",
                            "rgba(235, 9, 9, 0.5)",
                            "rgba(39, 105, 193, 0.5)",
                            "rgba(138, 19, 31, 0.5)",
                            "rgba(128, 122, 98, 0.5)",
                          ],
                          //borderColor: "black",
                          responsive: true,
                          maintainAspectRatio: true,
                          borderWidth: 1,}]
                          setDataGraphRC({
                            labels,
                            datasets
      })
    }
    
    if (Object.keys(reports).length > 0 && dataGraphRS.labels.length === 0 && Object.keys(reports['Returns']['statusOrders']).length > 0 ){
      const labels = Object.keys(reports['Returns']['statusOrders'][0])
      const datasets = [{label: "Estado de Retornos", 
                          data: Object.values(reports['Returns']['statusOrders'][0]), 
                          backgroundColor: [
                            "rgba(255, 99, 132, 0.5)",
                            "rgba(108, 148, 188, 0.5)",
                            "rgba(87, 161, 31, 0.5)",
                            "rgba(235, 9, 9, 0.5)",
                            "rgba(39, 105, 193, 0.5)",
                            "rgba(138, 19, 31, 0.5)",
                            "rgba(128, 122, 98, 0.5)",
                          ],
                          //borderColor: "black",
                          maintainAspectRatio: false,
                          responsive: true,
                          borderWidth: 1,}]
                          setDataGraphRS({
                            labels,
                            datasets
      })
    }

    //Transfer
    if (Object.keys(reports).length > 0 && dataGraphTC.labels.length === 0 && Object.keys(reports['Transfer']['rankingTransferClients'][0]['amountTransfersClient']).length > 0){
      const labels =  reports['Transfer']['rankingTransferClients'][0]['amountTransfersClient'].map((data) => data.applicant_name)
      const datasets = [{label: "Cantidad de Traslados", 
                          data: reports['Transfer']['rankingTransferClients'][0]['amountTransfersClient'].map((data) => data.sum), 
                          backgroundColor: [
                            "rgba(255, 99, 132, 0.5)",
                            "rgba(108, 148, 188, 0.5)",
                            "rgba(87, 161, 31, 0.5)",
                            "rgba(235, 9, 9, 0.5)",
                            "rgba(39, 105, 193, 0.5)",
                            "rgba(138, 19, 31, 0.5)",
                            "rgba(128, 122, 98, 0.5)",
                          ],
                          //borderColor: "black",
                          responsive: true,
                          maintainAspectRatio: true,
                          borderWidth: 1,}]
                          setDataGraphTC({
                            labels,
                            datasets
      })
    }

    if (Object.keys(reports).length > 0 && dataGraphTS.labels.length === 0 && Object.keys(reports['Transfer']['statusOrders']).length > 0 ){
      const labels = Object.keys(reports['Transfer']['statusOrders'][0])
      const datasets = [{label: "Estado de Traslados", 
                          data: Object.values(reports['Transfer']['statusOrders'][0]), 
                          backgroundColor: [
                            "rgba(255, 99, 132, 0.5)",
                            "rgba(108, 148, 188, 0.5)",
                            "rgba(87, 161, 31, 0.5)",
                            "rgba(235, 9, 9, 0.5)",
                            "rgba(39, 105, 193, 0.5)",
                            "rgba(138, 19, 31, 0.5)",
                            "rgba(128, 122, 98, 0.5)",
                          ],
                          //borderColor: "black",
                          maintainAspectRatio: false,
                          responsive: true,
                          borderWidth: 1,}]
                          setDataGraphTS({
                            labels,
                            datasets
      })
    }

  })

  return (
    <div className={classes.containerView}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={4} style={{justifyContent:'left'}}>
            <Item>
              <h4>Cantidad Pedidos</h4>
              <p style={{ fontSize: '10px' }}>*En este apartado podrá visualizar el número de <b>pedidos</b> según el estado, así como el total</p>
              <p>Aprobados: {Object.keys(reports).length > 0 ? reports['Orders']['amounts'][0]['approved'] : 0}</p>
              <p>Rechazados: {Object.keys(reports).length > 0 ? reports['Orders']['amounts'][0]['refused'] : 0}</p>
              <p>En espera: {Object.keys(reports).length > 0 ? reports['Orders']['amounts'][0]['pending'] : 0 }</p>
              <p>Backorder: {Object.keys(reports).length > 0 ? reports['Orders']['amounts'][0]['backorder'] : 0}</p>
              <p>Total Pedidos Realizados: {Object.keys(reports).length > 0 ? reports['Orders']['amounts'][0]['placed'] : 0}</p>
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Item>
              <h4>Cantidad Retornos</h4>
              <p style={{ fontSize: '10px' }}>*En este apartado podrá visualizar el número de <b>retornos</b> según el estado, así como el total</p>
              <p>Aprobados: {Object.keys(reports).length > 0 ? reports['Returns']['amounts'][0]['approved'] : 0}</p>
              <p>Rechazadas: {Object.keys(reports).length > 0 ? reports['Returns']['amounts'][0]['refused'] : 0}</p>
              <p>En espera: {Object.keys(reports).length > 0 ? reports['Returns']['amounts'][0]['pending'] : 0} </p>
              <p>Total Devoluciones Realizadas: {Object.keys(reports).length > 0 ? reports['Returns']['amounts'][0]['placed'] : 0} </p>
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Item>
              <h4>Cantidad Traslados</h4>
              <p style={{ fontSize: '10px' }}>*En este apartado podrá visualizar el número de <b>traslados</b> según el estado, así como el total</p>
              <p>Aprobados: {Object.keys(reports).length > 0 ? reports['Transfer']['amounts'][0]['approved'] : 0}</p>
              <p>Rechazadas: {Object.keys(reports).length > 0 ? reports['Transfer']['amounts'][0]['refused'] : 0}</p>
              <p>En espera: {Object.keys(reports).length > 0 ? reports['Transfer']['amounts'][0]['pending'] : 0}</p>
              <p>Total Traslados Realizadas: {Object.keys(reports).length > 0 ? reports['Transfer']['amounts'][0]['placed'] : 0}</p>
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Item>
            <h4>Ranking Clientes vs Pedidos</h4>
            <p style={{ fontSize: '10px' }}>*En este apartado podrá observar la cantidad de <b>pedidos</b> por cliente, con un límite de los 10 primeros</p>
            <Bar data={dataGraphOC} height={318}/>
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Item>
              <h4>Estado de Pedidos</h4>
              <p style={{ fontSize: '10px' }}>*En este apartado podrá observar la cantidad de <b>pedidos</b> en los diferentes estados de tracking</p>
              <Doughnut data={dataGraphOS} />
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Item>
            <h4>Ranking Modelos Pedidos</h4>
            <p style={{ fontSize: '10px' }}>*En este apartado observara los modelos que más tienen solicitudes de <b>pedidos</b></p>
            {Object.keys(reports).length > 0 ? 
                <Table striped bordered hover>
                  <thead position-absolute top-0 start-50 translate-middle>
                      <tr>
                        <th>Modelo</th>
                        <th>Cantidad</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reports['Orders']['rankingSkus'][0]['rankingSkusOrders'].map( (element, index) => (
                        <tr key={index}>
                          <th>{element.model}</th>
                          <th>{element.orders}</th>
                        </tr>
                      ))}
                    </tbody>
              </Table>
              : ''}
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Item>
              <h4>Ranking Clientes vs Retornos</h4>
              <p style={{ fontSize: '10px' }}>*En este apartado podrá observar la cantidad de <b>retornos</b> por cliente, con un límite de los 10 primeros</p>
              <Bar data={dataGraphRC} height={318}/>
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Item>
                <h4>Estado de Retornos</h4>
                <p style={{ fontSize: '10px' }}>*En este apartado podrá observar la cantidad de <b>retornos</b> en los diferentes estados de tracking</p>
                <Doughnut data={dataGraphRS} /></Item>
          </Grid>
          <Grid item xs={4}>
            <Item>
            <h4>Ranking Modelos Retornos</h4>
            <p style={{ fontSize: '10px' }}>*En este apartado observara los modelos que más tienen solicitudes de <b>retornos</b></p>
            {Object.keys(reports).length > 0 ? 
                <Table striped bordered hover>
                  <thead position-absolute top-0 start-50 translate-middle>
                      <tr>
                        <th>Modelo</th>
                        <th>Cantidad</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reports['Returns']['rankingSkus'][0]['rankingSkusReturn'].map( (element, index) => (
                        <tr key={index}>
                          <th>{element.model}</th>
                          <th>{element.orders}</th>
                        </tr>
                      ))}
                    </tbody>
              </Table>
              : ''}
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Item>
             <h4>Ranking Clientes vs Traslados</h4>
             <p style={{ fontSize: '10px' }}>*En este apartado podrá observar la cantidad de <b>traslados</b> por cliente, con un límite de los 10 primeros</p>
              <Bar data={dataGraphTC} height={318}/>
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Item>
                <h4>Estado de Traslados</h4>
                <p style={{ fontSize: '10px' }}>*En este apartado podrá observar la cantidad de <b>traslados</b> en los diferentes estados de tracking</p>
                <Doughnut data={dataGraphTS} />
            </Item>       
          </Grid>
          <Grid item xs={4}>
            <Item>
            <h4>Ranking Modelos Traslados</h4>
            <p style={{ fontSize: '10px' }}>*En este apartado observara los modelos que más tienen solicitudes de <b>traslados</b></p>
            {Object.keys(reports).length > 0 ? 
                <Table striped bordered hover>
                  <thead position-absolute top-0 start-50 translate-middle>
                      <tr>
                        <th>Modelo</th>
                        <th>Cantidad</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reports['Transfer']['rankingSkus'][0]['rankingSkusTransfer'].map( (element, index) => (
                        <tr key={index}>
                          <th>{element.model}</th>
                          <th>{element.orders}</th>
                        </tr>
                      ))}
                    </tbody>
              </Table>
              : ''}
            </Item>       
          </Grid>
          
        </Grid>
      </Box>

    </div>
    
  );
}
export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
    data: state.root.general.dataUserAPI,
    applicant: state.root.user.userInfo.firstName,
    id: state.root.user.userInfo.memberId,
    address: state.root.user.userInfo.address,
    contactNo: state.root.user.userInfo.contactNo,
  }))(ReturnReports)
);