import React, { Component } from "react";
import { GoogleApiWrapper } from "google-maps-react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

export class PlaceAutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // for google map places autocomplete
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
    };
  }

  handleSelect = (address) => {
    //this.setState({ address });
    this.props.onChange("address", address);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        // update center state
        this.props.onChange("lat", latLng.lat);
        this.props.onChange("lng", latLng.lng);
      })
      .catch((error) => console.error("Error", error));
  };

  handleChange = (address) => {
    this.props.onChange("address", address);
  };

  render() {
    return (
      <PlacesAutocomplete
        value={this.props.value}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: "Address",
                className: "location-search-input",
              })}
              style={
                this.props.error
                  ? {
                      position: "relative",
                      width: "100%",
                      borderRadius: 5,
                      border: "1px solid grey",
                      height: 38,
                      padding: 5,
                      borderLeft: "4px solid #FF7C7C",
                      zIndex: 10,
                    }
                  : {
                      position: "relative",
                      width: "100%",
                      borderRadius: 5,
                      border: "1px solid grey",
                      height: 38,
                      padding: 5,
                      borderLeft: "4px solid #74E791",
                      zIndex: 10,
                    }
              }
            />
            <div
              className="autocomplete-dropdown-container"
              style={{
                position: "absolute",
                backgroundColor: "white",
                boxShadow: "0px 0px 2px grey",
                borderRadius: 5,
                width: "46%",
                marginTop: -2,
                zIndex: 1,
                marginLeft: 5,
              }}
            >
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {
                      backgroundColor: "#fafafa",
                      cursor: "pointer",
                      padding: 5,
                      borderBottom: "1px solid grey",
                    }
                  : {
                      backgroundColor: "#fffff",
                      cursor: "pointer",
                      padding: 5,
                      borderBottom: "1px solid grey",
                    };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      style={{ color: "grey", marginRight: 5 }}
                    />
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLEMAP_KEY,
})(PlaceAutoComplete);
