

 // ##############################
  // // // appBar styles
  // #############################
 
  
  const addUsersStyle = theme => ({
      container:{
          marginTop: "87px"
      },
    container_title:{
        borderBottom: "1px solid grey",
        marginBottom: 10
    },
    container_form: {
        borderRadius: 5,
        border: "1px solid #DCDCDC"
    },
    container_form_header: {
        backgroundColor: "#F3F3F3",
        padding: 10,
        borderBottom: "1px solid #DCDCDC"
    },
    container_form_inputs:{
        padding: 10
    },
    line: {
        widht: "100%",
        height: 10,
        borderBottom: "1px solid #DCDCDC"
    },
    container_btn:{
        padding: 10,
    },
    btn_submit:{
        backgroundColor: "#3C70FF"
    }
    
  });
  
  export default addUsersStyle;
  