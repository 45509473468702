import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SwitchUnstyled, {
  switchUnstyledClasses,
} from "@mui/core/SwitchUnstyled";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

//Translation
import { useTranslation } from "react-i18next";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#1D1D1D",
    //#1D1D1D
    font: "bold 98% Poppins",
    borderBottom: "1px solid #C7C7C7",
    backgroundColor: "#b2cfee",
    paddingTop: "14px",
    paddingBottom: "14px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    font: "98% monospace",
    // border: "1px solid #81B39F",
    backgroundColor: "#eceffb",
    cursor: "pointer",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

const StyledTableCellChild = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "black",
    font: "bold 98% monospace",
    border: "1px solid #DDDDDD",
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#4C4C4C",
    font: "bold 88% monospace",
    border: "none",
    borderBottom: "1px solid #4C4C4C",
    paddingTop: "9px",
    paddingBottom: "9px",
    transition: "all 600ms ease",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
    background: "rgba(241,237,255,0.8)",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const Root = styled("span")`
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 23px;
  height: 12px;

  cursor: pointer;

  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchUnstyledClasses.track} {
    background: #b3c3d3;
    border-radius: 10px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 8px;
    height: 8px;
    top: 2px;
    left: 2px;
    border-radius: 16px;
    background-color: #000;
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: 13px;
      top: 2px;
      background-color: #fff;
    }

    .${switchUnstyledClasses.track} {
      background: #007fff;
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
`;

export default function CustomizedTables(props) {
  const {
    clinic,
    products,
    model,
    showToast,
    handleChangeStatus,
    clinicDeparment,
    noFilter,
  } = props;
  const [show, setShow] = React.useState(null);
  const label = { componentsProps: { input: { "aria-label": "Demo switch" } } };

  const { t } = useTranslation("userView");

  return (
    <div style={noFilter ? null : { minHeight: "50vh", marginBottom: 20 }}>
      {model === "specialist" ? (
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 700 }}
            aria-label="custom pagination table"
            size="small"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>GTIN</StyledTableCell>
                <StyledTableCell>UPN</StyledTableCell>
                <StyledTableCell>{t("Description")}</StyledTableCell>
                <StyledTableCell>{t("Stock Level")}</StyledTableCell>
                <StyledTableCell>{t("Delivery")}</StyledTableCell>
                <StyledTableCell>{t("Batch")}</StyledTableCell>
                <StyledTableCell>{t("Expiration")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((row, key) => (
                <>
                  <StyledTableRow
                    onClick={() =>
                      show !== null ? setShow(null) : setShow(key)
                    }
                  >
                    <StyledTableCell component="th" scope="row">
                      {row.gtin}
                    </StyledTableCell>
                    <StyledTableCell>{row.upn}</StyledTableCell>
                    <StyledTableCell>{row.description}</StyledTableCell>
                    <StyledTableCell>{row.stockLevel}</StyledTableCell>
                    <StyledTableCell />
                    <StyledTableCell />
                    <StyledTableCell />
                  </StyledTableRow>
                  {show === key
                    ? row.products.map((child) => (
                        <>
                          <StyledTableRow>
                            <StyledTableCellChild component="th" scope="row">
                              {child.gtin}
                            </StyledTableCellChild>
                            <StyledTableCellChild>
                              {child.upn}
                            </StyledTableCellChild>
                            <StyledTableCellChild>
                              {child.description}
                            </StyledTableCellChild>
                            <StyledTableCellChild>
                              {child.quantity}
                            </StyledTableCellChild>
                            <StyledTableCellChild>
                              {child.delivery}
                            </StyledTableCellChild>
                            <StyledTableCellChild>
                              {child.batch}
                            </StyledTableCellChild>
                            <StyledTableCellChild>
                              {child.expirationDate.slice(0, -14)}
                            </StyledTableCellChild>
                          </StyledTableRow>
                        </>
                      ))
                    : null}
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : model === "clinicInventory" ? (
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 700 }}
            aria-label="custom pagination table"
            size="small"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>UPN</StyledTableCell>
                <StyledTableCell>{t("Stock Level")}</StyledTableCell>
                <StyledTableCell>{t("Batch")}</StyledTableCell>
                <StyledTableCell>{t("Expiration")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((row, key) => (
                <>
                  <StyledTableRow
                    onClick={() =>
                      show !== null ? setShow(null) : setShow(key)
                    }
                  >
                    <StyledTableCell>{row.upn}</StyledTableCell>
                    <StyledTableCell>{row.stockLevel}</StyledTableCell>
                    <StyledTableCell />
                    <StyledTableCell />
                  </StyledTableRow>
                  {show === key
                    ? row.products.map((child) => (
                        <>
                          <StyledTableRow>
                            <StyledTableCellChild>
                              {child.upn}
                            </StyledTableCellChild>
                            <StyledTableCellChild>
                              {child.quantity}
                            </StyledTableCellChild>
                            <StyledTableCellChild>
                              {child.batch}
                            </StyledTableCellChild>
                            <StyledTableCellChild>
                              {child.expirationDate
                                ? child.expirationDate.slice(0, -14)
                                : "null"}
                            </StyledTableCellChild>
                          </StyledTableRow>
                        </>
                      ))
                    : null}
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : model === "incomingProducts" ? (
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 700 }}
            aria-label="custom pagination table"
            size="small"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>{t("Delivery")}</StyledTableCell>
                <StyledTableCell>{t("Description")}</StyledTableCell>
                <StyledTableCell>UPN</StyledTableCell>
                <StyledTableCell>{t("Quantity")}</StyledTableCell>
                <StyledTableCell>Gtin</StyledTableCell>
                <StyledTableCell>{t("Batch")}</StyledTableCell>
                <StyledTableCell>{t("Expiration")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((row, key) => (
                <>
                  <StyledTableRow
                    onClick={() =>
                      show !== null ? setShow(null) : setShow(key)
                    }
                  >
                    <StyledTableCell>{row.orderNo}</StyledTableCell>
                    <StyledTableCell />
                    <StyledTableCell />
                    <StyledTableCell />
                    <StyledTableCell />
                    <StyledTableCell />
                    <StyledTableCell />
                  </StyledTableRow>

                  {show === key
                    ? row.products.map((child) => (
                        <>
                          <StyledTableRow>
                            <StyledTableCellChild>
                              {child.delivery}
                            </StyledTableCellChild>
                            <StyledTableCellChild>
                              {child.description}
                            </StyledTableCellChild>
                            <StyledTableCellChild>
                              {child.upn}
                            </StyledTableCellChild>
                            <StyledTableCellChild>
                              {child.quantity}
                            </StyledTableCellChild>
                            <StyledTableCellChild>
                              {child.gtin}
                            </StyledTableCellChild>
                            <StyledTableCellChild>
                              {child.batch}
                            </StyledTableCellChild>
                            <StyledTableCellChild>
                              {child.expirationDate
                                ? child.expirationDate.slice(0, 11)
                                : "null"}
                            </StyledTableCellChild>
                          </StyledTableRow>
                        </>
                      ))
                    : null}
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </div>
  );
}
