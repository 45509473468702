import React, { useEffect } from "react";
import { Grid, makeStyles, withStyles, Box } from "@material-ui/core";
import Order from "components/OrderAuthorization/index";
import { ApprovalhistoryController } from "controllers/ApprovalhistoryController";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Toast from "components/Toast/Toast";
// import ExportExcel from "react-export-excel";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";

/* const ExcelFile = ExportExcel.ExcelFile;
const ExcelSheet = ExportExcel.ExcelSheet;
const ExcelColumn = ExportExcel.ExcelColumn; */

const useStyles = makeStyles((theme) => ({
  Orders: {
    width: "100%",
    //height: "500px",
    marginTop: "10px",
    padding: "10px",
    // backgroundColor: "red",
  },
  tittleApprovalhistory: {
    fontSize: "14px",
  },
  containerView: {
    padding: 20,
  },
  "@media (max-Width: 960px)": {
    tittleApprovalhistory: {
      fontSize: "1.8vh",
    },
    shearch: {
      marginLeft: "10%",
    },
  },
}));

function Approvalhistory(props) {
  let history = true;
  const {
    backOrderList,
    handlegetApprovalhistory,
    handleChange,
    orderId,
    // handlegetXmlData,
    // xmlData,
    downloadPdf,
  } = ApprovalhistoryController(props);

  useEffect(
    async () => {
      await handlegetApprovalhistory(props.userToken, props.id);
    },
    [orderId]
  );
  /*   useEffect(async () => {
    await handlegetXmlData(props.userToken);
  }, []);
 */
  const classes = useStyles();

  return (
    <div className={classes.containerView}>
      <Grid xs={12} container>
        <Grid xs={8}>
          <h5 className={classes.tittleApprovalhistory}>
            <b>Historial de pedidos backorder</b>{" "}
          </h5>
        </Grid>
        {/*  <Grid xs={2}>
          <ExcelFile
            element={
              <Button
                variant="contained"
                sx={{
                  "@media (max-Width: 960px)": {
                    width: 40,
                    height: 40,
                    fontSize: "1.2vh",
                  },
                }}
              >
                Exportar a Excel
              </Button>
            }
            filename="Historial de Aprobaciones"
          >
            <ExcelSheet data={xmlData} name="Historial de Aprobaciones">
              <ExcelColumn label="Sales org" value="salesOrg" />
              <ExcelColumn label="Order Type" value="orderType" />
              <ExcelColumn label="PO Category" value="" />
              <ExcelColumn label="Sold-to" value="soldTo" />
              <ExcelColumn label="Ship-to" value="shipTo" />
              <ExcelColumn label="Bill-to" value="billTo" />
              <ExcelColumn label="Payer" value="" />
              <ExcelColumn label="Deliver to" value="" />
              <ExcelColumn label="PO#" value="poNum" />
              <ExcelColumn label="PO Date" value="po" />
              <ExcelColumn label="Price Date" value="" />
              <ExcelColumn label="Buying Group Partner" value="" />
              <ExcelColumn label="Header external remarks" value="" />
              <ExcelColumn label="Header internal remarks" value="" />
              <ExcelColumn label="Customer shipping instructions" value="" />
              <ExcelColumn label="Date of service" value="" />
              <ExcelColumn label="Requested Delivery date" value="" />
              <ExcelColumn label="d" value="" />
              <ExcelColumn label="d" value="" />
              <ExcelColumn label="Order reason" value="orderReason" />
              <ExcelColumn label="Delivery Block" value="" />
              <ExcelColumn label="Billing Block" value="" />
              <ExcelColumn label="Delivery priority" value="" />
              <ExcelColumn label="Consi fillup Req Del Date" value="" />
              <ExcelColumn label="Material" value="material" />
              <ExcelColumn label="Customer Order Item" value="" />

              <ExcelColumn label="Special Stocking Partner" value="" />
              <ExcelColumn label="Benefitting Rep" value="" />
              <ExcelColumn label="Batch#" value="batch" />
              <ExcelColumn label="Quantity" value="quantity" />
              <ExcelColumn label="UOM" value="" />
              <ExcelColumn label="Plant" value="plant" />
              <ExcelColumn label="Serial #" value="serial" />
              <ExcelColumn label="DDT Text" value="" />
              <ExcelColumn label="Bundle Group" value="" />
              <ExcelColumn label="Bundle Line Type" value="" />
              <ExcelColumn label="Item Category" value="" />
              <ExcelColumn label="Manual Price" value="" />
              <ExcelColumn label="Customer Group 1" value="" />
              <ExcelColumn label="R/3 (ECC) Invoice Number" value="" />
              <ExcelColumn label="R/3 (ECC) Invoice Item" value="" />
            </ExcelSheet>
          </ExcelFile>
        </Grid> */}
        <Grid xs={2} className={classes.shearch}>
          <TextField
            id="filled-search"
            label="Busqueda por ID"
            type="search"
            variant="filled"
            onChange={handleChange}
            sx={{
              "@media (max-Width: 960px)": {
                width: 100,
                height: 60,
                fontSize: "0.5vh",
              },
            }}
          />
        </Grid>
      </Grid>

      <Grid container xs={12} className={classes.Orders}>
        {backOrderList.map((order, id) => (
          <Grid xs={12}>
            <Order
              order={order}
              id={id}
              history={history}
              downloadPdf={downloadPdf}
            />
          </Grid>
        ))}
      </Grid>
      {/*  <Toast
        innerRef={(ref) => {
          setToast(ref);
        }}
      /> */}
    </div>
  );
}
export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
    data: state.root.general.dataUserAPI,
    applicant: state.root.user.userInfo.firstName,
    id: state.root.user.userInfo.memberId,
    address: state.root.user.userInfo.address,
    contactNo: state.root.user.userInfo.contactNo,
  }))(Approvalhistory)
);
