import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableFooter from "@mui/material/TableFooter";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paper from "@mui/material/Paper";
import { Switch } from "antd";
import {
  faTrash,
  faPenToSquare,
  faChartBar,
  faPills,
  faBagShopping,
  faCalculator,
  faLink,
  faCalendarDays,
  faListCheck,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import SwitchUnstyled, {
  switchUnstyledClasses,
} from "@mui/core/SwitchUnstyled";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

import Modal from "../../Modal";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#1D1D1D",
    //#1D1D1D
    font: "bold 98% monospace",
    borderBottom: "1px solid #C7C7C7",
    backgroundColor: "#b2cfee",
    paddingTop: "14px",
    paddingBottom: "14px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    font: "98% monospace",
    // border: "1px solid #81B39F",
    backgroundColor: "#eceffb",
    cursor: "pointer",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
    background: "rgba(241,237,255,0.8)",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const Root = styled("span")`
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 23px;
  height: 12px;

  cursor: pointer;

  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchUnstyledClasses.track} {
    background: #b3c3d3;
    border-radius: 10px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 8px;
    height: 8px;
    top: 2px;
    left: 2px;
    border-radius: 16px;
    background-color: #fff;
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: 13px;
      top: 2px;
      background-color: #fff;
    }

    .${switchUnstyledClasses.track} {
      background: #007fff;
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
`;

export default function CustomizedTables(props) {
  const {
    clinic,
    members,
    product,
    showToast,
    handleChangeStatus,
    clinicDeparment,
    notifications,
    Expired,
  } = props;
  const label = { componentsProps: { input: { "aria-label": "Demo switch" } } };
  const [codeShow, setCodeShow] = useState("");
  const [modal, setModal] = useState(false);
  const { t } = useTranslation("userView");
  return (
    <div style={{ minHeight: "50vh", marginBottom: 20 }}>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 700 }}
          aria-label="custom pagination table"
          size="small"
        >
          {product ? (
            <TableHead>
              <TableRow>
                <StyledTableCell>CFN</StyledTableCell>
                <StyledTableCell>GTIN</StyledTableCell>
                <StyledTableCell>{t("Description")}</StyledTableCell>
                {/*<StyledTableCell>{t("Sales Org.")}</StyledTableCell>
                <StyledTableCell>{t("Division")}</StyledTableCell>*/}
              </TableRow>
            </TableHead>
          ) : notifications ? (
            <TableHead>
              <TableRow>
                <StyledTableCell>UPN</StyledTableCell>
                <StyledTableCell>{t("Batch")}</StyledTableCell>
                <StyledTableCell>{t("Expiration")}</StyledTableCell>
                <StyledTableCell>{t("Type")}</StyledTableCell>
                <StyledTableCell>{t("Actions")}</StyledTableCell>
              </TableRow>
            </TableHead>
          ) : Expired ? (
            <TableHead>
              <TableRow>
                <StyledTableCell>UPN</StyledTableCell>
                <StyledTableCell>{t("Batch")}</StyledTableCell>
                <StyledTableCell>{t("Expiration")}</StyledTableCell>
                <StyledTableCell>{t("Quantity")}</StyledTableCell>
                <StyledTableCell>{t("Reference ID")}</StyledTableCell>
                <StyledTableCell>{t("Admission")}</StyledTableCell>
              </TableRow>
            </TableHead>
          ) : (
            <TableHead>
              <TableRow>
                <StyledTableCell>#</StyledTableCell>
                {clinicDeparment === "departmentPlaces" ? (
                  <StyledTableCell>{t("Code")}</StyledTableCell>
                ) : (
                  <StyledTableCell>{t("First Name")}</StyledTableCell>
                )}
                {clinicDeparment === "departmentPlaces" ? (
                  <StyledTableCell>{t("Name")}</StyledTableCell>
                ) : (
                  <StyledTableCell>{t("Last Name")}</StyledTableCell>
                )}
                {clinicDeparment === "departmentPlaces" ? null : (
                  <StyledTableCell>{t("Email")}</StyledTableCell>
                )}
                {clinicDeparment === "departmentPlaces" ? null : (
                  <StyledTableCell>{t("Company")}</StyledTableCell>
                )}
                {clinicDeparment === "departmentPlaces" ? null : (
                  <StyledTableCell>{t("Contact No")}</StyledTableCell>
                )}
                {clinicDeparment === "departmentPlaces" ? null : (
                  <StyledTableCell>{t("Action")}</StyledTableCell>
                )}
              </TableRow>
            </TableHead>
          )}
          {product ? (
            <TableBody>
              {members.map((row) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell component="th" scope="row">
                    {row.UPN}
                  </StyledTableCell>
                  <StyledTableCell>{row.GTIN}</StyledTableCell>
                  <StyledTableCell>{row.Description}</StyledTableCell>
                  {/*<StyledTableCell>{row.Sales_org}</StyledTableCell>
                  <StyledTableCell>{row.Division}</StyledTableCell>*/}
                </StyledTableRow>
              ))}
            </TableBody>
          ) : notifications ? (
            <TableBody>
              {members.map((row) => (
                <StyledTableRow key={row.member_id}>
                  <StyledTableCell component="th" scope="row">
                    {row.upn}
                  </StyledTableCell>
                  <StyledTableCell>{row.batch}</StyledTableCell>
                  <StyledTableCell>
                    {row.expiration_date.slice(0, 18)}
                  </StyledTableCell>
                  <StyledTableCell>{row.type}</StyledTableCell>
                  <StyledTableCell>
                    <FontAwesomeIcon
                      icon={faTrash}
                      style={{
                        color: "#FF3939",
                        fontSize: 12,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        showToast(
                          "warning",
                          t("Delete This Alert?"),
                          row.alert_id,
                          true
                        );
                      }}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          ) : Expired ? (
            <TableBody>
              {members.map((row) => (
                <StyledTableRow key={row.member_id}>
                  <StyledTableCell component="th" scope="row">
                    {row.upn}
                  </StyledTableCell>
                  <StyledTableCell>{row.batch}</StyledTableCell>
                  <StyledTableCell>
                    {row.expiration_date.slice(0, 18)}
                  </StyledTableCell>
                  <StyledTableCell>{row.quantity}</StyledTableCell>
                  <StyledTableCell>
                    {row.other_system_reference_id}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.admission_date.slice(0, 18)}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              {members.map((row) => (
                <StyledTableRow key={row.name}>
                  {clinicDeparment === "departmentPlaces" ? (
                    <StyledTableCell component="th" scope="row">
                      {row.placeId}
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell component="th" scope="row">
                      {row.memberId}
                    </StyledTableCell>
                  )}
                  {clinicDeparment === "departmentPlaces" ? (
                    <StyledTableCell>{row.placeCode}</StyledTableCell>
                  ) : (
                    <StyledTableCell>{row.firstName}</StyledTableCell>
                  )}
                  {clinicDeparment === "departmentPlaces" ? (
                    <StyledTableCell>{row.placeName}</StyledTableCell>
                  ) : (
                    <StyledTableCell>{row.lastName}</StyledTableCell>
                  )}
                  {clinicDeparment === "departmentPlaces" ? null : (
                    <StyledTableCell>{row.email}</StyledTableCell>
                  )}
                  {clinicDeparment === "departmentPlaces" ? null : (
                    <StyledTableCell>{row.companyName}</StyledTableCell>
                  )}
                  {clinicDeparment === "departmentPlaces" ? null : (
                    <StyledTableCell>{row.contactNo}</StyledTableCell>
                  )}
                  {clinicDeparment === "departmentPlaces" ? null : (
                    <StyledTableCell
                      style={clinic ? { width: "160px" } : { width: "110px" }}
                    >
                      {/*clinic ? (
                      <>
                        <FontAwesomeIcon
                          icon={faChartBar}
                          style={{
                            color: "grey",
                            fontSize: 12,
                          }}
                        />
                        <FontAwesomeIcon
                          icon={faPills}
                          style={{
                            color: "#71D1FF",
                            fontSize: 12,
                            marginLeft: 5,
                            marginRight: 5,
                          }}
                        />
                        <FontAwesomeIcon
                          icon={faBagShopping}
                          style={{
                            color: "#305AFF",
                            fontSize: 12,
                          }}
                        />
                        <FontAwesomeIcon
                          icon={faCalculator}
                          style={{
                            color: "#4A4A4A",
                            fontSize: 12,
                            marginLeft: 5,
                            marginRight: 5,
                          }}
                        />
                        <FontAwesomeIcon
                          icon={faLink}
                          style={{
                            color: "#CFBF00",
                            fontSize: 12,
                          }}
                        />
                        <FontAwesomeIcon
                          icon={faCalendarDays}
                          style={{
                            color: "#4A4A4A",
                            fontSize: 12,
                            marginLeft: 5,
                            marginRight: 5,
                          }}
                        />
                        <FontAwesomeIcon
                          icon={faListCheck}
                          style={{
                            color: "#305AFF",
                            fontSize: 12,
                          }}
                        />
                      </>
                    ) : null*/}{" "}
                      <SwitchUnstyled
                        checked={row.status}
                        onChange={() =>
                          handleChangeStatus(row.memberId, props, row.status)
                        }
                        component={Root}
                        {...label}
                      />
                      <FontAwesomeIcon
                        icon={faPenToSquare}
                        style={{
                          color: "#24ac44",
                          fontSize: 12,
                          marginLeft: 5,
                          marginRight: 5,
                          marginTop: 5,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          props.history.push({
                            pathname: "/dashboard/member/edit",
                            state: {
                              data: row,
                              type: "member",
                              clinicDeparment: clinicDeparment,
                            },
                          });
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{
                          color: "#FF3939",
                          fontSize: 12,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          showToast(
                            "warning",
                            "Delete This Record?",
                            row.memberId
                          );
                        }}
                      />
                      {clinicDeparment === "departmentPlaces" ? null : (
                        <FontAwesomeIcon
                          icon={faQrcode}
                          style={{
                            color: "#121116",
                            fontSize: 12,
                            marginLeft: 5,
                            marginRight: 5,
                            marginTop: 5,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setModal(!modal);
                            setCodeShow(row.qrCode);
                          }}
                        />
                      )}
                      {modal ? (
                        <Modal
                          type={"QR"}
                          setModal={setModal}
                          code={codeShow}
                        />
                      ) : null}
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
}
