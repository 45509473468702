import React, { useState } from "react";
import { getScannedIncomingProductBatch } from "protocols/requests";
import { dataTotal } from "redux/actions/general";

const ScannedIncomingProductBatchControllers = (props) => {
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(10);
  const [localRecords, setLocalRecords] = useState(10);
  const [batchId, setBatchId] = useState(null);
  const [filter, setFilter] = useState(false);
  const [clinic, setClinic] = useState(null);

  const handleGetScannedIncomingProductBatch = async (data) => {
    if (page !== 0 || (page === 0 && !props.data.scannedBatchIncoming)) {
      const result = await getScannedIncomingProductBatch(data.token, {
        page,
        records: localRecords,
        batchId,
      });
      if (result.status === 200) {
        const products = result.response.response.scanned;
        const total = result.response.response.totalRecords;
        const total_page = Math.ceil(total / localRecords);
        setTotalPage(total_page);
        setProducts(products);
        if (page === 0 && !props.data.scannedBatchIncoming) {
          props.dispatch(
            dataTotal(result.response.response, "scannedBatchIncoming")
          );
        }
      }
    }
  };

  const handleGetScannedFilter = async () => {
    if (batchId || clinic) {
      props.setLoaderVisible(true);
      const value = batchId
        ? {
            page,
            records: localRecords,
            batchId,
          }
        : {
            page,
            records: localRecords,
            clinic,
          };
      const result = await getScannedIncomingProductBatch(
        props.userToken,
        value
      );
      props.setLoaderVisible(false);
      if (result.status === 200) {
        const products = result.response.response.scanned;
        const total = result.response.response.totalRecords;
        const total_page = Math.ceil(total / localRecords);
        setTotalPage(total_page);
        setProducts(products);
        setFilter(true);
      }
      setBatchId(null);
    }
  };

  const handlerChangeRecordsPage = (value) => {
    setLocalRecords(value);
  };
  const handleChangePage = (page) => {
    page = page - 1;
    setPage(page);
    setFilter(false);
  };

  const handlerChangeData = (e) => {
    var value = e.target.value;
    var id = e.target.id;
    if (id === "clinics") setClinic(value);
    else setBatchId(value);
  };

  return {
    handleGetScannedIncomingProductBatch,
    products,
    page,
    handleChangePage,
    totalPage,
    handlerChangeRecordsPage,
    localRecords,
    setBatchId,
    batchId,
    handlerChangeData,
    handleGetScannedFilter,
    filter,
  };
};

export default ScannedIncomingProductBatchControllers;
