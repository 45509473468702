/* eslint-disable */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@mui/material/Grid";
import batchViewsStyle from "assets/jss/material-dashboard-pro-react/components/BatchViewStyle";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  faLink,
  faCubes,
  faCopy,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ItemList(props) {
  const {
    classes,
    id,
    setShowInformation,
    showInformation,
    setShowInformationId,
    orders,
  } = props;
  const [status, setStatus] = useState("");
  const [show, setShow] = useState(false);
  useEffect(async () => {
    switch (orders.status) {
      case "1":
        setStatus("order created");
        break;
      case "2":
        setStatus("Order Received");
        break;
      case "3":
        setStatus("Order Delivered");
        break;
      case "4":
        setStatus("Order Received");
        break;
      default:
        null;
        break;
    }
    setShow(true);
  }, []);
  return (
    <>
      {show ? (
        <Grid container xs={12} className={classes.container_list_item}>
          <Grid container item xs={11}>
            <p
              className={classes.content_item_id}
              onClick={() => {
                setShowInformation(!showInformation);
                setShowInformationId(id);
              }}
            >
              ID: {orders.order_id}
            </p>
            <p className={classes.content_item}>Status: {status}</p>
          </Grid>
          <Grid container item xs={1} className={classes.container_actions}>
            <FontAwesomeIcon
              icon={faCircleInfo}
              className={classes.icon_order}
              style={{ color: "#0090C8" }}
              onClick={() => {
                {
                  props.history.push({
                    pathname: "/dashboard/myOrders/details",
                    state: {
                      orderId: orders.order_id,
                      status: orders.status,
                    },
                  });
                }
              }}
            />
            {/*<FontAwesomeIcon
        icon={faCubes}
        className={classes.icon_order}
        style={{ color: "#06A6C4" }}
      />
      <FontAwesomeIcon
        icon={faLink}
        className={classes.icon_order}
        style={{ color: "#009890" }}
      />*/}
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}

ItemList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(batchViewsStyle)(ItemList);
