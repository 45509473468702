import io from "socket.io-client";
import * as actions from "./actions";
import { updateuserInfoByKey } from "redux/actions/general";
import config from "config.js";

const R = require("ramda");
var socket = null;

const socketMiddleware = (url) => {
  const checkSocket = (store) => {
    if (!socket) {
      store.dispatch(updateuserInfoByKey("socket", "DISCONNECTED"));
    }
  };

  const onDisconnect = (store) => {
    console.log("websocket disconnect");
    store.dispatch(updateuserInfoByKey("socket", "DISCONNECTED"));
  };

  const onConnect = (store, token) => {
    console.log("websocket connect");
    // send authentication
    socket.emit("authenticate", { token });
  };

  const onAuthenticated = (store, url) => {
    console.log("websocket authenticated");
    store.dispatch(updateuserInfoByKey("socket", "CONNECTED"));
    store.dispatch(updateuserInfoByKey("socketUrl", url));
  };

  const onNewMessage = (store, message) => {
    console.log("websocket newMessage", message);

    const state = store.getState();
    const chatMessages =
      R.clone(state.root.user.userKeyStore.chatMessages) || [];
    store.dispatch(
      updateuserInfoByKey("chatMessages", [...chatMessages, message])
    );
  };

  const onSuccessEnterRoom = (store, serverMsg, action) => {
    console.log("websocket success", serverMsg);
    let callbackFunc = action.callbackFunc;
    if (callbackFunc) callbackFunc(serverMsg, action.data);

    socket.off("success");

    //
    if (action.data && action.data.room && action.data.room.id) {
      socket.emit("read", { room: action.data.room.id });
    }
  };

  const onNewMessageForRoom = (store, roomId) => {
    console.log("websocket newMessageForRoom", roomId);

    const state = store.getState();
    const newMessageForRoom =
      R.clone(state.root.user.userKeyStore.newMessageForRoom) || {};
    newMessageForRoom[roomId] = newMessageForRoom[roomId]
      ? newMessageForRoom[roomId] + 1
      : 1;
    store.dispatch(updateuserInfoByKey("newMessageForRoom", newMessageForRoom));
  };

  return (storeAPI) => (next) => (action) => {
    // socket instance check
    const state = storeAPI.getState();
    const connectionStatus = state.root.user.userKeyStore.socket;
    // if(connectionStatus && connectionStatus === 'CONNECTED'){
    //     if(!socket){
    //         storeAPI.dispatch(updateuserInfoByKey('socket', 'DISCONNECTED'));
    //     }
    // }
    // console.log(state);
    if (connectionStatus === "CONNECTED" && !socket) {
      console.error("ERROR SOCKET & SOCKET STATUS");
    }
    switch (action.type) {
      case "WS_CONNECT": {
        if (socket !== null) {
          socket.close();
        }

        let url = action.host;
        let token = action.token;
        // connect to the remote host
        socket = io.connect(
          url,
          { transports: ["websocket"], upgrade: false, reconnection: false }
        );
        // websocket handlers
        socket.on("connect", () => onConnect(storeAPI, token));
        socket.on("disconnect", () => onDisconnect(storeAPI));
        socket.on("authenticated", () => onAuthenticated(storeAPI, url));
        socket.on("newMessage", (message) => onNewMessage(storeAPI, message));
        // socket.on('success', (serverMsg)=>onSuccessEnterRoom(storeAPI, serverMsg));
        socket.on("newMessageForRoom", (roomId) =>
          onNewMessageForRoom(storeAPI, roomId)
        );

        break;
      }
      case "WS_DISCONNECT": {
        if (socket !== null) {
          socket.close();
        }
        socket = null;
        storeAPI.dispatch(updateuserInfoByKey("socket", "DISCONNECTED"));

        break;
      }
      case "WS_ENTER_CHAT": {
        if (socket !== null) {
          let id = action.id;
          socket.emit("createChat", { receiverId: id });
        } else {
          checkSocket(storeAPI);
          console.error("SOCKET is NULL.");
        }
        socket.on("success", (serverMsg) =>
          onSuccessEnterRoom(storeAPI, serverMsg, action)
        );

        break;
      }
      case "WS_LEAVE_CHAT": {
        if (socket !== null) {
          let id = action.roomId;
          socket.emit("unsubscribe", id);
        } else {
          checkSocket(storeAPI);
          console.error("SOCKET is NULL.");
        }

        break;
      }
      case "WS_SEND_CHAT_MESSAGE": {
        if (socket !== null) {
          let room = action.roomId;
          let message = action.message;
          socket.emit("chatMessage", { message, room });
        } else {
          checkSocket(storeAPI);
          console.error("SOCKET is NULL.");
        }

        break;
      }
      // default:
      //     console.log('the next action:', action);
      //     return next(action);
    }

    return next(action);
  };
};

export default socketMiddleware();
