const pagesStyle = theme => ({
  wrapper: {
    height: "auto",
    minHeight: "auto",
    position: "relative",
    top: "0"
  },
  change_language:{
    bottom: 0,
    position: "absolute",
    width: "100%",
    height: "auto",
    background: "#090913",
    boxShadow: "0px 3px 36px 5px rgba(0,0,0,0.66)",
    display: "flex",
    justifyContent: "center"
  },
  change_language_inside: {
    width: "93%",
    display: "flex",
    justifyContent: "end"
  }
  // fullPage: {
  //   padding: "0",
  //   position: "relative",
  //   minHeight: "100vh",
  //   display: "flex",
  //   margin: "0",
  //   border: "0",
  //   color: "#fff",
  //   alignItems: "center",
  //   backgroundSize: "cover",
  //   backgroundPosition: "center center",
  //   height: "100%",
  //   [theme.breakpoints.down("sm")]: {
  //     minHeight: "fit-content!important"
  //   },
    // "& footer": {
    //   position: "absolute",
    //   bottom: "0",
    //   width: "100%",
    //   border: "none !important"
    // },
    //
    // "&:before,&:after": {
    //   display: "block",
    //   content: '""',
    //   position: "absolute",
    //   width: "100%",
    //   height: "100%",
    //   top: "0",
    //   left: "0",
    //   zIndex: "2"
    // }
  // }
});

export default pagesStyle;
