import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import 'antd/dist/antd.css';
import { Select } from 'antd';

import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";

function SelectComponent({ ...props }) {
  const {    
    handlerChangeData,
    value,
    page,
    data,
    id
  } = props;

  const { Option } = Select;
  return (   
    <>
    {
      data       
      ? 
      <Select
        showSearch
        style={page ?{ width: "100%"} :{ width: "100%",  borderRadius: 5, border: "none"}}
        placeholder="Select"
        optionFilterProp="children"
        size="large"
        onChange={(value) => {
          handlerChangeData({
            target:{
              id: id,
              value: value
            }
          })
        }}
        value={value}
      >        
        {data.map((item) => (
          <Option value={item.value}>{item.name}</Option>
        ))}            
      </Select> 
      :
          <Select
              showSearch
              style={page ?{ width: "100%"} :{ width: "100%",  borderRadius: 5, border: "none"}}
              placeholder="Select"
              optionFilterProp="children"
              size="large"
              onChange={(value) => {
                handlerChangeData({
                  target:{
                    id: id,
                    value: value
                  }
                })
              }}
              value={value}
          >
            <Option value="name">Name</Option>
            <Option value="email">Email</Option>
            <Option value="company">Company</Option>
          </Select>
    }       
    </>        
  );
}

SelectComponent.propTypes = {
  classes: PropTypes.object.isRequired, 
};

export default withStyles(customInputStyle)(SelectComponent);
