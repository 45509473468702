/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@mui/material/Grid";
import batchViewsStyle from "assets/jss/material-dashboard-pro-react/components/BatchViewStyle";
import InformationItem from "./InformationItem";
import ItemList from "./ItemList";
import Button from "components/CustomButtons/Button.jsx";
import { useTranslation } from "react-i18next";
function OrderListComponent(props) {
  const {
    classes,
    orders,
    handlerChangeData,
    handlerCreateOrdes,
    data,
  } = props;

  const [showInformation, setShowInformation] = useState(false);
  const [showInformationId, setShowInformationId] = useState(null);
  const { t } = useTranslation("batchView");
  return (
    <Grid style={{ marginTop: 20 }}>
      {orders.map((item, key) => (
        <>
          <ItemList
            id={key}
            setShowInformation={setShowInformation}
            showInformation={showInformation}
            setShowInformationId={setShowInformationId}
            orders={item}
            history={props.history}
          />
          {showInformation && showInformationId === key ? (
            <InformationItem id={key} orders={item} />
          ) : null}
        </>
      ))}
    </Grid>
  );
}

OrderListComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(batchViewsStyle)(OrderListComponent);
