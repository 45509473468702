import * as types from "./types";

export const dataTotal = (value, name, title) => {
  return {
    type: types.DATATOTAL,
    payload: value,
    name,
  };
};

export const clear = function() {
  return {
    type: types.CLEAR_STATE,
  };
};

export const changeLanguage = () => {
  return {
    type: types.CHANGE_LANGUAGE,
  };
};

export const updateuserInfoByKey = (key, value) => {
  return {
    type: types.UPDATE_USER_INFO_BY_KEY,
    payload: value,
    key,
  };
};
