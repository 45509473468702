/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import notificationStyle from "assets/jss/material-dashboard-pro-react/views/notificationStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import SettingDaysComponent from "components/SettingDays";
import SettingDaysControllers from "controllers/SettingDaysControllers";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Toast from "components/Toast/Toast.jsx";
const SettingDays = ({ classes, ...rest }) => {
  const {
    handleUpdatedDays,
    handlerChangeData,
    setToast,
  } = SettingDaysControllers(rest);
  return (
    <>
      {
        //<h1 className={classes.title}>Cambiar dias a expirar</h1>  TODO: titulo adecuado.
      }
      <div className={classes.containerView}>
        <SettingDaysComponent
          onSubmit={handleUpdatedDays}
          onChange={handlerChangeData}
          setToast={setToast}
        />
        <Toast
          innerRef={(ref) => {
            setToast(ref);
          }}
        />
      </div>
    </>
  );
};

SettingDays.propTypes = {
  classes: PropTypes.object.isRequired,
};

var withStylesClass = withStyles(notificationStyle)(SettingDays);
export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    notificationCount: state.root.user.notificationCount,
    notificationsData: state.root.user.notificationData,
  }))(withStylesClass)
);
