// ##############################
// // // App styles
// #############################

import {
  drawerWidth,
  drawerMiniWidth,
  transition,
  containerFluid
} from "assets/jss/material-dashboard-pro-react.jsx";

const appStyle = theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "auto",
    "&:after": {
      display: "table",
      clear: "both",
      content: '" "'
    }
  },
  mainPanel: {
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
   // maxHeight: "80vh",
    width: "100%",
   // overflowScrolling: "touch",
  },
  content: {
    marginTop: "70px",
    padding: "0px 15px",
    minHeight: "calc(100vh - 123px)",
    marginBottom: "50px"
  },
  container: { ...containerFluid },
  map: {
    marginTop: "70px"
  },
  mainPanelSidebarMini: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerMiniWidth}px)`
    }
  },
  mainPanelWithPerfectScrollbar: {
    overflow: "hidden !important"
  },
  change_language:{
    bottom: 0,
    position: "fixed",
    width: "100%",
    height: "auto",
    background: "#090913",
    boxShadow: "0px 3px 36px 5px rgba(0,0,0,0.66)",
    display: "flex",
    justifyContent: "center",
    zIndex: "999"
  },
  change_language_inside: {
    width: "93%",
    display: "flex",
    justifyContent: "end"
  }
});

export default appStyle;
