import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { connect } from "react-redux";
import Toast from "components/Toast/Toast.jsx";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx";

//import image from "assets/img/sidebar.jpg";
//import logo from "assets/img/Logo.png";
// Wrap
import Config from "layouts/Config.jsx";

// core components

import Sidebar from "components/Sidebar/Sidebar.jsx";
import Loader from "components/Loader/Loader.jsx";
import ChangeLanguage from "../components/ChangeLanguage";
import {
  getIncomingProducts,
  getProducts,
  getProductsInventory,
  getScannedIncomingProductBatch,
  getScannedProductBatch,
  getSpecialistInventory,
  getTransferBetweenSpecialist,
  getMembers,
  getRecords,
  getClinicPlaces,
} from "../protocols/requests";
import { dataTotal } from "../redux/actions/general";

var ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.toast = React.createRef();
    this.state = {
      mobileOpen: true,
      miniActive: false,
      username: "",
      dashboardRoutes: [],
      refresh: false,
    };
    this.loader = React.createRef();
  }

  async Data(refresh = false) {
    if (Object.keys(this.props.data).length === 0 || refresh) {
      switch (this.props.userInfo.groupSlug) {
        case "main-manager":
          let membersMap = await getMembers("all", {
            token: this.props.userToken,
            page: 0,
            records: 0,
          });
          let records = await getRecords(this.props.userToken);
          let products = await getProducts(this.props.userToken, {
            page: 0,
            records: 10,
          });
          let scannedBatchMain = await getScannedProductBatch(
            this.props.userToken,
            { page: 0, records: 10 }
          );
          let scannedBatchIncomingMain = await getScannedIncomingProductBatch(
            this.props.userToken,
            { page: 0, records: 10 }
          );

          if (membersMap.response.code === 200) {
            this.props.dispatch(
              dataTotal(membersMap.response.response, "members")
            );
          }
          if (records.response.code === 200) {
            this.props.dispatch(
              dataTotal(records.response.response, "records")
            );
          }
          if (products.response.code === 200) {
            this.props.dispatch(
              dataTotal(products.response.response, "products")
            );
          }
          if (scannedBatchMain.response.code === 200) {
            this.props.dispatch(
              dataTotal(scannedBatchMain.response.response, "scannedBatch")
            );
          }
          if (scannedBatchIncomingMain.response.code === 200) {
            this.props.dispatch(
              dataTotal(
                scannedBatchIncomingMain.response.response,
                "scannedBatchIncoming"
              )
            );
          }

          if (Object.keys(this.props.data).length === 0) {
            this.props.dispatch(dataTotal({}, ""));
          }

          break;
        case "manager":
          let members = await getMembers("all", {
            token: this.props.userToken,
            page: 0,
            records: 0,
          });
          let recordsDash = await getRecords(this.props.userToken);
          //  let productsManager = await getProducts(this.props.userToken, {page: 0, records: 10})

          if (members.response.code === 200) {
            this.props.dispatch(
              dataTotal(members.response.response, "members")
            );
          }
          if (recordsDash.response.code === 200) {
            this.props.dispatch(
              dataTotal(recordsDash.response.response, "records")
            );
          }
          //  if(productsManager.response.code === 200){
          //    this.props.dispatch(dataTotal(productsManager.response.response, "products"));
          //  }

          if (Object.keys(this.props.data).length === 0) {
            this.props.dispatch(dataTotal({}, ""));
          }

          break;
        case "clinic-hospital":
          let incomingProducts = await getIncomingProducts(
            this.props.userToken
          );
          let inventoryProducts = await getProductsInventory(
            this.props.userToken
          );
          let scannedBatchClinic = await getScannedProductBatch(
            this.props.userToken,
            { page: 0, records: 10 }
          );
          let scannedBatchIncomingClinic = await getScannedIncomingProductBatch(
            this.props.userToken,
            { page: 0, records: 10 }
          );

          if (inventoryProducts.response.code === 200) {
            inventoryProducts.response.response["title"] = "Products in Stock";
            inventoryProducts.response.response["url"] = "products-inventory";
            this.props.dispatch(
              dataTotal(
                inventoryProducts.response.response,
                "inventoryProducts"
              )
            );
          }

          if (incomingProducts.response.code === 200) {
            incomingProducts.response["title"] = "Incoming Products";
            incomingProducts.response["url"] = "incoming-products";
            this.props.dispatch(
              dataTotal(incomingProducts.response, "incomingProductsClinic")
            );
          }

          if (scannedBatchClinic.response.code === 200) {
            scannedBatchClinic.response.response["title"] =
              "Clinic Products Batch";
            scannedBatchClinic.response.response["url"] =
              "scannedProductsBatch";
            this.props.dispatch(
              dataTotal(scannedBatchClinic.response.response, "scannedBatch")
            );
          }

          if (scannedBatchIncomingClinic.response.code === 200) {
            scannedBatchIncomingClinic.response.response["title"] =
              "Incoming Clinic Products Batch";
            scannedBatchIncomingClinic.response.response["url"] =
              "scannedProductsBatchIncoming";
            this.props.dispatch(
              dataTotal(
                scannedBatchIncomingClinic.response.response,
                "scannedBatchIncoming"
              )
            );
          }

          if (Object.keys(this.props.data).length === 0) {
            this.props.dispatch(dataTotal({}, ""));
          }

          break;
        case "clinical-specialist":
          let InventorySpecialist = await getSpecialistInventory(
            this.props.userToken
          );
          let TransferSpecialist = await getTransferBetweenSpecialist(
            this.props.userToken
          );

          if (InventorySpecialist.response.code === 200) {
            InventorySpecialist.response.response["title"] =
              "Products in Stock";
            InventorySpecialist.response.response["url"] =
              "clinical-specialist-products";
            this.props.dispatch(
              dataTotal(
                InventorySpecialist.response.response,
                "InventorySpecialist"
              )
            );
          }

          if (TransferSpecialist.response.code === 200) {
            TransferSpecialist.response["title"] = "Transferred Specialists";
            TransferSpecialist.response["url"] = "transferred-products";
            this.props.dispatch(
              dataTotal(TransferSpecialist.response, "TransferSpecialist")
            );
          }

          if (Object.keys(this.props.data).length === 0) {
            this.props.dispatch(dataTotal({}, ""));
          }

          break;
        case "sales-executive":
          let inventoryProductsExecutive = await getProductsInventory(
            this.props.userToken
          );

          if (inventoryProductsExecutive.response.code === 200) {
            inventoryProductsExecutive.response.response["title"] =
              "Products in Stock";
            inventoryProductsExecutive.response.response["url"] =
              "products-inventory";
            this.props.dispatch(
              dataTotal(
                inventoryProductsExecutive.response.response,
                "inventoryProducts"
              )
            );
          }

          if (Object.keys(this.props.data).length === 0) {
            this.props.dispatch(dataTotal({}, ""));
          }

          break;
      }
      this.setState({ refresh: false });
      this.setLoaderVisible(false);
    }
  }

  async componentDidMount() {
    await this.Data();
  }

  switchRoutes = () => (
    <Switch>
      {this.state.dashboardRoutes?.map((prop, key) => {
        if (prop.redirect)
          return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
        if (prop.collapse)
          return prop.views.map((prop, collapseKey) => {
            return (
              <Route
                path={prop.path}
                exact={prop.exact}
                component={(props) => {
                  return (
                    <prop.component
                      {...props}
                      setLoaderVisible={this.setLoaderVisible}
                      showToast={this.showToast}
                    />
                  );
                }}
                key={key}
              />
            );
          });
        return (
          <Route
            path={prop.path}
            exact={prop.exact}
            component={(props) => {
              return (
                <prop.component
                  {...props}
                  setLoaderVisible={this.setLoaderVisible}
                  showToast={this.showToast}
                />
              );
            }}
            key={key}
          />
        );
      })}
    </Switch>
  );

  showToast = (type, content, notification) => {
    if (this.toast) {
      if (type === "success") {
        this.toast.showSuccess(content);
      } else if (type === "warning" && notification) {
        this.toast.showNotify(content);
      } else {
        this.toast.showError(content);
      }
    }
  };

  afterConfig = (username, dashboardRoutes) => {
    this.setState({ username, dashboardRoutes });
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  logout = () => {
    this.props.history.push("/");
  };

  showAccountSetting = () => {
    this.props.history.push("/dashboard/setting");
  };

  setLoaderVisible = (bShow) => {
    if (this.loader) this.loader.setLoaderVisible(bShow);
  };

  setOpenSide = () => {
    this.setState({ mobileOPen: !this.state.mobileOPen });
  };

  setRefresh = async () => {
    this.setLoaderVisible(true);
    await this.Data(true);
  };

  render() {
    const { classes, ...rest } = this.props;
    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1,
      });

    return (
      <div className={classes.wrapper}>
        <Config afterConfig={this.afterConfig} />
        <Loader
          innerRef={(ref) => {
            this.loader = ref;
          }}
        />
        <Toast
          innerRef={(ref) => {
            this.toast = ref;
          }}
        />

        {this.state.refresh ? (
          <Loader
            innerRef={(ref) => {
              this.loader = ref;
            }}
          />
        ) : null}

        <Sidebar
          routes={this.state.dashboardRoutes}
          logoText="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
          //logo={logo}
          refresh={this.setRefresh}
          bgColor="black"
          open={this.setOpenSide}
          miniActive={this.state.miniActive}
          logout={this.logout}
          showAccountSetting={this.showAccountSetting}
          {...rest}
        />
        <div className={mainPanel} ref="mainPanel">
          <div className={classes.content}>{this.switchRoutes()}</div>
        </div>
        <div className={classes.change_language}>
          <div className={classes.change_language_inside}>
            <ChangeLanguage />
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

var withStylesClass = withStyles(appStyle)(Dashboard);
export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
    data: state.root.general.dataUserAPI,
  }))(withStylesClass)
);
