

 // ##############################
  // // // batch view styles
  // #############################
 
  
  const fileViewStyle = theme => ({  
    containerPrimary:{
      backgroundColor:"#1E88EF",
      padding: 20,
      marginBottom: 50,
      borderRadius: 10,
      boxShadow: "0px 3px 10px 2px rgba(0,0,0,0.66)",
      width: "45%",
      marginRight: "5%",
    },
    containerSeconds:{
        backgroundColor:"#EFC51E",
        padding: 20,
        marginBottom: 50,
        borderRadius: 10,
        boxShadow: "0px 3px 10px 2px rgba(0,0,0,0.66)",
        width: "45%",
      },    
    title:{
        color: "#EFF3F1",
        fontSize: "3vw"
       },
      uploadFile: {
        display: "flex",
        borderTop: "1px solid #EFF3F1",
        paddingBottom: 10,
        paddingTop: 10,
        '& input': {
          display: "none"
        },
        '& label': {
          display: "block",
          borderRadius: "6px",
          cursor:"pointer",
          color: "#EFF3F1",
          width: "40%",
          textAlign: "center",
          padding: "4px 2px 4px 2px",
          border: "1px solid",
          height: "80%"
        },
        '& p': {
          width: "100%",
          marginLeft: 5,
          color: "#EFF3F1"
        }
      },

      btnFile: {
        marginTop: -2
      }
      
  });
  
  export default fileViewStyle;
  