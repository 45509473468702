const stepSkusStyles = (theme) => ({
  container: {
    backgroundColor: "rgba(231, 231, 231, 0.33)",
    width: "90%",
    margin: "0 auto",
    borderRadius: 8,
    paddingTop: "10px",
    paddingLeft: "3%",
    paddingRight: "3%",
    marginTop: "5%",
  },
  tittle: {
    marginTop: "20px",
    marginLeft: "4%",
    "& h5": {
      fontSize: "3vh",
    },
    marginBottom: "5px",
  },
  alert: {
    color: "rgb(92, 199, 248)",
    fontSize: "1rem",
  },
  containerSkuItem: {
    borderBottom: "1px solid gray",
    padding: "15px",
  },
  items: {
    textAlign: "center",
  },
  containerModel: {
    paddingLeft: "4%",
  },
  Select1: {
    width: "40%",
  },
  Select2: {
    width: "40%",
  },
  selects: {
    justifyContent: "spacearround",
    //marginLeft: "15%",
    marginBottom: "20px",
  },
  current_inv_num: {
    color: "red",
  },
  desc: {
    justifyContent: "center",
    alignItem: "center",
    textAlign: "center",
    marginRight: "5%",
    wordWrap: "break-word",
  },
  materialGroup: {
    paddingLeft: "50%",
    paddingBottom: "10px",
  },
  txtdesc: {
    wordWrap: "break-word",
  },
  colum2: {
    borderRadius: 10,
    // boxShadow: "2px 2px 10px gray",
    backgroundColor: "#F1F1F1",
    padding: "10px",
    marginTop: "5%",
  },
  txtResumen: {
    fontSize: "1.5vh",
  },
  CheckboxContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "10%",
  },
  CheckboxGroup: {
    display: "flex",
    flexWrap: "wrap",
    gap: 10,
  },
  "@media (max-Width: 960px)": {
    txtdesc: {
      fontSize: "1vh",
    },
    desc: {
      fontSize: "1vh",
    },
    model: {
      fontSize: "1vh",
    },
    items: {
      fontSize: "1vh",
    },
    current_inv_num: {
      fontSize: "1vh",
    },
    optimum_national: {
      fontSize: "1vh",
    },
    current_inv: {
      fontSize: "1vh",
    },
    warehouse_nearby: {
      fontSize: "1vh",
    },
    warehouse_inv: {
      fontSize: "1vh",
    },
  },
});

export default stepSkusStyles;
