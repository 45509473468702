import * as types from "../actions/types";
import { combineReducers } from "redux";

const languageState = {language: false};

const change_language = (state = languageState, action) => {
    if(action.type === types.CHANGE_LANGUAGE){
        return{
            ...state,
            language: !state.language
        }
    }else{
        return state
    }
}

const data = {}

const dataUserAPI = (state = data, action) => {
    if(action.type === types.DATATOTAL){
        return {
            ...state,
            [action.name]: action.payload,
        }
    }else{
        return state
    }

}

const generalReducer = combineReducers({
    change_language,
    dataUserAPI,
});

export default generalReducer;