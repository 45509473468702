import React from "react";
import ReactDOM from "react-dom";
import { Provider as ProviderRedux } from "react-redux";
import { Helmet } from "react-helmet";
import "./styles.css";
//import { Router, Route } from 'react-router'
import { Router, Route, Switch } from "react-router-dom";
import { store, persistor } from "./setting";
import { PersistGate } from "redux-persist/integration/react";

import "assets/scss/material-dashboard-pro-react.css?v=1.4.0";

import indexRoutes from "routes/index.jsx";

//translate
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import welcomePage_en from "./Translations/en/welcomePage.json";
import welcomePage_es from "./Translations/es/welcomePage.json";
import loginPage_en from "./Translations/en/loginPage.json";
import loginPage_es from "./Translations/es/loginPage.json";
import sidebar_en from "./Translations/en/sidebar.json";
import sidebar_es from "./Translations/es/sidebar.json";
import userForm_en from "./Translations/en/userForm.json";
import userForm_es from "./Translations/es/userForm.json";
import userView_en from "./Translations/en/userView.json";
import userView_es from "./Translations/es/userView.json";
import batchView_en from "./Translations/en/batchView.json";
import batchView_es from "./Translations/es/batchView.json";
import dashboardData_en from "./Translations/en/dashboardData.json";
import dashboardData_es from "./Translations/es/dashboardData.json";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    es: {
      welcomePage: welcomePage_es,
      loginPage: loginPage_es,
      sidebar: sidebar_es,
      userForm: userForm_es,
      userView: userView_es,
      batchView: batchView_es,
      dashboardData: dashboardData_es,
    },
    en: {
      welcomePage: welcomePage_en,
      loginPage: loginPage_en,
      sidebar: sidebar_en,
      userForm: userForm_en,
      userView: userView_en,
      batchView: batchView_en,
      dashboardData: dashboardData_en,
    },
  },
});

const history = require("history").createBrowserHistory();

class Root extends React.Component {
  render() {
    return (
      <ProviderRedux store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Helmet>
            <meta http-equiv="Expires" content="0" />
            <meta http-equiv="Last-Modified" content="0" />
            <meta
              http-equiv="Cache-Control"
              content="no-cache, must-revalidate"
            />
            <meta http-equiv="Pragma" content="no-cache" />
          </Helmet>
          <Router history={history}>
            <Switch>
              {indexRoutes.map((prop, key) => {
                return (
                  <Route
                    path={prop.path}
                    component={prop.component}
                    key={key}
                  />
                );
              })}
            </Switch>
          </Router>
        </PersistGate>
      </ProviderRedux>
    );
  }
}

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <Root />
  </I18nextProvider>,
  document.getElementById("root")
);
