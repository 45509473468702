import React, { useEffect, useState } from "react";
import UserViews from "components/userViews";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ClinicPlacesController from "controllers/ClinicPlacesControllers";

const ClinicHospitalDepartmentPlace = (props) => {
  const [show, setShow] = useState(false);
  const title = "Department / Place";
  const {
    handleGetClinicPlaces,
    products,
    page,
    handleChangePage,
    totalPage,
    handlerChangeRecordsPage,
    localRecords,
    dataFilter,
  } = ClinicPlacesController();

  useEffect(async () => {
    props.setLoaderVisible(true);
    await handleGetClinicPlaces(props.userToken);
    props.setLoaderVisible(false);
    setShow(true);
  }, []);

  return (
    <div>
      {show ? (
        <UserViews
          clinicDeparment={"departmentPlaces"}
          title={title}
          //handlerAddMember={handlerAddMember}
          members={products.places}
          handleChangePage={handleChangePage}
          totalPage={totalPage}
          handlerChangeRecordsPage={handlerChangeRecordsPage}
          places
          {...props}
        />
      ) : null}
    </div>
  );
};

export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
  }))(ClinicHospitalDepartmentPlace)
);
