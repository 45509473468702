import React, { useEffect } from "react";
import ScannedIncomingProductBatchControllers from "../../../controllers/ScannedIncomingProductBatchControllers";
import empty from "../../../assets/img/dashboard/empty.svg";

// component view
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import BatchView from "../../../components/Batch/BatchView";

function ScannedBatchProductsIncoming(props) {
  const title = "Scanned Products Batch Incoming";
  const {
    handleGetScannedIncomingProductBatch,
    products,
    handleChangePage,
    page,
    totalPage,
    handlerChangeRecordsPage,
    localRecords,
    handleGetScannedFilter,
    handlerChangeData,
    filter,
  } = ScannedIncomingProductBatchControllers(props);
  const token = props.userToken;

  useEffect(
    async () => {
      if (products.length === 0) props.setLoaderVisible(true);
      await handleGetScannedIncomingProductBatch({ token });
      if (products.length === 0) props.setLoaderVisible(false);
    },
    [page || localRecords]
  );

  return (
    <>
      {props.data.scannedBatchIncoming ? (
        <BatchView
          title={title}
          products={
            !filter
              ? page === 0 && localRecords === 10
                ? props.data.scannedBatchIncoming
                  ? props.data.scannedBatchIncoming.scanned
                  : products
                : products
              : products
          }
          batchIncoming={true}
          handleChangePage={handleChangePage}
          handlerChangeRecordsPage={handlerChangeRecordsPage}
          totalPage={
            page === 0 && localRecords === 10
              ? Math.ceil(props.data.scannedBatchIncoming.totalRecords / 10)
              : totalPage
          }
          handleGetScannedFilter={handleGetScannedFilter}
          handlerChangeData={handlerChangeData}
        />
      ) : (
        <img
          src={empty}
          alt="Not found data"
          style={{ height: "16rem", margin: "6rem 3rem" }}
        />
      )}
    </>
  );
}

export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
    data: state.root.general.dataUserAPI,
  }))(ScannedBatchProductsIncoming)
);
