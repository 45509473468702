import React, { useState } from "react";
import { getSpecialistInventory } from "protocols/requests";

const SpecialistInventoryControllers = () => {
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(null);
  const [localRecords, setLocalRecords] = useState(10);
  const [dataFilter, setDataFilter] = useState([
    {
      name: "UPN",
      value: "UPN",
    },
    {
      name: "GTIN",
      value: "GTIN",
    },
    {
      name: "Product Description",
      value: "description",
    },
  ]);
  const handleGetSpecialistInventory = async (token) => {
    const result = await getSpecialistInventory(token);
    if (result.status === 200) {
      const response = result.response.response.orders;
      setProducts(response);
      /* const products = result.response.response.members;
      const total = result.response.response.totalRecords;
      const total_page = Math.ceil(total / localRecords);
      setTotalPage(total_page);
      setProducts(products);
    }*/
    }
  };

  const handlerChangeRecordsPage = (value) => {
    setLocalRecords(value);
  };

  const handleChangePage = (page) => {
    page = page - 1;
    setPage(page);
  };

  return {
    products,
    page,
    handleChangePage,
    totalPage,
    handlerChangeRecordsPage,
    localRecords,
    dataFilter,
    handleGetSpecialistInventory,
  };
};

export default SpecialistInventoryControllers;
