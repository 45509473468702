import React from "react";

class TrueFreight extends React.Component {
    componentDidMount() {}
    render() {
        const title = "TrueFreight";
        return <h1>{title}</h1>
    }
}

export default TrueFreight;
