import React, { useEffect, useState } from "react";
import {
  updateMembers,
  deleteMembers,
  updatedClinic,
} from "protocols/requests";

const EditMemberControllers = (member, token, props) => {

  const body = member.data;

  const [toast, setToast] = React.useState(React.createRef());
  const [data, setData] = React.useState({
    id: body.memberId,
    firstName: body.firstName,
    lastName: body.lastName,
    email: body.email,
    password: "",
    companyName: body.companyName,
    contactNo: body.contactNo,
    address: body.address,
    city: body.city,
    country: body.country,
    zipCode: body.zipCode,
    addressDetails: body.addressDetails,
    state: body.state,
    lat: body.latitud,
    lng: body.longitud,
    otherSystemReferenceId: body.otherSystemReferenceId,
    fax: body.fax,
  });

  useEffect(() => {
    if (body.groupId === 4) {
      setData({
        ...data,
        pendingPoLocation: body.pendingPoLocation,
        creditLimit: body.creditLimit,
        creditUsed: body.creditUsed,
        employeeId: body.employeeId,
        clinicNote: body.clinicNote,
        replenishmentTime: body.replenishmentTime,
        billingTime: body.billingTime,
        replenishmentDate: body.replenishmentDate,
        billingDate: body.billingDate,
        isClinicReport: body.isClinicReport,
        isDash: body.isDash,
        hasInternal_id: body.hasInternal_id,
        clinicId: body.memberId,
        parentId: 1,
        nextCutoffDateBilling: "2021-12-06 08:50:36",
        nextCutoffDateReplenishment: "2021-12-06 08:50:36",
        contactNo: Number(body.contactNo),
        address: body.addressDetails,
        addressGoogle: body.address,
      });
    } else if (body.groupId === 6) {
      setData({
        ...data,
        employeeId: body.employeeId,
        accountType: body.accountType,
      });
    }
  }, []);

  const handlerChangeData = (e) => {
    var key = e.target.id;
    var value = e.target.value;
    setData({ ...data, [key]: value });
  };
  const handleChangeAddress = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const showToast = (type, content) => {
    if (toast) {
      if (type === "success") {
        toast.showSuccess(content);
      } else {
        toast.showError(content);
      }
    }
  };

  const handleEditMember = async () => {
    let result;
    if (!data.password) delete data["password"];
    props.setLoaderVisible(true);
    if (body.groupId === 4) {
      if (!data.pendingPoLocation) delete data["pendingPoLocation"];
      if (!data.fax) delete data["fax"];
      data.creditLimit = Number(data.creditLimit);
      data.creditUsed = Number(data.creditUsed);
      data.contactNo = Number(data.contactNo);
      result = await updatedClinic(data, token);
    } else {
      result = await updateMembers(data, token, member.type);
    }
    props.setLoaderVisible(false);
    if (result.status === 200) {
      showToast("success", result.response.description);
      props.history.goBack();
    } else {
      showToast("error", result.response.description);
    }
  };

  return {
    handleEditMember,
    data,
    handlerChangeData,
    handleChangeAddress,
    setToast,
    handleRemoveMember,
  };
};

export const handleRemoveMember = async (id, token) => {
  const body = {
    id,
  };
  const result = await deleteMembers(body, token);
  return result;
};

export const handleChangeStatus = async (id, props, status) => {
  props.setLoaderVisible(true);
  const body = {
    id: id,
    status: status === 1 ? 0 : 1,
  };
  const result = await updateMembers(body, props.userToken, "status");
  props.setLoaderVisible(false);
  if (result.status === 200) {
    window.location.reload();
  }
};

export default EditMemberControllers;
