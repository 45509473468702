import React, { useEffect } from "react";
import { Grid, makeStyles, withStyles, Box } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import OrderStatusItem from "components/OrderStatusItem";
import TransfersRequestController from "../../../controllers/TransfersRequest";
import { Pagination } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  Orders: {
    width: "100%",
    //height: "500px",
    marginTop: "10px",
    padding: "10px",
    // backgroundColor: "red",
  },
  tittleOrderStatus: {
    fontSize: "14px",
  },
  containerView: {
    padding: 20,
  },
}));

const OrderStatus = (props) => {
  const {
    handleGetTransfersProcessedOrders,
    transfersOrdersProcessed,
    setPageTransferOrderStatus,
    numberPageTransferOrderStatus,
    pageTransferOrderStatus,
    handleChange,
    orderId,
    downloadPdf,
  } = TransfersRequestController(props);

  const handleChangePage = (pageOrderStatus) => {
    setPageTransferOrderStatus(pageOrderStatus - 1);
  };
  useEffect(
    async () => {
      await handleGetTransfersProcessedOrders(props.userToken);
    },
    [pageTransferOrderStatus, orderId]
  );

  let orderstatus = true;
  const classes = useStyles();
  return (
    <Grid className={classes.containerView} container>
      <Grid xs={10}>
        <h5 className={classes.tittleOrderStatus}>
          <b>Estado de Traslados</b>{" "}
        </h5>
      </Grid>
      <Grid xs={2} className={classes.shearch}>
        <TextField
          id="filled-search"
          label="Busqueda por ID"
          type="search"
          variant="filled"
          onChange={handleChange}
          sx={{
            "@media (max-Width: 960px)": {
              width: 100,
              height: 60,
              fontSize: "0.5vh",
            },
          }}
        />
      </Grid>
      <Grid container xs={12} className={classes.Orders}>
        {transfersOrdersProcessed.map((order, id) => (
          <Grid xs={12}>
            <OrderStatusItem
              order={order}
              id={id}
              transfer
              downloadPdf={downloadPdf}
            />
          </Grid>
        ))}
      </Grid>
      {transfersOrdersProcessed && (
        <Pagination
          hideNextButton={true}
          hidePrevButton={true}
          onChange={(e) => handleChangePage(e.target.textContent)}
          count={
            parseInt(numberPageTransferOrderStatus) < 10
              ? 1
              : parseInt(numberPageTransferOrderStatus) % 10 != 0
                ? parseInt(numberPageTransferOrderStatus / 10) + 1
                : parseInt(numberPageTransferOrderStatus / 10)
          }
          page={pageTransferOrderStatus + 1}
        />
      )}
    </Grid>
  );
};

export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
    data: state.root.general.dataUserAPI,
    applicant: state.root.user.userInfo.firstName,
    id: state.root.user.userInfo.memberId,
    address: state.root.user.userInfo.address,
    contactNo: state.root.user.userInfo.contactNo,
  }))(OrderStatus)
);
