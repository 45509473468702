import React, { useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ErrorIcon from "@mui/icons-material/Error";
import Batchitem from "./BatchItem";

const useStyles = makeStyles((theme) => ({
  Line: {
    width: "95%",
    borderBottom: "0.5 solid black",
  },
  Line2: {
    width: "95%",
    borderBottom: "1.5px solid #1976d2",
    color: "#1976d2",
  },
  returnitem: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    "& h8": {
      fontSize: "100%",
    },
    "& h6": {
      fontSize: "90%",
    },

    // backgroundColor: "yellow",
  },
  selectreason: {
    marginTop: "20px",
    paddingRight: "20px",
    paddingLeft: "20px",
  },
  selectwarehouse: {
    marginTop: "20px",
    paddingRight: "20px",
    paddingLeft: "20px",
  },
  selectypedevolution: {
    marginTop: "20px",
    paddingRight: "20px",
    paddingLeft: "20px",
  },
  container: {
    paddingRight: "10px",
    // backgroundColor: "blue",
  },
  currentInvErr: {
    color: "red",
  },
  batchs: {
    marginTop: "10px",
  },
  txt: {
    fontSize: "100%",
    wordWrap: "break-word",
  },
  txt2: {
    fontSize: "100%",
    marginLeft: "22%",
  },
}));

const Returnrequestbody = (props) => {
  const {
    shiptosorskus,
    id,
    reasonCancelation,
    setReasonCancelation,
    handleAddSkuShipto,
    handleChangeQty,
    index,
    // handleChangeReasonReturn,
    // getValueReasonReturn,
    // handleChangeWarehouse,
    // getValueWarehouse,
    getValueTypeReturn,
    handleChangeTypeReturn,
    request,
    returnreason,
    warehouse,
    reasonsReturn,
  } = props;
  const [detail, setDetail] = useState(false);

  const [typedevolution, setTypedevolution] = useState(false);
  const [citywarehouse, setCitywarehouse] = useState("");
  const inv = parseInt(shiptosorskus.current_inv);
  const opt = parseInt(shiptosorskus.optimum_national);
  const classes = useStyles();

  /*  const handleChange = (e) => {
    //console.log("x1", shiptosorskus)

    handleChangeReasonReturn(e.target.value, shiptosorskus);
    //setReasonCancelation(e.target.value);
    //setWarehouse(true);
  }; */
  const handleChange2 = (e) => {
    handleChangeQty(e.target.value, e.target.id);
  };
  /*  const handleChange3 = (e) => {
    handleChangeWarehouse(e.target.value, shiptosorskus);
    //setCitywarehouse(e.target.value);
    //setTypedevolution(true);
  }; */
  const handleChange4 = (e) => {
    handleChangeTypeReturn(e.target.value, index);
  };

  const generalCurrentInv = shiptosorskus.batchs.reduce(
    (total, batch) => total + batch.current_inv,
    0
  );

  return (
    <Grid container xs={12} key={index} className={classes.container}>
      <Grid xs={12} className={classes.returnitem} container>
        <Grid xs={8} className={classes.txt}>
          {request == 1 ? (
            <div>
              <h8>CLIENTE</h8> <br />
              <h8>
                {shiptosorskus.shipto} {shiptosorskus.cust_name}
              </h8>
            </div>
          ) : (
            <div>
              <h8>PRODUCTO</h8> <br />
              <h8>
                {shiptosorskus.model} {shiptosorskus.material_description}
              </h8>
            </div>
          )}
        </Grid>

        <Grid xs={2} className={classes.txt}>
          <h6>Inventario óptimo</h6>
          <b>
            <h8>{shiptosorskus.optimum_national}</h8>
          </b>{" "}
        </Grid>
        <Grid xs={2}>
          <h6>Inventario Actual</h6>
          <b>
            <h8>{generalCurrentInv}</h8>
          </b>
        </Grid>
        <hr className={classes.Line2} />
        <Grid xs={12} className={classes.txt}>
          {shiptosorskus.batchs.map((item, id) => (
            <Batchitem
              item={item}
              id={id}
              handleChangeQty={handleChangeQty}
              handleAddSkuShipto={handleAddSkuShipto}
              shiptosorskus={shiptosorskus}
              opt={opt}
              // getValueReasonReturn={getValueReasonReturn}
              // getValueWarehouse={getValueWarehouse}
              index={index}
              warehouse={warehouse}
              reasonsReturn={reasonsReturn}
            />
          ))}
        </Grid>
      </Grid>
      {/*   {detail && (
        <Grid xs={12} container className={classes.selectreason}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Motivo de devolución
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={getValueReasonReturn(shiptosorskus)}
              label="Motivo de devolución"
              onChange={handleChange}
              sx={{
                "@media (max-Width: 960px)": {
                  width: 150,
                  height: 50,
                  fontSize: "1vh",
                },
              }}
            >
              <MenuItem value={"Exceso"}>Exceso</MenuItem>
              <MenuItem value={"Bajarotación"}>Baja rotación</MenuItem>
              <MenuItem value={"Cortafecha"}>Corta fecha</MenuItem>
              <MenuItem value={"Retiroproductodeprocedimiento"}>
                Retiro producto de procedimiento
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )} */}

      {/*    {detail && (
        <Grid xs={12} container className={classes.selectwarehouse}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Envio a Bodega
            </InputLabel>
            {returnreason === "Cortafecha" ? (
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label=" Envio a Bodega"
                onChange={handleChange3}
                value={getValueWarehouse(shiptosorskus)}
                sx={{
                  "@media (max-Width: 960px)": {
                    width: 150,
                    height: 50,
                    fontSize: "1vh",
                  },
                }}
              >
                <MenuItem value={"Bogota"}>Bogotá</MenuItem>
              </Select>
            ) : (
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label=" Envio a Bodega"
                onChange={handleChange3}
                value={getValueWarehouse(shiptosorskus)}
                sx={{
                  "@media (max-Width: 960px)": {
                    width: 150,
                    height: 50,
                    fontSize: "1vh",
                  },
                }}
              >
                <MenuItem value={"Bogota"}>Bogotá</MenuItem>
                <MenuItem value={"Medellin"}>Medellín</MenuItem>
                <MenuItem value={"Cali"}>Cali</MenuItem>
                <MenuItem value={"Barranquilla"}>Barranquilla</MenuItem>
              </Select>
            )}
          </FormControl>
        </Grid>
      )} */}
      {/* {getValueWarehouse(index) &&
        detail && (
          <Grid xs={12} container className={classes.selectypedevolution}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Tipo de devolución
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label=" Tipo de devolución"
                onChange={handleChange4}
                vale={getValueTypeReturn(index)}
              >
                <MenuItem value={"DevoluciónHabitual"}>
                  Devolución Habitual
                </MenuItem>
                <MenuItem value={"DevoluciónCertificada"}>
                  Devolución Certificada
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )} */}

      {/* <Grid xs={12} className={classes.arrow}>
        {!detail ? (
          <h6 className={classes.txt2}>
            Despliegue para seleccionar el motivo y la bodega
          </h6>
        ) : null}

        {!detail ? (
          <ArrowDownwardIcon
            fontSize="small"
            onClick={() => {
              setDetail(!detail);
            }}
            sx={{
              cursor: "pointer",
              marginLeft: "43%",
              color: "#009FC6",
            }}
          />
        ) : (
          <ArrowUpwardIcon
            fontSize="small"
            onClick={() => {
              setDetail(!detail);
              setWarehouse(false);
              setTypedevolution(false);
            }}
            sx={{
              cursor: "pointer",
              marginLeft: "43%",
              color: "#009FC6",
            }}
          />
        )}
      </Grid> */}
      <hr className={classes.Line} />
    </Grid>
  );
};
export default Returnrequestbody;
