import React from "react";

import { MapContainer, TileLayer, Marker } from "react-leaflet";
import PropTypes from "prop-types";
import "leaflet/dist/leaflet.css";
import PopupComponent from "./Popup";
import withStyles from "@material-ui/core/styles/withStyles";
import mapViewStyle from "assets/jss/material-dashboard-pro-react/components/mapViewStyle";

const MapView = (props) => {
  const { members, selectorIcon, selectorHeaderTitle } = props;

  return (
    <MapContainer
      center={[6.25184, -75.56359]}
      zoom={5}
      scrollWheelZoom={true}
      style={{ height: "90vh", width: "100%" }}
    >
      <TileLayer
        attribution="&copy; <a href=&quot;https://www.maptiler.com/&quot;>MapTiler</a> &copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
        url="https://api.maptiler.com/maps/basic/256/{z}/{x}/{y}.png?key=fXmTwJM642uPLZiwzhA1"
      />

      {members.map(
        (item, key) =>
          item.groupId != 1 ? (
            <Marker
              key={key}
              position={{
                lat: item.latitud,
                lng: item.longitud,
              }}
              icon={selectorIcon(parseInt(item.groupId, 10))}
            >
              <PopupComponent
                data={item}
                selectorHeaderTitle={selectorHeaderTitle}
                {...props}
              />
            </Marker>
          ) : null
      )}
    </MapContainer>
  );
};

MapView.propTypes = {
  classes: PropTypes.object.isRequired,
};

var withStylesClass = withStyles(mapViewStyle)(MapView);

export default withStylesClass;
