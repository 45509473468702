import React from "react";
import UserViews from "components/userViews";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getMembers } from "protocols/requests";

class PurchaseOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      members: [],
    };
  }
  /* async componentDidMount() {
    const result = await getMembers(
      "clinic-hospital-department",
      this.props.userToken
    );
    const members = result.response.response;
    this.setState({
      members,
    });
  }*/

  render() {
    const title = "Purchase Order";
    const handlerAddMember = () => {
      this.props.history.push({
        pathname: "/dashboard/member/add",
        state: { title: title, id: 6 },
      });
    };
    return (
      <>
        <h1>{title}</h1>
      </>
    );
  }
}

export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
  }))(PurchaseOrders)
);
