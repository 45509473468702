import React, { useEffect } from "react";
import OrderComponent from "components/Orders/OrderComponent";
import OrdersControllers from "controllers/ordersControllers";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Toast from "components/Toast/Toast.jsx";

function Order(props) {
  const {
    handleGetOrders,
    orders,
    handlerChangeData,
    handlerCreateOrdes,
    data,
    setToast,
  } = OrdersControllers(props, props.userToken);
  const title = "My Order(s)";
  const token = props.userToken;
  useEffect(() => {
    handleGetOrders(token);
  }, []);

  return (
    <div>
      <Toast
        innerRef={(ref) => {
          setToast(ref);
        }}
      />
      <OrderComponent
        title={title}
        orders={orders}
        data={data}
        {...props}
        handlerChangeData={handlerChangeData}
        handlerCreateOrdes={handlerCreateOrdes}
      />
    </div>
  );
}

export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
  }))(Order)
);
