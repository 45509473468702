/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@mui/material/Grid";
import fileViewStyle from "assets/jss/material-dashboard-pro-react/components/FilesViewStyle";
import Button from "components/CustomButtons/Button.jsx";
import { useTranslation } from "react-i18next";
function FilesComponent(props) {
  const {
    data,
    handlerChangeData,
    handlerCreateOrdes,
    handlerCreateDeliverys,
    classes,
  } = props;
  const { t } = useTranslation("batchView");

  return (
    <Grid style={{ marginTop: 20, display: "flex", padding: 20 }}>
      <div className={classes.containerPrimary}>
        <form>
          <h1 className={classes.title}>{t("loadOrders")}</h1>
          <div className={classes.uploadFile}>
            <label for="fileOrders">{t("loadFile")}</label>
            {data.nameOrders ? <p>{data.nameOrders}</p> : <p />}
            <input
              type="file"
              id="fileOrders"
              onChange={(e) => handlerChangeData(e)}
            />
            <Button
              type="button"
              onClick={(e) => handlerCreateOrdes()}
              className={classes.btnFile}
              size="sm"
              color="success"
            >
              {t("send")}
            </Button>
          </div>
        </form>
      </div>
      <div className={classes.containerSeconds}>
        <form>
          <h1 className={classes.title}>{t("loadDeliveries")}</h1>
          <div className={classes.uploadFile}>
            <label for="fileDeliverys">{t("loadFile")}</label>
            {data.nameDeliverys ? <p>{data.nameDeliverys}</p> : <p />}
            <input
              type="file"
              id="fileDeliverys"
              onChange={(e) => handlerChangeData(e)}
            />
            <Button
              type="button"
              onClick={(e) => handlerCreateDeliverys()}
              className={classes.btnFile}
              size="sm"
              color="success"
            >
              {t("send")}
            </Button>
          </div>
        </form>
      </div>
    </Grid>
  );
}

FilesComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(fileViewStyle)(FilesComponent);
