import React, { useState, useEffect } from "react";
import AddUsers from "components/AddUsers";
import Toast from "components/Toast/Toast.jsx";
import { createClinic } from "protocols/requests";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Geocode from "react-geocode";

function AddClinicHospital(props) {
  const { title, id } = props.location.state;
  let toast = React.createRef();
  const [data, setData] = useState({
    isClinicReport: 0,
    isDash: 0,
    hasInternal_id: 0,
    parentId: 2,
  });

  useEffect(
    () => {
      handlerChangeLatLng();
    },
    [data.lat && data.lng]
  );

  const showToast = (type, content) => {
    if (toast) {
      if (type === "success") {
        toast.showSuccess(content);
      } else {
        toast.showError(content);
      }
    }
  };

  const handlerChangeData = (e) => {
    var key = e.target.id;
    var value = e.target.value;
    if (
      key === "isClinicReport" ||
      key === "isDash" ||
      key === "hasInternal_id"
    )
      setData({ ...data, [key]: value === "0" ? 1 : 0 });
    else setData({ ...data, [key]: value });
  };

  const handlerSubmitMember = async () => {
    if (
      data.firstName &&
      data.lastName &&
      data.email &&
      data.password &&
      data.companyName &&
      data.contactNo &&
      data.address &&
      data.city &&
      data.country &&
      data.zipCode &&
      data.addressDetails &&
      data.state &&
      data.lng &&
      data.lat &&
      data.replenishmentTime &&
      data.billingTime &&
      data.employeeId &&
      data.clinicNote
    ) {
      data.addressGoogle = data.address;
      data.address = data.addressDetails;
      data.contactNo = Number(data.contactNo);
      if (data.creditUsed) data.creditUsed = Number(data.creditUsed);
      if (data.creditLimit) data.creditLimit = Number(data.creditLimit);
      console.log("x1", data);
      /* const result = await createClinic(data, props.userToken);
      if (result.status === 200) {
        showToast("success", result.response.description);
        setData({
          isClinicReport: 0,
          isDash: 0,
          hasInternal_id: 0,
          parentId: 2,
        });
      } else {
        showToast("error", result.response.description);
      }*/
    } else {
      showToast("error", "There are uncompleted fields");
    }
    console.log("x1", data);
  };

  const handleChangeAddress = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const handlerChangeLatLng = () => {
    let city, state, country, zipCode;
    Geocode.setApiKey(process.env.REACT_APP_GOOGLEMAP_KEY);
    Geocode.setLocationType("ROOFTOP");
    Geocode.fromLatLng(data.lat, data.lng)
      .then((response) => {
        response.results[0].address_components.map((item) => {
          switch (item.types[0]) {
            case "locality":
              city = item.long_name;
              break;
            case "administrative_area_level_1":
              state = item.long_name;
              break;
            case "country":
              country = item.long_name;
              break;
            case "postal_code":
              zipCode = item.long_name;
              break;
          }
        });
        setData({ ...data, city, state, country, zipCode });
      })
      .catch((error) => {
        console.log("error");
      });
  };

  return (
    <div>
      <Toast
        innerRef={(ref) => {
          toast = ref;
        }}
      />
      <AddUsers
        title={title}
        data={data}
        handlerChangeData={handlerChangeData}
        handlerSubmitMember={handlerSubmitMember}
        clinic
        handleChangeAddress={handleChangeAddress}
        handlerChangeLatLng={handlerChangeLatLng}
      />
    </div>
  );
}

export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
  }))(AddClinicHospital)
);
