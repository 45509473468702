// general
export const CLEAR_STATE = "CLEAR_STATE";
export const UPDATE_USER_INFO_BY_KEY = "UPDATE_USER_INFO_BY_KEY";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
// user
export const UPDATE_USERTOKEN = "UPDATE_USERTOKEN";
export const CLEAR_POSTS_STATUS = "CLEAR_POSTS_STATUS";
export const UPDATE_userInfo = "UPDATE_userInfo";
export const UPDATE_userData = "UPDATE_userData";
export const DATATOTAL = "DATATOTAL";
