import React, {useEffect} from "react";
import AddUsers from "components/AddUsers";
import Toast from "components/Toast/Toast.jsx";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {AddUserControllers} from 'controllers/AddUserControllers'

function AddUser(props){
    const {title, id, clinicDeparment} = props.location.state;
    const {
      data,     
      setToast,         
      handlerChangeData, 
      handleChangeAddress, 
      handlerSubmitMember,
      handlerChangeLatLng
      } = AddUserControllers(id, props); 

      useEffect(() =>{
        handlerChangeLatLng();
      }, [data.lat && data.lng])

    return (
    <div >
       <Toast
          innerRef={ref => {
            setToast(ref) 
          }}
        />
      <AddUsers 
         title={title} 
         data={data} 
         handlerChangeData={handlerChangeData} 
         handlerSubmitMember={handlerSubmitMember} 
         handleChangeAddress={handleChangeAddress} 
         clinicDeparment={clinicDeparment}
        {...props}
        /> 
    </div>)
  
}

export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
  }))(AddUser)
);

