// ##############################
// // // appBar styles
// #############################

const abstractStyle = (theme) => ({
  container: {
    boxShadow: "2px 2px 10px gray",
    paddingLeft: "30px",
    paddingTop: "15px",
    paddingBottom: "15px",
    backgroundColor: "white",
    borderRadius: 10,
    marginBottom: 15,
    wordWrap: "break-word",
  },
  containerSkus: {
    paddingLeft: "15px",
    paddingTop: "5px",
  },
  containerShipto: {
    paddingLeft: "15px",
    marginBottom: "5px",
  },
  nameShipto: {
    fontWeight: "bold",
  },

  detailProduct: {
    justifyContent: "center",
    textAlign: "center", // Agregamos esta propiedad para centrar el texto
    color: "white", // Cambiamos el color del texto a blanco
    fontWeight: "bold",
    marginTop: "10px",
  },
  LineResume: {
    width: "95%",
    borderBottom: "1.5px solid #1976d2",
    color: "#1976d2",
  },

  "@media (max-Width: 960px)": {
    container: {
      paddingLeft: "10px",
    },
    containerSkus: {
      paddingLeft: "2px",
    },
    containerShipto: {
      paddingLeft: "10px",
    },
  },
});

export default abstractStyle;
