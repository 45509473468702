import React from "react";
import Geocode from "react-geocode";
import { updateMembers, resetPassword } from "protocols/requests";
import { clear } from "redux/actions/general";
import cookie from "js-cookie";
export const MyProfileControllers = (props) => {
  const user = props.userData;
  const [toast, setToast] = React.useState(React.createRef());
  const [data, setData] = React.useState({
    id: user.memberId,
    firstName: user.firstName,
    lastName: user.lastName,
    companyName: user.companyName,
    contactNo: user.contactNo,
    address: user.address,
    city: user.city,
    country: user.country,
    zipCode: user.zipCode,
    addressDetails: user.addressDetails,
    state: user.state,
    lat: user.lat,
    lng: user.lng,
    otherSystemReferenceId: user.otherSystemReferenceId,
    fax: user.fax,
  });

  const [dataPassword, setDataPassword] = React.useState({
    currentPassword: "",
    password: "",
    repeatPassword: "",
    id: user.memberId,
  });

  const handlerChangeData = (e) => {
    var key = e.target.id;
    var value = e.target.value;
    setData({ ...data, [key]: value });
  };

  const handlerChangePassword = (e) => {
    var key = e.target.id;
    var value = e.target.value;
    setDataPassword({ ...dataPassword, [key]: value });
  };

  const handleChangeAddress = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const handlerChangeLatLng = () => {
    let city, state, country, zipCode;
    Geocode.setApiKey(process.env.REACT_APP_GOOGLEMAP_KEY);
    Geocode.setLocationType("ROOFTOP");
    Geocode.fromLatLng(data.lat, data.lng)
      .then((response) => {
        response.results[0].address_components.map((item) => {
          switch (item.types[0]) {
            case "locality":
              city = item.long_name;
              break;
            case "administrative_area_level_1":
              state = item.long_name;
              break;
            case "country":
              country = item.long_name;
              break;
            case "postal_code":
              zipCode = item.long_name;
              break;
          }
        });
        setData({ ...data, city, state, country, zipCode });
      })
      .catch((error) => {
        console.log("error");
      });
  };

  const showToast = (type, content) => {
    if (toast) {
      if (type === "success") {
        toast.showSuccess(content);
      } else {
        toast.showError(content);
      }
    }
  };

  const handlerUpdateUser = async () => {
    props.setLoaderVisible(true);
    const result = await updateMembers(data, props.userToken, "member");
    props.setLoaderVisible(false);
    if (result.status === 200) {
      showToast("success", result.response.description);
      await props.dispatch(clear());
      cookie.remove("token");
      props.history.push("/welcome-page");
    } else {
      showToast("error", result.response.description);
    }
  };

  const handlerResetPassword = async () => {
    if (dataPassword.password === dataPassword.repeatPassword) {
      props.setLoaderVisible(true);
      const result = await resetPassword(dataPassword, props.userToken);
      props.setLoaderVisible(false);
      if (result.status === 200) {
        showToast("success", result.response.description);
        await props.dispatch(clear());
        cookie.remove("token");
        props.history.push("/login-page");
      } else {
        showToast("error", result.response.description);
      }
    } else {
      showToast("error", "Passwords do not match");
    }
  };

  return {
    data,
    dataPassword,
    handlerChangeData,
    handleChangeAddress,
    handlerChangeLatLng,
    handlerUpdateUser,
    handlerChangePassword,
    setToast,
    handlerResetPassword,
  };
};
