import React, { useState } from "react";
import { updateNotifications } from "protocols/requests";

const NotificationsControllers = (token) => {
  const handleUpdatedNotifications = async (id) => {
    const body = {
      status: 0,
      alertId: id.toString(),
    };

    const result = await updateNotifications(body, token);
    if (result.status === 200) {
      return {
        status: true,
        response: result,
      };
    } else {
      return {
        status: false,
        response: result,
      };
    }
  };

  return {
    handleUpdatedNotifications,
  };
};

export default NotificationsControllers;
