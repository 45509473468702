import React, { useState } from "react";
import {
  getScannedProductBatch,
  createOrdersReplacement,
} from "protocols/requests";
import { dataTotal } from "redux/actions/general";
import { useTranslation } from "react-i18next";

const ScannedProductBatchControllers = (props) => {
  const [toast, setToast] = React.useState(React.createRef());
  const { t } = useTranslation("userView");
  const [products, setProducts] = useState([]);
  //const page = 0;
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(10);
  const [localRecords, setLocalRecords] = useState(10);
  const [batchId, setBatchId] = useState(null);
  const [clinic, setClinic] = useState(null);
  const [filter, setFilter] = useState(false);

  const handleGetScannedProductBatch = async (data) => {
    if (page !== 0 || (page === 0 && !props.data.scannedBatch)) {
      const result = await getScannedProductBatch(data.token, {
        page,
        records: localRecords,
        batchId,
      });
      if (result.status === 200) {
        const products = result.response.response.scanned;
        const total = result.response.response.totalRecords;
        const total_page = Math.ceil(total / localRecords);
        setTotalPage(total_page);
        setProducts(products);
        if (page === 0 && !props.data.scannedBatch) {
          props.dispatch(dataTotal(result.response.response, "scannedBatch"));
        }
      }
    }
  };

  const handleGetScannedFilter = async () => {
    if (batchId || clinic) {
      props.setLoaderVisible(true);
      const value = batchId
        ? {
            page,
            records: localRecords,
            batchId,
          }
        : {
            page,
            records: localRecords,
            clinic,
          };
      const result = await getScannedProductBatch(props.userToken, value);
      props.setLoaderVisible(false);
      if (result.status === 200) {
        const products = result.response.response.scanned;
        const total = result.response.response.totalRecords;
        const total_page = Math.ceil(total / localRecords);
        setTotalPage(total_page);
        setProducts(products);
        setFilter(true);
      }
      setBatchId(null);
    }
  };

  const handlerChangeRecordsPage = (value) => {
    setLocalRecords(value);
  };
  const handleChangePage = (page) => {
    page = page - 1;
    setPage(page);
    setFilter(false);
  };

  const handlerChangeData = (e) => {
    var value = e.target.value;
    var id = e.target.id;
    if (id === "clinics") setClinic(value);
    else setBatchId(value);
  };

  const handlerCreateReplacementOrders = async () => {
    props.setLoaderVisible(true);
    const result = await createOrdersReplacement(props.userToken);
    props.setLoaderVisible(false);
    if (result.status === 200) {
      showToast("success", t("replacementSuccess"));
    } else if (
      result.response.code === 404 &&
      result.response.response.code === "RO404"
    ) {
      showToast("error", t("replacementNotFound"));
    } else {
      showToast("error", t("replacementError"));
    }
  };

  const showToast = (type, content) => {
    if (toast) {
      if (type === "success") {
        toast.showSuccess(content);
      } else {
        toast.showError(content);
      }
    }
  };

  return {
    handleGetScannedProductBatch,
    products,
    page,
    handleChangePage,
    totalPage,
    handlerChangeRecordsPage,
    localRecords,
    setBatchId,
    batchId,
    handlerChangeData,
    handleGetScannedFilter,
    filter,
    handlerCreateReplacementOrders,
    setToast,
  };
};

export default ScannedProductBatchControllers;
