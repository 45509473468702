import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import ordersStyle from "assets/jss/material-dashboard-pro-react/views/ordersStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";

import "bootstrap/dist/css/bootstrap.min.css";

function OrderSteps(props) {
  const { orderId, classes, status } = props;
  const { t } = useTranslation("userView");

  return (
    <>
      <h1 className={classes.title}>{t("Order Details")}</h1>
      <Grid container xs={12}>
        <Grid xs={3} className={classes.container}>
          <div className={status === "1" ? classes.stepsActive : classes.steps}>
            <h1>1</h1>
          </div>
          <p>{t("order created")}</p>
        </Grid>
        <Grid xs={3} className={classes.container}>
          <div className={status === "2" ? classes.stepsActive : classes.steps}>
            <h1>2</h1>
          </div>
          <p>
            {t("Order Received")}
            <br />
            {t("carrier")}
          </p>
        </Grid>
        <Grid xs={3} className={classes.container}>
          <div className={status === "3" ? classes.stepsActive : classes.steps}>
            <h1>3</h1>
          </div>
          <p>{t("Delivered")}</p>
        </Grid>
        <Grid xs={3} className={classes.container}>
          <div className={status === "4" ? classes.stepsActive : classes.steps}>
            <h1>4</h1>
          </div>
          <p>
            {t("Order Received")}
            <br />
            {t("clinic")}
          </p>
        </Grid>
      </Grid>
    </>
  );
}

OrderSteps.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(ordersStyle)(OrderSteps);
