import React, { useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ErrorIcon from "@mui/icons-material/Error";
import Batchitem from "./BatchItem";

const useStyles = makeStyles((theme) => ({
  Line: {
    width: "95%",
    height: "10px",
  },
  returnitem: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    "& h8": {
      fontSize: "100%",
    },
    "& h6": {
      fontSize: "90%",
    },
    // backgroundColor: "yellow",
  },
  selectreason: {
    marginTop: "20px",
    paddingRight: "20px",
    paddingLeft: "20px",
  },
  selectwarehouse: {
    marginTop: "20px",
    paddingRight: "20px",
    paddingLeft: "20px",
  },
  selectypedevolution: {
    marginTop: "20px",
    paddingRight: "20px",
    paddingLeft: "20px",
  },
  container: {
    paddingRight: "10px",
    // backgroundColor: "blue",
  },
  currentInvErr: {
    color: "red",
  },
  batchs: {
    marginTop: "10px",
  },
  expirationDate: {
    color: "red",
  },
  txt: {
    fontSize: "100%",
    wordWrap: "break-word",
  },
}));

const TransfersRequestbody = (props) => {
  const {
    shiptosorskus,
    id,
    handleAddSkuShipto,
    handleChangeQty,
    index,
    request,
    locked,
  } = props;
  /*   const [detail, setDetail] = useState(false);
  const [warehouse, setWarehouse] = useState(false);
  const [typedevolution, setTypedevolution] = useState(false);
  const [citywarehouse, setCitywarehouse] = useState(""); */
  // const inv = parseInt(shiptosorskus.current_inv);
  const opt = parseInt(shiptosorskus.optimum_national);
  const classes = useStyles();

  return (
    <Grid container xs={12} key={index} className={classes.container}>
      <Grid xs={12} className={classes.returnitem}>
        <Grid xs={3} className={classes.txt}>
          {request == 1 ? (
            <h8>
              {shiptosorskus.shipto} {shiptosorskus.cust_name}
            </h8>
          ) : (
            <h8>
              {" "}
              {shiptosorskus.model} {shiptosorskus.material_description}
            </h8>
          )}
        </Grid>
        <Grid xs={2} className={classes.txt}>
          <b>
            <h8>{shiptosorskus.optimum_national}</h8>
          </b>{" "}
          <br />
          <h6>Inventario óptimo</h6>
        </Grid>

        <Grid xs={7} className={classes.txt}>
          {shiptosorskus.batchs.map((item, id) => (
            <Batchitem
              item={item}
              id={id}
              handleChangeQty={handleChangeQty}
              handleAddSkuShipto={handleAddSkuShipto}
              shiptosorskus={shiptosorskus}
              opt={opt}
              locked={locked}
              request={request}
            />
          ))}
        </Grid>
      </Grid>

      <hr className={classes.Line} />
    </Grid>
  );
};
export default TransfersRequestbody;
