import L from "leaflet";

export const IconLocationManager = L.icon({
  iconUrl: require("assets/img/location/user.png"),
  iconRetinaUrl: require("assets/img/location/user.png"),
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [35, 35],
  className: "Leaflet-venue-icon",
});

export const IconLocationClinic = L.icon({
  iconUrl: require("assets/img/location/hospital.png"),
  iconRetinaUrl: require("assets/img/location/hospital.png"),
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [35, 35],
  className: "Leaflet-venue-icon",
});

export const IconLocation = L.icon({
  iconUrl: require("assets/img/location/location-pin-4063.svg"),
  iconRetinaUrl: require("assets/img/location/location-pin-4063.svg"),
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [35, 35],
  className: "Leaflet-venue-icon",
});

export const IconLocationMainManager = L.icon({
  iconUrl: require("assets/img/location/MainManager.png"),
  iconRetinaUrl: require("assets/img/location/MainManager.png"),
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [35, 35],
  className: "Leaflet-venue-icon",
});

export const IconLocationCarrier = L.icon({
  iconUrl: require("assets/img/location/lorry.png"),
  iconRetinaUrl: require("assets/img/location/lorry.png"),
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [35, 35],
  className: "Leaflet-venue-icon",
});

export const IconLocationWarehouse = L.icon({
  iconUrl: require("assets/img/location/warehouse.png"),
  iconRetinaUrl: require("assets/img/location/warehouse.png"),
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [35, 35],
  className: "Leaflet-venue-icon",
});
