import React, { useEffect } from "react";
import { Grid, makeStyles, withStyles, Box } from "@material-ui/core";
import Order from "components/OrderAuthorization/index";
import ReturnRequestController from "controllers/ReturnRequest";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Toast from "components/Toast/Toast";
const useStyles = makeStyles((theme) => ({
  Orders: {
    width: "100%",
    //height: "500px",
    marginTop: "10px",
    padding: "10px",
    // backgroundColor: "red",
  },
  tittlePendingOrders: {
    fontSize: "14px",
  },
  containerView: {
    padding: 20,
  },
}));

function PendingReturnOrders(props) {
  let history = true;
  const { pendingOrders, handlegetPendingOrders } = ReturnRequestController(
    props
  );

  useEffect(async () => {
    await handlegetPendingOrders(props.userToken);
  }, []);
  const classes = useStyles();

  return (
    <div className={classes.containerView}>
      <h5 className={classes.tittlePendingOrders}>
        <b>Historial de retornos pendientes</b>{" "}
      </h5>

      <Grid container xs={12} className={classes.Orders}>
        {pendingOrders?.map((order, id) => (
          <Grid xs={12}>
            <Order
              order={order}
              id={id}
              history={history}
              returnOrder
              pending
            />
          </Grid>
        ))}
      </Grid>
      {/*  <Toast
        innerRef={(ref) => {
          setToast(ref);
        }}
      /> */}
    </div>
  );
}
export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
    data: state.root.general.dataUserAPI,
    applicant: state.root.user.userInfo.firstName,
    id: state.root.user.userInfo.memberId,
    address: state.root.user.userInfo.address,
    contactNo: state.root.user.userInfo.contactNo,
  }))(PendingReturnOrders)
);
