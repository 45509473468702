import React, { useEffect } from "react";
import { Grid, makeStyles, withStyles, Box } from "@material-ui/core";
import { Pagination } from "@mui/material";
import Order from "components/OrderAuthorization/index";
import TransfersRequestController from "controllers/TransfersRequest";
import { OrdersAuthorizationController } from "controllers/OrdersAutorizathion";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Toast from "components/Toast/Toast";
import TextField from "@mui/material/TextField";
import UpdatePdf from "../../components/UpdatePdf";
// import jwtDecode from "jwt-decode";

import ExportExcel from "react-export-excel";
import { Button } from "@mui/material";

const ExcelFile = ExportExcel.ExcelFile;
const ExcelSheet = ExportExcel.ExcelSheet;
const ExcelColumn = ExportExcel.ExcelColumn;
const useStyles = makeStyles((theme) => ({
  Orders: {
    width: "100%",
    //height: "500px",
    marginTop: "10px",
    padding: "10px",
    // backgroundColor: "red",
  },
  tittlePendingOrders: {
    fontSize: "14px",
  },
  containerView: {
    padding: 20,
  },
  "@media (max-Width: 960px)": {
    tittlePendingOrders: {
      fontSize: "1.8vh",
    },
    shearch: {
      marginLeft: "10%",
    },
  },
}));

function Transfershistory(props) {
  // var jwt = jwtDecode(props.userToken);
  let history = true;
  const {
    transfersHistory,
    handlegetTransfersHistory,
    handleChange,
    orderId,
    handlegetXmlData,
    xmlData,
    handleGenerateTemplate,
    template,
    setToast,
    handleUpdateTransfers,
    jwtToken,
    disable,
    setDisable,
    selectedPDF,
    setSelectedPDF,
    handleSubmitPdf,
    downloadPdf,
    pageTransferHistory,
    setPageTransferHistory,
    numberPageTransferHistory,
  } = TransfersRequestController(props);

  const handleChangePage = (pageTransferHistory) => {
    setPageTransferHistory(pageTransferHistory - 1);
  };

  useEffect(
    async () => {
      await handlegetTransfersHistory(props.userToken);
    },
    [orderId]
  );
  useEffect(async () => {
    await handlegetXmlData(props.userToken);
    await handleGenerateTemplate();
  }, []);
  const classes = useStyles();

  return (
    <div className={classes.containerView}>
      <Grid xs={12} container>
        <Grid xs={8}>
          <h5 className={classes.tittlePendingOrders}>
            <b>Historial de traslados</b>{" "}
          </h5>
        </Grid>
        <Grid xs={2}>
          <ExcelFile
            element={
              <Button
                variant="contained"
                sx={{
                  "@media (max-Width: 960px)": {
                    width: 40,
                    height: 40,
                    fontSize: "1.2vh",
                  },
                }}
              >
                Exportar a Excel
                {/*    <a href={template} target="_blank">
                </a> */}
              </Button>
            }
            filename="Historial de Aprobaciones"
          >
            {/*     <ExcelSheet data={xmlData} name="Historial de Aprobaciones">
              <ExcelColumn label="Sales org" value="salesOrg" />
              <ExcelColumn label="Order Type" value="orderType" />
              <ExcelColumn label="PO Category" value="" />
              <ExcelColumn label="Sold-to" value="soldTo" />
              <ExcelColumn label="Ship-to" value="shipTo" />
              <ExcelColumn label="Bill-to" value="billTo" />
              <ExcelColumn label="Payer" value="" />
              <ExcelColumn label="Deliver to" value="" />
              <ExcelColumn label="PO#" value="orderId" />
              <ExcelColumn label="PO Date" value="po" />
              <ExcelColumn label="Price Date" value="" />
              <ExcelColumn label="Buying Group Partner" value="" />
              <ExcelColumn label="Header external remarks" value="" />
              <ExcelColumn label="Header internal remarks" value="" />
              <ExcelColumn label="Customer shipping instructions" value="" />
              <ExcelColumn label="Date of service" value="" />
              <ExcelColumn label="Requested Delivery date" value="" />
              <ExcelColumn label="d" value="" />
              <ExcelColumn label="d" value="" />
              <ExcelColumn label="Order reason" value="orderReason" />
              <ExcelColumn label="Delivery Block" value="" />
              <ExcelColumn label="Billing Block" value="" />
              <ExcelColumn label="Delivery priority" value="" />
              <ExcelColumn label="Consi fillup Req Del Date" value="" />
              <ExcelColumn label="Material" value="material" />
              <ExcelColumn label="Customer Order Item" value="" />

              <ExcelColumn label="Special Stocking Partner" value="" />
              <ExcelColumn label="Benefitting Rep" value="" />
              <ExcelColumn label="Batch#" value="batch" />
              <ExcelColumn label="Quantity" value="quantity" />
              <ExcelColumn label="UOM" value="" />
              <ExcelColumn label="Plant" value="plant" />
              <ExcelColumn label="Serial #" value="serial" />
              <ExcelColumn label="DDT Text" value="" />
              <ExcelColumn label="Bundle Group" value="" />
              <ExcelColumn label="Bundle Line Type" value="" />
              <ExcelColumn label="Item Category" value="" />
              <ExcelColumn label="Manual Price" value="" />
              <ExcelColumn label="Customer Group 1" value="" />
              <ExcelColumn label="R/3 (ECC) Invoice Number" value="" />
              <ExcelColumn label="R/3 (ECC) Invoice Item" value="" />
            </ExcelSheet>      */}
            <ExcelSheet data={xmlData} name="FIT Item Upload">
              <ExcelColumn label="Material" value="material" />
              <ExcelColumn label="Serial" value="serial" />
              <ExcelColumn label="Batch" value="batch" />
              <ExcelColumn label="Quantity" value="quantity" />
              <ExcelColumn label="UOM" value="" />
            </ExcelSheet>
          </ExcelFile>
        </Grid>
        <Grid xs={2} className={classes.shearch}>
          <TextField
            id="filled-search"
            label="Busqueda por ID"
            type="search"
            variant="filled"
            onChange={handleChange}
            sx={{
              "@media (max-Width: 960px)": {
                width: 100,
                height: 60,
                fontSize: "0.5vh",
              },
            }}
          />
        </Grid>
      </Grid>

      <Grid container xs={12} className={classes.Orders}>
        {transfersHistory?.map((order, id) => (
          <Grid xs={12} key={id}>
            <Order
              order={order}
              id={id}
              history={history}
              finance
              quality
              transfer
              superuser={jwtToken.groupSlug === "super-user" ? true : null}
              accountManager={
                jwtToken.groupSlug === "Account-manager" ? true : null
              }
              handleUpdateOrder={handleUpdateTransfers}
              setDisable={setDisable}
              disable={disable}
              selectedPDF={selectedPDF}
              setSelectedPDF={setSelectedPDF}
              handleSubmitPdf={handleSubmitPdf}
              downloadPdf={downloadPdf}
              UpdatePdf={UpdatePdf}
            />
          </Grid>
        ))}
      </Grid>
      {transfersHistory && (
        <Pagination
          hideNextButton={true}
          hidePrevButton={true}
          onChange={(e) => handleChangePage(e.target.textContent)}
          count={
            parseInt(numberPageTransferHistory) < 10
              ? 1
              : parseInt(numberPageTransferHistory) % 10 != 0
                ? parseInt(numberPageTransferHistory / 10) + 1
                : parseInt(numberPageTransferHistory / 10)
          }
          page={pageTransferHistory + 1}
        />
      )}
      <Toast
        innerRef={(ref) => {
          setToast(ref);
        }}
      />
    </div>
  );
}
export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
    data: state.root.general.dataUserAPI,
    applicant: state.root.user.userInfo.firstName,
    id: state.root.user.userInfo.memberId,
    address: state.root.user.userInfo.address,
    contactNo: state.root.user.userInfo.contactNo,
  }))(Transfershistory)
);
