// ##############################
// // // LoginPage view styles
// #############################

import {
  container,
  cardTitle,
} from "assets/jss/material-dashboard-pro-react.jsx";

const loginPageStyle = (theme) => ({
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px",
    },
  },
  cardTitle: {
    ...cardTitle,
    color: "#FFFFFF",
  },
  textCenter: {
    textAlign: "center",
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: "#FFFFFF",
    },
    marginLeft: "5px",
    marginRight: "5px",
  },
  inputAdornment: {
    marginRight: "18px",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)",
  },
  cardHeader: {
    marginBottom: "20px",
  },
  socialLine: {
    padding: "0.9375rem 0",
  },
  containerCode: {
    zIndex: "999",
    background: "rgba(0,0,0,0.52)",
    width: "100%",
    height: "100vh",
    top: 0,
    left: 0,
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  containerInside: {
    width: "80%",
  },
  infoAuth: {
    fontWeight: "bold",
  },
  containerAuth: {
    lineHeight: "15px",
    marginTop: "10px",
  },
});

export default loginPageStyle;
