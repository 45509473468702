import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

//Import Style
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-dashboard-pro-react/components/modalStyle";

const Modal = ({ classes, ...rest }) => {
  const modalRef = useRef(null);
  const modalOutRef = useRef(null);
  //console.log("x1", rest.code);
  useEffect(() => {
    function handler(e) {
      if (modalRef.current?.contains(e.target)) {
        if (!modalOutRef.current?.contains(e.target)) {
          rest.setModal(false);
        }
      }
    }
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, []);

  return (
    <div className={classes.containerModal} ref={modalRef}>
      <div className={classes.containerInside} ref={modalOutRef}>
        {rest.type === "QR" ? <img src={rest.code} alt="QR code" /> : null}
      </div>
    </div>
  );
};

Modal.prototype = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(modalStyle)(Modal);
