import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@material-ui/core/styles";

import OrdersRequestController from "../../controllers/OrdersRequest/index.js";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Toast from "components/Toast/Toast.jsx";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  searchInput: {
    marginLeft: theme.spacing(1),
  },
  detailButton: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  modalTitle: {
    marginBottom: theme.spacing(2),
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  productContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
    backgroundColor: "#f5f5f5",
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
  },
  attachmentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  attachment: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  dialogPaper: {
    height: "400px",
    minWidth: "500px",
    maxWidth: "800px",
    margin: "auto",
  },
}));

function AccountManagerRequests(props) {
  const {
    handleGetClientUnlockRequest,
    clientUnlockRequest,
    handleUpdateUnlockOrder,
    handleDeleteDocumentById,
    setToast,
    showToast,
    handleGetDocumentsById,
  } = OrdersRequestController(props);
  const classes = useStyles();

  const [selectedRequest, setSelectedRequest] = useState(null);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [pdfList, setPdfList] = useState([]);
  const [newPdfList, setNewPdfList] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  useEffect(
    () => {
      const fetchData = async () => {
        await handleGetClientUnlockRequest(props.userToken, null, null, true);
      };

      if (props.userToken) {
        fetchData();
      }
    },
    [props.userToken]
  );

  const handleClickOpen = (request) => {
    setSelectedRequest(request);
    setPdfList(request.documents || []);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRequest(null);
    setPdfList([]);
  };

  const handleSave = () => {
    handleUpdateUnlockOrder({
      observationUnlock: selectedRequest.observationUnlock,
      pdfList,
      newPdfList,
      selectedRequest,
      isTransfer: selectedRequest.receiver ? true : null,
    });
    handleClose();
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredResults = clientUnlockRequest.filter(
    (request) =>
      request.statusName === "Pendiente de aprobación" ||
      request.statusName === "Solicitar más información"
  );

  const filteredRequests = filteredResults.filter((request) =>
    request.order_id.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handlePDFChange = (event) => {
    const files = event.target.files;
    const maxFiles = 5;

    if (pdfList.length + files.length > maxFiles) {
      alert(`Solo se permite subir un máximo de ${maxFiles} archivos.`);
      return;
    }

    for (let i = 0; i < files.length; i++) {
      const selectedFile = files[i];
      const fileNameParts = selectedFile.name.split(".");
      const fileExtension = fileNameParts[
        fileNameParts.length - 1
      ].toLowerCase();

      if (
        fileExtension === "pdf" ||
        fileExtension === "jpg" ||
        fileExtension === "jpeg" ||
        fileExtension === "png"
      ) {
        if (selectedFile.size <= 4.49 * 1024 * 1024) {
          setPdfList([...pdfList, selectedFile]);
          setNewPdfList([...newPdfList, selectedFile]);
        } else {
          alert(
            "El archivo seleccionado es demasiado grande. Por favor, seleccione un archivo PDF o una imagen de 4.49MB o menos."
          );
        }
      } else {
        alert("Por favor, seleccione un archivo PDF, JPG o PNG.");
      }
    }
    if (pdfList.length + files.length >= maxFiles) {
      setIsButtonDisabled(true);
    }
  };

  const handleRemovePDF = (index) => {
    setPdfList(pdfList.filter((_, i) => i !== index));
    setIsButtonDisabled(false);
  };

  const formatWithCommas = (value) => {
    return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <Grid className={classes.container}>
      <Typography variant="h4" className={classes.title}>
        Gestión de solicitudes de desbloqueo por cartera - Account Manager
      </Typography>
      <div className={classes.searchContainer}>
        <SearchIcon />
        <TextField
          className={classes.searchInput}
          placeholder="Buscar..."
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Solicitud ID</TableCell>
              <TableCell>Shipto</TableCell>
              <TableCell>División</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Fecha Solicitud</TableCell>
              <TableCell>Observaciones</TableCell>
              <TableCell>Tipo de solicitud</TableCell>
              <TableCell>Detalle</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRequests.map((request, id) => (
              <TableRow key={id}>
                <TableCell>{request.order_id}</TableCell>
                <TableCell>{request.skus[0].name_shipto}</TableCell>
                <TableCell>{request?.div}</TableCell>
                <TableCell>{request.statusName}</TableCell>
                <TableCell>{request.creation_date.split("T")[0]}</TableCell>
                <TableCell>{request.coordinatorObservation}</TableCell>
                <TableCell>
                  {request.receiver ? "Traslado" : "Pedido"}
                </TableCell>

                <TableCell>
                  <Typography
                    className={classes.detailButton}
                    onClick={() => handleClickOpen(request)}
                  >
                    Ver detalle
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ className: classes.dialogPaper }}
      >
        <DialogTitle className={classes.modalTitle}>
          Detalle de Solicitud
        </DialogTitle>
        <DialogContent className={classes.modalContent}>
          <Typography variant="subtitle1">
            <strong>Shipto:</strong> {selectedRequest?.skus[0].name_shipto}
          </Typography>
          <Typography variant="subtitle2" color="error">
            <strong>Cliente bloqueado por cartera: </strong>
          </Typography>
          <Typography>Solicitud desbloqueo temporal:</Typography>
          <Typography variant="subtitle2">
            <strong>Observación del Coordinador de Cartera:</strong>
          </Typography>
          <Typography
            variant="body1"
            style={{
              backgroundColor: "#f5f5f5",
              padding: "8px",
              borderRadius: "4px",
            }}
            helperText={`${
              selectedRequest?.coordinatorObservation?.length
            }/${250}`}
            inputProps={{ maxLength: 250 }}
          >
            {selectedRequest?.coordinatorObservation}
          </Typography>
          <Typography variant="subtitle2">
            <strong>Observación del Account Manager:</strong>
          </Typography>
          {selectedRequest?.statusName === "Solicitar más información" ? (
            <TextField
              fullWidth
              value={selectedRequest?.observationUnlock || ""}
              onChange={(e) =>
                setSelectedRequest({
                  ...selectedRequest,
                  observationUnlock: e.target.value,
                })
              }
              variant="outlined"
              multiline
              rows={3}
              placeholder="Ingrese sus observaciones"
              helperText={`${
                selectedRequest?.observationUnlock?.length
              }/${250}`}
              inputProps={{ maxLength: 250 }}
            />
          ) : (
            <Typography
              variant="body1"
              style={{
                backgroundColor: "#f5f5f5",
                padding: "8px",
                borderRadius: "4px",
              }}
            >
              {selectedRequest?.observationUnlock}
            </Typography>
          )}
          <Typography variant="subtitle2">
            <strong>Productos solicitados:</strong>
          </Typography>
          {selectedRequest?.skus?.map((product, index) => (
            <div key={index} className={classes.productContainer}>
              <Typography>{product.desc}</Typography>
              <Typography>{product.quantity}</Typography>
            </div>
          ))}
          <Typography variant="subtitle2">
            <strong>Valor estimado:</strong>
          </Typography>
          <Typography
            variant="body1"
            style={{
              backgroundColor: "#f5f5f5",
              padding: "8px",
              borderRadius: "4px",
            }}
          >
            $ {formatWithCommas(selectedRequest?.estimatedValue)}
          </Typography>
          <Typography variant="subtitle2">
            <strong>Adjuntar soportes:</strong>
          </Typography>
          <div className={classes.attachmentContainer}>
            {pdfList.map((pdf, index) => (
              <div key={index} className={classes.attachment}>
                <AttachFileIcon />
                <Typography
                  onClick={() =>
                    handleGetDocumentsById(
                      pdf.document_id,
                      pdf.name || pdf.fileName
                    )
                  }
                  key={index}
                  className={classes.dialogTypography}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "#3f51b5",
                  }}
                >
                  {pdf.name || pdf.fileName}
                </Typography>
                {selectedRequest?.statusName ===
                  "Solicitar más información" && (
                  <IconButton
                    key={index}
                    onClick={() => {
                      handleRemovePDF(index);
                      if (pdf.document_id) {
                        handleDeleteDocumentById(
                          props.userToken,
                          pdf.document_id
                        );
                      }
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </div>
            ))}
          </div>
          {selectedRequest?.statusName === "Solicitar más información" && (
            <Button
              variant="contained"
              component="label"
              color="primary"
              disabled={isButtonDisabled}
            >
              Adjuntar soportes
              <input type="file" hidden multiple onChange={handlePDFChange} />
            </Button>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Volver
          </Button>
          {selectedRequest?.statusName === "Solicitar más información" && (
            <Button onClick={handleSave} color="primary">
              Reenviar
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Toast
        innerRef={(ref) => {
          setToast(ref);
        }}
      />
    </Grid>
  );
}

export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
    data: state.root.general.dataUserAPI,
    applicant: state.root.user.userInfo.firstName,
    id: state.root.user.userInfo.memberId,
    address: state.root.user.userInfo.address,
    contactNo: state.root.user.userInfo.contactNo,
  }))(AccountManagerRequests)
);
