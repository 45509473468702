import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import { Input } from 'antd';
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";

function CustomInput({ ...props }) {
  const {    
    placeHolder,
    onChange
  } = props;

  return (
    <Input placeholder={placeHolder ? placeHolder : "Basic usage"} onChange={onChange} style={{width: "100%", height: 40, backgroundColor: "transparent", border: "1px solid #DFDFDF", borderRadius: 5}}/>
  );
}

CustomInput.propTypes = {
  classes: PropTypes.object.isRequired, 
};

export default withStyles(customInputStyle)(CustomInput);
