/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@mui/material/Grid";
import myProfileStyle from "assets/jss/material-dashboard-pro-react/components/myProfileStyle";
import InputForm from "components/CustomInput/InputForm";
import PlacesAutoComplete from "components/GoogleMap/PlaceAutoComplete";
import Button from "components/CustomButtons/Button";

//Translation
import { useTranslation } from 'react-i18next';

function MyProfileView(props) {
  var {
    title,
    classes,
    data,
    handlerChangeData,
    handleChangeAddress,
    handlerUpdateUser,
    password,
    dataPassword,
    handlerChangePassword,
    handlerResetPassword,
  } = props;

  const { t } = useTranslation("userForm")

  return (
    <div className={classes.container}>
      <Grid className={classes.container_title}>
        <h3>{t(title)}</h3>
      </Grid>
      <Grid item className={classes.container_form}>
        <Grid continer xs={password ? 6 : 12}>
          <Grid className={classes.container_form_header} xs={12}>
            <h5>{password ? t("Change Password") : t("My Profile")}</h5>
          </Grid>
          {password ? (
            <Grid
              container
              item
              xs={12}
              className={password ? classes.container_reseat_password : null}
            >
              <Grid item xs={12}>
                <p>{t("Current Password")}</p>
                <InputForm
                  error={data["currentPassword"] ? false : true}
                  handlerChangeData={handlerChangePassword}
                  value={dataPassword.currentPassword}
                  id="currentPassword"
                />
              </Grid>
              <Grid item xs={12}>
                <p>{t("New Password")}</p>
                <InputForm
                  error={data["password"] ? false : true}
                  handlerChangeData={handlerChangePassword}
                  value={dataPassword.password}
                  id="password"
                />
              </Grid>
              <Grid item xs={12}>
                <p>{t("Repeat New Password")}</p>
                <InputForm
                  error={data["repeatPassword"] ? false : true}
                  handlerChangeData={handlerChangePassword}
                  value={dataPassword.repeatPassword}
                  id="repeatPassword"
                />
              </Grid>
              <Grid item xs={12} className={classes.container_btn}>
                <Button
                  className={classes.btn_submit}
                  iconSubmit
                  onClick={handlerResetPassword}
                >
                  {password ? t("Submit") : t("Update Profile")}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              item
              spacing={2}
              className={classes.container_form_inputs}
            >
              <Grid item xs={12} sm={6}>
                <p>{t("First Name")}</p>
                <InputForm
                  error={data["firstName"] ? false : true}
                  handlerChangeData={handlerChangeData}
                  value={data.firstName}
                  id="firstName"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <p>{t("Last Name")}</p>
                <InputForm
                  error={data["lastName"] ? false : true}
                  handlerChangeData={handlerChangeData}
                  value={data.lastName}
                  id="lastName"
                />
              </Grid>
              <Grid item xs={12}>
                <h5>{t("Address Fields")}</h5>
                <div className={classes.line} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <p>{t("Company Name")}</p>
                <InputForm
                  error={data["companyName"] ? false : true}
                  value={data.companyName}
                  handlerChangeData={handlerChangeData}
                  id="companyName"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <p>{t("Contact No.")}</p>
                <InputForm
                  number
                  error={data["contactNo"] ? false : true}
                  value={data.contactNo}
                  handlerChangeData={handlerChangeData}
                  id="contactNo"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <p>{t("Fax No.")}</p>
                <InputForm
                  optional
                  value={data.fax}
                  handlerChangeData={handlerChangeData}
                  id="fax"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <p>{t("Address")}</p>
                <PlacesAutoComplete
                  error={data["address"] ? false : true}
                  value={data.address}
                  onChange={handleChangeAddress}
                  id="address"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <p>{t("City")}</p>
                <InputForm
                  disabled
                  error={data["city"] ? false : true}
                  value={data.city}
                  handlerChangeData={handlerChangeData}
                  id="city"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <p>{t("State")}</p>
                <InputForm
                  disabled
                  error={data["state"] ? false : true}
                  value={data.state}
                  handlerChangeData={handlerChangeData}
                  id="state"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <p>{t("Country")}</p>
                <InputForm
                  disabled
                  error={data["country"] ? false : true}
                  value={data.country}
                  handlerChangeData={handlerChangeData}
                  id="country"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <p>{t("Zip/Postal Code")}</p>
                <InputForm
                  disabled
                  error={data["zipCode"] ? false : true}
                  value={data.zipCode}
                  handlerChangeData={handlerChangeData}
                  id="zipCode"
                />
              </Grid>
              <Grid item xs={12}>
                <p>{t("Other Address Details")}</p>
                <InputForm
                  error={data["addressDetails"] ? false : true}
                  area
                  value={data.addressDetails}
                  handlerChangeData={handlerChangeData}
                  id="addressDetails"
                />
              </Grid>
            </Grid>
          )}
          {!password ? (
            <Grid item xs={12} className={classes.container_btn}>
              <Button
                className={classes.btn_submit}
                iconSubmit
                onClick={handlerUpdateUser}
              >
                {password ? t("Submit") : t("Update Profile")}
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
}

MyProfileView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(myProfileStyle)(MyProfileView);
