import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Button, Pagination } from "@mui/material";
import withStyles from "@material-ui/core/styles/withStyles";
import StepShiptoStyles from "assets/jss/material-dashboard-pro-react/views/StepShiptoStyles";
import InputLabel from "@mui/material/InputLabel";
import NativeSelect from "@mui/material/NativeSelect";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Loader from "components/Loader/Loader";

import ShiptoItem from "./ShiptoItem";

/**
 * This is a React component that renders a form for selecting a shipping destination and order type,
 * and displays a table of previously saved shipping destinations with options to add or delete them.
 * @returns The component `ShipTo` is being returned.
 */
const ShipTo = ({
  classes,
  shipto,
  // OpenCloseModal,
  auxShipto,
  setAuxShipto,
  setOrderType,
  page,
  setPage,
  numberPage,
  allshipto,
  setShearchShipto,
  shearchShipto,
  handleAddShipto,
  handleDeleteShipto,
  auxbuttonsId,
  handleGetMaterialGroup,
  token,
}) => {
  const OrderTypes = ["Consignación", "Procedimiento", "Venta directa"];

  /**
   * The function `handleChangePage` sets the current page to the value passed as an argument.
   * @param page - The `page` parameter is a variable that represents the page number that the user wants
   * to navigate to. It is passed as an argument to the `handleChangePage` function. The function then
   * sets the `page` state variable to the new page number, triggering a re-render of the component with
   * the
   */
  const handleChangePage = (page) => {
    setPage(page);
  };
  /**
   * The function `handleChange` sets the `orderType` state based on the inner text of the target element
   * passed as an argument.
   * @param e - The parameter "e" is an event object that is passed to the function when an event occurs,
   * such as a user clicking on an element or typing into an input field. In this case, it is used to
   * capture the text content of the element that triggered the event, which is then used to
   */
  const handleChange = (e) => {
    setOrderType(e.target.innerText);
  };

  /* The `return` statement is returning a JSX code that renders a form for selecting a shipping
 destination and order type, and displays a table of previously saved shipping destinations with
 options to add or delete them. It also includes a pagination component for navigating through the
 table. The JSX code is wrapped in a `div` element and uses Material-UI components such as `Grid`,
 `Autocomplete`, `TextField`, `Pagination`, and a custom `ShiptoItem` component. The component also
 receives several props such as `classes`, `shipto`, `setAuxShipto`, `setOrderType`, `page`,
 `setPage`, `numberPage`, `allshipto`, `setShearchShipto`, `shearchShipto`, `handleAddShipto`,
 `handleDeleteShipto`, `auxbuttonsId`, `handleGetMaterialGroup`, and `token`. */
  return (
    <div>
      <Grid xs={12} className={classes.ordertype} container>
        <Autocomplete
          options={OrderTypes}
          onChange={handleChange}
          sx={{
            width: 200,
            "@media (max-Width: 960px)": {
              width: 100,
              height: 100,
            },
          }}
          renderInput={(params) => (
            <TextField {...params} label="Tipo de pedido" />
          )}
        />

        <Autocomplete
          options={allshipto}
          onChange={(e, newValue) => {
            setShearchShipto(newValue?.value);
          }}
          sx={{
            width: 200,
            "@media (max-Width: 960px)": {
              width: 100,
              height: 100,
            },
          }}
          renderInput={(params) => <TextField {...params} label="Buscador" />}
        />
      </Grid>
      <Grid xs={12} className={classes.tittle}>
        <h5>1.Enviar a</h5>
      </Grid>
      <Grid className={classes.container}>
        <Grid xs={12} className={classes.headertable}>
          <Grid xs={4}>
            <h1 className={classes.txtH}>Cliente</h1>
          </Grid>
          <Grid xs={2}>
            <h1 className={classes.txtH}>Ciudad</h1>
          </Grid>
          <Grid xs={2}>
            <h1 className={classes.txtH}>Ship To</h1>
          </Grid>
          <Grid xs={4} />
        </Grid>
        <hr className={classes.Line} /> <br />
        <Grid xs={12}>
          {shipto.map((shipto, id) => (
            <ShiptoItem
              id={id}
              shipto={shipto}
              handleDeleteShipto={handleDeleteShipto}
              handleAddShipto={handleAddShipto}
              auxbuttonsId={auxbuttonsId}
            />
          ))}
        </Grid>
        {/* `Pagination` is a Material-UI component that provides a pagination control for navigating
       through a list of items. In this code, it is being used to display a pagination control for
       the table of previously saved shipping destinations. */}
        <Pagination
          hideNextButton={true}
          hidePrevButton={true}
          onChange={(e) => handleChangePage(e.target.textContent)}
          count={
            parseInt(numberPage) < 7 ? numberPage : parseInt(numberPage / 7)
          }
        />
      </Grid>
    </div>
  );
};

export default withStyles(StepShiptoStyles)(ShipTo);
