import React, { useEffect } from "react";
import UserViews from "components/userViews";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ProductsControllers from "controllers/ProductControllers";
import empty from "../../../assets/img/dashboard/empty.svg";

function Products(props) {
  let {
    handleGetProducts,
    products,
    handleChangePage,
    page,
    totalPage,
    handlerChangeRecordsPage,
    localRecords,
    dataFilter,
    handlerChangeData,
    handleGetProductFilter,
    filter,
  } = ProductsControllers(props);
  const token = props.userToken;

  useEffect(
    async () => {
      if (products.length === 0) props.setLoaderVisible(true);
      //if (page !== 0 || (page === 0 && localRecords !== 10))
      await handleGetProducts({ token });
      if (products.length === 0) props.setLoaderVisible(false);
    },
    [page || localRecords]
  );

  const title = "My Products List";

  return (
    <>
      {props.data.products ? (
        <UserViews
          title={title}
          members={
            !filter
              ? page === 0 && localRecords === 10
                ? props.data.products.products
                : products
              : products
          }
          products={true}
          scannedBatch={false}
          handleChangePage={handleChangePage}
          handlerChangeRecordsPage={handlerChangeRecordsPage}
          totalPage={
            page === 0 && localRecords === 10
              ? Math.ceil(props.data.products.totalRecords / 10)
              : totalPage
          }
          dataFilter={dataFilter}
          mainManager
          handlerChangeData={handlerChangeData}
          handleGetProductFilter={handleGetProductFilter}
        />
      ) : (
        <img
          src={empty}
          alt="Not found data"
          style={{ height: "16rem", margin: "6rem 3rem" }}
        />
      )}
    </>
  );
}
export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
    data: state.root.general.dataUserAPI,
  }))(Products)
);
