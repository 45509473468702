import React, { useEffect } from "react";
import MyProfileView from "components/MyProfileView";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { MyProfileControllers } from "controllers/MyProfileControllers";
import Toast from "components/Toast/Toast.jsx";

function Profile(props) {
  const {
    data,
    handlerChangeData,
    handleChangeAddress,
    handlerChangeLatLng,
    handlerUpdateUser,
    setToast,
  } = MyProfileControllers(props);
  const title = "My Profile";
  const token = props.userToken;

  useEffect(
    () => {
      handlerChangeLatLng();
    },
    [data.lat && data.lng]
  );

  return (
    <div>
      <Toast
        innerRef={(ref) => {
          setToast(ref);
        }}
      />
      <MyProfileView
        title={title}
        data={data}
        handlerChangeData={handlerChangeData}
        handleChangeAddress={handleChangeAddress}
        handlerUpdateUser={handlerUpdateUser}
      />
    </div>
  );
}

export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
    userData: state.root.user.userInfo,
  }))(Profile)
);
