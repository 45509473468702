import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import stepOrderConfirmStyles from "assets/jss/material-dashboard-pro-react/components/stepOrderConfirmStyles";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import UpdatePdf from "../../../components/UpdatePdf";

/**
 * The Orderconfirm function is a React component that displays the details of an order and allows the
 * user to confirm the order with options for urgency and observation.
 * @returns A React component called "Orderconfirm" with some props and JSX elements inside. It is also
 * exporting the component with some styles applied to it.
 */
const Orderconfirm = ({
  classes,
  shipto,
  auxSkus,
  selectauxShipto,
  applicant,
  address,
  contactNo,
  handleChangeLifeUrgency,
  lifeUrgency,
  handleChangeObservation,
  observation,
  orderSkusByKits,
  handleDeleteOrderList,
  selectedPDF,
  setSelectedPDF,
}) => {
  const handlePDFChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileNameParts = selectedFile.name.split(".");
      const fileExtension = fileNameParts[
        fileNameParts.length - 1
      ].toLowerCase();

      // Verifica que la extensión sea PDF.
      if (fileExtension === "pdf") {
        // Verifica el tamaño del archivo (en bytes).
        if (selectedFile.size <= 4.49 * 1024 * 1024) {
          setSelectedPDF(selectedFile);
        } else {
          alert(
            "El archivo seleccionado es demasiado grande. Por favor, seleccione un archivo PDF de 4.49MB o menos."
          );
          setSelectedPDF(null);
        }
      } else {
        alert("Por favor, seleccione un archivo PDF.");
        setSelectedPDF(null);
      }
    }
  };

  /* The `return` statement is returning a JSX element that represents the UI of the `Orderconfirm`
component. It is using the `Grid` component from the Material-UI library to create a grid layout
with multiple rows and columns. Inside the grid, it is displaying the details of the order,
including the products being ordered, the shipping address, and the urgency and observation options.
It is also using the `Checkbox` and `TextField` components from the Material-UI library to allow the
user to select the urgency and add an observation to the order. */

  return (
    <Grid container xs={12}>
      <Grid xs={12} container>
        <Grid xs={12} className={classes.tittle}>
          <h1>3.Confirma el pedido</h1>
        </Grid>
        <Grid xs={12} className={classes.column1}>
          <h5 className={classes.tittleproducts}>Estas por enviar:</h5>
          <Grid className={classes.product}>
            {[...orderSkusByKits, ...auxSkus]?.map((auxSkus, id) => (
              <Grid className={classes.productName}>
                <FontAwesomeIcon
                  icon={faClose}
                  className={classes.icon_order}
                  style={{
                    color: "#14A2DC",
                    fontSize: "120%",
                    marginRight: "10px",
                  }}
                  onClick={() => handleDeleteOrderList(auxSkus.id)}
                />

                <b>
                  (x
                  {auxSkus.backorderQty
                    ? auxSkus.backorderQty
                    : auxSkus.quantity || auxSkus.amountTaken}
                  )
                </b>
                <h5 className={classes.txtproducts}>
                  {auxSkus.material_description}
                </h5>
                <h5 className={classes.txtproducts}>
                  ,tu pedido será enviado a:{" "}
                  {auxSkus.name ? auxSkus.name : auxSkus.shitoName}
                </h5>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={2}>
            <h5 className={classes.tittleproducts}>Urgencia vital:</h5>
          </Grid>
          <Grid item xs={10}>
            <Checkbox
              onChange={handleChangeLifeUrgency}
              checked={lifeUrgency}
            />
          </Grid>
        </Grid>
        <Grid container xs={12} className={classes.select}>
          <TextField
            id="standard-multiline-flexible"
            label="Observación"
            multiline
            inputProps={{ maxLength: 255 }}
            helperText={`${observation.length}/${255}`}
            maxRows={4}
            size="small"
            value={observation}
            onChange={handleChangeObservation}
            sx={{
              width: 500,
            }}
          />
        </Grid>
        <Grid xs={12} container>
          <UpdatePdf pdf={selectedPDF} onChange={handlePDFChange} />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default withStyles(stepOrderConfirmStyles)(Orderconfirm);
