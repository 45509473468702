const modalStyle = () => ({
    containerModal: {
        background: "rgba(0,0,0,0.52)",
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: "200",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden"
    },
    containerInside: {
        width: "auto",
        background: "#c9c9c9",
        height: "auto",
    }
})

export default modalStyle;