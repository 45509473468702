import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from 'react-redux'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import MenuIcon from '@mui/icons-material/Menu';

// core components
import Button from "components/CustomButtons/Button";
//import logo from "assets/img/Logo.png";

import pagesRoutes from "routes/pages.jsx";

import pagesHeaderStyle from "assets/jss/material-dashboard-pro-react/components/pagesHeaderStyle.jsx";

class PagesHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  handleDrawerToggle = () => {
    this.setState({ open: !this.state.open });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.setState({ open: false });
    }
  }

  languageOption = (op) => {
    if(this.props.language.language){
      if(op === "Home"){
        return "Inicio"
      }else if(op === "Login"){
        return "Ingresar"
      }
    }else{
      return op
    }

  }

  render() {
    const { classes, color } = this.props;
    const appBarClasses = cx({
      [" " + classes[color]]: color
    });

    var list = (
      <List className={classes.list}>
        {/* <ListItem className={classes.listItem}>
          <NavLink to={"/dashboard"} className={classes.navLink}>
            <ListItemIcon className={classes.listItemIcon}>
              <Dashboard />
            </ListItemIcon>
            <ListItemText
              primary={"Welcome"}
              disableTypography={true}
              className={classes.listItemText}
            />
          </NavLink>
        </ListItem> */}
        {pagesRoutes.map((prop, key) => {
          if (prop.redirect || prop.hide) {
            return null;
          }
          const navLink =
            classes.navLink +
            cx({
              [" " + classes.navLinkActive]: this.activeRoute(prop.path)
            });
          return (
            <ListItem key={key} className={classes.listItem}>
              <NavLink to={prop.path} className={navLink}>
                <ListItemIcon className={classes.listItemIcon} style={{minWidth: 'auto'}}>
                  <prop.icon />
                </ListItemIcon>
                <ListItemText
                  primary={this.languageOption(prop.short)}
                  disableTypography={true}
                  className={classes.listItemText}
                />
              </NavLink>
            </ListItem>
          );
        })}
      </List>
    );
    return (
      <AppBar position="fixed" className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container}>
          <Hidden smDown>
            <div className={classes.flex}>
            <a onClick={e => e.preventDefault()}>
                 <div className={classes.image}>
                   {/*<img src={logo} alt="..." style={{position: "relative",  height: "26vh", paddingTop: "2vh"}}/>*/}
                 </div>
            </a>
            </div>
          </Hidden>
          <Hidden mdUp>
            <div className={classes.flex}>
              <a onClick={e => e.preventDefault()}>
                <div className={classes.image} >
                  {/*<img src={logo} alt="..." style={{position: "relative",  height: "20vh", paddingTop: "2vh"}}/>*/}
                </div>
              </a>
            </div>
          </Hidden>
          <Hidden smDown>{list}</Hidden>
          <Hidden mdUp>
            <Button
              size="large"
              className={classes.sidebarButton}
              color="transparent"
              aria-label="open drawer"
              onClick={this.handleDrawerToggle}
            >
                <MenuIcon style={{transform: "scale(2)"}} />
            </Button>
          </Hidden>
          <Hidden mdUp>
            <Hidden mdUp>
              <Drawer
                variant="temporary"
                anchor={"right"}
                open={this.state.open}
                classes={{
                  paper: classes.drawerPaper
                }}
                onClose={this.handleDrawerToggle}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
              >
                  {list}
              </Drawer>
            </Hidden>
          </Hidden>
        </Toolbar>
      </AppBar>
    );
  }
}

PagesHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"])
};

export default withRouter(connect(
    (state) => ({
      language: state.root.general.change_language
    })
)(withStyles(pagesHeaderStyle)(PagesHeader)))
