import React, { useState } from "react";
import { getClinicPlaces } from "protocols/requests";

const ClinicPlacesControllers = () => {
const [products, setProducts] = useState([]);
const [page, setPage] = useState(0);
const [totalPage, setTotalPage] = useState(10);
const [localRecords, setLocalRecords] = useState(10);
const [dataFilter, setDataFilter] = useState([
    {
        name: "UPN",
        value: "UPN",
    },
    {
        name: "GTIN",
        value: "GTIN",
    }
]);

const handleGetClinicPlaces = async (data) => {
    const result = await getClinicPlaces(data);
    if (result.code === 200) {
        const products = result.response;
        const total = result.response.length;
        const total_page = Math.ceil(total / localRecords);
        setTotalPage(total_page);
        setProducts(products);
    }
};

const handlerChangeRecordsPage = (value) => {
    setLocalRecords(value.target.value);
};
const handleChangePage = (page) => {
    page = page - 1;
    setPage(page);
};

return {
    handleGetClinicPlaces,
    products,
    page,
    handleChangePage,
    totalPage,
    handlerChangeRecordsPage,
    localRecords,
    dataFilter
};
};

export default ClinicPlacesControllers;
