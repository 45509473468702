import React, { useState, useEffect } from "react";
import {
  Grid,
  makeStyles,
  Typography,
  IconButton,
  Modal,
  Backdrop,
  Fade,
  Button,
} from "@material-ui/core";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    backgroundColor: "white",
    width: "70%",
    maxHeight: "75vh",
    borderRadius: 8,
    padding: "2%",
    overflow: "auto",
    margin: "0 auto",
  },
  client: {
    fontSize: "30px",
    backgroundColor: "#CBCBCB",
    marginBottom: "2%",
    textDecoration: "underline",
    textDecorationColor: "grey",
  },
  txt: {
    marginBottom: "2%",
  },
  observation: {
    marginBottom: "2%",
  },
  item1: {
    marginBottom: "2%",
  },
  units: {
    backgroundColor: "#CBCBCB",
    width: "40px",
    textAlign: "center",
    textDecoration: "underline",
    textDecorationColor: "grey",
  },
  underlineText: {
    textDecoration: "underline",
    textDecorationColor: "grey",
  },
  editIcon: {
    cursor: "pointer",
    marginLeft: theme.spacing(1),
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    alignItems: "center",
    width: "100%",
  },
  button: {
    "&:hover": {
      backgroundColor: "#1976D2",
    },
    margin: theme.spacing(1),
    backgroundColor: "#1976D2",
  },
  button2: {
    "&:hover": {
      backgroundColor: "#1976D2",
    },
    backgroundColor: "#1976D2",
  },
  pdfList: {
    marginTop: theme.spacing(2),
  },
  pdfItem: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  pdfName: {
    marginLeft: theme.spacing(1),
  },
  containerPdf: {
    marginTop: theme.spacing(1),
  },
}));

const ModalTransfersUnlockRequest = (props) => {
  const classes = useStyles();
  const {
    returnList,
    handleConfirmReturnOrder,
    isOpen,
    onClose,
    estimatedValue,
    setEstimatedValue,
    observationUnlock,
    setObservationUnlock,
    pdfList,
    setPdfList,
  } = props;

  const [isEditable, setIsEditable] = useState(false);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [errors, setErrors] = useState({});
  let clientUnlockRequest = true;

  const handleEditClick = () => {
    setIsEditable(!isEditable);
  };

  const handleValueChange = (event) => {
    const value = event.target.value.replace(/,/g, "");
    if (!isNaN(value)) {
      setEstimatedValue(Number(value));
    }
  };

  const handleObservationChange = (event) => {
    setObservationUnlock(event.target.value);
  };

  const handlePDFChange = (event) => {
    const files = event.target.files;
    const maxFiles = 5;

    if (pdfList.length >= maxFiles) {
      alert(`Solo se permite subir un máximo de ${maxFiles} archivos.`);
      return;
    }

    for (let i = 0; i < files.length; i++) {
      const selectedFile = files[i];
      const fileNameParts = selectedFile.name.split(".");
      const fileExtension = fileNameParts[
        fileNameParts.length - 1
      ].toLowerCase();

      if (
        fileExtension === "pdf" ||
        fileExtension === "jpg" ||
        fileExtension === "jpeg" ||
        fileExtension === "png"
      ) {
        if (selectedFile.size <= 4.49 * 1024 * 1024) {
          setPdfList([...pdfList, selectedFile]);
        } else {
          alert(
            "El archivo seleccionado es demasiado grande. Por favor, seleccione un archivo PDF o una imagen de 4.49MB o menos."
          );
        }
      } else {
        alert("Por favor, seleccione un archivo PDF, JPG o PNG.");
      }
    }

    if (pdfList.length + files.length >= maxFiles) {
      setIsButtonDisabled(true);
    }
  };

  const handleRemovePDF = (index) => {
    setPdfList(pdfList.filter((pdf, i) => i !== index));
    setIsButtonDisabled(false);
  };
  const formatNumber = (value) => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleSubmit = () => {
    let validationErrors = {};

    if (!observationUnlock) {
      validationErrors.observationUnlock = "Este campo es obligatorio.";
    }

    if (!estimatedValue) {
      validationErrors.estimatedValue = "El valor estimado es obligatorio.";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setErrors({});

    handleConfirmReturnOrder(
      observationUnlock,
      estimatedValue,
      clientUnlockRequest,
      pdfList
    );
  };

  const formatWithCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.container}>
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.client}>
              {returnList[0]?.addressee}
            </Grid>
            <Grid item xs={12} className={classes.txt}>
              <h3>
                Cliente bloqueado por cartera, solicitar desbloqueo temporal:
              </h3>
            </Grid>
            <Grid item xs={12} className={classes.observation}>
              <h3>Observación para área de cartera:</h3>
              <TextField
                id="filled-multiline-static"
                label="Observación"
                multiline
                rows={4}
                variant="filled"
                value={observationUnlock}
                onChange={handleObservationChange}
                sx={{ width: "100%" }}
                helperText={
                  errors.observationUnlock
                    ? errors.observationUnlock
                    : `${observationUnlock.length}/${250}`
                }
                inputProps={{ maxLength: 250 }}
                error={!!errors.observationUnlock}
              />
            </Grid>
            <Grid item xs={12} className={classes.item1}>
              <h3 className={classes.underlineText}>Productos solicitados:</h3>
              <Grid container spacing={2}>
                {returnList?.map((item, id) => (
                  <Grid container item xs={12} spacing={2} key={id}>
                    <Grid item xs={8}>
                      <h5>{item.model}</h5>
                    </Grid>
                    <Grid item xs={4} className={classes.units}>
                      <h4>{item.qty}</h4>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <h3 className={classes.underlineText}>Valor estimado:</h3>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <TextField
                    id="outlined-number"
                    label="Número"
                    type="text"
                    value={formatWithCommas(estimatedValue)}
                    onChange={handleValueChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      inputProps: { style: { textAlign: "right" } },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={!!errors.estimatedValue}
                    helperText={errors.estimatedValue}
                  />
                </Grid>
                <Grid item>
                  {/*  <ModeEditIcon
                    className={classes.editIcon}
                    onClick={handleEditClick}
                  /> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.containerPdf}>
              <Grid item xs={12}>
                {/* Input para seleccionar PDF */}
                <input
                  accept="application/pdf,image/jpeg,image/jpg,image/png"
                  id="pdf-upload"
                  multiple
                  type="file"
                  style={{ display: "none" }}
                  onChange={handlePDFChange}
                />
                <label htmlFor="pdf-upload">
                  <Button
                    variant="contained"
                    component="span"
                    disabled={isButtonDisabled}
                    style={{ backgroundColor: "#1976D2", color: "white" }}
                  >
                    Adjuntar soportes
                  </Button>
                </label>
              </Grid>
              <Grid item xs={12} className={classes.pdfList}>
                {/* Lista de PDFs cargados */}
                {pdfList.map((pdf, index) => (
                  <div key={index} className={classes.pdfItem}>
                    <Typography variant="body1">{pdf.name}</Typography>
                    <IconButton
                      aria-label="Eliminar"
                      onClick={() => handleRemovePDF(index)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.buttonsContainer}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                className={classes.button}
              >
                Enviar Solicitud
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button2}
                onClick={onClose}
                style={{
                  padding: "2px 12px",
                  height: "35px",
                }}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

export default ModalTransfersUnlockRequest;
