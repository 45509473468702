import React from "react";
import AddUsers from "components/AddUsers";
import Toast from "components/Toast/Toast.jsx";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//controllers
import EditMemberControllers from "controllers/EditMemberControllers";

function EditMember(props) {
  const { clinicDeparment } = props.location.state;
  const {
    handleEditMember,
    data,
    handlerChangeData,
    handleChangeAddress,
    setToast,
  } = EditMemberControllers(props.location.state, props.userToken, props);
  return (
    <>
      <Toast
        innerRef={(ref) => {
          setToast(ref);
        }}
      />

      <AddUsers
        data={data}
        handlerChangeData={handlerChangeData}
        handlerSubmitMember={handleEditMember}
        handleChangeAddress={handleChangeAddress}
        clinic={props.location.state.data.groupId === 4 ? true : false}
        clinicDeparment={clinicDeparment}
        {...props}
      />
    </>
  );
}

export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
  }))(EditMember)
);
