import React, { useEffect } from "react";
import { Grid, makeStyles, withStyles, Box } from "@material-ui/core";
import Order from "components/OrderAuthorization/index";
import TransfersRequestController from "controllers/TransfersRequest";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Toast from "components/Toast/Toast";
const useStyles = makeStyles((theme) => ({
  Orders: {
    width: "100%",
    //height: "500px",
    marginTop: "10px",
    padding: "10px",
    // backgroundColor: "red",
  },
  tittleAutorizathionOrders: {
    fontSize: "14px",
  },
  containerView: {
    padding: 20,
  },
}));

function ReturnRequestAuthorization(props) {
  let finance = true;
  const {
    setToast,
    handleGetTransfersRequest,
    transfersRequest,
    handleUpdateTransfers,
    disable,
    setDisable,
  } = TransfersRequestController(props);

  useEffect(async () => {
    await handleGetTransfersRequest(props.userToken, 1);
  }, []);
  const classes = useStyles();

  return (
    <div className={classes.containerView}>
      <h5 className={classes.tittleAutorizathionOrders}>
        <b>Resumen de traslados para aprobación</b>{" "}
      </h5>

      <Grid container xs={12} className={classes.Orders}>
        {transfersRequest.map((order, id) => (
          <Grid xs={12}>
            <Order
              order={order}
              id={id}
              handleUpdateOrder={handleUpdateTransfers}
              returnOrder={false}
              finance={finance}
              disable={disable}
              setDisable={setDisable}
              transfer
            />
          </Grid>
        ))}
      </Grid>
      <Toast
        innerRef={(ref) => {
          setToast(ref);
        }}
      />
    </div>
  );
}
export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
    data: state.root.general.dataUserAPI,
    applicant: state.root.user.userInfo.firstName,
    id: state.root.user.userInfo.memberId,
    address: state.root.user.userInfo.address,
    contactNo: state.root.user.userInfo.contactNo,
  }))(ReturnRequestAuthorization)
);
