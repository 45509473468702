import UKA from '../../../img/UKA.svg'
import Spain from '../../../img/Spain.svg'

const changeLanguageStyle = () => ({
    container: {
        margin: "6px",
        display: "flex",
        alignItems: "center"
    },
    toggle: {
        width: "47px",
        height: "20px",
        webkitAppearance: "none",
        backgroundImage: `url(${UKA})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        outline: "none",
        borderRadius: "20px",
        padding: ".5px 0px",
        boxShadow: "inset 0px 0px 9px 0px rgba(0,0,0,0.82)",
        position: "relative",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        transition: "all 0.6s 0s ease",
    },
    dot:{
        height: "24px",
        width: "24px",
        // margin: "2px 2px",
        background: "#96c4ff",
        position: "relative",
        borderRadius: "50%",
        boxShadow: "0px 0px 9px 0px rgba(0,0,0,0.82)",
        transition: "all 0.6s 0s ease"
    },
    dotActive:{
        background: "#ffd672",
        marginLeft: "23px",
        transition: "all 0.6s 0s ease",
    },
    toggleActive: {
        backgroundImage: `url(${Spain})`,
        transition: "all 0.6s 0s ease"
    },
    language: {
        margin: "0px 7px",
        fontWeight: "bold",
        fontSize: "15px",
        color: "#a3a3a3"
    }
})

export default changeLanguageStyle;