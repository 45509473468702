/* The above code is a React component that imports various components and libraries such as UserViews,
Grid, makeStyles, withStyles, Box, Modal, Button, ShipToStep, SkusStep, Orderconfirm, jwtDecode,
connect, withRouter, OrdersRequestController, HeaderSteps, Toast, and configFirebase. */
import React, { useEffect, useState, useRef } from "react";
import UserViews from "components/userViews";
import { Grid, makeStyles, withStyles, Box, Modal } from "@material-ui/core";
import { Button } from "@mui/material";
import "../../assets/jss/material-dashboard-pro-react/views/StepShiptoStyles";
import ShipToStep from "components/OrderRequest/ShipTo";
import SkusStep from "components/OrderRequest/Skus";
import Orderconfirm from "components/OrderRequest/orderconfirm";
import UnlockComponent from "components/OrderRequest/Unlockrequest";
// import jwtDecode from "jwt-decode";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import OrdersRequestController from "../../controllers/OrdersRequest/index";
import HeaderSteps from "components/OrderRequest/HeaderSteps";

import Toast from "components/Toast/Toast.jsx";
import { configFirebase } from "util/firebase";

/* The above code is defining a set of styles using the makeStyles hook from the Material-UI library
for a component related to orders. It includes styles for the container, header, body, icons,
buttons, modals, and text elements. It also includes media queries for responsive design. */
const useStyles = makeStyles((theme) => ({
  containerOrders: {
    width: "100%",
    //height: "500px",
    marginTop: "80px",
    padding: "10px",
    //backgroundColor: "red",
  },
  headerOrders: {
    height: "100px",
    // border: "solid 1px red",
    borderRadius: 10,
    boxShadow: "5px 5px 10px black",
    display: "flex",
    alignItems: "center",
    paddingLeft: "8%",
    // backgroundColor: "yellow",
  },
  bodyOrders: {
    // border: "solid 1px black",
    marginTop: "20px",
    borderRadius: 10,
    boxShadow: "2px 2px 10px gray",
    // backgroundColor: "blue",
  },

  icons: {
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
    marginBottom: "-18px",
    marginLeft: "20px",
  },
  txt: {
    fontSize: "12px",
  },
  Line: {
    marginTop: "-2px",
    width: "80%",
    height: "2px",
    backgroundColor: "#5DADE2",
  },

  vline: {
    borderLeft: " solid black",
    height: "40%",
    left: "6%",
    marginRight: "10%",
  },
  gridbutton: {
    paddingBottom: "10px",
    marginRight: "10%",
    marginTop: "20px",
  },
  button: {
    position: "relative",
    left: "45%",
    border: "none",
    borderRadius: "8px",
    width: "12%",
    backgroundColor: "#5DADE2",
    color: "white",
    marginRight: "12px",
  },
  modal: {
    position: "absolute",
    width: "30vw",
    height: "35%",
    backgroundColor: "#5DADE2",
    borderRadius: "6px",
    padding: "0px 10px 30px 10px",
    marginLeft: "auto",
    marginRight: "auto",
    top: "32.5%",
    left: "37.5%",
    transforms: "translate(-50%,-50%)",
  },
  modalconfirm: {
    position: "absolute",
    width: "20vw",
    height: "10%",
    backgroundColor: "white",
    borderRadius: "6px",
    boxShadow: "2px 2px 10px gray",
    padding: "0px 10px 10px 10px",
    top: "45%",
    left: "42.5%",
    transforms: "translate(-50%,-50%)",
  },
  containermodal: {
    backgroundColor: "white",
    borderRadius: "6px",
    margin: "0px auto",
    height: "95%",
  },
  titlemodal: { marginLeft: "6%", paddingTop: "5px" },

  buttonModal: {
    // paddingTop: "100px",
  },
  clientprofiletxt: {
    fontSize: "100%",
    fontWeight: "bold",
  },
  modalinfo: { paddingLeft: "6%" },
  modalinfochildren: {
    display: "flex",
    alignItems: "center",
    marginLeft: "6%",
  },
  nameshipto: {
    fontSize: "100%",
    fontWeight: "bold",
  },
  idshipto: {
    fontSize: "80%",
  },
  textinfo: {
    fontSize: "80%",
    marginBottom: 20,
    marginTop: -10,
  },
  textinfoheader: {
    fontSize: "100%",
    color: "white",
  },
  textPrices: {
    fontSize: "120%",
    fontWeight: "bold",
  },
  closeicon: { color: "white", marginLeft: "10px", marginTop: "-10px" },
  modalheader: {
    display: "flex",
    position: "relative",
    float: "right",
    // left: "40%",
    alignItems: "center",
    paddingTop: 10,
  },

  tittlemodalconfirm: {
    textAlign: "center",
    //fontSize: "14px",
    //marginLeft: "20%",
  },
  bodymodalconfirm: {
    display: "flex",
    marginRight: "55%",
    paddingTop: "20px",
  },
  containerTxtShipto: {
    marginBottom: 20,
  },
  containerPrices: {
    paddingTop: "5%",
  },
  "@media (max-Width: 960px)": {
    button: {
      width: "28%",
    },
    modalconfirm: {
      fontSize: "1.5vh",
      width: "50vw",
      height: "10%",
      left: "30%",
    },
  },
}));

//controller

/**
 * The OrderRequest function renders a multi-step form for creating an order, with different components
 * for selecting a shipping address, adding SKUs, and confirming the order.
 * @param props - An object containing various properties passed down from the parent component to this
 * component. These properties include userToken, id, applicant, address, and contactNo, among others.
 * @returns a React component that renders different steps of an order request form, including a
 * ShipToStep, SkusStep, and Orderconfirm. It also includes buttons to navigate between steps and a
 * confirmation modal for submitting the order.
 */
function OrderRequest(props) {
  const classes = useStyles();
  const title = "Order Request";
  const {
    shipto,
    handleGetShipto,
    steps,
    setSteps,
    fourStep,
    auxShipto,
    setAuxShipto,
    handleGetidRep,
    materialGroup,
    handleGetMaterialGroup,
    handlegetSkus,
    skus,
    setSkus,
    auxSkus,
    setAuxSkus,
    data,
    setData,
    amount,
    setAmount,
    next,
    handleConfirmOrder,
    setToast,
    setOrderType,
    handleSelectAuxShipto,
    selectauxShipto,
    page,
    setPage,
    numberPage,
    handlegetAllshipto,
    allshipto,
    setShearchShipto,
    shearchShipto,
    handleAddShipto,
    handleDeleteShipto,
    auxbuttonsId,
    setShearchSkus,
    shearchSkus,
    allSkus,
    handlegetAllSkus,
    handleAddSku,
    auxbuttonsIdSkus,
    handleDeleteSku,
    setAuxbuttonsIdSkus,
    materialGroupSelected,
    setMaterialGroupSelected,
    existSkuButton,
    setExistSkuButton,
    handleChangeLifeUrgency,
    lifeUrgency,
    firstModel,
    numberPageSkus,
    setPageSkus,
    pageSkus,
    setNumberPageSkus,
    setSelectauxShipto,
    skusAux,
    auxwherehouse_inv,
    handleChangeObservation,
    observation,
    jwtToken,
    handleGetKits,
    namesKits,
    handleGetSkusByKits,
    orderSkusByKits,
    setOrderSkusByKits,
    handleDeleteOrderList,
    selectedPDF,
    setSelectedPDF,
    handleCancelUnlockRequest,
  } = OrdersRequestController(props);
  const [modal, setModal] = useState(false);

  /* The above code is written in JavaScript and is using the `useEffect` hook. It is defining an
asynchronous function that is executed when the component mounts or when the `page` or
`searchShipto` variables change. */
  useEffect(
    async () => {
      await handleGetidRep(props.userToken, props.id);
      await handlegetAllshipto(props.userToken, props.id);

      const test = await configFirebase();
    },
    [page, shearchShipto]
  );

  useEffect(
    async () => {
      await handlegetAllSkus(props.userToken, selectauxShipto);
    },
    [materialGroupSelected]
  );
  // var jwt = jwtDecode(props.userToken);
  const [confirmModal, setConfirmModal] = useState(false);

  const OpenCloseConfirmModal = () => {
    setConfirmModal(!confirmModal);
  };

  /* The above code is creating a confirmation modal with two buttons ("Confirmar" and "Cancelar") and
handling their onClick events. The "Confirmar" button calls the handleConfirmOrder function with a
userToken parameter and closes the modal, while the "Cancelar" button just closes the modal and
resets some state variables. The modal includes a title ("¿Estas seguro?") and is styled using CSS
classes. */
  const confirm = (
    <Grid xs={12} className={classes.modalconfirm}>
      <Grid className={classes.tittlemodalconfirm}>
        <h8>¿Estas seguro?</h8>
      </Grid>
      <Grid
        container
        item
        xs={12}
        spacing={2}
        className={classes.bodymodalconfirm}
      >
        <Grid item xs={6}>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              handleConfirmOrder(props.userToken);
              OpenCloseConfirmModal();
            }}
            sx={{
              backgroundColor: "#5CC7F8",
              width: "100%",
              //height: "20px",
              fontSize: "80%",
              //marginLeft: "15%",
              "@media (max-Width: 960px)": {
                width: 20,
                height: 20,
              },
            }}
          >
            Confirmar
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            size="small"
            variant="contained"
            sx={{
              backgroundColor: "#5CC7F8",
              width: "100%",
              //height: "20px",
              fontSize: "80%",
              //marginLeft: "15%",
              "@media (max-Width: 960px)": {
                width: 20,
                height: 20,
              },
            }}
            onClick={() => {
              OpenCloseConfirmModal();
              setSteps(2);
              if (selectauxShipto != "") {
                setSelectauxShipto("");
              }
              setSkus([]);
              setShearchSkus("");
              setAuxSkus([]);
              setAuxbuttonsIdSkus([]);
              setNumberPageSkus(null);
            }}
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  /* The above code is a React component that renders a multi-step form for creating an order. The form
has three steps: Ship To, Skus, and Order Confirm. The component conditionally renders different
components based on the value of the `steps` variable. It also conditionally renders a button
component based on the values of the `steps` and `jwt.groupSlug` variables. If `steps` is equal to 3
and `jwt.groupSlug` is not equal to "super-user", then a button with the label "Realizar pedido" is
rendered and an `OpenCloseConfirmModal */
  return (
    <div>
      <Grid container xs={12} className={classes.containerOrders}>
        <Grid xs={12}>
          <HeaderSteps steps={steps} fourStep={fourStep} />
        </Grid>
        <Grid xs={12} className={classes.bodyOrders}>
          <Box>
            {steps == 1 ? (
              <ShipToStep
                shipto={shipto}
                // OpenCloseModal={OpenCloseModal}
                auxShipto={auxShipto}
                setAuxShipto={setAuxShipto}
                setOrderType={setOrderType}
                page={page}
                setPage={setPage}
                numberPage={numberPage}
                allshipto={allshipto}
                setShearchShipto={setShearchShipto}
                shearchShipto={shearchShipto}
                handleAddShipto={handleAddShipto}
                handleDeleteShipto={handleDeleteShipto}
                auxbuttonsId={auxbuttonsId}
              />
            ) : steps == 2 ? (
              <SkusStep
                shipto={shipto}
                auxShipto={auxShipto}
                materialGroup={materialGroup}
                handlegetSkus={handlegetSkus}
                token={props.userToken}
                skus={skus}
                setSkus={setSkus}
                setAuxSkus={setAuxSkus}
                auxSkus={auxSkus}
                setData={setData}
                data={data}
                setAmount={setAmount}
                amount={amount}
                handleSelectAuxShipto={handleSelectAuxShipto}
                selectauxShipto={selectauxShipto}
                setShearchSkus={setShearchSkus}
                shearchSkus={shearchSkus}
                allSkus={allSkus}
                handleAddSku={handleAddSku}
                auxbuttonsIdSkus={auxbuttonsIdSkus}
                handleDeleteSku={handleDeleteSku}
                setAuxbuttonsIdSkus={setAuxbuttonsIdSkus}
                materialGroupSelected={materialGroupSelected}
                setMaterialGroupSelected={setMaterialGroupSelected}
                existSkuButton={existSkuButton}
                setExistSkuButton={setExistSkuButton}
                handleGetMaterialGroup={handleGetMaterialGroup}
                firstModel={firstModel}
                numberPageSkus={numberPageSkus}
                setPageSkus={setPageSkus}
                pageSkus={pageSkus}
                skusAux={skusAux}
                auxwherehouse_inv={auxwherehouse_inv}
                setNumberPageSkus={setNumberPageSkus}
                handleGetKits={handleGetKits}
                namesKits={namesKits}
                handleGetSkusByKits={handleGetSkusByKits}
                orderSkusByKits={orderSkusByKits}
                setOrderSkusByKits={setOrderSkusByKits}
                handleDeleteOrderList={handleDeleteOrderList}
              />
            ) : steps == 3 ? (
              <Orderconfirm
                amount={amount}
                auxSkus={auxSkus}
                applicant={props.applicant}
                address={props.address}
                contactNo={props.contactNo}
                handleChangeLifeUrgency={handleChangeLifeUrgency}
                lifeUrgency={lifeUrgency}
                handleChangeObservation={handleChangeObservation}
                observation={observation}
                orderSkusByKits={orderSkusByKits}
                handleDeleteOrderList={handleDeleteOrderList}
                selectedPDF={selectedPDF}
                setSelectedPDF={setSelectedPDF}
              />
            ) : steps == 4 ? (
              <UnlockComponent
                auxSkus={auxSkus}
                orderSkusByKits={orderSkusByKits}
                token={props.userToken}
                handleConfirmOrder={handleConfirmOrder}
                handleCancelUnlockRequest={handleCancelUnlockRequest}
                jwtToken={jwtToken}
              />
            ) : null}
          </Box>
          <Grid className={classes.gridbutton}>
            {/*  The above code is a JavaScript code that conditionally renders a
            button component based on the values of the variables `steps` and
            `jwt.groupSlug`. If `steps` is equal to 3 and `jwt.groupSlug` is not
            equal to "super-user", then a button with the label "Realizar
            pedido" is rendered and an `OpenCloseConfirmModal()` function is
            called when the button is clicked. Otherwise, a button with the
            label "Confirmar" is rendered and a `next()` function is called when
            the button is clicked. */}
            {steps == 3 && fourStep == false ? (
              jwtToken.groupSlug !== "super-user" && (
                <button
                  className={classes.button}
                  onClick={() => OpenCloseConfirmModal()}
                >
                  Realizar pedido
                </button>
              )
            ) : steps == 4 && fourStep == true ? null : (
              <button className={classes.button} onClick={next}>
                Confirmar
              </button>
            )}
          </Grid>
        </Grid>

        <Modal open={confirmModal} onClose={OpenCloseConfirmModal}>
          {confirm}
        </Modal>
      </Grid>
      <Toast
        innerRef={(ref) => {
          setToast(ref);
        }}
      />
    </div>
  );
}

/* The above code is exporting a higher-order component that connects the `OrderRequest` component to
the Redux store using the `connect` function from the `react-redux` library. It also uses the
`withRouter` higher-order component from the `react-router-dom` library to pass the `history`,
`location`, and `match` props to the `OrderRequest` component. The `connect` function maps the state
properties `userToken`, `userInfo`, `data`, `applicant`, `id`, `address`, and `contactNo` from the
Redux store to props that can be accessed by */
export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
    data: state.root.general.dataUserAPI,
    applicant: state.root.user.userInfo.firstName,
    id: state.root.user.userInfo.memberId,
    address: state.root.user.userInfo.address,
    contactNo: state.root.user.userInfo.contactNo,
  }))(OrderRequest)
);
