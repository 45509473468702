import React, { useEffect } from "react";
import UserViews from "components/userViews";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
//controller
import SpecialistInventoryController from "controllers/SpecialistInventoryControllers";
import empty from "../../../assets/img/dashboard/empty.svg";

function InventoryProducts(props) {
  const title = "My Inventory Products";
  const {
    products,
    handleChangePage,
    totalPage,
    handlerChangeRecordsPage,
    dataFilter,
    handleGetSpecialistInventory,
  } = SpecialistInventoryController();

  useEffect(async () => {
    if (products.length === 0) props.setLoaderVisible(true);
    if(Object.keys(props.data).length === 0) await handleGetSpecialistInventory(props.userToken);
    if (products.length === 0) props.setLoaderVisible(false);
  }, []);

  return (
      <>
        {products.length !== 0 || Object.keys(props.data).length !== 0 ?
            <UserViews
                title={title}
                dataFilter={dataFilter}
                //handlerAddMember={handlerAddMember}
                members={Object.keys(props.data).length !== 0 ? props.data.InventorySpecialist ? props.data.InventorySpecialist.orders : products : products}
                handleChangePage={handleChangePage}
                totalPage={totalPage}
                handlerChangeRecordsPage={handlerChangeRecordsPage}
                specialist
                {...props}
            />
            :<img src={empty} alt="Not found data" style={{height: "16rem", margin: "6rem 3rem"}}/>}
      </>
  );
}

export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
    data: state.root.general.dataUserAPI
  }))(InventoryProducts)
);
