// ##############################
// // // SweetAlert view styles
// #############################


const notificationStyle = {
  containerView:{
    paddingTop: "10%",
    paddingLeft: "20%",
    paddingRight: "20%"
  },
  container:{
    boxShadow: "0 8px 8px 5px rgba(0, 0, 0, 0.14)",
    padding: "2%" ,
    borderTop: "5px solid #6495ED"   
  },
  ctnButton: {
    textAlign: "center"
  },
  title: {
    borderBottom: "2px solid #636363",
    paddingBottom: 20
  }
};

export default notificationStyle;
