/* eslint-disable */
import React, {useState, useRef, useEffect} from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from '@material-ui/core/FormLabel';
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import { useTranslation } from 'react-i18next';

// assets
//import logo from "assets/img/Logo.png";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

const Login = ({ classes, ...rest }) => {

    const [codeAuth, setCodeAuth] = useState(false);
    const modalCode = useRef(null);
    const modalCodeOut = useRef(null);
    const {t} = useTranslation("loginPage")

    useEffect(()=>{
        function handler(e) {
            if(!modalCode.current?.contains(e.target)){
                if(modalCodeOut.current?.contains(e.target)){
                    setCodeAuth(false);
                }
            }
        }
        window.addEventListener('click', handler)
        return () => window.removeEventListener('click', handler)
    },[])

    const insertCode = () => {
        if(rest.username !== "" && rest.password !== ""){
            setCodeAuth(!codeAuth);
        }else{
            rest.complete();
        }
    }

    const handleKeyPress = (e) => {
        if (e.which === 13 /* Enter */) {
            if(rest.username !== "" && rest.password !== "") {
                if(rest.code === ""){
                    setCodeAuth(!codeAuth);
                }
            }else{
                rest.complete();
            }

            if(rest.username !== "" && rest.password !== "" && rest.code !== ""){
                rest.handleSubmit(rest.username, rest.password, rest.code)
                setCodeAuth(!codeAuth);
            }
        }
    }

    return (
        <div className={classes.container} style={{overflow: "hidden"}}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={6} md={6} style={{textAlign: 'center'}}>

                    {rest.authorizationAlert}
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    {
                                        rest.lockedUsername
                                        ?
                                        <div style={{marginTop: '20px'}}>
                                            <FormLabel>
                                            Welcome, <strong>{rest.username}</strong>!
                                            </FormLabel>
                                        </div>
                                        :
                                        <CustomInput
                                            labelText={t("text.user")}
                                            id="username"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onKeyPress: handleKeyPress,
                                                onChange: e => rest.handleInputChange(e),
                                                value: rest.username
                                            }}
                                        />
                                    }
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput
                                        labelText={t("text.pass")}
                                        id="password"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            type: "password",
                                            onKeyPress: handleKeyPress,
                                            onChange: e => rest.handleInputChange(e),
                                            value: rest.password
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <a /*href="/forgotPassword"*/ style={{fontSize:"16px", fontWeight:"600"}}>{t("text.forgot")}</a>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <Button style={{backgroundColor: "#215ab4", fontSize:"16px", fontWeight:"600"}} round fullWidth={true} onClick={() =>{insertCode()}}>
                                {t("text.login")}
                          </Button>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
            {codeAuth ?
                <div className={classes.containerCode} ref={modalCodeOut}>
                    <div className={classes.containerInside} ref={modalCode}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={6} style={{textAlign: 'center'}} >
                                <Card>
                                    <CardBody>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={12}>
                                                <div className={classes.containerAuth}>
                                                    <p>{t("text.infoAuth")}:</p>
                                                    <p className={classes.infoAuth}>{rest.username}</p>
                                                </div>
                                                <CustomInput
                                                    labelText="Authentication Code"
                                                    id="code"
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                    inputProps={{
                                                        onKeyPress: handleKeyPress,
                                                        onChange: e => rest.handleInputChange(e),
                                                        value: rest.code
                                                    }}
                                                    twoStep={true}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                    </CardBody>
                                    <CardFooter>
                                        <Button style={{backgroundColor: "#215ab4", fontSize:"16px", fontWeight:"600"}} round fullWidth={true} onClick={() =>{rest.handleSubmit(rest.username, rest.password, rest.code)}}>
                                            Check Code
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>: null}
        </div>
    );
}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    handleKeyPress: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};


export default withStyles(loginPageStyle)(Login);
