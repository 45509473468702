import * as fetch from "./fetch";
import fetchIso from "isomorphic-fetch";

const createHeader = (token) => {
  return {
    "Content-Type": "application/json",
    "x-healthenviron-request-caller": "HealthEnviron-Member",
    "x-healthenviron-api-key": token,
  };
};
const createHeader2 = (apikey) => {
  return {
    "Content-Type": "application/json;charset=utf-8",
    Authorization: apikey,
  };
};

const createHeaderFormData = (token) => {
  return {
    "Content-Type": "multipart/form-data",
    "x-healthenviron-request-caller": "HealthEnviron-Member",
    "x-healthenviron-api-key": token,
  };
};

const createHeaderFormDataExcel = (token) => {
  return {
    "Content-Type": "application/json",
    "x-healthenviron-request-caller": "HealthEnviron-Member",
    "x-healthenviron-api-key": token,
  };
};

export const login = async (username, password, codeToken) => {
  let header = {
    "Content-Type": "application/json",
    "x-healthenviron-request-caller": "HealthEnviron-Member",
  };
  let body = {
    email: username,
    password: password,
    codeToken: codeToken,
  };
  let result = await fetch.post("/Authenticate", header, body);

  return result;
};

export const getMembers = async (memberType, data) => {
  let header = createHeader(data.token);
  let result = await fetch.get(
    `/get-members?memberType=${memberType}&page=${data.page}&records=${
      data.records
    }`,
    header
  );
  return result;
};

export const createMembers = async (body, token) => {
  let header = createHeader(token);
  let result = await fetch.post(`/create-member`, header, body);
  return result;
};

export const getProducts = async (token, query) => {
  let header = createHeader(token);
  const { page, records, filterType, filterValue } = query;
  let result = await fetch.get(
    filterType && filterValue
      ? `/get-products?page=${page}&records=${records}&${filterType}=${filterValue}`
      : `/get-products?page=${page}&records=${records}`,
    header
  );
  return result;
};

export const getOrders = async (token) => {
  let header = createHeader(token);
  let result = await fetch.get("/get-orders", header);
  return result;
};

export const getRecords = async (token) => {
  let header = createHeader(token);
  let result = await fetch.get("/get-records", header);
  return result;
};

export const updateMembers = async (body, token, type) => {
  let header = createHeader(token);
  let result = await fetch.post(`/update-member?update=${type}`, header, body);
  return result;
};

export const deleteMembers = async (body, token) => {
  let header = createHeader(token);
  let result = await fetch.remove(`/delete-member`, header, body);
  return result;
};

export const resetPassword = async (body, token) => {
  let header = createHeader(token);
  let result = await fetch.post(`/reset-password`, header, body);
  return result;
};

export const createClinic = async (body, token) => {
  let header = createHeader(token);
  let result = await fetch.post(`/create-clinics-hospital`, header, body);
  return result;
};

export const updatedClinic = async (body, token) => {
  let header = createHeader(token);
  let result = await fetch.put(`/update-clinics-Hospitals`, header, body);
  return result;
};

export const getSpecialistInventory = async (token) => {
  let header = createHeader(token);
  let result = await fetch.get("/get-srai-products?type=upn", header);
  return result;
};

export const getProductsInventory = async (token) => {
  let header = createHeader(token);
  let result = await fetch.get("/get-clinic-inventory", header);
  return result;
};

export const getIncomingProducts = async (token) => {
  let header = createHeader(token);
  let result = await fetch.get(
    "/get-incoming-products?totalRecords=true&clinic=true",
    header
  );
  return result;
};

export const getTransferBetweenSpecialist = async (token) => {
  let header = createHeader(token);
  let result = await fetch.get("/get-transfer-between-specialist", header);
  return result;
};

export const getScannedProductBatch = async (token, query) => {
  let header = createHeader(token);
  const { page, records, batchId, clinic } = query;
  let result = await fetch.get(
    batchId
      ? `/get-scanned-product-batch?page=${page}&records=${records}&batchId=${batchId}`
      : clinic
        ? `/get-scanned-product-batch?page=${page}&records=${records}&clinic=${clinic}`
        : `/get-scanned-product-batch?page=${page}&records=${records}`,
    header
  );
  return result;
};

export const getScannedIncomingProductBatch = async (token, query) => {
  let header = createHeader(token);
  const { page, records, batchId, clinic } = query;
  let result = await fetch.get(
    batchId
      ? `/get-scanned-batch-incoming?page=${page}&records=${records}&batchId=${batchId}`
      : clinic
        ? `/get-scanned-batch-incoming?page=${page}&records=${records}&clinic=${clinic}`
        : `/get-scanned-batch-incoming?page=${page}&records=${records}`,
    header
  );
  return result;
};

export const getClinicPlaces = async (token) => {
  let header = createHeader(token);
  let result = await fetchIso(
    "https://9zyfzt4oqc.execute-api.us-east-2.amazonaws.com/test/get-clinic-places?totalRecords=true",
    {
      method: "GET",
      headers: header,
    }
  );
  result = result.json();
  return result;
};

export const getNotifications = async (token) => {
  let header = createHeader(token);
  let result = await fetch.get("/get-notifications", header);
  return result;
};

export const getExpiredInventory = async (token) => {
  let header = createHeader(token);
  let result = await fetch.get("/get-expired-inventory", header);
  return result;
};

export const updateExpiredDays = async (body, token) => {
  let header = createHeader(token);
  let result = await fetch.put("/updated-date-expired", header, body);
  return result;
};

export const updateNotifications = async (body, token) => {
  let header = createHeader(token);
  let result = await fetch.put("/updated-alert", header, body);
  return result;
};

export const createOrders = async (body, token) => {
  let header = createHeaderFormData(token);
  let result = await fetch.formData("/importOrders", header, body);
  return result;
};

export const createDeliverys = async (body, token) => {
  let header = createHeaderFormData(token);
  let result = await fetch.formData("/import-delivery", header, body);
  return result;
};

export const createOrdersReplacement = async (token) => {
  let header = createHeader(token);
  let result = await fetch.post("/create-replacement-orders", header);
  return result;
};

/**
 * This function retrieves ship-to information based on various parameters and returns the result.
 * @param token - an authentication token required to access the API
 * @param benefitting_Rep - The representative who is benefiting from the ship-to address.
 * @param page - The page number of the ship-to records to retrieve.
 * @param all - a boolean value indicating whether to retrieve all ship-to records or just a specific
 * number of records per page.
 * @param shearchShipto - This parameter is used to search for a specific ship-to address. If it is
 * provided, the API will return ship-to addresses that match the search query. If it is not provided,
 * the API will return all ship-to addresses for the given page and number of records.
 * @returns the result of a GET request to a specific endpoint with parameters such as page, records,
 * solicitante, and shipto. The result is being fetched using the provided header and returned as a
 * promise.
 */
export const getShipTo = async (
  token,
  benefitting_Rep,
  page,
  all,
  shearchShipto
) => {
  let header = createHeader(token);

  let result = await fetch.get(
    all
      ? `/get-ship-to?page=${page}&records=${"all"}&solicitante=${benefitting_Rep}`
      : shearchShipto
        ? `/get-ship-to?page=${page}&records=7&solicitante=${benefitting_Rep}&shipto=${shearchShipto}`
        : `/get-ship-to?page=${page}&records=7&solicitante=${benefitting_Rep}`,
    header
  );
  return result;
};

/**
 * This function retrieves the reputation of a member with a given ID using a provided token.
 * @param token - The authentication token needed to access the API endpoint.
 * @param id - The `id` parameter is the ID of a member for which we want to retrieve the ID of their
 * representative.
 * @returns The function `getidRep` is returning the result of a `fetch` request to the endpoint
 * `/get-id-rep` with the query parameter `idMember` set to the value of the `id` parameter. The
 * request is made with the `header` object that is created using the `createHeader` function and the
 * `token` parameter. The function is returning the result of the
 */
export const getidRep = async (token, id) => {
  let header = createHeader(token);

  let result = await fetch.get(`/get-id-rep?idMember=${id}`, header);

  return result;
};

/**
 * This function retrieves a material group using a token and a representative ID.
 * @param token - The token is likely an authentication token that is used to verify the identity of
 * the user making the API request. It is usually obtained by logging in or authenticating with the API
 * provider.
 * @param idRep - The `idRep` parameter is an identifier for an account. It is used in the API endpoint
 * `/get-material-group` to retrieve the material group associated with the specified account.
 * @returns The `getiMaterialGoup` function is returning the result of a GET request to the
 * `/get-material-group` endpoint with an `account` parameter set to `idRep`. The result is being
 * fetched using the `fetch.get` method and is returned as a Promise.
 */
export const getiMaterialGoup = async (token, idRep, type) => {
  let header = createHeader(token);

  let result = await fetch.get(
    `/get-material-group?&account=${idRep}&type=${type}`,
    header
  );

  return result;
};

export const getSkusbyKits = async (token, shipto, kit, idRep) => {
  let header = createHeader(token);

  let kitJson = JSON.stringify(kit); // Convertir el array en una cadena JSON

  let result = await fetch.get(
    `/get-skus-kit?&shipto=${shipto}&kit=${kitJson}&account=${idRep}`, // Incluir kitJson en la URL
    header
  );

  return result;
};

/**
 * This function retrieves SKUs based on various parameters such as token, shipto, model, type, idRep,
 * all, shearchSkus, selectauxMaterialGroup, and pageSkus.
 * @param token - an authentication token for accessing the API
 * @param shipto - The shipping destination for the SKUs.
 * @param model - A string representing the model of the SKU being searched for.
 * @param type - a string representing the type of SKU to retrieve
 * @param idRep - This parameter is likely an identifier for a sales representative or account manager.
 * It is used in the API call to retrieve SKUs associated with that representative or account.
 * @param all - A boolean value indicating whether to retrieve all records or just a limited number of
 * records.
 * @param shearchSkus - A string representing the SKU being searched for.
 * @param selectauxMaterialGroup - It is a boolean flag indicating whether to filter the SKUs by a
 * specific material group or not.
 * @param pageSkus - The page number of the results to retrieve. If not provided, the default value is
 * 0.
 * @returns the result of a fetch request made to a specific endpoint based on the input parameters.
 * The result is likely a list of SKUs (stock keeping units) that match the search criteria.
 */
export const getSkus = async (
  token,
  shipto,
  model,
  type,
  idRep,
  all,
  shearchSkus,
  selectauxMaterialGroup,
  pageSkus
) => {
  let header = createHeader(token);

  // Codificar shearchSkus para evitar problemas con caracteres especiales
  const encodedShearchSkus = shearchSkus ? encodeURIComponent(shearchSkus) : "";

  let result = await fetch.get(
    selectauxMaterialGroup && shearchSkus
      ? `/get-skus-by-shipto?sku=${encodedShearchSkus}&page=${
          pageSkus ? pageSkus : 0
        }&records=10&type=${type}&account=${idRep}&ph1=${selectauxMaterialGroup}&shipto=${shipto}`
      : selectauxMaterialGroup && all
        ? `/get-skus-by-shipto?&page=0&records=all&type=${type}&account=${idRep}&ph1=${selectauxMaterialGroup}&shipto=${shipto}`
        : selectauxMaterialGroup
          ? `/get-skus-by-shipto?&page=${
              pageSkus ? pageSkus : 0
            }&records=10&type=${type}&account=${idRep}&ph1=${selectauxMaterialGroup}&shipto=${shipto}`
          : shearchSkus
            ? `/get-skus-by-shipto?sku=${encodedShearchSkus}&page=${
                pageSkus ? pageSkus : 0
              }&records=10&type=${type}&account=${idRep}&shipto=${shipto}`
            : shipto && idRep
              ? `/get-skus-by-shipto?shipto=${shipto}&page=${
                  pageSkus ? pageSkus : 0
                }&records=10&type=${type}&account=${idRep}`
              : shipto
                ? `/get-skus-by-shipto?shipto=${shipto}&page=0&records=10&type=${type}`
                : shipto && !model
                  ? `/get-skus-by-shipto?shipto=${shipto}&page=0&records=10&type=${type}`
                  : model && !shipto
                    ? `/get-skus-by-shipto?sku=${model}&page=${
                        pageSkus ? pageSkus : 0
                      }&records=10&type=${type}&account=${idRep}`
                    : all
                      ? `/get-skus-by-shipto?shipto=${shipto}&page=0&records=${"all"}&type=${type}&account=${idRep}`
                      : `/get-skus-by-shipto?page=0&records=50`,
    header
  );

  return result;
};

/**
 * This function confirms an order by sending a POST request with a body, token, and account ID.
 * @param body - The data to be sent in the request body.
 * @param token - The `token` parameter is likely a user authentication token that is used to verify
 * the identity of the user making the request. It is likely passed in the header of the HTTP request
 * to the server.
 * @param id - The `id` parameter is the account ID of the user who is confirming the order. It is used
 * in the API endpoint URL to specify which account is confirming the order.
 * @returns The `confirmOrder` function is returning the result of the `fetch.post` request made to the
 * `/confirm-order` endpoint with the provided `header` and `body` parameters.
 */
export const confirmOrder = async (body, token, id, pdfId) => {
  let header = createHeader(token);
  let result = await fetch.post(
    pdfId
      ? `/confirm-order?account=${id}&documentId=${pdfId}`
      : `/confirm-order?account=${id}`,

    header,
    body
  );
  return result;
};

/**
 * This function retrieves orders authorizations for a specific member using their ID and
 * authentication token.
 * @param token - The token parameter is likely a string representing an authentication token that is
 * used to authenticate the user making the request. This token is likely generated when the user logs
 * in or signs up and is used to verify their identity for subsequent requests.
 * @param id - The `id` parameter is the ID of the member for whom the orders authorizations are being
 * retrieved.
 * @returns The `getOrdersAuthorization` function is returning the result of a `fetch` request to the
 * `/orders-authorizations` endpoint with a query parameter of `idMember` set to the `id` parameter
 * passed into the function. The request is being made with an authorization header created using the
 * `createHeader` function and the `token` parameter passed into the function. The function is
 * returning the result
 */
export const getOrdersAuthorization = async (token, id) => {
  let header = createHeader(token);

  let result = await fetch.get(`/orders-authorizations?idMember=${id}`, header);

  return result;
};

/**
 * This function updates the status of an order and returns the result.
 * @param id - The ID of the order that needs to be updated.
 * @param token - The authentication token required to access the API endpoint for updating order
 * status.
 * @param status - The new status that you want to update the order to.
 * @param returnOrder - A boolean value indicating whether the order is a return order or not. If it is
 * a return order, the value should be true, otherwise it should be false.
 * @param idRep - idRep is a code or identifier for the representative who is handling the order
 * update. It is used in the API call to track which representative made the update.
 * @returns the result of the fetch request made to update the order status.
 */
export const updateOrderStatus = async (
  id,
  token,
  status,
  returnOrder,
  idRep,
  idUser
) => {
  let header = createHeader(token);
  let result = await fetch.post(
    returnOrder
      ? `/update-order-status?orderID=${id}&status=${status}&returnOrder=true&code=${idRep}&idUser=${idUser}`
      : `/update-order-status?orderID=${id}&status=${status}&code=${idRep}&idUser=${idUser}`,
    header
  );
  return result;
};

/**
 * This function retrieves the approval history of an order or member using a token and optional
 * parameters.
 * @param token - The authentication token required to access the API endpoint.
 * @param orderId - The ID of the order for which the approval history is being requested. If this
 * parameter is not provided, the function will return the approval history for all orders associated
 * with the specified memberId.
 * @param memberId - The ID of the member whose approval history is being requested.
 * @param GroupSlug - The GroupSlug parameter is a string that represents the group slug of the account
 * manager. It is used to filter the orders history based on the account manager's group.
 * @returns the result of a GET request to the endpoint `/orders-history`. The endpoint is determined
 * based on the values of the `orderId`, `memberId`, and `GroupSlug` parameters. The function is using
 * the `createHeader` function to create the header for the request using the `token` parameter. The
 * result of the GET request is being returned.
 */
export const getApprovalhistory = async (
  token,
  orderId,
  memberId,
  GroupSlug,
  page
) => {
  let header = createHeader(token);

  let result = await fetch.get(
    orderId
      ? `/orders-history?orderId=${orderId}&idMember=${memberId}`
      : GroupSlug == "Account-manager"
        ? `/orders-history?idMember=${memberId}&account=${GroupSlug}&page=${page}&records=10`
        : `/orders-history?idMember=${memberId}&page=0&records=10`,
    header
  );

  return result;
};

/**
 * This function retrieves pending return orders based on the user's token, representative ID, and
 * group slug.
 * @param token - The authentication token required to access the API endpoint.
 * @param idRep - The id of the representative for whom the pending return orders are being fetched.
 * @param groupSlug - The groupSlug parameter is a string that determines which group the user belongs
 * to. It can have one of the following values: "Account-manager", "finance", "quality", or any other
 * value. Depending on the value of groupSlug, the function will make a different API call to retrieve
 * pending return
 * @returns the result of a fetch request to get pending return orders based on the provided parameters
 * (token, idRep, groupSlug). The result is a promise that will resolve to the response of the fetch
 * request.
 */
export const getPendingreturnorders = async (token, idRep, groupSlug) => {
  let header = createHeader(token);

  let result = await fetch.get(
    groupSlug === "Account-manager"
      ? `/get-pending-return-orders?account=${idRep}`
      : groupSlug === "finance"
        ? `/get-pending-return-orders?finance=${idRep}`
        : groupSlug === "quality"
          ? `/get-pending-return-orders?quality=${idRep}`
          : `/get-pending-return-orders`,
    header
  );

  return result;
};
/**
 * This function retrieves the return order history based on various parameters and returns the result.
 * @param token - an authentication token for the user making the request
 * @param orderId - The ID of the order for which the return order history is being requested.
 * @param memberId - The ID of the member whose return order history is being requested.
 * @param idRep - The ID of the representative who is accessing the return order history.
 * @param groupSlug - a string that specifies the group the user belongs to (e.g. "Account-manager",
 * "finance", "quality")
 * @returns the result of a fetch request made to a specific endpoint based on the input parameters.
 * The result is a Promise object that will resolve to the response of the fetch request.
 */

export const getReturnOrderHistory = async (
  token,
  orderId,
  memberId,
  idRep,
  groupSlug,
  page
) => {
  let header = createHeader(token);

  let result = await fetch.get(
    orderId && groupSlug === "super-user"
      ? `/get-return-order-history?orderId=${orderId}&idMember=${memberId}&account=${idRep}&page=${page}&records=10`
      : orderId && groupSlug === "Account-manager"
        ? `/get-return-order-history?orderId=${orderId}&idMember=${memberId}&account=${idRep}&page=${page}&records=10`
        : orderId && groupSlug === "finance"
          ? `/get-return-order-history?orderId=${orderId}&idMember=${memberId}&finance=${idRep}&page=${page}&records=10`
          : orderId && groupSlug === "quality"
            ? `/get-return-order-history?orderId=${orderId}&idMember=${memberId}&quality=${idRep}&page=${page}&records=10`
            : groupSlug === "super-user"
              ? `/get-return-order-history?idMember=${memberId}&account=${idRep}&page=${page}&records=10`
              : groupSlug === "Account-manager"
                ? `/get-return-order-history?idMember=${memberId}&account=${idRep}&page=${page}&records=10`
                : groupSlug === "finance"
                  ? `/get-return-order-history?idMember=${memberId}&finance=${idRep}&page=${page}&records=10`
                  : groupSlug === "quality"
                    ? `/get-return-order-history?idMember=${memberId}&quality=${idRep}&page=${page}&records=10`
                    : `/get-return-order-history?idMember=${memberId}&page=0&records=10`,
    header
  );

  return result;
};

/**
 * This function retrieves return reports using a token for authentication.
 * @param token - The `token` parameter is likely a user authentication token that is used to verify
 * the identity of the user making the API request. It is passed as an argument to the
 * `getReturnReports` function and is used to create a header for the API request.
 * @returns The `getReturnReports` function is returning the result of a `fetch` request to the
 * `/get-reports` endpoint with a header that includes the provided `token`. The specific data being
 * returned depends on the response from the server.
 */
export const getReturnReports = async (token) => {
  let header = createHeader(token);
  let result = await fetch.get(`/get-reports`, header);
  //console.log(result)
  return result;
};

/**
 * This function retrieves pending transfer orders based on the user's role and ID.
 * @param token - The authentication token required to access the API endpoint.
 * @param idRep - The id of the representative for whom the pending transfer orders are being fetched.
 * @param groupSlug - The groupSlug parameter is a string that represents the group to which the user
 * belongs. It is used to determine the API endpoint to call for retrieving pending transfer orders.
 * The possible values for groupSlug are "Account-manager", "finance", "quality", or any other string
 * representing a different group.
 * @returns the result of a GET request to a specific endpoint based on the provided parameters (token,
 * idRep, groupSlug). The endpoint is determined by the value of groupSlug, which can be
 * "Account-manager", "finance", "quality", or any other value. The result of the request is returned
 * as a Promise.
 */
export const getPendingTransferorders = async (token, idRep, groupSlug) => {
  let header = createHeader(token);

  let result = await fetch.get(
    groupSlug === "Account-manager"
      ? `/get-pending-transfer-orders?account=${idRep}`
      : groupSlug === "finance"
        ? `/get-pending-transfer-orders?finance=${idRep}`
        : groupSlug === "quality"
          ? `/get-pending-transfer-orders?quality=${idRep}`
          : groupSlug === "super-user"
            ? `/get-pending-transfer-orders?superuser=${idRep}`
            : `/get-pending-transfer-orders`,
    header
  );

  return result;
};

/**
 * This function retrieves transfer order history based on various parameters and returns the result.
 * @param token - The authentication token required to make API requests.
 * @param orderId - The ID of the transfer order for which the history is being requested. If not
 * provided, the API will return the transfer history for all orders associated with the provided
 * account ID.
 * @param idRep - The id of the representative.
 * @param groupSlug - The groupSlug parameter is a string that specifies the group to which the user
 * belongs. It can have one of the following values: "Account-manager", "finance", "quality", or
 * "super-user".
 * @returns the result of the fetch request made to the specified endpoint, with the specified header.
 */
export const getTransfersHistory = async (
  token,
  orderId,
  idRep,
  groupSlug,
  page
) => {
  let header = createHeader(token);

  let result = await fetch.get(
    orderId && groupSlug === "Account-manager"
      ? `/get-transfer-order-history?orderId=${orderId}&account=${idRep}&page=${page}&records=10`
      : orderId && groupSlug === "finance"
        ? `/get-transfer-order-history?orderId=${orderId}&finance=${idRep}&page=${page}&records=10`
        : orderId && groupSlug === "quality"
          ? `/get-transfer-order-history?orderId=${orderId}&quality=${idRep}&page=${page}&records=10`
          : orderId && groupSlug === "super-user"
            ? `/get-transfer-order-history?orderId=${orderId}&account=${idRep}&page=${page}&records=10`
            : groupSlug === "Account-manager"
              ? `/get-transfer-order-history?account=${idRep}&page=${page}&records=10`
              : groupSlug === "super-user"
                ? `/get-transfer-order-history?account=${idRep}&page=${page}&records=10`
                : groupSlug === "finance"
                  ? `/get-transfer-order-history?&finance=${idRep}&page=${page}&records=10`
                  : groupSlug === "quality"
                    ? `/get-transfer-order-history?&quality=${idRep}&page=${page}&records=10`
                    : `/get-transfer-order-history?account=${idRep}&page=0p&records=10`,

    header
  );

  return result;
};

/**
 * This function retrieves all shipto or sku data based on the specified type and account ID.
 * @param token - The authentication token needed to access the API endpoint.
 * @param type - The type of data to retrieve, which could be either "shipto" or "sku".
 * @param idRep - The parameter `idRep` is likely an identifier for a sales representative or account
 * manager. It is being used in the API call to filter the results of the `shipto-or-sku` endpoint by
 * account.
 * @returns The function `getAllShiptoOrSku` is returning the result of a GET request to the
 * `/shipto-or-sku` endpoint with query parameters `type` and `account`, using the `token` and `idRep`
 * variables to create the request header. The result of the request is being returned.
 */
export const getAllShiptoOrSku = async (
  token,
  type,
  idRep,
  materialGroupSelected
) => {
  let header = createHeader(token);

  let result = await fetch.get(
    materialGroupSelected
      ? `/shipto-or-sku?type=ph1&account=${idRep}&ph1=${materialGroupSelected}`
      : `/shipto-or-sku?type=${type}&account=${idRep}`,
    header
  );

  return result;
};

/**
 * This function creates a return order using the provided body, token, and ID.
 * @param body - The request body that contains the data needed to create a return order. It could
 * include information such as the items being returned, the reason for the return, and any additional
 * notes or comments.
 * @param token - The token is likely an authentication token that is used to verify the identity of
 * the user making the request. It is usually obtained by logging in or authenticating with the server.
 * @param id - The `id` parameter is the account ID that is used to identify the account for which the
 * return order is being created.
 * @returns the result of the fetch request made to create a return order.
 */
export const createReturnOrder = async (body, token, id, pdfId) => {
  let header = createHeader(token);

  let result = await fetch.post(
    pdfId
      ? `/creater-return-order?account=${id}&division=test&documentId=${pdfId}`
      : `/creater-return-order?account=${id}&division=test`,
    header,
    body
  );

  return result;
};

/**
 * This function retrieves return orders using a token and representative ID.
 * @param token - The token is likely an authentication token that is used to verify the identity of
 * the user making the request. It is usually obtained through a login process and is required to
 * access protected resources on the server.
 * @param idRep - It seems that `idRep` is a code used to identify a representative. This code is
 * passed as a query parameter in the API endpoint `/get-return-orders`. The function `getReturnOrders`
 * uses this code to retrieve return orders associated with the representative identified by the code.
 * @returns The function `getReturnOrders` is returning the result of a GET request to the endpoint
 * `/get-return-orders` with a query parameter `code` equal to the value of `idRep`. The result is
 * being returned as a Promise.
 */
export const getReturnOrders = async (token, idRep) => {
  let header = createHeader(token);

  let result = await fetch.get(`/get-return-orders?code=${idRep}`, header);

  return result;
};

/**
 * This function creates a transfer order with a given body, token, and account ID.
 * @param body - The body parameter is likely an object containing data that will be sent in the
 * request body to create a transfer order. The specific properties and values of this object will
 * depend on the requirements of the API being used.
 * @param token - The `token` parameter is likely an authentication token or access token that is used
 * to authenticate the user making the API request. It is passed as an argument to the
 * `createTranferOrder` function to include it in the request header for authentication purposes.
 * @param id - The `id` parameter is the account ID to which the transfer order will be created.
 * @returns The function `createTranferOrder` is returning the result of the `fetch.post` request made
 * to the `/create-transfer-order` endpoint with the provided `header` and `body` parameters.
 */
export const createTranferOrder = async (body, token, id, pdfId) => {
  let header = createHeader(token);

  let result = await fetch.post(
    pdfId
      ? `/create-transfer-order?account=${id}&documentId=${pdfId}`
      : `/create-transfer-order?account=${id}`,
    header,
    body
  );

  return result;
};
/**
 * This function retrieves transfer orders based on a given token, type, and ID.
 * @param token - The token is likely an authentication token that is used to verify the identity of
 * the user making the request. It is usually obtained by logging in or authenticating with the server.
 * @param type - The type of transfer order being requested. It could be "inbound" or "outbound".
 * @param idRep - The parameter `idRep` is likely an identifier or code for a specific representative
 * or location. It is being used in the API endpoint `/get-transfer-order` to retrieve transfer orders
 * of a certain type for that representative or location.
 * @returns The function `getTranferOrders` is returning the result of a `fetch` request to the
 * endpoint `/get-transfer-order` with query parameters `type` and `code` set to the values of the
 * `type` and `idRep` parameters respectively, and with the `header` object passed as the second
 * argument to the `fetch.get` method. The specific data being returned depends
 */

export const getTranferOrders = async (token, type, idRep) => {
  let header = createHeader(token);

  let result = await fetch.get(
    `/get-transfer-order?type=${type}&code=${idRep}`,
    header
  );

  return result;
};

/**
 * This function updates the status of a transfer order with a given order ID, status, and
 * representative ID using a provided token.
 * @param token - The authentication token required to access the API endpoint for updating the status
 * of a transfer order.
 * @param orderID - The ID of the transfer order that needs to be updated.
 * @param status - The new status that the transfer order will be updated to.
 * @param idRep - Based on the function signature, `idRep` is a parameter that represents the code of
 * the representative who is updating the status of the transfer order.
 * @returns the result of the fetch request made to the `/update-transfer-order` endpoint with the
 * specified parameters. The result could be any data that is returned by the server in response to the
 * request.
 */
export const updateStatusTransferOrder = async (
  token,
  orderID,
  status,
  idRep,
  memberId
) => {
  let header = createHeader(token);

  let result = await fetch.post(
    `/update-transfer-order?status=${status}&orderID=${orderID}&code=${idRep}&memberId=${memberId}`,
    header
  );
  return result;
};

export const saveToken = async (body, token) => {
  let header = createHeader(token);

  let result = await fetch.post(`/save-user-token`, header, body);

  return result;
};

/**
 * The function retrieves XML data based on the provided parameters using a token and fetch request.
 * @param token - The authentication token needed to access the API.
 * @param typeorder - It is a string parameter that specifies the type of order for which the XML data
 * is being requested.
 * @param idRep - The idRep parameter is likely an identifier for a representative or account manager,
 * finance personnel, or quality personnel depending on the value of the groupSlug parameter. It is
 * used to filter the data that will be retrieved from the API endpoint.
 * @param groupSlug - The groupSlug parameter is a string that represents the group slug of the user.
 * It is used to determine the endpoint URL for the API call. The possible values for groupSlug are
 * "Account-manager", "finance", and "quality". If the groupSlug is not one of these values, the
 * default
 * @returns The function `getXmlData` is returning the result of a `fetch` request made to a specific
 * endpoint based on the provided parameters. The result is an asynchronous operation, so it returns a
 * promise that will resolve to the response of the request.
 */
export const getXmlData = async (token, typeorder, idRep, groupSlug) => {
  let header = createHeader(token);

  let result = await fetch.get(
    groupSlug === "Account-manager"
      ? `/get-data-xml?typeOrder=${typeorder}&account=${idRep}`
      : groupSlug === "super-user"
        ? `/get-data-xml?typeOrder=${typeorder}&account=${idRep}`
        : groupSlug === "finance"
          ? `/get-data-xml?typeOrder=${typeorder}&finance=${idRep}`
          : groupSlug === "quality"
            ? `/get-data-xml?typeOrder=${typeorder}&quality=${idRep}`
            : `/get-data-xml?typeOrder=${typeorder}&user=${idRep}`,
    header
  );

  return result;
};

/**
 * This function retrieves processed orders using a token for authentication.
 * @param token - The `token` parameter is likely a user authentication token that is used to verify
 * the identity of the user making the request. It is passed as an argument to the `getProcessedOrders`
 * function and is used to create a header that is sent along with the HTTP request to the server. The
 * server
 * @returns The function `getProcessedOrders` is returning the result of a GET request to the
 * "/get-processed-order" endpoint with the provided token in the header. The result could be any data
 * type depending on the response from the server.
 */
export const getProcessedOrders = async (
  token,
  idRep,
  groupSlug,
  page,
  orderId
) => {
  let header = createHeader(token);
  let result = await fetch.get(
    orderId
      ? `/get-processed-order?account=${idRep}&page=${page}&records=10&id=${orderId}`
      : groupSlug === "Account-manager"
        ? `/get-processed-order?account=${idRep}&page=${page}&records=10`
        : groupSlug === "super-user"
          ? `/get-processed-order?account=${idRep}&page=${page}&records=10`
          : "/get-processed-order",
    header
  );
  return result;
};

/**
 * This function retrieves processed return orders based on the provided token, representative ID, and
 * group slug.
 * @param token - The authentication token needed to access the API.
 * @param idRep - The idRep parameter is likely an identifier for a representative or account manager.
 * It is used in the function to filter processed return orders based on the account manager if the
 * groupSlug is "Account-manager".
 * @param groupSlug - The `groupSlug` parameter is a string that determines the group of users for
 * which the processed return orders should be retrieved. If the `groupSlug` is "Account-manager", the
 * function will retrieve processed return orders for a specific account manager identified by the
 * `idRep` parameter. Otherwise, if
 * @returns the result of a GET request to the endpoint `/processed-return-order` with optional query
 * parameters depending on the value of `groupSlug`. The result is being fetched using the `fetch.get`
 * method and is being returned as a Promise.
 */
export const getProcessedReturnOrders = async (
  token,
  idRep,
  groupSlug,
  page,
  orderId
) => {
  let header = createHeader(token);
  let result = await fetch.get(
    orderId
      ? `/processed-return-order?account=${idRep}&page=${page}&records=10&id=${orderId}`
      : groupSlug === "Account-manager"
        ? `/processed-return-order?account=${idRep}&page=${page}&records=10`
        : groupSlug === "super-user"
          ? `/processed-return-order?account=${idRep}&page=${page}&records=10`
          : `/processed-return-order?`,
    header
  );
  return result;
};

/**
 * This function retrieves processed transfer orders based on the provided parameters.
 * @param token - The authentication token needed to access the API endpoint.
 * @param idRep - The `idRep` parameter is likely an identifier for a representative or account
 * manager. It is used in the API call to filter processed transfer orders by the account manager's ID
 * if the `groupSlug` parameter is set to "Account-manager".
 * @param groupSlug - The `groupSlug` parameter is a string that represents the group or category of
 * transfer orders to retrieve. It can have two possible values: "Account-manager" or any other value.
 * If the value is "Account-manager", the function will retrieve only the processed transfer orders
 * that belong to the account manager
 * @returns the result of a GET request to the endpoint `/processed-transfer-order` or
 * `/processed-transfer-order?account=` depending on the value of the `groupSlug` parameter.
 * The result is being fetched using the `fetch.get` method and the `header` parameter is being passed
 * as the second argument. The result of the GET request is being returned by the function.
 */
export const getProcessedTransferOrders = async (
  token,
  idRep,
  groupSlug,
  page,
  orderId
) => {
  let header = createHeader(token);

  let result = await fetch.get(
    orderId
      ? `/processed-transfer-order?account=${idRep}&page=${page}&records=10&id=${orderId}`
      : groupSlug === "Account-manager"
        ? `/processed-transfer-order?account=${idRep}&page=${page}&records=10`
        : groupSlug === "super-user"
          ? `/processed-transfer-order?account=${idRep}&page=${page}&records=10`
          : `/processed-transfer-order`,
    header
  );
  return result;
};

export const createTranferTemplateOrder = async (body, apikey) => {
  let header = createHeader2(apikey);
  let template = true;

  let result = await fetch.post(
    `/processes/9zmwn5zd/u1ggel1/start`,
    header,
    body,
    null,
    template
  );

  return result;
};

export const createReturnTemplateOrder = async (body, apikey) => {
  let header = createHeader2(apikey);
  let template = true;

  let result = await fetch.post(
    `/processes/9zmwn5zd/clsx2rv/start`,
    header,
    body,
    null,
    template
  );

  return result;
};

export const orderPdf = async (body, token, orderId, type) => {
  let header = createHeaderFormData(token);

  let result = await fetch.formData(
    orderId
      ? `/charge-documents?type=${type}&orderId=${orderId}`
      : `/charge-documents?type=${type}`,
    header,
    body
  );

  return result;
};

export const orderExcel = async (body, token, orderId, type) => {
  let header = createHeader2(token);

  let result = await fetch.post(
    orderId
      ? `/charge-documents?type=${type}&orderId=${orderId}`
      : `/charge-documents?type=${type}`,
    header,
    body
  );

  return result;
};

export const getClientUnlockRequest = async (
  token,
  status,
  transfer,
  idRep,
  history
) => {
  let header = createHeader(token);

  let result = await fetch.get(
    status && transfer
      ? `/get-client-unlock-request?page=0&records=100&status=${status}&transfer=${transfer}&coordinator=${idRep}`
      : history && transfer
        ? `/get-client-unlock-request?page=0&records=100&transfer=${transfer}&coordinator=${idRep}`
        : transfer
          ? `/get-client-unlock-request?page=0&records=100&transfer=${transfer}&account=${idRep}`
          : status
            ? `/get-client-unlock-request?page=0&records=100&status=${status}&coordinator=${idRep}`
            : history
              ? `/get-client-unlock-request?page=0&records=100&coordinator=${idRep}`
              : `/get-client-unlock-request?page=0&records=100&account=${idRep}`,
    header
  );
  return result;
};

export const updateUnlockRequest = async (body, token, orderId, isTransfer) => {
  let header = createHeader(token);
  let result = await fetch.post(
    isTransfer
      ? `/updated-client-unlock-request?orderId=${orderId}&transfer=${isTransfer}`
      : `/updated-client-unlock-request?orderId=${orderId}`,

    header,
    body
  );
  return result;
};

export const deleteDocumentById = async (id, token) => {
  let header = createHeader(token);
  let result = await fetch.post(
    `/delete-document-by-id?documentId=${id}`,
    header
  );
  return result;
};

export const updateUnlockOrderStatus = async (
  body,
  token,
  orderId,
  status,
  idRep
) => {
  let header = createHeader(token);
  let result = await fetch.post(
    `/update-order-status?orderID=${orderId}&status=${status}&code=${idRep}&idUser=null&wallet=true`,

    header,
    body
  );
  return result;
};

export const getDocumentById = async (token, documentId) => {
  let header = createHeader(token);

  let result = await fetch.get(
    `/get-document-by-id?documentId=${documentId}`,
    header
  );
  return result;
};

export const updateUnlockTransferStatus = async (
  body,
  token,
  orderId,
  status,
  idRep
) => {
  let header = createHeader(token);
  let result = await fetch.post(
    `/update-transfer-order?orderID=${orderId}&status=${status}&code=${idRep}&idUser=null&wallet=true`,

    header,
    body
  );
  return result;
};
export const getOrdersReports = async (
  token,
  startDate,
  endDate,
  account,
  orderId,
  reportType,
  page,
  records
) => {
  let header = createHeader(token);

  let result = await fetch.get(
    startDate && endDate && reportType && orderId
      ? `/get-order-reports?page=${page}&records=${records}&startDate=${startDate}&endDate=${endDate}&orderType=${reportType}&orderId=${orderId}`
      : startDate && endDate && account && reportType && orderId
        ? `/get-order-reports?page=${page}&records=${records}&startDate=${startDate}&endDate=${endDate}&accountId=${account}&orderType=${reportType}&orderId=${orderId}`
        : startDate && endDate && account && reportType
          ? `/get-order-reports?page=${page}&records=${records}&startDate=${startDate}&endDate=${endDate}&accountId=${account}&orderType=${reportType}`
          : startDate && endDate && reportType
            ? `/get-order-reports?page=${page}&records=${records}&startDate=${startDate}&endDate=${endDate}&orderType=${reportType}`
            : startDate && endDate && account
              ? `/get-order-reports?page=${page}&records=${records}&startDate=${startDate}&endDate=${endDate}&accountId=${account}`
              : orderId && reportType
                ? `/get-order-reports?page=${page}&records=${records}&orderId=${orderId}&orderType=${reportType}`
                : orderId
                  ? `/get-order-reports?page=${page}&records=${records}&orderId=${orderId}`
                  : account && orderId
                    ? `/get-order-reports?page=${page}&records=${records}&accountId=${account}&orderId=${orderId}`
                    : account && reportType
                      ? `/get-order-reports?page=${page}&records=${records}&accountId=${account}&orderType=${reportType}`
                      : reportType
                        ? `/get-order-reports?page=${page}&records=${records}&orderType=${reportType}`
                        : `/get-order-reports?page=${page}&records=${records}`,
    header
  );

  return result;
};

export const getCatalogAccounts = async (token) => {
  let header = createHeader(token);

  let result = await fetch.get(`/get-catalog?catalog=account-manager`, header);
  return result;
};

export const loadBlockedClients = async (body, token, user) => {
  let header = createHeader(token);
  let result = await fetch.post(
    `/load-clients-coordinators?user=${user}`,
    header,
    body
  );
  return result;
};

export const getBlockedCustomerHistory = async (token) => {
  let header = createHeader(token);

  let result = await fetch.get(
    `/get-blocked-customer-history-handlers?page=0&records=10`,
    header
  );
  return result;
};

export const DeleteBlockedClientsDocuments = async (id, token) => {
  let header = createHeader(token);
  let result = await fetch.post(
    `/delete-blocked-customer-history-handlers?id=${id}`,
    header
  );
  return result;
};

export const getBlockedClients = async (token) => {
  let header = createHeader(token);

  let result = await fetch.get(
    `/get-blocked-clients?page=0&records=500`,
    header
  );
  return result;
};

export const manageBlockedClients = async (body, token, user) => {
  let header = createHeader(token);
  let result = await fetch.post(
    `/manage-blocked-clients?user=${user}`,
    header,
    body
  );
  return result;
};
