import React, { useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import OrderSteps from "components/Orders/OrderSteps";
import "bootstrap/dist/css/bootstrap.min.css";

function Details(props) {
  const { orderId, classes, status } = props.location.state;
  const [progress, setProgress] = useState(15);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (status === "1") {
      setProgress(15);
    } else if (status === "2") {
      setProgress(40);
    } else if (status === "3") {
      setProgress(65);
    } else if (status === "4") {
      setProgress(100);
    }
    setShow(true);
  }, []);

  return (
    <div>
      {show ? (
        <>
          <OrderSteps status={status} />
          <ProgressBar striped variant="info" now={progress} animated />
        </>
      ) : null}
    </div>
  );
}

export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
  }))(Details)
);
