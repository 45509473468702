import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Button from "components/CustomButtons/Button.jsx";
import lockScreenPageStyle from "assets/jss/material-dashboard-pro-react/views/lockScreenPageStyle.jsx";
import {withTranslation} from "react-i18next";

// Background image
import backHome from "../../assets/img/backHome.jpg";
import {withRouter} from "react-router-dom";

class App extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden"
    };
  }
  componentDidMount = async() =>{
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  render() {
    const { classes, t } = this.props;

    return (
        <div className={classes.container}>
            <div className={classes.content}>
                <h2>{t("text.text_1")}</h2>
                <h1 className="title">{t("text.text_2")}</h1>
                <div className="text">
                    <h4>{t("text.text_3")}</h4>
                </div>
                <Button className={classes.btn} onClick={() => this.props.history.push("/login-page")}>
                    Login
                </Button>
            </div>
            <div className={classes.containerImg}>
                <img src={backHome} style={{height:"100%", position:"relative", left:"-28rem", bottom:"-10rem"}}/>
            </div>
        </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withTranslation("welcomePage")(withStyles(lockScreenPageStyle)(App)));
