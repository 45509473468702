/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@mui/material/Grid";
import batchViewsStyle from "assets/jss/material-dashboard-pro-react/components/BatchViewStyle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
//Translate
import { useTranslation } from "react-i18next";

function InformationItem(props) {
  const { classes, products, batch, batchIncoming } = props;
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: "#efefef",
      font: "bold 98% monospace",
      border: "1px solid #DDDDDD",
      background: "#6c94bc",
    },
    [`&.${tableCellClasses.body}`]: {
      color: "black",
      fontFamily: "monospace",
      border: "1px solid #DDDDDD",
      margin: "8x 10px",
      padding: "8px 10px"
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      border: "1px solid #DDDDDD",
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: "1px solid #DDDDDD",
    },
  }));

  const { t } = useTranslation("batchView");

  return (
    <Grid container xs={12} className={classes.container_list_item_information}>
      {batch ? <Grid container xs={12} sm={12}>
            <Grid item xs={12} sm={6}>
              <Grid  style={{ marginBottom: 20 }}>
                <p>{t("Clinic / Hospital")}</p>
                <br/>
                <span>{products.company_name} - {products.f_name} {products.l_name}</span>
                <br/>
                <span>{products.email}</span>
              </Grid>
              <Grid xs={12} sm={6} style={{ marginBottom: "20px" }}>
                <p style={{ marginBottom: "12px" }}>{t("Department / Place")}</p>
                <span>{t("Code")}: None</span>
                <br/>
                <span>{t("Name")}: None</span>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid xs={12} sm={6} style={{ marginBottom: "20px" }}>
                <p style={{ marginBottom: "12px" }}>{t("Patient")}</p>
                <span>{t("Patient ID")}: {products.patient_id}</span>
                <br/>
                <span>{t("Internal ID")}: {products.patient_internal_id}</span>
                <br/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid xs={12} sm={6} style={{ marginBottom: "20px" }}>
                  <p style={{ marginBottom: "12px" }}>{t("Clinic / Hospital Details")}</p>
                  <span>{products.address}, {products.city}, {products.state}, {products.country} - {products.zipcode}</span>
                  <br/>
                  <span>{t("Phone")}: {products.contactno}</span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>: batchIncoming ? <Grid container xs={12} sm={12}>
            <Grid item xs={12} sm={6}>
              <Grid  style={{ marginBottom: 20 }}>
                <p>{t("Clinic / Hospital")}</p>
                <br/>
                <span>{products.company_name} - {products.f_name} {products.l_name}</span>
                <br/>
                <span>{products.email}</span>
              </Grid>
              <Grid xs={12} sm={6} style={{ marginBottom: "20px" }}>
                <p style={{ marginBottom: "12px" }}>{t("Department / Place")}</p>
                <span>{t("Code")}: {products.place_code}</span>
                <br/>
                <span>{t("Name")}: {products.place_name}</span>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid item xs={12} sm={6}>
                <Grid xs={12} sm={6} style={{ marginBottom: "20px" }}>
                  <p style={{ marginBottom: "12px" }}>{t("Clinic / Hospital Details")}</p>
                  <span>{products.address}, {products.city}, {products.state}, {products.country} - {products.zipcode}</span>
                  <br/>
                  <span>{t("Phone")}: {products.contactno}</span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>:
        <Grid container xs={12} sm={12}>
        <Grid item xs={12} sm={6}>
          <Grid  style={{ marginBottom: 20 }}>
            <p>{t("Clinical Specialist")}</p>
            <span>{products.f_name_origin} {products.l_name_origin}</span>
          </Grid>
          <Grid xs={12} sm={6} style={{ marginBottom: "20px" }}>
            <p>{t("Clinical Specialist Details")}</p>
            <span>{t("Email")}: {products.email_origin}</span>
            <br/>
            <span>{t("Address")}: {products.address_origin}, {products.city_origin}, {products.state_origin}, {products.country_origin}, {products.zipcode_origin}</span>
            <br/>
            <span>{t("Company")}: {products.company_name_origin}</span>
            <br/>
            <span>{t("Members")}: {products.member_origin}</span>
            <br/>
            <span>{t("Phone")}: {products.contactno_origin}</span>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid xs={12} sm={6} style={{ marginBottom: "20px" }}>
            <p>{t("Clinical Specialist Transfer")}</p>
            <span>{products.f_name_transfer} {products.l_name_transfer}</span>
          </Grid>
          <Grid xs={12} sm={6} style={{ marginBottom: "20px" }}>
            <p>{t("Clinical Specialist Transfer Details")}</p>
            <span>{t("Email")}: {products.email_transfer}</span>
            <br/>
            <span>{t("Address")}: {products.address_transfer}, {products.city_transfer}, {products.state_transfer}, {products.country_transfer}, {products.zipcode_transfer}</span>
            <br/>
            <span>{t("Company")}: {products.company_name_transfer}</span>
            <br/>
            <span>{t("Members")}: {products.member_transfer}</span>
            <br/>
            <span>{t("Phone")}: {products.contactno_transfer}</span>
            <br/>
          </Grid>
        </Grid>
      </Grid>}
      {(batch || batchIncoming) ? <Grid xs={12}>
            <TableContainer component={Paper} style={{ marginBottom: "7px" }}>
              <Table
                  sx={{ minWidth: 700 }}
                  aria-label="customized table"
                  size="small"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>UPN</StyledTableCell>
                    <StyledTableCell>GTIN</StyledTableCell>
                    <StyledTableCell>{t("Description")}</StyledTableCell>
                    <StyledTableCell>Qty</StyledTableCell>
                    <StyledTableCell>{t("Batch")}</StyledTableCell>
                    <StyledTableCell>{t("Exp. date")}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.products.map((product) => (
                      <StyledTableRow key={product.batch_id}>
                        <StyledTableCell>{product.upn}</StyledTableCell>
                        <StyledTableCell>{product.gtin}</StyledTableCell>
                        <StyledTableCell>{product.description}</StyledTableCell>
                        <StyledTableCell>{batch ? product.qty : product.quantity}</StyledTableCell>
                        <StyledTableCell>{batch ? product.lot : product.batch}</StyledTableCell>
                        <StyledTableCell>{batch ? product.expiry : product.expiration_date.slice(0,-8)}</StyledTableCell>
                      </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid> :
          <Grid xs={12}>
            <TableContainer component={Paper} style={{ marginBottom: "7px" }}>
              <Table
                  sx={{ minWidth: 700 }}
                  aria-label="customized table"
                  size="small"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>UPN</StyledTableCell>
                    <StyledTableCell>{t("Batch")}</StyledTableCell>
                    <StyledTableCell>{t("Description")}</StyledTableCell>
                    <StyledTableCell>Conv</StyledTableCell>
                    <StyledTableCell>{t("Quantity")}</StyledTableCell>
                    <StyledTableCell>Qn</StyledTableCell>
                    <StyledTableCell>{t("Line")}</StyledTableCell>
                    <StyledTableCell>{t("Days Consigned")}</StyledTableCell>
                    <StyledTableCell>{t("Delivery")}</StyledTableCell>
                    <StyledTableCell>Remito</StyledTableCell>
                    <StyledTableCell>{t("# Order")}</StyledTableCell>
                    <StyledTableCell>{t("Purchase Order")}</StyledTableCell>
                    <StyledTableCell>{t("Exp. date")}</StyledTableCell>
                    <StyledTableCell>{t("Ord. date")}</StyledTableCell>
                    <StyledTableCell>{t("Disp. date")}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.products.map((product) => (
                      <StyledTableRow key={product.batch_id}>
                        <StyledTableCell>{product.upn}</StyledTableCell>
                        <StyledTableCell>{product.batch}</StyledTableCell>
                        <StyledTableCell>{product.description}</StyledTableCell>
                        <StyledTableCell>{product.conv}</StyledTableCell>
                        <StyledTableCell>{product.quantity}</StyledTableCell>
                        <StyledTableCell>{product.qn}</StyledTableCell>
                        <StyledTableCell>{product.line}</StyledTableCell>
                        <StyledTableCell>{product.days_consigned}</StyledTableCell>
                        <StyledTableCell>{product.delivery}</StyledTableCell>
                        <StyledTableCell>{product.remito}</StyledTableCell>
                        <StyledTableCell>{product.order_no}</StyledTableCell>
                        <StyledTableCell>{product.purchase_order}</StyledTableCell>
                        <StyledTableCell>{product.expiration_date.slice(0, -14)}</StyledTableCell>
                        <StyledTableCell>{product.order_date.slice(0, -14)}</StyledTableCell>
                        <StyledTableCell>{product.dispatch_date.slice(0, -14)}</StyledTableCell>
                      </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>}
    </Grid>
  );
}

InformationItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(batchViewsStyle)(InformationItem);
