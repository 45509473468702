// ##############################
// // // SweetAlert view styles
// #############################


const ordersStyle = {
    title:{
      fontSize: 30,
      borderBottom: "solid 1px black",
      marginBottom: 50,
      marginTop: 80,
      color: "#696969"
    },
    steps:{
      width: 50,
      backgroundColor: "#008B8B",
      borderRadius: "100%",
      textAlign: "center", 
      height: 50,
      paddingTop: 10,    
      marginLeft: "50%",
      "& h1":{
        color: "white",
        fontSize: "25px",
      }
    },
    stepsActive:{
      width: 50,
      backgroundColor: "#3CB371",
      borderRadius: "100%",
      textAlign: "center", 
      height: 50,
      paddingTop: 10,    
      marginLeft: "50%",
      "& h1":{
        color: "white",
        fontSize: "25px",
      }
    },
    container:{
      "& p":{
      marginLeft: "40%",

      }
    }
  };

  
  export default ordersStyle;
  