import React, { useEffect } from "react";

import ScannedProductBatchControllers from "../../../controllers/ScannedProductBatchControllers";

// component view
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import BatchView from "../../../components/Batch/BatchView";
import empty from "../../../assets/img/dashboard/empty.svg";
import Toast from "components/Toast/Toast.jsx";

function ScannedBatchProducts(props) {
  const title = "Batch of Scanned Products";
  const {
    handleGetScannedProductBatch,
    products,
    handleChangePage,
    page,
    totalPage,
    handlerChangeRecordsPage,
    localRecords,
    handleGetScannedFilter,
    handlerChangeData,
    filter,
    handlerCreateReplacementOrders,
    setToast,
  } = ScannedProductBatchControllers(props);
  const token = props.userToken;

  useEffect(
    async () => {
      if (products.length === 0) props.setLoaderVisible(true);
      await handleGetScannedProductBatch({ token });
      if (products.length === 0) props.setLoaderVisible(false);
    },
    [page || localRecords]
  );

  return (
    <>
      <Toast
        innerRef={(ref) => {
          setToast(ref);
        }}
      />
      {props.data.scannedBatch ? (
        <BatchView
          title={title}
          products={
            !filter
              ? page === 0 && localRecords === 10
                ? props.data.scannedBatch
                  ? props.data.scannedBatch.scanned
                  : products
                : products
              : products
          }
          batch={true}
          handleChangePage={handleChangePage}
          handlerChangeRecordsPage={handlerChangeRecordsPage}
          handlerCreateReplacementOrders={handlerCreateReplacementOrders}
          totalPage={
            page === 0 && localRecords === 10
              ? Math.ceil(props.data.scannedBatch.totalRecords / 10)
              : totalPage
          }
          handleGetScannedFilter={handleGetScannedFilter}
          handlerChangeData={handlerChangeData}
        />
      ) : (
        <img
          src={empty}
          alt="Not found data"
          style={{ height: "16rem", margin: "6rem 3rem" }}
        />
      )}
    </>
  );
}

export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
    data: state.root.general.dataUserAPI,
  }))(ScannedBatchProducts)
);
