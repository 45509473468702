/* eslint-disable */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@mui/material/Grid";
import batchViewsStyle from "assets/jss/material-dashboard-pro-react/components/BatchViewStyle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

function InformationItem(props) {
  const { classes, orders } = props;
  const { t } = useTranslation("batchView");
  const [status, setStatus] = useState("");

  useEffect(() => {
    switch (orders.order_status) {
      case 0:
        setStatus("order created");
        break;
      case 1:
        setStatus("dispatched");
        break;
      case 2:
        setStatus("driver accepted");
        break;
      case 3:
        setStatus("picked up");
        break;
      case 4:
        setStatus("in transit");
        break;
      case 5:
        setStatus("delivered");
        break;
      case 6:
        setStatus("order completed");
        break;

      default:
        null;
        break;
    }
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: "#efefef",
      font: "bold 98% monospace",
      border: "1px solid #DDDDDD",
      background: "#6c94bc",
    },
    [`&.${tableCellClasses.body}`]: {
      color: "black",
      fontFamily: "monospace",
      border: "1px solid #DDDDDD",
      margin: "8x 10px",
      padding: "8px 10px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      border: "1px solid #DDDDDD",
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: "1px solid #DDDDDD",
    },
  }));
  return (
    <Grid container xs={12} className={classes.container_list_item_information}>
      <Grid xs={12}>
        <TableContainer component={Paper} style={{ marginBottom: "7px" }}>
          <Table
            sx={{ minWidth: 700 }}
            aria-label="customized table"
            size="small"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>UPN</StyledTableCell>
                <StyledTableCell>{t("Batch")}</StyledTableCell>
                <StyledTableCell>{t("Description")}</StyledTableCell>
                <StyledTableCell>Qty</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.products.map((product) => (
                <StyledTableRow key={product.order_id}>
                  <StyledTableCell>{product.upn}</StyledTableCell>
                  <StyledTableCell>{product.batch}</StyledTableCell>
                  <StyledTableCell>{product.description}</StyledTableCell>
                  <StyledTableCell>{product.quantity}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

InformationItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(batchViewsStyle)(InformationItem);
