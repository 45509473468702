import * as types from "./types";

export const updateUserToken = function(token) {
  return {
    type: types.UPDATE_USERTOKEN,
    payload: token,
  };
};

export const updateuserInfoToken = function(info) {
  return {
    type: types.UPDATE_userInfo,
    payload: info,
  };
};

export const updateuserInfo = function(info) {
  return {
    type: types.UPDATE_userData,
    payload: info,
  };
};

export const clearPostsStatus = () => {
  return {
    type: types.CLEAR_POSTS_STATUS,
  };
};
