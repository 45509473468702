import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'
import socketMiddleware from './socket/middleware';

// import reducers
import root from 'redux/reducer'

const rootReducer = combineReducers({
  root,
})

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const middleware = [thunk, socketMiddleware];
export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middleware)));
//export const store = createStore(persistedReducer, applyMiddleware(...middleware));

export const persistor = persistStore(store)

