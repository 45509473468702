import AccountBoxIcon  from "@material-ui/icons/AccountBox";
import WelcomePage from "views/Pages/WelcomePage.jsx";
import LoginPage from "views/Pages/LoginPage.jsx";

// @material-ui/icons
import HomeIcon from '@material-ui/icons/Home';

const pagesRoutes = [
  {
    path: "/welcome-page",
    name: "Home Page",
    short: "Home",
    mini: "WP",
    icon: HomeIcon,
    component: WelcomePage,
  },  
  {
    path: "/login-page",
    name: "Login Page",
    short: "Login",
    icon: AccountBoxIcon,
    component: LoginPage
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/welcome-page",
    name: "Welcome Page"
  }
];

export default pagesRoutes;
