import React, { useEffect } from "react";
import UserViews from "components/userViews";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MemberControllers from "controllers/MemberControllers";

function ClinicalSpecialist(props) {
  const title = "List of Specialists";
  const {
    handleGetMember,
    members,
    handleChangePage,
    page,
    totalPage,
    handlerAddMember,
    localRecords,
  } = MemberControllers(title, props, 8);
  const token = props.userToken;

  useEffect(
    async () => {
      if (members.length === 0) props.setLoaderVisible(true);
      await handleGetMember("clinical-specialist", { token });
      if (members.length === 0) props.setLoaderVisible(false);
    },
    [page || localRecords]
  );
  return (
    <div>
      <UserViews
        title={title}
        handlerAddMember={handlerAddMember}
        members={members}
        handleChangePage={handleChangePage}
        totalPage={totalPage}
        {...props}
      />
      ;
    </div>
  );
}

export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
  }))(ClinicalSpecialist)
);
