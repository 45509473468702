import React from "react";
import { createMembers } from "protocols/requests";
import Geocode from "react-geocode";

export const AddUserControllers = (id, props) => {

  const [data, setData] = React.useState({
    firstName: "",
    lastName: "",
    groupId: id,
    email: "",
    password: "",
    companyName: "",
    contactNo: 0,
    address: "",
    city: "",
    country: "",
    zipCode: "",
    addressDetails: "",
    status: "1",
    state: "",
    lat: "",
    lng: "",
    otherSystemReferenceId: "",
    fax: "",
    accountType: "consumer",
  });
  const [toast, setToast] = React.useState(React.createRef());

  const showToast = (type, content) => {
    if (toast) {
      if (type === "success") {
        toast.showSuccess(content);
      } else {
        toast.showError(content);
      }
    }
  };

  const handlerChangeData = (e) => {
    var key = e.target.id;
    var value = e.target.value;
    setData({ ...data, [key]: value });
  };

  const handleChangeAddress = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const handlerSubmitMember = async () => {
    console.log("1x", data)
    if (
      data.firstName &&
      data.lastName &&
      data.groupId &&
      data.email &&
      data.password &&
      data.companyName &&
      data.contactNo &&
      data.address &&
      data.city &&
      data.country &&
      data.zipCode &&
      data.addressDetails &&
      data.state &&
      data.lng &&
      data.lat
    ) {
      if (id === 6) {
        if (!data.employeeId || !data.accountType) {
          showToast("error", "There are uncompleted fields");
          return false;
        } else {
          data.clinic = "true";
          data.parent_id = props.userInfo.id.toString();
        }
      }
      data.contactNo = parseInt(data.contactNo)
      data.status = parseInt(data.status)
      props.setLoaderVisible(true);
      const result = await createMembers(data, props.userToken);
      props.setLoaderVisible(false);
      if (result.status === 200) {
        showToast("success", result.response.description);
        setData({
          firstName: "",
          lastName: "",
          groupId: id,
          email: "",
          password: "",
          companyName: "",
          contactNo: "",
          address: "",
          city: "",
          country: "",
          zipCode: "",
          addressDetails: "",
          status: "1",
          state: "",
          lat: "",
          lng: "",
          otherSystemReferenceId: "",
          fax: "",
          accountType: "consumer",
        });
      } else {
        if (result.response.errors) {
          const error = result.response.errors[0];
          showToast("error", error.message);
        } else {
          showToast("error", result.response.description);
        }
      }
    } else {
      showToast("error", "There are uncompleted fields");
    }
  };

  const handlerChangeLatLng = () => {
    let city, state, country, zipCode;
    Geocode.setApiKey(process.env.REACT_APP_GOOGLEMAP_KEY);
    Geocode.setLocationType("ROOFTOP");
    Geocode.fromLatLng(data.lat, data.lng)
      .then((response) => {
        response.results[0].address_components.map((item) => {
          switch (item.types[0]) {
            case "locality":
              city = item.long_name;
              break;
            case "administrative_area_level_1":
              state = item.long_name;
              break;
            case "country":
              country = item.long_name;
              break;
            case "postal_code":
              zipCode = item.long_name;
              break;
          }
        });
        setData({ ...data, city, state, country, zipCode });
      })
      .catch((error) => {
        console.log("error");
      });
  };

  return {
    data,
    setToast,
    setData,
    handlerChangeData,
    handleChangeAddress,
    handlerSubmitMember,
    showToast,
    handlerChangeLatLng,
  };
};
