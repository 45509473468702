import { combineReducers } from 'redux'
import { CLEAR_STATE } from './actions/types'
// list of the reducers
import user from './reducers/user'
import general from './reducers/general'

const reducer = combineReducers({
    user,
    general
});

const root = (state, action) => {
    if (action.type === CLEAR_STATE)
        state = undefined;

    return reducer(state, action)
}

export default root;