import React, { useEffect } from "react";
import OrderComponent from "components/Orders/OrderComponent";
import FilesControllers from "controllers/Files";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Toast from "components/Toast/Toast.jsx";
import FilesComponent from "components/Files";
function Files(props) {
  const {
    handlerChangeData,
    handlerCreateOrdes,
    handlerCreateDeliverys,
    data,
    setToast,
  } = FilesControllers(props, props.userToken);
  return (
    <>
      <Toast
        innerRef={(ref) => {
          setToast(ref);
        }}
      />
      <FilesComponent
        data={data}
        handlerChangeData={handlerChangeData}
        handlerCreateOrdes={handlerCreateOrdes}
        handlerCreateDeliverys={handlerCreateDeliverys}
      />
    </>
  );
}

export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
  }))(Files)
);
