import React, { useEffect } from "react";
import BatchView from "components/Batch/BatchView";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
//controller
import TransferBetweenSpecialist from "controllers/TransferBetweenSpecialist";

function TransferSpecialist(props) {
    const title = "Transferred Products";
    const {
        products,
        handleChangePage,
        totalPage,
        handlerChangeRecordsPage,
        dataFilter,
        handleGetTransferBetweenSpecialist,
    } = TransferBetweenSpecialist();

    useEffect(async () => {
        if (products.length === 0) props.setLoaderVisible(true);
        if(Object.keys(props.data).length === 0) await handleGetTransferBetweenSpecialist(props.userToken);
        if (products.length === 0) props.setLoaderVisible(false);
    }, []);

    return (
        <div>
            {products.length !== 0 || Object.keys(props.data).length !== 0 ?
                <BatchView title={title} products={Object.keys(props.data).length !== 0 ?
                    props.data.TransferSpecialist ? props.data.TransferSpecialist.response : products : products} transferred={true}/>:
                <img src="../../../assets/img/dashboard/empty.svg" alt="Not found data"/>}
        </div>
    );
}

export default withRouter(
    connect((state) => ({
        userToken: state.root.user.userToken,
        userInfo: state.root.user.userInfoToken,
        data: state.root.general.dataUserAPI
    }))(TransferSpecialist)
);
