import React, { useState } from "react";
import jwtDecode from "jwt-decode";
import {
  getSkus,
  getAllShiptoOrSku,
  createReturnOrder,
  getidRep,
  getReturnOrders,
  updateOrderStatus,
  getPendingreturnorders,
  getReturnOrderHistory,
  getXmlData,
  getReturnReports,
  getProcessedReturnOrders,
  createReturnTemplateOrder,
  orderPdf,
  getiMaterialGoup,
} from "protocols/requests";
const ReturnRequestController = (props) => {
  const [shiptoandskus, setShiptoandskus] = useState([]);

  const [reasonCancelation, setReasonCancelation] = useState("");
  const [returnreason, setReturnReason] = useState("");
  const [warehouse, setWarehouse] = useState("");
  const [typeReturn, setTypeReturn] = useState([]);
  const [typeReturn2, setTypeReturn2] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const [quantities, setQuantities] = useState([]);
  const [returnReports, setReturnReports] = useState([]);
  const [reasonsReturn, setReasonReturn] = useState("");
  const [returnList, setReturnList] = useState([]);
  const [toast, setToast] = React.useState(React.createRef());
  const [returnOrders, setReturnOrders] = useState([]);
  const [returnOrdersProcessed, setReturnOrdersProcessed] = useState([]);
  const [desc, setDesc] = useState("");
  const [options, setOptions] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [returnOrderHistory, setReturnOrderHistory] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [xmlData, setXmlData] = useState("");
  const [shearch, setShearch] = useState("");
  const [shearchItem, setShearchItem] = useState("");
  const [template, setTemplate] = useState("");
  const [observation, setObservation] = useState("");
  const [pageReturn, setPageReturn] = useState(0);
  const [numberPageReturn, setNumberPageReturn] = useState();
  const [valueAutocomplete, setValueAutocomplete] = useState("");
  const [
    valueAutocompleteTypeReturn,
    setValueAutocompleteTypeReturn,
  ] = useState("");
  const [
    valueAutocompleteReasonReturn,
    setValueAutocompleteReasonReturn,
  ] = useState("");
  const [valueAutocompleteWarehouse, setValueAutocompleteWarehouse] = useState(
    ""
  );
  const [selectShiptorsku, setSelectShiptorsku] = useState("");
  const [selectSkuDesc, setSelectSkuDesc] = useState("");
  const [selectShiptoDesc, setSelectShiptoDesc] = useState("");
  const [jwtToken, setJwtToken] = useState("");
  const [disable, setDisable] = useState(false);
  const [
    numberPageReturnOrderStatus,
    setNumberPageReturnOrderStatus,
  ] = useState();
  const [pageReturnOrderStatus, setPageReturnOrderStatus] = useState(0);
  const [selectedPDF, setSelectedPDF] = useState(null);
  const [pageReturnHistory, setPageReturnHistory] = useState(0);
  const [numberPageReturnHistory, setNumberPageReturnHistory] = useState();
  const [materialGroup, setMaterialGroup] = useState([]);
  const [materialGroupSelected, setMaterialGroupSelected] = useState("");

  const showToast = (type, content) => {
    if (toast) {
      if (type === "success") {
        toast.showSuccess(content);
      } else {
        toast.showError(content);
      }
    }
  };

  const handleGetMaterialGroup = async (token) => {
    const idRep = await getidRep(props.userToken, props.id);
    const result = await getiMaterialGoup(
      token,
      idRep.response?.response[0]?.benefitting_Rep
    );
    if (result.status === 200) {
      const response = result.response.response;
      setMaterialGroup(response);
    }
  };

  const handleChangeObservation = (event) => {
    setObservation(event.target.value);
  };

  const handlegetSkus = async (token, shipto, group, model) => {
    let type = "returns";
    const idRep = await getidRep(props.userToken, props.id);
    props.setLoaderVisible(true);
    const result = await getSkus(
      token,
      shipto,
      model,
      type,
      idRep.response?.response[0]?.benefitting_Rep,
      null,
      shearchItem,
      null,
      parseInt(pageReturn)
    );
    props.setLoaderVisible(false);
    if (result.status === 200) {
      const response = result.response.response;
      const totalRecords = result.response.response.total_records;
      setShiptoandskus(response.skus);
      setNumberPageReturn(totalRecords);
    } else if (result.status === 422) {
      setPageReturn(0);
    } else if (result.status === 404) {
      setNumberPageReturn(null);
    } else {
      showToast("error", result.response.description);
      setNumberPageReturn(null);
    }
  };

  const handleGetReturnProcessedOrders = async (token) => {
    var jwt = jwtDecode(token);
    const idRep = await getidRep(props.userToken, props.id);
    const result = await getProcessedReturnOrders(
      token,
      idRep.response?.response[0]?.benefitting_Rep,
      jwt.groupSlug,
      parseInt(pageReturnOrderStatus),
      orderId
    );

    if (result.status === 200) {
      const response = result.response.response;
      const totalRecords = result.response.total_records;
      setReturnOrdersProcessed(response);
      setNumberPageReturnOrderStatus(totalRecords);
    }
  };

  const handlegetPendingOrders = async (token) => {
    var jwt = jwtDecode(token);
    props.setLoaderVisible(true);
    const idRep = await getidRep(props.userToken, props.id);
    const result = await getPendingreturnorders(
      token,
      idRep.response?.response[0]?.benefitting_Rep,
      jwt.groupSlug
    );
    props.setLoaderVisible(false);
    if (result.status === 200) {
      const response = result.response.orders;
      setPendingOrders(response);
    }
  };

  const handlegetReturnOrderHistory = async (token, memberId) => {
    var jwt = jwtDecode(token);
    setJwtToken(jwt);
    props.setLoaderVisible(true);
    const idRep = await getidRep(props.userToken, props.id);
    const result = await getReturnOrderHistory(
      token,
      orderId,
      memberId,
      idRep.response?.response[0]?.benefitting_Rep,
      jwt.groupSlug,
      pageReturnHistory
    );
    props.setLoaderVisible(false);
    if (result.status === 200) {
      const response = result.response.response;
      const totalRecords = result.response.total_records;
      setReturnOrderHistory(response);
      setNumberPageReturnHistory(totalRecords);
    } else if (result.status === 422) {
      setPageReturnHistory(0);
    } else if (result.status === 404) {
      setNumberPageReturnHistory(null);
    } else {
      showToast("error", result.response.description);
      setNumberPageReturnHistory(null);
    }
  };

  const handlegetReturnReports = async (token) => {
    props.setLoaderVisible(true);
    const result = await getReturnReports(token);
    props.setLoaderVisible(false);
    if (result.status === 200) {
      const clients = result.response.response;
      setReturnReports(clients);
    }
  };

  const handlegetAllShiptoOrSku = async (token, type, shearchShiptoOrSku) => {
    var jwt = jwtDecode(token);
    setJwtToken(jwt);
    const idRep = await getidRep(props.userToken, props.id);
    const result = await getAllShiptoOrSku(
      token,
      type,
      idRep.response?.response[0]?.benefitting_Rep,
      materialGroupSelected ? materialGroupSelected : null
    );
    if (result.status === 200) {
      const response = result.response.response;
      setOptions(response);
    }
    const result2 = await getAllShiptoOrSku(
      token,
      shearchShiptoOrSku,
      idRep.response?.response[0]?.benefitting_Rep,
      materialGroupSelected ? materialGroupSelected : null
    );
    if (result2.status === 200) {
      const response2 = result2.response.response;
      setShearch(response2);
    }
  };

  const handleAddSkuShipto = async (item, batch, id) => {
    let quantity, warehouseValue, typeReturnValue, reasonsReturnValue;
    for (let qty of quantities) {
      if (
        qty.model == item.model &&
        qty.shipto == item.shipto &&
        qty.batch == batch.batch &&
        qty.serialbatch == batch.serial
      )
        quantity = qty.value;
    }

    /*  for (let wh of warehouse) {
      if (wh.model == item.model && wh.shipto == item.shipto)
        warehouseValue = wh.value;
    } */
    for (let tr of typeReturn) {
      if (tr.id == id) typeReturnValue = tr.value;
    }
    /*    for (let rr of reasonsReturn) {
      if (rr.model == item.model && rr.shipto == item.shipto)
        reasonsReturnValue = rr.value;
    }
 */
    const returnOrder = {
      model: item.model,
      qty: quantity,
      shipto: item.shipto,
      shiptoName: item.cust_name,
      warehouse: warehouse,
      typeReturn: typeReturnValue,
      batch: batch.batch,
      reasonsReturn: reasonsReturn,
      shiptoName: item.cust_name,
      optimum: item.optimum_national,
      current_inv: batch.current_inv,
      material_description: item.material_description,
      zfin: batch.material,
      serial: batch.serial,
      consignedDays: batch.consignedDays,
    };
    let exist = false;

    for (let item of returnList) {
      if (
        item.model == returnOrder.model &&
        item.shipto == returnOrder.shipto &&
        item.batch == returnOrder.batch &&
        item.serial == returnOrder.serial
      ) {
        exist = true;
      }
    }

    if (!exist) {
      setShowLoader(false);
      await setReturnList((prevArray) => [...prevArray, returnOrder]);
      setShowLoader(true);
    }
  };

  const handleDeleteReturnList = async (id) => {
    const auxReturnLis = [];
    setShowLoader(false);
    await returnList.map((item, index) => {
      if (id != index) auxReturnLis.push(item);
    });
    await setReturnList(auxReturnLis);
    setShowLoader(true);
  };

  const handleChangeQty = (value, item, batch, serialbatch) => {
    let IdExist = false;
    const aux = {
      model: item.model,
      shipto: item.shipto,
      batch: batch,
      serialbatch: serialbatch,
      value,
    };
    if (quantities.length > 0) {
      for (let qty of quantities) {
        if (
          qty.model == item.model &&
          qty.shipto == item.shipto &&
          qty.batch == batch &&
          qty.serialbatch == serialbatch
        ) {
          qty.value = value;
          IdExist = true;
        }
      }

      if (!IdExist) {
        setQuantities((prev) => [...prev, aux]);
      } else {
        setQuantities(quantities);
      }
    } else {
      setQuantities((prev) => [...prev, aux]);
    }
  };

  /*   const handleChangeReasonReturn = (value, item) => {
    setReturnReason(value);
    let IdExist = false;
    const aux = {
      model: item.model,
      shipto: item.shipto,
      value,
    };

    if (reasonsReturn.length > 0) {
      for (let reason of reasonsReturn) {
        if (reason.model == item.model && reason.shipto == item.shipto) {
          reason.value = value;
          IdExist = true;
        }
      }

      if (!IdExist) {
        setReasonReturn((prev) => [...prev, aux]);
      } else {
        setReasonReturn(reasonsReturn);
      }
    } else {
      setReasonReturn((prev) => [...prev, aux]);
    }
  }; */

  /*  const handleChangeWarehouse = (value, item) => {
    let IdExist = false;
    const aux = {
      model: item.model,
      shipto: item.shipto,
      value,
    };
    if (warehouse.length > 0) {
      for (let reason of warehouse) {
        if (reason.id == item.id) {
          reason.value = value;
          IdExist = true;
        }
      }

      if (!IdExist) {
        setWarehouse((prev) => [...prev, aux]);
      } else {
        setWarehouse(warehouse);
      }
    } else {
      setWarehouse((prev) => [...prev, aux]);
    }
  }; */

  const handleDescSkus = (value) => {
    setDesc(value);
  };

  const handleChangeTypeReturn = (value) => {
    setTypeReturn2(value);
    /*   let IdExist = false;
    const aux = {
      id,
      value,
    };
    if (setTypeReturn.length > 0) {
      typeReturn.map((item) => {
        if (item.id == id) {
          item.value = value;
          IdExist = true;
        }
      });
      if (!IdExist) {
        setTypeReturn((prev) => [...prev, aux]);
      } else {
        setTypeReturn(typeReturn);
      }
    } else {
      setTypeReturn((prev) => [...prev, aux]);
    } */
  };

  /*   const getValueReasonReturn = (item) => {
    let value;
    for (let reason of reasonsReturn) {
      if (reason.model == item.model && reason.shipto == item.shipto)
        value = reason.value;
    }

    return value;
  }; */

  /*   const getValueWarehouse = (item) => {
    let value;
    for (let reason of warehouse) {
      if (reason.model == item.model && reason.shipto == item.shipto)
        value = reason.value;
    }
    return value;
  };
 */
  const getValueTypeReturn = (id) => {
    let value;
    typeReturn.map((item) => {
      if (item.id == id) value = item.value;
    });
    return value;
  };

  const getValueReturn = (id) => {
    let value;
    returnList.map((item) => {
      if (item.id == id) value = item.value;
    });
    return value;
  };

  const handleConfirmReturnOrder = async () => {
    if (!typeReturn2) {
      showToast("error", "Seleccione el típo de devolución");
    } else {
      // setSelectShiptorsku("");
      const skus = [];

      if (returnList.length > 0) {
        returnList.map((item) => {
          const order = {
            shipTo: item.shipto,
            model: item.model,
            desc: item.material_description,
            optimum: item.optimum,
            current_inv: item.current_inv,
            quantity: parseInt(item.qty),
            warehouse: item.warehouse,
            reasonReturn: item.reasonsReturn,
            batch: item.batch,
            nameShipto: item.shiptoName,
            zfin: item.zfin,
            serial: item.serial,
            consignedDays: item.consignedDays,
          };

          skus.push(order);
        });
      }
      const body = {
        type_return: typeReturn2,
        observation: observation,
        skus: skus,
      };
      props.setLoaderVisible(true);
      const idRep = await getidRep(props.userToken, props.id);
      if (idRep.status == 200) {
        if (selectedPDF) {
          const type = "return";
          /*         const palabras = result.response.description.split(" ");
          const ordenCreada = palabras[palabras.length - 1]; */
          let formData = new FormData();
          formData.append("pdfFile", selectedPDF);
          const result2 = await orderPdf(formData, props.userToken, null, type);
          if (result2.status == 200) {
            const result = await createReturnOrder(
              body,
              props.userToken,
              idRep.response?.response[0]?.benefitting_Rep,
              result2.response.response.id
            );
            if (result.status == 200) {
              setShiptoandskus();
              setSelectShiptorsku("");

              setShearchItem(null);
              setValueAutocomplete(null);
              setValueAutocompleteTypeReturn(null);
              setValueAutocompleteReasonReturn(null);
              setValueAutocompleteWarehouse(null);
              setTypeReturn2(null);
              setSelectShiptorsku("");
              setSelectSkuDesc("");
              setSelectShiptoDesc("");
              setReasonReturn("");
              setWarehouse("");
              setReturnList([]);
              setTypeReturn([]);
              setQuantities([]);
              setObservation("");
              setSelectedPDF(null);
              setNumberPageReturn(null);

              showToast("success", result.response.description);
            } else {
              showToast("error", result.response.description);
            }
          } else if (result2.status == 413) {
            showToast(
              "error",
              "El documento adjunto supera el tamaño máximo permitido (4.49MB)"
            );
            props.setLoaderVisible(false);
          } else {
            showToast("error", result2.response.description);
          }
        } else {
          const result = await createReturnOrder(
            body,
            props.userToken,
            idRep.response?.response[0]?.benefitting_Rep
          );
          if (result.status == 200) {
            setShiptoandskus();
            setSelectShiptorsku("");

            setShearchItem(null);
            setValueAutocomplete(null);
            setValueAutocompleteTypeReturn(null);
            setValueAutocompleteReasonReturn(null);
            setValueAutocompleteWarehouse(null);
            setTypeReturn2(null);
            setSelectShiptorsku("");
            setSelectSkuDesc("");
            setSelectShiptoDesc("");
            setReasonReturn("");
            setWarehouse("");
            setReturnList([]);
            setTypeReturn([]);
            setQuantities([]);
            setObservation("");
            setSelectedPDF(null);
            setNumberPageReturn(null);

            showToast("success", result.response.description);
          } else {
            showToast("error", result.response.description);
          }
        }
      }

      props.setLoaderVisible(false);
    }
  };

  const handleGetReturnOrders = async (token) => {
    const idRep = await getidRep(props.userToken, props.id);
    props.setLoaderVisible(true);
    const result = await getReturnOrders(
      token,
      idRep.response?.response[0]?.benefitting_Rep
    );
    props.setLoaderVisible(false);
    if (result.status == 200) {
      setReturnOrders(result.response.response);
    }
  };

  const handleUpdateOrder = async (id, status, superuser) => {
    const idRep = await getidRep(props.userToken, props.id);
    props.setLoaderVisible(true);
    const result = await updateOrderStatus(
      id,
      props.userToken,
      status,
      true,
      idRep.response?.response[0]?.benefitting_Rep,
      superuser === true ? props.id : null
    );
    props.setLoaderVisible(false);
    if (result.status == 200) {
      showToast("success", result.response.description);
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } else if (result.status == 404) {
      showToast("error", result.response.description);
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } else {
      showToast("error", result.response.description);
    }
  };
  const handleChange = (e) => {
    setOrderId(e.target.value);
  };
  const handlegetXmlData = async (token) => {
    var jwt = jwtDecode(token);
    let typeOrder = "return";
    props.setLoaderVisible(true);
    const idRep = await getidRep(props.userToken, props.id);
    const result = await getXmlData(
      token,
      typeOrder,
      idRep.response.response[0].benefitting_Rep,
      jwt.groupSlug
    );
    props.setLoaderVisible(false);
    if (result.status === 200) {
      const response = result.response.response;
      setXmlData(response);
    }
  };

  const handleGenerateTemplate = async () => {
    const skus = [];

    const body = {
      sku: [
        {
          model: "value1",
          description: "value2",
          serial: "value3",
          batch: "value4",
          quantity: "value5",
          expirationdate: "value6",
          returnreason: "value7",
        },
      ],
      order_id: "value1",
      shipto: "value2",
      shipto_name: "value3",
      number_return: "value4",
      visual_inspection: "value5",
      observations: "value6",
      final_disposition: "value7",
      returner_name: "value8",
      return_date: "value9",
    };
    props.setLoaderVisible(true);

    const result = await createReturnTemplateOrder(
      body,
      "8c75f2cddb5947eea54295c2b2463936"
    );
    if (result.status == 200) {
      const response = result.response.link;
      setTemplate(response);
    }

    props.setLoaderVisible(false);
  };

  const downloadPdf = (pdfData, fileName) => {
    // Decodifica la cadena base64 a un ArrayBuffer
    const binaryData = atob(pdfData);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const byteArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      byteArray[i] = binaryData.charCodeAt(i);
    }

    // Crea un Blob a partir del ArrayBuffer
    const blob = new Blob([arrayBuffer], { type: "application/pdf" });

    // Crea un objeto URL a partir del Blob
    const url = window.URL.createObjectURL(blob);

    // Crea un elemento de anclaje invisible y configura los atributos
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = fileName; // Nombre del archivo de descarga

    // Agrega el elemento de anclaje al cuerpo del documento
    document.body.appendChild(a);

    // Simula un clic en el enlace para iniciar la descarga
    a.click();

    // Revoca el objeto URL para liberar recursos
    window.URL.revokeObjectURL(url);
  };

  return {
    shiptoandskus,
    handlegetSkus,
    handlegetAllShiptoOrSku,
    reasonCancelation,
    setReasonCancelation,
    handleAddSkuShipto,
    returnList,
    setReturnList,
    handleDeleteReturnList,
    showLoader,
    handlegetReturnReports,
    handleChangeQty,
    /*  handleChangeReasonReturn,
    getValueReasonReturn, */
    /*     handleChangeWarehouse,
    getValueWarehouse, */
    handleChangeTypeReturn,
    getValueTypeReturn,
    getValueReturn,
    handleConfirmReturnOrder,
    setToast,
    handleGetReturnOrders,
    returnOrders,
    returnReports,
    handleUpdateOrder,
    setShiptoandskus,
    handleDescSkus,
    options,
    handlegetPendingOrders,
    pendingOrders,
    returnOrderHistory,
    handlegetReturnOrderHistory,
    orderId,
    handleChange,
    handlegetXmlData,
    xmlData,
    returnreason,
    handleGetReturnProcessedOrders,
    returnOrdersProcessed,
    shearch,
    setShearchItem,
    shearchItem,
    handleGenerateTemplate,
    template,
    observation,
    handleChangeObservation,
    showToast,
    pageReturn,
    numberPageReturn,
    setPageReturn,
    setNumberPageReturn,
    valueAutocomplete,
    valueAutocompleteTypeReturn,
    valueAutocompleteReasonReturn,
    valueAutocompleteWarehouse,
    setValueAutocomplete,
    setValueAutocompleteTypeReturn,
    setValueAutocompleteReasonReturn,
    setValueAutocompleteWarehouse,
    selectShiptorsku,
    setSelectShiptorsku,
    selectSkuDesc,
    selectShiptoDesc,
    setSelectSkuDesc,
    setSelectShiptoDesc,
    setTypeReturn2,
    typeReturn2,
    jwtToken,
    disable,
    setDisable,
    numberPageReturnOrderStatus,
    setNumberPageReturnOrderStatus,
    setPageReturnOrderStatus,
    pageReturnOrderStatus,
    setReasonReturn,
    reasonsReturn,
    setWarehouse,
    warehouse,
    selectedPDF,
    setSelectedPDF,
    downloadPdf,
    pageReturnHistory,
    setPageReturnHistory,
    numberPageReturnHistory,
    handleGetMaterialGroup,
    materialGroup,
    materialGroupSelected,
    setMaterialGroupSelected,
  };
};
export default ReturnRequestController;
