import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import cookie from "js-cookie";
import { withRouter } from "react-router-dom";

// core components
import { clear, open } from "redux/actions/general";
import * as request from "redux/actions/request";
import Login from "views/Login/Login.jsx";
import * as util from "../../util/util";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import appBarStyle from "assets/jss/material-dashboard-pro-react/components/appBarStyle";
import Button from "components/CustomButtons/Button";
import logo from "assets/img/LogoAbbott.webp";
import MenuIcon from "@mui/icons-material/Menu";
import { forEach, slice } from "ramda";
import generalReducer from "../../redux/reducers/general";

class AppBarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "false",
      uri: [],
      url: "",
    };
  }

  validateUserType() {
    let type = "";
    switch (this.props.userInfo.groupSlug) {
      case "main-manager":
        type = "Main Manager";
        break;
      case "manager":
        type = "Manager";
        break;
      case "clinic-hospital":
        type = "Clinic / Hospital";
        break;
      case "clinical-specialist":
        type = "Clinical Specialist";
        break;
      case "sales-executive":
        type = "Sales Executive";
        break;
      default:
        type = "User";
        break;
    }
    return type;
  }

  clearStoreData = async () => {
    await this.props.dispatch(clear());
    cookie.remove("token");
    console.log("1x", this.props);
    this.props.history.push("/login-page");
    // window.location.reload();
  };

  openMenu = () => {
    this.props.open();
    // this.props.dispatch(open());
  };

  pathsUrl = () => {
    const url = window.location.href;
    let cont = 0;
    let urlParts = "";
    const paths = [];

    for (let x = 0; x <= url.length; x++) {
      if (url[x] === "/") {
        cont++;
        if (cont === 4) {
          for (let i = x + 1; i <= url.length; i++) {
            if (url[i] === "/" || i === url.length) {
              paths.push(`${urlParts}`);
              urlParts = "";
            } else {
              urlParts += url[i];
            }
          }
        }
      }
    }

    return paths;
  };

  componentDidMount() {
    // this.setState({uri: window.location.href.slice(32, window.location.href.length)});
    this.setState({ uri: this.pathsUrl(), url: window.location.href });
    console.log(this.state.uri);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.url !== window.location.href) {
      this.setState({ uri: this.pathsUrl(), url: window.location.href });
    }
  }

  routesText = (subUri) => {
    let urlText = "";

    for (let i = 0; i < this.state.uri.length; i++) {
      if (subUri !== this.state.uri[i]) {
        urlText += this.state.uri[i] + "/";
      } else {
        urlText += this.state.uri[i];
        i = this.state.uri.length;
      }
    }

    return (
      <span style={{ marginLeft: "0px", paddingLeft: "0px" }}>
        <a className={this.props.classes.routes} href={`/dashboard/${urlText}`}>
          {subUri}
        </a>
        <span>/</span>
      </span>
    );
  };

  render() {
    const { classes, userInfo, open } = this.props;

    return (
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 999 }}
      >
        <Toolbar className={classes.appBar}>
          <Grid container xs={12} className={classes.appBar_container}>
            <Grid item xs={2}>
              <div className={classes.logoHeader}>
                {
                  <img
                    src={logo}
                    style={{
                      position: "relative",
                      paddingTop: "5px",
                      width: "50%",
                    }}
                  />
                }
              </div>
            </Grid>
            <Grid item xs={7} className={classes.appBar_container_route}>
              <a href="/dashboard" className={classes.routes}>
                Homepage
              </a>
              <span>/</span>
              {this.state.uri.map((subUri) => this.routesText(subUri))}
              {/*<a className={classes.routes} href={`/dashboard/${this.state.uri}`}>{this.state.uri}</a>*/}
            </Grid>
            <Grid item xs={3} className={classes.appBar_container_logout}>
              <div className={classes.Appbar_User}>
                <span style={{ fontFamily: "Poppins" }}>
                  {this.validateUserType()}
                </span>
              </div>
              <Button
                color="transparent"
                onClick={this.clearStoreData}
                iconLogout
              >
                <span style={{ marginTop: 2, fontFamily: "Poppins" }}>
                  Logout
                </span>
              </Button>
            </Grid>
          </Grid>
          <div className={classes.appBar_button}>
            <Button color="transparent" onClick={this.openMenu}>
              <MenuIcon style={{ transform: "scale(2)" }} />
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

AppBarComponent.defaultProps = {
  bgColor: "red",
};

// option: hideInSideBar: false,
AppBarComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
  }))(withStyles(appBarStyle)(AppBarComponent))
);
