/* eslint-disable */
import React, {Component} from 'react'
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from '@mui/material/CircularProgress';
// assets

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
const styles = {
    preloader: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 10001,
    },
    loader: {
        display: 'block',
        position: 'relative',
        left: '45%',
        top: '40%',
        width: '100%',
        //margin: '-12.5% 0 0 -12.5%',
        //backgroundColor: "red"
    },
    blocker: {
        background: 'rgba(34,34,34,0.9)',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '200%',
        height: '200%',
        zIndex: 10000,
    }
};

class LoaderComponent extends Component {

    state = {
        loaderVisible: false,
    }

    setLoaderVisible = (bShow) => {
        this.setState({ loaderVisible: bShow });
    }

    render() {
        
        var { classes, ...rest } = this.props;
        if(this.state.loaderVisible){
            return (
                <div>
                    <div  className={classes.blocker}/>
                    <div className={classes.preloader}>
                        <div className={classes.loader}>
                          <Loader
                            type="Puff"
                            color="#00BFFF"
                            height={100}
                            width={100}                        
                          /> 
                        </div>
                      
                    </div>
                </div>
            );
        }else
            return null;
    }
}

LoaderComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(LoaderComponent);
