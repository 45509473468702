/* eslint-disable */
import React, { useTransition } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@mui/material/Grid";
import batchViewsStyle from "assets/jss/material-dashboard-pro-react/components/BatchViewStyle";
//icons
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//components
import InputForm from "components/CustomInput/InputForm";
import Select from "components/CustomInput/Select";
import Button from "components/CustomButtons/Button";

function FilterComponent(props) {
  const { classes, showFilter, setShowFilter } = props;

  return (
    <>
      <div
        className={classes.filter_container}
        onClick={() => setShowFilter(!showFilter)}
      >
        <span>Search Filter</span>
        <FontAwesomeIcon
          icon={showFilter ? faAngleUp : faAngleDown}
          style={{
            color: "grey",
            fontSize: 12,
          }}
        />
      </div>
      {showFilter ? (
        <div className={classes.filter_container_data}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <p>By Quotation Id</p>
              <InputForm optional />
            </Grid>
            <Grid item xs={12} sm={4}>
              <p>By Order Id</p>
              <InputForm optional />
            </Grid>
            <Grid item xs={12} sm={4}>
              <p>Order Status</p>
              <Select />
            </Grid>
            <Grid item xs={12} sm={4}>
              <p>Carrier Company/Contact Name</p>
              <InputForm optional />
            </Grid>
            <Grid item xs={12} sm={4}>
              <p>Origin Company/Contact Name</p>
              <InputForm optional />
            </Grid>
            <Grid item xs={12} sm={4}>
              <p>Destination Company/Contact Name</p>
              <InputForm optional />
            </Grid>
            <Grid item xs={12} sm={3}>
              <p>Origin City</p>
              <InputForm optional />
            </Grid>
            <Grid item xs={12} sm={3}>
              <p>Origin Department/State</p>
              <Select />
            </Grid>
            <Grid item xs={12} sm={3}>
              <p>Destination City</p>
              <InputForm optional />
            </Grid>
            <Grid item xs={12} sm={3}>
              <p>Destination Department/State</p>
              <Select />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>Equipment</p>
              <Select />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>Load Type</p>
              <Select />
            </Grid>
            <Grid item xs={12} sm={3}>
              <p>Pickup Date - From</p>
              <InputForm optional />
            </Grid>
            <Grid item xs={12} sm={3}>
              <p>Pickup Date - to</p>
              <InputForm optional />
            </Grid>
            <Grid item xs={12} sm={3}>
              <p>Delivery Date - From</p>
              <InputForm optional />
            </Grid>
            <Grid item xs={12} sm={3}>
              <p>Delivery Date - to</p>
              <InputForm optional />
            </Grid>
            <Grid
              item
              xs={12}
              spacing={2}
              className={classes.filter_container_btn}
            >
              <Button color="info" size="sm" style={{ marginRight: 5 }}>
                {" "}
                Search
              </Button>
              <Button size="sm" style={{ marginLeft: 5 }}>
                {" "}
                Clean
              </Button>
            </Grid>
          </Grid>
        </div>
      ) : null}
    </>
  );
}

FilterComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(batchViewsStyle)(FilterComponent);
