import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  makeStyles,
  Tooltip,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { Search, AttachFile } from "@material-ui/icons";

import OrdersRequestController from "../../../controllers/OrdersRequest/index";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Toast from "components/Toast/Toast";

const useStyles = makeStyles((theme) => ({
  containerView: {
    padding: theme.spacing(3),
    backgroundColor: "#f9f9f9",
    borderRadius: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  table: {
    minWidth: 650,
  },
  newRequest: {
    backgroundColor: "#e3f2fd",
  },
  detailButton: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  searchBox: {
    marginBottom: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
  },
  searchField: {
    width: "100%",
    marginRight: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1, 0),
    minWidth: 120,
  },
  fileInput: {
    display: "none",
  },
  fileLabel: {
    marginTop: theme.spacing(2),
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  dialogActions: {
    justifyContent: "space-between",
  },
  button: {
    margin: theme.spacing(1, 0),
  },
  productContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
    backgroundColor: "#f5f5f5",
    borderRadius: "4px",
    marginBottom: theme.spacing(1),
  },
  productText: {
    flex: 1,
  },
  dialogTypography: {
    padding: theme.spacing(1),
    borderRadius: "4px",
    backgroundColor: "#e8eaf6",
  },
  fileLabelText: {
    marginTop: theme.spacing(2),
    color: theme.palette.primary.dark,
    cursor: "pointer",
  },
  switchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
  },
  switchLabel: {
    margin: theme.spacing(0, 2),
  },
  dialogPaper: {
    height: "400px",
    minWidth: "400px",
    maxWidth: "700px",
    margin: "auto",
  },
}));

const ManageUnlockRequests = (props) => {
  const {
    handleGetClientUnlockRequest,
    clientUnlockRequest,
    handleUpdateUnlockOrderStatus,
    selectedRequest,
    setSelectedRequest,
    setDecision,
    observationCoordinator,
    setObservationCoordinator,
    unlockType,
    setUnlockType,
    handleGetDocumentsById,
    handleUpdateUnlockTransfersStatus,
    setToast,
    showToast,
  } = OrdersRequestController(props);
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [isTransfer, setIsTransfer] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [error, setError] = useState("");

  useEffect(
    () => {
      const fetchData = async () => {
        await handleGetClientUnlockRequest(props.userToken, 18, isTransfer);
      };

      if (props.userToken) {
        fetchData();
      }
    },
    [props.userToken, isTransfer]
  );

  const handleDetailClick = (request) => {
    setSelectedRequest(request);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDecision("");
    setObservationCoordinator("");
    setUnlockType("");
    setSelectedRequest(null);
    setAttachment(null);
    setError("");
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredRequests = clientUnlockRequest.filter((request) =>
    request.order_id.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleFileChange = (event) => {
    setAttachment(event.target.files[0]);
  };

  const handleSave = (status, selectedRequest, observationCoordinator) => {
    if (isTransfer === true) {
      handleUpdateUnlockTransfersStatus(
        status,
        selectedRequest,
        observationCoordinator
      );
    } else {
      handleUpdateUnlockOrderStatus(
        status,
        selectedRequest,
        observationCoordinator
      );
    }
  };
  const formatWithCommas = (value) => {
    return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div className={classes.containerView}>
      <Typography variant="h5" className={classes.title}>
        Gestión de solicitudes de desbloqueo
      </Typography>
      <Box className={classes.switchContainer}>
        <Typography className={classes.switchLabel}>
          Solicitudes de Pedidos
        </Typography>
        <Switch
          checked={isTransfer}
          onChange={() => setIsTransfer(!isTransfer)}
          color="primary"
        />
        <Typography className={classes.switchLabel}>
          Solicitudes de Traslados
        </Typography>
      </Box>
      <Box className={classes.searchBox}>
        <TextField
          className={classes.searchField}
          label="Buscar"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
          InputProps={{
            endAdornment: <Search />,
          }}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Solicitud ID</TableCell>
              <TableCell>Account Manager</TableCell>
              <TableCell>Shipto</TableCell>
              <TableCell>División</TableCell>
              <TableCell>Fecha Solicitud</TableCell>
              <TableCell>Detalle</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRequests.map((request, index) => (
              <TableRow
                key={index}
                className={request.isNew ? classes.newRequest : ""}
              >
                <TableCell>{request.order_id}</TableCell>
                <TableCell>{request.email}</TableCell>
                <TableCell>
                  {request?.receiver
                    ? request?.receiver
                    : request?.skus[0]?.name_shipto}
                </TableCell>
                <TableCell>{request?.div}</TableCell>
                <TableCell>{request.creation_date.split("T")[0]}</TableCell>
                <TableCell>
                  <Tooltip title="Ver detalle">
                    <span
                      className={classes.detailButton}
                      onClick={() => handleDetailClick(request)}
                    >
                      Ver detalle
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{ className: classes.dialogPaper }}
      >
        <DialogTitle>
          Detalle de solicitud - {selectedRequest?.order_id}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography variant="subtitle1" className={classes.dialogTypography}>
            <strong>Shipto:</strong>{" "}
            {selectedRequest?.receiver
              ? selectedRequest?.receiver
              : selectedRequest?.skus[0].name_shipto}
          </Typography>
          <Typography variant="subtitle2" color="error">
            <strong>Cliente bloqueado por cartera: </strong>
          </Typography>
          <Typography>Solicitud desbloqueo temporal:</Typography>
          <Typography variant="subtitle1" className={classes.dialogTypography}>
            <strong>Observación del Account Manager:</strong>
          </Typography>
          <TextField
            margin="dense"
            id="observationUnlock"
            type="text"
            multiline
            maxRows={2}
            fullWidth
            disabled
            value={selectedRequest?.observationUnlock || ""}
          />
          <Typography variant="subtitle2" className={classes.dialogTypography}>
            <strong>Productos solicitados:</strong>
          </Typography>
          {selectedRequest?.skus?.map((product, index) => (
            <div key={index} className={classes.productContainer}>
              <Typography className={classes.productText}>
                {product.desc}
              </Typography>
              <Typography
                className={classes.productText}
                style={{ marginLeft: "20px" }}
              >
                {product.quantity}
              </Typography>
            </div>
          ))}
          <Typography variant="subtitle2" className={classes.dialogTypography}>
            <strong>Valor estimado:</strong> $
            {formatWithCommas(selectedRequest?.estimatedValue)}
          </Typography>
          <Typography variant="subtitle1" className={classes.dialogTypography}>
            <strong>Soportes:</strong>
          </Typography>
          {selectedRequest?.documents.map((file, index) => (
            <Typography
              onClick={() =>
                handleGetDocumentsById(
                  file.document_id,
                  file.name || file.fileName
                )
              }
              key={index}
              className={classes.dialogTypography}
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                color: "#3f51b5",
              }}
            >
              {file.name || file.fileName}
            </Typography>
          ))}
          <Typography variant="subtitle2">
            <strong>Observación del Coordinador:</strong>
          </Typography>
          <TextField
            margin="dense"
            id="observaciónCoordinador"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={observationCoordinator}
            onChange={(e) => setObservationCoordinator(e.target.value)}
            helperText={`${observationCoordinator?.length}/${250}`}
            inputProps={{ maxLength: 250 }}
          />
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel id="unlockTypeLabel">Tipo de desbloqueo</InputLabel>
            <Select
              labelId="unlockTypeLabel"
              id="unlockType"
              value={unlockType}
              onChange={(e) => setUnlockType(e.target.value)}
            >
              <MenuItem value="">
                <em>Ninguno</em>
              </MenuItem>
              <MenuItem value="Temporal">Temporal</MenuItem>
              <MenuItem value="Definitivo">Definitivo</MenuItem>
            </Select>
          </FormControl>
          {error && <Typography color="error">{error}</Typography>}
          <input
            accept="application/pdf"
            className={classes.fileInput}
            id="attachment"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="attachment" className={classes.fileLabelText}>
            <Button
              variant="contained"
              color="default"
              component="span"
              startIcon={<AttachFile />}
            >
              Adjuntar PDF
            </Button>
            {attachment && (
              <Typography variant="body2">{attachment.name}</Typography>
            )}
          </label>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={handleClose}
            color="default"
            className={classes.button}
          >
            Volver
          </Button>
          <Button
            onClick={() =>
              handleSave(3, selectedRequest, observationCoordinator)
            }
            color="secondary"
            className={classes.button}
          >
            Rechazar
          </Button>
          <Button
            onClick={() =>
              handleSave(4, selectedRequest, observationCoordinator)
            }
            color="primary"
            className={classes.button}
          >
            Solicitar Más Información
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={() => {
              if (unlockType) {
                handleSave(
                  unlockType === "Temporal"
                    ? 1
                    : unlockType === "Definitivo"
                      ? 2
                      : null,
                  selectedRequest,
                  observationCoordinator
                );
              } else {
                showToast("error", "Debe seleccionar un tipo de desbloqueo");
              }
            }}
          >
            Aprobar
          </Button>
        </DialogActions>
      </Dialog>
      <Toast
        innerRef={(ref) => {
          setToast(ref);
        }}
      />
    </div>
  );
};

export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
    data: state.root.general.dataUserAPI,
    applicant: state.root.user.userInfo.firstName,
    id: state.root.user.userInfo.memberId,
    address: state.root.user.userInfo.address,
    contactNo: state.root.user.userInfo.contactNo,
  }))(ManageUnlockRequests)
);
