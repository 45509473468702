import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import LocationOnIcon from "@mui/icons-material/LocationOn";
const useStyles = makeStyles((theme) => ({
  vline: {
    borderLeft: " solid black",
    height: "30px",
  },
  headerReturnRequest: {
    display: "flex",
  },
  models: {
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },
  modelsOff: {
    display: "flex",
    justifyContent: "center",
    color: "gray",
    cursor: "pointer",
  },
  clients: {
    display: "flex",
    cursor: "pointer",
    justifyContent: "center",
  },
  clientsOff: {
    display: "flex",
    justifyContent: "center",
    color: "gray",
    cursor: "pointer",
  },
  txt: {
    fontSize: "14px",
  },
  txtOff: {
    fontSize: "14px",
    color: "gray",
  },
  Line: {
    width: "60%",
    height: "2px",
    backgroundColor: "#5DADE2",
    marginLeft: "18%",
  },
}));

export default function HeaderTransfersRequest(props) {
  const { request, setRequest } = props;
  const classes = useStyles();
  return (
    <div>
      <Grid xs={12} spacing={2} className={classes.headerReturnRequest}>
        <Grid xs={6}>
          <Grid
            xs={12}
            className={request == 1 ? classes.models : classes.modelsOff}
          >
            <ManageSearchIcon />
            <h5
              className={request == 1 ? classes.txt : classes.txtOff}
              onClick={() => setRequest(1)}
            >
              Solicitud por modelos
            </h5>
          </Grid>
          <Grid xs={12} className={classes.containerLine}>
            {request == 1 ? <div className={classes.Line}> </div> : null} <br />
          </Grid>
        </Grid>
        <div className={classes.vline} />
        <Grid xs={6}>
          <Grid
            xs={12}
            className={request == 2 ? classes.clients : classes.clientsOff}
          >
            <LocationOnIcon fontSize="small" />
            <h5
              className={request == 2 ? classes.txt : classes.txtOff}
              onClick={() => setRequest(2)}
            >
              Solicitud por clientes
            </h5>
          </Grid>
          <Grid xs={12} className={classes.containerLine}>
            {request == 2 ? <div className={classes.Line}> </div> : null} <br />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
