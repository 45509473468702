/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@mui/material/Grid";
import batchViewsStyle from "assets/jss/material-dashboard-pro-react/components/BatchViewStyle";
import InformationItem from "./InformationItem";
import ItemList from "./ItemList";
import Pagination from "@mui/material/Pagination";

function BatchListComponent(props) {
  const {
    classes,
    products,
    batch,
    transferred,
    handleChangePage,
    handlerChangeRecordsPage,
    totalPage,
    batchIncoming,
    noFilter,
    filter,
  } = props;
  const items = [
    { data: 1 },
    { data: 1 },
    { data: 1 },
    { data: 1 },
    { data: 1 },
    { data: 1 },
    { data: 1 },
    { data: 1 },
    { data: 1 },
    { data: 1 },
    { data: 1 },
    { data: 1 },
    { data: 1 },
    { data: 1 },
    { data: 1 },
    { data: 1 },
  ];

  const [showInformation, setShowInformation] = useState(false);
  const [showInformationId, setShowInformationId] = useState(null);
  return (
    <div>
      {products ? (
        <Grid style={noFilter ? null : { marginTop: 20, marginBottom: 20 }}>
          {products.map((product, key) => (
            <>
              <ItemList
                id={key}
                setShowInformation={setShowInformation}
                showInformation={showInformation}
                setShowInformationId={setShowInformationId}
                product={product}
                batch={batch}
                transferred={transferred}
                batchIncoming={batchIncoming}
              />
              {showInformation && showInformationId === key ? (
                <InformationItem
                  products={product}
                  batch={batch}
                  batchIncoming={batchIncoming}
                />
              ) : null}
            </>
          ))}
        </Grid>
      ) : (
        <Grid style={{ marginTop: 20, marginBottom: 20 }}>
          {items.map((item, key) => (
            <>
              <ItemList
                id={key}
                batch
                setShowInformation={setShowInformation}
                showInformation={showInformation}
                setShowInformationId={setShowInformationId}
              />
              {showInformation && showInformationId === key ? (
                <InformationItem />
              ) : null}
            </>
          ))}
        </Grid>
      )}
      <Grid
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "25px",
        }}
      >
        {totalPage > 1 && !filter ? (
          <Pagination
            count={totalPage}
            color="primary"
            onChange={(event, page) => {
              handleChangePage(page);
            }}
          />
        ) : null}
      </Grid>
    </div>
  );
}

BatchListComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(batchViewsStyle)(BatchListComponent);
