import React, { useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import ErrorIcon from "@mui/icons-material/Error";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";

const useStyles = makeStyles((theme) => ({
  currentInvErr: {
    color: "red",
  },
  batchs: {
    marginTop: "10px",
  },
  expirationDate: {
    color: "red",
  },
  txt: {
    fontSize: "100%",
    wordWrap: "break-word",
  },
  txtError: {
    fontSize: "100%",
    wordWrap: "break-word",
    color: "Red",
  },
  line: {
    padding: "10px",
  },
}));

const Batchitem = (props) => {
  const {
    item,
    id,
    opt,
    handleChangeQty,
    handleAddSkuShipto,
    shiptosorskus,
    // getValueReasonReturn,
    // getValueWarehouse,
    index,
    reasonsReturn,
    warehouse,
  } = props;

  const [amount, setAmount] = useState(0);
  const classes = useStyles();

  const handleChange2 = (e) => {
    setAmount(e.target.value);
    handleChangeQty(e.target.value, shiptosorskus, item.batch, item.serial);
  };

  return (
    <Grid container xs={12} className={classes.line}>
      <Grid container xs={12} sm={10} className={classes.batchs} key={id}>
        <Grid xs={2}>
          <h6>Lote</h6>
          <b>
            <h8>{item.batch}</h8>
          </b>
        </Grid>
        <Grid xs={2}>
          <h6>Serial</h6>
          <b>
            <h8>{item.serial}</h8>
          </b>
        </Grid>
        {!item.closeExpiration ? (
          <Grid xs={2}>
            <h6>Fecha de vencimiento</h6>
            <b>
              <h8>{item.expirationDate}</h8>
            </b>
          </Grid>
        ) : (
          <Grid xs={2}>
            <h6>Fecha de vencimiento</h6>
            <b>
              <h8 className={classes.expirationDate}>{item.expirationDate}</h8>
              <ErrorIcon fontSize="small" color="error" />
            </b>
          </Grid>
        )}
        {item.consignedDays < 45 ? (
          <Grid item xs={2}>
            <h6>Dias en consignación</h6>
            <b>
              <h5 className={classes.txt}>{item.consignedDays}</h5>
            </b>
          </Grid>
        ) : (
          <Grid item xs={2}>
            <h6>Dias en consignación</h6>
            <b>
              <h5 className={classes.txtError}>
                {item.consignedDays}
                <ErrorIcon fontSize="small" color="error" />
              </h5>
            </b>
          </Grid>
        )}

        {item.current_inv > opt ? (
          <Grid xs={2}>
            <h6>Inventario Actual</h6>
            <b>
              <h8 className={classes.currentInvErr}>
                {item.current_inv} <ErrorIcon fontSize="small" color="error" />
              </h8>
            </b>{" "}
          </Grid>
        ) : (
          <Grid xs={2}>
            <h6>Inventario Actual</h6>
            <b>
              <h8>{item.current_inv}</h8>
            </b>{" "}
          </Grid>
        )}
      </Grid>
      <Grid xs={12} sm={2}>
        <TextField
          key={id}
          id={id}
          label="Cantidad"
          variant="outlined"
          type="number"
          //   color="primary"
          InputProps={{
            inputProps: {
              min: 1,
              max: item.current_inv,
              style: {
                height: "0.5em",
              },
            },
          }}
          onChange={handleChange2}
          sx={{
            backgroundColor: "white",
            marginBottom: "5px",
            "@media (max-Width: 960px)": {
              fontSize: "1vh",
            },
          }}
          error={item.current_inv < amount ? true : null}
          color={item.current_inv < amount ? "error" : "primary"}
          helperText={
            item.current_inv < amount
              ? "Error su pedido supera el inventario actual"
              : null
          }
        />

        <Button
          key={id}
          fullWidth
          variant="contained"
          disabled={
            !amount
              ? true
              : amount <= 0
                ? true
                : item.current_inv < amount
                  ? true
                  : reasonsReturn != "" &&
                    reasonsReturn != null &&
                    warehouse != "" &&
                    warehouse != null
                    ? false
                    : true
          }
          onClick={() => {
            handleAddSkuShipto(shiptosorskus, item, id);
          }}
          sx={{
            fontSize: "1.5vh",
            "@media (max-Width: 960px)": {
              fontSize: "1vh",
              padding: 0,
            },
          }}
        >
          Recoger
        </Button>
      </Grid>
    </Grid>
  );
};

export default Batchitem;
