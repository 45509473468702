import * as user from "./user";
import * as util from "../../util/util";
import * as ajax from "../../protocols/requests";

/**
 * This is a common request which must include in each request.
 */
const commonRequest = () => {
  return (dispatch, getState) => {
    const root = getState().root;
  };
};

export const login = (username, password, codeToken) => {
  return async (dispatch, getState) => {
    commonRequest();
    const root = getState().root;
    const { userToken } = root.user;
    let result = await ajax.login(username, password, codeToken);

    if (result.status === 200) {
      const token = result.response.response.token;
      const member = result.response.response.member;
      dispatch(user.updateUserToken(token));
      dispatch(user.updateuserInfo(member));
      return { data: result.response.response };
    } else {
      result.error = true;
      return result;
    }
  };
};

// important !!! required for login
export const getUserDataToken = () => {
  return async (dispatch, getState) => {
    commonRequest();

    const root = getState().root;
    const { userToken } = root.user;
    var decoded = util.decodeToken(userToken);
    dispatch(user.updateuserInfoToken(decoded));
    return decoded;
  };
};
